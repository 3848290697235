import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    displayName: null,
    email: null,
    photoURL: null,
    isAuthenticated: false,
  },
  reducers: {
    login: (state, action) => {
      state.displayName = action.payload.displayName;
      state.email = action.payload.email;
      state.photoURL = action.payload.photoURL;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.displayName = null;
      state.email = null;
      state.photoURL = null;
      state.isAuthenticated = false;
    },
  },
});

export const { login, logout } = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
