import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTheme } from "./ThemeContext";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "./Spinner";
import PageHeader from "./PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faEnvelope,
  faCheck,
  faEdit,
  faTrash,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import MainTableAdminInvoices from "./MainTableAdminInvoices";
import EditInvoiceModal from "./EditInvoiceModal"
import ConfirmDeleteModal from './ConfirmDeleteModal';

const AccountingAdmin = () => {
  const { theme } = useTheme();
  const primaryColor = theme.primary;
  const [invoiceData, setInvoiceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const { currentUser } = useAuth();
  const [selectedRows, setSelectedRows] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [originalInvoiceData, setOriginalInvoiceData] = useState([]);
  const [invoiceSearchQuery, setInvoiceSearchQuery] = useState("");
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const policies = useSelector((state) => state.policies.policies);
  const [policyNumber, setPolicyNumber] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailToSend, setEmailToSend] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSendSuccess, setEmailSendSuccess] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedInvoiceForEdit, setSelectedInvoiceForEdit] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [sortState, setSortState] = useState([{ id: 'TransactionDate', desc: true }]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const dropdownRef = useRef(null);
  const [outstandingAmount, setOutstandingAmount] = useState(0);
const [paidLast30Days, setPaidLast30Days] = useState(0);
const [openInvoicesCount, setOpenInvoicesCount] = useState(0);
const [averageInvoiceAmount, setAverageInvoiceAmount] = useState(0);

  const clearAlert = () => setAlertMessage(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://fuellink-db-ui.azurewebsites.net/api/getAdminInvoicesFinTrans?",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: currentUser?.userId }),
        }
      );

      if (response.ok) {
        const fetchedData = await response.json();
        setInvoiceData(fetchedData.invoices);
        setOriginalInvoiceData(fetchedData.invoices); // Store original data for filtering        
      } else {
        setAlertMessage(`Server Error: ${await response.text()}`);
      }
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  

  const onViewPdf = (invoiceDetails) => {
    navigate("/pdf-viewer", {
      state: {
        invoiceNumber: invoiceDetails.InvoiceID,
        invoiceDate: invoiceDetails.InvoiceDate,
        transactionId: invoiceDetails.TransactionID,
        InvoiceStatus: invoiceDetails.InvoiceStatus,
        invoiceAmount: invoiceDetails.RemainingAmount,
        TransactionIdRef: invoiceDetails.TransactionIdRef,
      },
    });
  };

  const toggleDropdown = (rowId, event) => {
    console.log(
      "Toggling Dropdown for Row:",
      rowId,
      "Current State:",
      openDropdown
    );
    if (openDropdown === rowId) {
      setOpenDropdown(null); 
    } else {
      setOpenDropdown(rowId); 
      // Delay the position calculation until after the dropdown is rendered
      setTimeout(() => {
        if (dropdownRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const spaceBelow = window.innerHeight - dropdownRect.bottom;
            const spaceAbove = dropdownRect.top;
            const dropdownHeight = 200;

            dropdownRef.current.style.bottom = "auto";
        dropdownRef.current.style.top = "100%";

        // If not enough space below, but more space above, render upwards
        if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
          dropdownRef.current.style.bottom = "100%";
          dropdownRef.current.style.top = "auto";
        }

        dropdownRef.current.style.maxHeight = `${dropdownHeight}px`;
        dropdownRef.current.style.overflowY = "auto";
        }
    }, 0); // A timeout of 0 ms delays the execution until after the browser has rendered the dropdown
    }
  };

  const selectEmailInvoice = (invoiceDetails) => {
    setSelectedInvoice(invoiceDetails); 
    setShowEmailModal(true); 
  };

  const onEmailInvoice = async (invoiceDetails, recipientEmail) => {
    setIsLoading(true);
    const apiUrl = "https://fuellink-db-ui.azurewebsites.net/api/generateInvoicePdf?";
  
    const requestData = {
      TenantId: currentUser.tenantId,
      TransactionId: invoiceDetails.TransactionID,
      PolicyNumber: invoiceDetails.PolicyNumber,
      InvoiceNumber: invoiceDetails.InvoiceID,
      InvoiceDate: invoiceDetails.InvoiceDate,
      InvoiceStatus: invoiceDetails.InvoiceStatus,
      TransactionIdRef: invoiceDetails.TransactionIdRef,
      email: recipientEmail,
      isEmail: true,
    };
  
    console.log("Request Data: ", requestData);
  
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const contentType = response.headers.get("Content-Type");
      let result;
      if (contentType && contentType.includes("application/json")) {
        result = await response.json();
      } else {
        result = await response.text();
      }
  
      console.log("Email request sent successfully.");
      setIsLoading(false);
      setShowEmailModal(false);
      setOpenDropdown(null);
      setAlertMessage("Successfully Sent The Invoice!");
      return result;
      
    } catch (error) {
      setIsLoading(false);
      setShowEmailModal(false);
      setOpenDropdown(null);
      setAlertMessage(`Error: ${error.message || 'Failed to send email'}`);
      console.error("Error:", error);
    }
  };
  

  const closeEmailModal = () => {
    setShowEmailModal(false);
    setEmailToSend('');
    setEmailSendSuccess(false);
  };

  const onMarkAsPaid = async (invoiceDetails) => {
    setIsLoading(true);
  
    const apiUrl = "https://fuellink-db-ui.azurewebsites.net/api/updateInvoiceStatus?";
  
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: currentUser.userId,
          invoiceUpdates: [{ InvoiceID: invoiceDetails.InvoiceID, Status: "Paid" }]
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const contentType = response.headers.get("Content-Type");
      let result;
      if (contentType && contentType.includes("application/json")) {
        result = await response.json();
      } else {
        result = await response.text();
      }

      await fetchData();
  
      console.log("Invoice marked as paid successfully.", result);
      setAlertMessage("Successfully marked invoice as paid!");
    } catch (error) {
      console.error("Error marking invoice as paid:", error);
      setAlertMessage(`Error: ${error.message || 'Failed to update invoice status'}`);
    } finally {
      setIsLoading(false);
      setOpenDropdown(null);
    }
  };
  

//   const markSelectedAsPaid = async () => {
//     setIsLoading(true);
  
//     const apiUrl = "https://fuellink-db-ui.azurewebsites.net/api/updateInvoiceStatus"; 
//     try {
//       const invoiceUpdates = selectedRows.map(rowId => {
//         const invoice = invoiceData.find(inv => inv.id === rowId);
//         return { InvoiceID: invoice.InvoiceID, Status: "Paid" };
//       });
  
//       const response = await fetch(apiUrl, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//           userId: currentUser.userId,
//           invoiceUpdates
//         }),
//       });
  
//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
  
//       const result = await response.json();
//       console.log("Selected invoices marked as paid successfully.");
//       setAlertMessage("Selected invoices marked as paid!");
//     } catch (error) {
//       console.error("Error marking selected invoices as paid:", error);
//       setAlertMessage(`Error: ${error.message}`);
//     } finally {
//       setIsLoading(false);
//     }
//   };
  

const onEditInvoice = (invoiceDetails) => {
    setSelectedInvoiceForEdit(invoiceDetails);
    setShowEditModal(true);
  };


  const onDeleteInvoice = (invoiceDetails) => {
    setInvoiceToDelete(invoiceDetails);
    setShowDeleteModal(true);
  };

  const handleDeleteInvoice = async (invoice) => {
    // Call to Azure Function to delete the invoice
    setIsLoading(true);
    try {
        const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/deleteInvoiceAdmin?', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ invoiceId: invoice.InvoiceID, userId: currentUser.userId }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        console.log("Successfully deleted the invoice!.");
        setAlertMessage("Successfully deleted the invoice!.");
        fetchData(); 
    } catch (error) {
        console.error("Error deleting invoice:", error);
        setAlertMessage(`Error deleting invoice: ${error.message}`);
    } finally {
        setIsLoading(false);
        setShowDeleteModal(false);
        setInvoiceToDelete(null);
        setOpenDropdown(null);
    }
};


  useEffect(() => {
    fetchData();
  }, []);

  const mapCompanyNameToInvoices = (invoices, policies) => {
    return invoices.map((invoice) => {
      const policy = policies.find(
        (p) => Number(p.PolicyNumber) === Number(invoice.PolicyNumber)
      );

      const companyNameOrDescription = policy
        ? policy.CompanyName || policy.Description
        : "Unknown";

      return { ...invoice, CompanyName: companyNameOrDescription };
    });
  };

  const mappedInvoices = mapCompanyNameToInvoices(invoiceData, policies);
  console.log("mapped Invoices: ", mappedInvoices);

  const applyFilters = () => {
    let filteredData = [...originalInvoiceData]; // Start with the original data

    if (startDate) {
      filteredData = filteredData.filter((invoice) => {
        const invoiceDate = new Date(invoice.InvoiceDate);
        return invoiceDate >= new Date(startDate);
      });
    }

    if (endDate) {
      filteredData = filteredData.filter((invoice) => {
        const invoiceDate = new Date(invoice.InvoiceDate);
        return invoiceDate <= new Date(endDate);
      });
    }

    if (invoiceStatus && invoiceStatus !== "") {
      filteredData = filteredData.filter(
        (invoice) => invoice.InvoiceStatus === invoiceStatus
      );
    }

    if (policyNumber && policyNumber !== "") {
      filteredData = filteredData.filter(
        (invoice) => Number(invoice.PolicyNumber) === Number(policyNumber)
      );
    }

    setInvoiceData(filteredData);
    setCurrentPage(0);
  };

  const dropdownOptions = policies.map((policy) => {
    const displayText = `${policy.PolicyNumber} - ${
      policy.CompanyName || policy.Description
    }`;
    return { label: displayText, value: policy.PolicyNumber };
  });

  const handleDropdownChange = (e) => {
    const selectedPolicyNumber = e.target.value;
    setPolicyNumber(selectedPolicyNumber);
  };
  
    const handleSaveInvoice = async (updatedInvoice) => {
        setIsLoading(true);        
            try {
                // Adjust dates to local timezone
                const formatDateToUTC = (dateString) => {
                    if (!dateString) return null;
                    const date = new Date(dateString);
                    // Convert to UTC
                    const year = date.getUTCFullYear();
                    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
                    const day = date.getUTCDate().toString().padStart(2, '0');
                    return `${year}-${month}-${day}T00:00:00.0000000-06:00`;
                };
        
                // Format InvoiceDate and DueDate
                updatedInvoice.InvoiceDate = formatDateToUTC(updatedInvoice.InvoiceDate);
                updatedInvoice.DueDate = formatDateToUTC(updatedInvoice.DueDate);
                console.log("Updated Invoice: ", updatedInvoice);
            console.log("Updated Invoice: ",updatedInvoice);
            const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/updateInvoiceAdmin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: currentUser.userId, 
                    invoiceData: updatedInvoice,
                }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const contentType = response.headers.get("Content-Type");
            let result;
    
            if (contentType && contentType.includes("application/json")) {
                result = await response.json();
                console.log("Invoice updated successfully:", result);                                
            } else {
                result = await response.text();
                console.log("Response received:", result);               
            }
            await fetchData();    
            setShowEditModal(false);
            setIsLoading(false);
            setOpenDropdown(null);
            setAlertMessage("Successfully updated the invoice!");
        } catch (error) {
            console.error("Error updating invoice:", error);
            setAlertMessage(`Error: ${error.message}`);
            setIsLoading(false);
            setOpenDropdown(null);
        }
    };

    const calculateInvoiceMetrics = useMemo(() => {
        let outstanding = 0;
        let paidLast30 = 0;
        let openInvoices = 0;
        let totalInvoiceAmt = 0;
      
        const currentDate = new Date();
        const thirtyDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 30));
      
        invoiceData.forEach((invoice) => {
          const invoiceDate = new Date(invoice.InvoiceDate);
      
          // Calculate Outstanding Amount
          if (invoice.InvoiceStatus === 'Open' || invoice.InvoiceStatus === 'Overdue') {
            outstanding += parseFloat(invoice.RemainingAmount);
          }
      
          // Calculate Paid in Last 30 Days
          if (invoice.InvoiceStatus === 'Paid' && invoiceDate >= thirtyDaysAgo) {
            paidLast30 += parseFloat(invoice.RemainingAmount);
          }
      
          // Count Open Invoices
          if (invoice.InvoiceStatus === 'Open') {
            openInvoices += 1;
          }
      
          // Sum up all invoice amounts
          totalInvoiceAmt += parseFloat(invoice.RemainingAmount);
        });
      
        // Set state variables
        setOutstandingAmount(outstanding);
        setPaidLast30Days(paidLast30);
        setOpenInvoicesCount(openInvoices);
        setAverageInvoiceAmount(invoiceData.length > 0 ? totalInvoiceAmt / invoiceData.length : 0);
      
      }, [invoiceData]);
      
      
    

  const invoiceColumns = useMemo(
    () => [
      // {
      //   Header: () => (
      //     <input
      //       type="checkbox"
      //       checked={selectAll}
      //       onChange={() => setSelectAll(!selectAll)}
      //     />
      //   ),
      //   id: "selection",
      //   Cell: ({ row }) => (
      //     <input
      //       type="checkbox"
      //       checked={selectedRows.includes(row.id)}
      //       onChange={() => {
      //         const updatedSelectedRows = [...selectedRows];
      //         if (selectedRows.includes(row.id)) {
      //           const index = updatedSelectedRows.indexOf(row.id);
      //           updatedSelectedRows.splice(index, 1);
      //         } else {
      //           updatedSelectedRows.push(row.id);
      //         }
      //         setSelectedRows(updatedSelectedRows);
      //         // Update selectAll state if necessary
      //         if (updatedSelectedRows.length === invoiceData.length) {
      //           setSelectAll(true);
      //         } else {
      //           setSelectAll(false);
      //         }
      //       }}
      //     />
      //   ),
      // },
      { Header: "Invoice Number", accessor: "InvoiceID" },
      { Header: "Company Name", accessor: "CompanyName" },
      {
        Header: "Invoice Date",
        accessor: "InvoiceDate",
        Cell: ({ value }) =>
          value ? new Date(value).toLocaleDateString() : "N/A",
      },    
      {
        Header: "Due Date",
        accessor: "DueDate",
        Cell: ({ value }) =>
          value ? new Date(value).toLocaleDateString() : "N/A",
      },
      {
        Header: "Amount",
        accessor: "RemainingAmount",
        Cell: ({ value }) => `$${parseFloat(value).toFixed(2)}`, // Format value as currency
      },
      {
        Header: "Invoice Status",
        accessor: "InvoiceStatus",
        Cell: ({ value }) => (
          <span
            className={`px-3 py-1 rounded-full text-sm font-semibold ${
              value === "Paid"
                ? "bg-green-200 text-green-800"
                : value === "Open"
                ? "bg-yellow-200 text-yellow-800"
                : value === "Outstanding"
                ? "bg-red-200 text-red-800"
                : "bg-gray-200 text-gray-800"
            }`}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="relative inline-block text-left">
            <button
              onClick={(e) => toggleDropdown(row.id, e)}
              className="inline-flex w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
            >
              <FontAwesomeIcon icon={faEllipsisH} />
            </button>
            {openDropdown === row.id &&
              (console.log("Rendering Dropdown for Row:", row.id),
              (
                <div
                  ref={dropdownRef}
                  className="dropdownAdminInvoices z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    <button
                      onClick={() => onViewPdf(row.original)}
                      className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    >
                      <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                      View PDF
                    </button>
                    <button
                      onClick={() => selectEmailInvoice(row.original)}
                      className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    >
                      <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                      Email Invoice
                    </button>
                    <button
                      onClick={() => onMarkAsPaid(row.original)}
                      className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    >
                      <FontAwesomeIcon icon={faCheck} className="mr-2" />
                      Mark As Paid
                    </button>
                    <button
                      onClick={() => onEditInvoice(row.original)}
                      className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    >
                      <FontAwesomeIcon icon={faEdit} className="mr-2" />
                      Edit Invoice
                    </button>
                    <button
                      onClick={() => onDeleteInvoice(row.original)}
                      className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2" />
                      Delete Invoice
                    </button>
                  </div>
                </div>
              ))}
          </div>
        ),
      },
    ],
    [selectedRows, openDropdown]
  );

  return (
    <>   
    {showDeleteModal && (
            <ConfirmDeleteModal
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleDeleteInvoice}
                invoice={invoiceToDelete}
            />
        )}
    {showEditModal && (
  <EditInvoiceModal
    invoice={selectedInvoiceForEdit}
    onClose={() => setShowEditModal(false)}
    onSave={handleSaveInvoice}
  />
)}
    {showEmailModal && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center modal">
    <div className="bg-white p-5 rounded-lg shadow-lg relative modal-content">
      <button
        onClick={closeEmailModal}
        className="absolute top-0 right-0 mt-2 mr-2 text-lg font-semibold text-gray-700 hover:text-gray-900"
      >
        &times; {/* This represents the 'X' character */}
      </button>
      <h2 className="text-lg font-bold mb-4">Please Enter an Email:</h2>
      <input
        type="email"
        value={emailToSend}
        onChange={(e) => setEmailToSend(e.target.value)}
        placeholder="Enter recipient's email"
        className="border p-2 rounded w-full mb-4"
        required
      />
      <button
        onClick={() => onEmailInvoice(selectedInvoice, emailToSend)}
        className="themed-button rounded w-full"
      >
        Send
      </button>
    </div>
  </div>
)}


      {isLoading && <Spinner />}
      <div>
        <PageHeader
          heading="Manage Invoices"
          context="View and edit invoices."
        />
        {/* Cards for Invoice Insights */}
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 my-4">

{/* Outstanding Amount Card */}
<div className="bg-white p-4 rounded-lg shadow-md">
  <h3 className="text-lg font-semibold text-gray-700">Outstanding Amount:</h3>
  <p className="text-red-500">${outstandingAmount.toFixed(2)}</p>
</div>

{/* Paid in Last 30 Days Card */}
<div className="bg-white p-4 rounded-lg shadow-md">
  <h3 className="text-lg font-semibold text-gray-700">Paid in Last 30 Days:</h3>
  <p className="text-green-500">${paidLast30Days.toFixed(2)}</p>
</div>

{/* Open Invoices Card */}
<div className="bg-white p-4 rounded-lg shadow-md">
  <h3 className="text-lg font-semibold text-gray-700">Open Invoices:</h3>
  <p className="text-blue-500">{openInvoicesCount}</p>
</div>

{/* Average Invoice Amount Card */}
<div className="bg-white p-4 rounded-lg shadow-md">
  <h3 className="text-lg font-semibold text-gray-700">Average Invoice Amount:</h3>
  <p className="text-purple-500">${averageInvoiceAmount.toFixed(2)}</p>
</div>
</div>

        <div className="tab-content">
          {loading && <Spinner />}
          <div className="filters-pane flex justify-between items-center p-4 bg-gray-300 rounded my-2">
            <div className="filter-items flex space-x-4">
              <label className="flex flex-col">
                <span className="text-sm font-semibold">Start Date:</span>
                <input
                  type="date"
                  value={startDate || ""}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="p-2 rounded border"
                />
              </label>
              <label className="flex flex-col">
                <span className="text-sm font-semibold">End Date:</span>
                <input
                  type="date"
                  value={endDate || ""}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="p-2 rounded border"
                />
              </label>
              <label className="flex flex-col">
                <span className="text-sm font-semibold">Invoice Status:</span>
                <select
                  value={invoiceStatus}
                  onChange={(e) => setInvoiceStatus(e.target.value)}
                  className="p-2 rounded border"
                >
                  <option value="">All</option>
                  <option value="Open">Open</option>
                  <option value="Overdue">Overdue</option>
                  <option value="Paid">Paid</option>
                </select>
              </label>
              <label className="flex flex-col">
                <span className="text-sm font-semibold">Policy Number:</span>
                <select
                  className="p-2 rounded border"
                  onChange={handleDropdownChange}
                  value={policyNumber}
                >
                  <option value="">All Policies</option>
                  {dropdownOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <button className="themed-button" onClick={applyFilters}>
              Apply Filters
            </button>
          </div>
                    
          <MainTableAdminInvoices
            columns={invoiceColumns}
            data={mappedInvoices}
            primaryColor={primaryColor}
            loading={loading}
            alertMessage={alertMessage}
            clearAlert={clearAlert}
            title="Invoices"
            searchQuery={invoiceSearchQuery}
            setSearchQuery={setInvoiceSearchQuery}
            onViewPdf={onViewPdf}
            onEmailInvoice={onEmailInvoice}
            onMarkAsPaid={onMarkAsPaid}
            onEditInvoice={onEditInvoice}
            onDeleteInvoice={onDeleteInvoice}
            openDropdown={openDropdown}
            toggleDropdown={toggleDropdown}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sortState={sortState}
            setSortState={setSortState}
          >
            {/* <button className="themed-button" onClick={markSelectedAsPaid}>
  Mark All As Paid
</button> */}
            </MainTableAdminInvoices>            
        </div>
      </div>
    </>
  );
};

export default AccountingAdmin;
