import React, { useState } from 'react';
import Card from './Card';

const TransactionCountCard = ({ data, theme }) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState('Last 30 Days');

  const timeRanges = ['Last 30 Days', 'Last 14 Days', 'Last 7 Days'];

  const handleTimeRangeChange = (e) => {
    setSelectedTimeRange(e.target.value);
  };

  const getTransactionCount = () => {
    if (!data) {
      return 0;
    }
    let count;
    switch (selectedTimeRange) {
      case 'Last 7 Days':
        count = data['Transaction Count Last 7 Days'];
        break;
      case 'Last 14 Days':
        count = data['Transaction Count Last 14 Days'];
        break;
      case 'Last 30 Days':
        count = data['Transaction Count Last 30 Days'];
        break;
      default:
        count = 0;
        break;
    }
    return count !== undefined && count !== null ? count : 0;
  };

  const transactionCount = getTransactionCount();

  return (
    <Card title="Number of Transactions" subtitle={selectedTimeRange} theme={theme}>
      <div className="p-2 rounded-lg shadow-lg text-center" style={{ backgroundColor: theme.primary }}>
        <select 
          id="timeRange" 
          onChange={handleTimeRangeChange} 
          value={selectedTimeRange}
          className="ml-2 p-1 rounded border"
        >
          {timeRanges.map((range) => (
            <option key={range} value={range}>
              {range}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-6 text-center">
      <h2 className="text-2xl font-bold mt-4">
          {transactionCount !== null ? transactionCount : 'Loading...'}
        </h2>        
      </div>
    </Card>
  );
};

export default TransactionCountCard;
