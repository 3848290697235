const defaultTheme = {
  primary: '#F16528',
  secondary: '#055fe7',
  primaryText: '#000000', // default primary text color
  secondaryText: '#ffffff', // default secondary text color
  BtnTxtPrimaryColor: '#ffffff',
  BtnTxtHoverColor: '#ffffff',
  menuText: '#000',
  tenantId: 'Go-Transport-LLC-o5bl2',
  logo: '/logos/fuellink-temp.png',
  favicon: '/logos/fuellinkfav.ico',
  pageTitle: 'FuelLink - Fuel Solutions',
  markerIcon: '',
  loginPrimaryBG: '#F16528',
  loginBtnPrimary: '#055fe7',
  loginBtnHover: '#033dab',
  loginImage: '/logos/fuellink-temp.png',
  logoSize: 'medium',
  onBehalfOf: '',
  carrierPortal: false,
  hasGasLimit: false,
};
const themes = {
  'gofuel.fuellink.net': { //Go Fuel white label
    primary: '#74cc10',
    secondary: '#000000',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/Go Fuel Network logo.png',
    favicon: '/logos/gofuelfav.ico',
    pageTitle: 'Go Fuel - Fuel Solutions',
    markerIcon: '',
    loginPrimaryBG: '#74cc10',
    loginBtnPrimary: '#74cc10',
    loginBtnHover: '#000000',
    loginImage: '/logos/Go Fuel Network logo.png',
    logoSize: 'medium',
    onBehalfOf: '',
    carrierPortal: false,
    hasGasLimit: false,
  },
  'fbt.fuellink.net': { //FBT
    primary: '#000000',
    secondary: '#f16528',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/FBT_Black_20.png',
    favicon: '/logos/favicon_fbt.ico',
    pageTitle: 'FBT Fuel',
    markerIcon: '',
    loginPrimaryBG: '#000000',
    loginBtnPrimary: '#000000',
    loginBtnHover: '#f28352',
    loginImage: '/logos/FBT_Black_20.png',
    logoSize: 'medium',
    onBehalfOf: '',
    carrierPortal: true,
    hasGasLimit: false,
  },
  '208moving.fuellink.net': { //208 Moving
    primary: '#7ac142',
    secondary: '#000000',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#bec0c2',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/208moving_logo.png',
    favicon: '/logos/208favicon.png',
    pageTitle: '208 Moving - Fuel',
    markerIcon: '',
    loginPrimaryBG: '#000000',
    loginBtnPrimary: '#7ac142',
    loginBtnHover: '#bec0c2',
    loginImage: '/logos/208moving_logo.png',
    logoSize: 'large',
    onBehalfOf: '',
    carrierPortal: true,
    hasGasLimit: true,
  },
  // 'gofuel.fuellink.net': { //Go Fuel non-white-label
  //   primary: '#F16528',
  //   secondary: '#055fe7',
  //   primaryText: '#000000',
  //   secondaryText: '#ffffff',
  //   BtnTxtPrimaryColor: '#ffffff',
  //   BtnTxtHoverColor: '#ffffff',
  //   menuText: '#000',
  //   tenantId: 'Go-Transport-LLC-o5bl2',
  //   logo: '/logos/fuellink-temp.png',
  //   favicon: '/logos/fuellinkfav.ico',
  //   pageTitle: 'FuelLink - Fuel Solutions',
  //   markerIcon: '',
  //   loginPrimaryBG: '#F16528',
  //   loginBtnPrimary: '#055fe7',
  //   loginBtnHover: '#033dab',
  //   loginImage: '/logos/fuellink-temp.png',
  //   logoSize: 'medium',
  //   onBehalfOf: 'Go Fuel',
  // },
  'ecapital.fuellink.net': { //eCapital whitelabel
    primary: '#5e707e',
    secondary: '#f7901e',
    primaryText: '#ffffff',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/ecapital_logo.png',
    favicon: '/logos/ecapital_favicon.webp',
    pageTitle: 'eCapital Fuel',
    markerIcon: '',
    loginPrimaryBG: '#5e707e',
    loginBtnPrimary: '#5e707e',
    loginBtnHover: '#f7901e',
    loginImage: '/logos/ecapital_logo.png',
    logoSize: 'medium',
    onBehalfOf: '',
    carrierPortal: true,
    hasGasLimit: false,
  },
  '1235456465': { //ecapital non-white-label
    primary: '#F16528',
    secondary: '#055fe7',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/fuellink-temp.png',
    favicon: '/logos/fuellinkfav.ico',
    pageTitle: 'FuelLink - Fuel Solutions',
    markerIcon: '',
    loginPrimaryBG: '#F16528',
    loginBtnPrimary: '#055fe7',
    loginBtnHover: '#033dab',
    loginImage: '/logos/fuellink-temp.png',
    logoSize: 'medium',
    onBehalfOf: 'eCapital',
    carrierPortal: false,
    hasGasLimit: false,
  },
  'intellitrux.fuellink.net': { //Intellitrux Fuel
    primary: '#005DA9',
    secondary: '#F16528',
    primaryText: '#ffffff',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/intellitrux_fuel.png',
    favicon: '/logos/intellitrux_favicon.ico',
    pageTitle: 'INTELLITRUX™ Fuel',
    markerIcon: '',
    loginPrimaryBG: '#005DA9',
    loginBtnPrimary: '#005DA9',
    loginBtnHover: '#F16528',
    loginImage: '/logos/intellitrux_fuel.png',
    logoSize: 'medium',
    onBehalfOf: '',
    carrierPortal: false,
    hasGasLimit: false,
  },
  'integrity.fuellink.net': { //Integrity Factoring
    primary: '#1f365c',
    secondary: '#f7901e',
    primaryText: '#ffffff',
    secondaryText: '#000000',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#000000',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/integrity_factoring_1.png',
    favicon: '/logos/integrity_fav.png',
    pageTitle: 'Integrity Factoring Fuel',
    markerIcon: '',
    loginPrimaryBG: '#1f365c',
    loginBtnPrimary: '#1f365c',
    loginBtnHover: '#f7901e',
    loginImage: '/logos/integrity_login_banner.png',
    logoSize: 'medium',
    onBehalfOf: '',
    carrierPortal: false,
    hasGasLimit: false,
  },
  'aqha.fuellink.net': { //AQHA
    primary: '#6284a9',
    secondary: '#cd001a',
    primaryText: '#ffffff',
    secondaryText: '#000000',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/aqha_logo_3.png',
    favicon: '/logos/aqha_favicon.ico',
    pageTitle: 'AQHA Fuel - AQHA',
    markerIcon: '/logos/aqha_marker_2.png',
    loginPrimaryBG: '#c1a481',
    loginBtnPrimary: '#ec1e27',
    loginBtnHover: '#b50015',
    loginImage: '/logos/aqha_login_banner.png',
    logoSize: 'large',
    onBehalfOf: '',
    carrierPortal: false,
    hasGasLimit: false,
  },
  'echevarria.fuellink.net': { //Echevarria Trucking
    primary: '#000000',
    secondary: '#aeaeae',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#000000',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/echevarria_logo.png',
    favicon: '/logos/fuellinkfav.ico',
    pageTitle: 'Echevarria Trucking - Fuel',
    loginPrimaryBG: '#1b1b1b',
    loginBtnPrimary: '#000000',
    loginBtnHover: '#aeaeae',
    loginImage: '/logos/echevarria_logo.png',
    logoSize: 'medium',
    onBehalfOf: '',
    carrierPortal: true,
    hasGasLimit: false,
  },
  'ocalhost': { //Power Funding
    primary: '#005027',
    secondary: '#99b9a9',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#000000',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/power-funding-logo.svg',
    favicon: 'https://powerfundingltd.com/favicon-32x32.png',
    pageTitle: 'Power Funding - Fuel',
    loginPrimaryBG: '#005027',
    loginBtnPrimary: '#4d8568',
    loginBtnHover: '#99b9a9',
    loginImage: '/logos/power-funding-logo.svg',
    logoSize: 'large',
    onBehalfOf: '',
    carrierPortal: false,
    hasGasLimit: false,
  },
  'sdr.fuellink.net': { //SDR
    primary: '#9c9c9c',
    secondary: '#cacaca',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#000000',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/sdr_logo.png',
    favicon: '/logos/fuellinkfav.ico',
    pageTitle: 'Ship Done Right - Fuel',
    loginPrimaryBG: '#9c9c9c',
    loginBtnPrimary: '#9c9c9c',
    loginBtnHover: '#cacaca',
    loginImage: '/logos/sdr_logo.png',
    logoSize: 'large',
    onBehalfOf: '',
    carrierPortal: true,
    hasGasLimit: true,
  },
  'ocalhostt': { //bg
    primary: '#192f5c',
    secondary: '#0334ad',
    primaryText: '#ffffff',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/bg_logo.png',
    favicon: '/logos/bg_favicon.ico',
    pageTitle: 'Pelican Fueling',
    loginPrimaryBG: '#192f5c',
    loginBtnPrimary: '#192f5c',
    loginBtnHover: '#60a4d4',
    loginImage: '/logos/bg_logo.png',
    logoSize: 'medium',
    onBehalfOf: '',
    carrierPortal: false,
    hasGasLimit: false,
  },  
};

export const getThemeForDomain = (domain) => {
  return themes[domain] || defaultTheme;
};

