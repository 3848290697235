// FBTFinancials.js

import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTable, usePagination } from 'react-table';
import { format, parseISO } from 'date-fns';
import Spinner from './Spinner';



const Modal = ({ isOpen, onClose, children, title, footer }) => {
  if (!isOpen) return null;
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 p-2"
      onClick={onClose}
    >
      <div
        className="relative w-full max-w-3/4 mx-auto my-6"
        onClick={(e) => e.stopPropagation()} // Prevent modal close on content click
      >
        {/* Modal content */}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh]">
          {/* Header */}
          <div className="flex items-start justify-between p-5 border-b border-solid border-gray-200 rounded-t">
            <h3 className="text-2xl font-semibold">{title}</h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-gray-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={onClose}
            >
              <span className="bg-transparent text-gray-500 h-6 w-6 text-2xl block">
                &times;
              </span>
            </button>
          </div>
          {/* Body */}
          <div className="relative p-6 flex-auto overflow-y-auto">
            {children}
          </div>
          {/* Footer */}
          {footer && (
            <div className="flex items-center justify-end p-4 border-t border-solid border-gray-200 rounded-b">
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


// PaymentsTable component with default page size of 25
const PaymentsTable = ({ payments }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'Date',
        Cell: ({ value }) => format(parseISO(value), 'yyyy-MM-dd'),
      },
      {
        Header: 'Transaction Type',
        accessor: 'TransactionType',
      },
      {
        Header: 'Amount',
        accessor: 'Amount',
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        Header: 'Description',
        accessor: 'Description',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: payments,
      initialState: { pageIndex: 0, pageSize: 25 }, // Set default page size to 25
    },
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="min-w-full bg-white border">
        <thead className="bg-gray-200">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  className="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-700"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody key={pageIndex} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                key={row.id}
                {...row.getRowProps()}
                className="hover:bg-gray-100"
              >
                {row.cells.map((cell) => (
                  <td
                    key={cell.column.id}
                    {...cell.getCellProps()}
                    className="py-2 px-4 border-b border-gray-200 text-sm text-gray-600"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination controls */}
      <div className="pagination flex justify-between items-center mt-2">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'<<'}
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'<'}
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'>'}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'>>'}
          </button>
        </div>
        <div>
          <span className="text-sm">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="ml-2 border rounded text-sm"
          >
            {[25, 50, 100].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

// TransactionsTable component
const TransactionsTable = ({ transactions, onSelectTransaction }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Transaction ID',
        accessor: 'TransactionId',
      },
      {
        Header: 'Date',
        accessor: 'Date',
        Cell: ({ value }) => format(parseISO(value), 'yyyy-MM-dd'),
      },
      {
        Header: 'Location Name',
        accessor: 'LocationName',
      },
      {
        Header: 'Location City',
        accessor: 'LocationCity',
      },
      {
        Header: 'Total Amount',
        accessor: 'TotalAmount',
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        Header: 'Total User Amount',
        accessor: 'TotalUserAmount',
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: transactions,
      initialState: { pageIndex: 0, pageSize: 25 }, // Set default page size to 25
    },
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="min-w-full bg-white">
        <thead className="bg-gray-200">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  className="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-700"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody key={pageIndex} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                key={row.id}
                {...row.getRowProps()}
                onClick={() => onSelectTransaction(row.original)}
                className="cursor-pointer hover:bg-gray-100"
              >
                {row.cells.map((cell) => (
                  <td
                    key={cell.column.id}
                    {...cell.getCellProps()}
                    className="py-2 px-4 border-b border-gray-200 text-sm text-gray-600"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination controls */}
      <div className="pagination flex justify-between items-center mt-2">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'<<'}
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'<'}
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'>'}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'>>'}
          </button>
        </div>
        <div>
          <span className="text-sm">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="ml-2 border rounded text-sm"
          >
            {[25, 50, 100].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

// LineItemsTable component
const LineItemsTable = ({ lineItems }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Category',
        accessor: 'Category',
      },
      {
        Header: 'Quantity',
        accessor: 'Quantity',
      },
      {
        Header: 'Amount',
        accessor: 'Amount',
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        Header: 'User Amount',
        accessor: 'UserAmount',
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: lineItems,
      initialState: { pageIndex: 0, pageSize: 25 }, // Set default page size to 25
    },
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="min-w-full bg-white">
        <thead className="bg-gray-200">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  className="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-700"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody key={pageIndex} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                key={row.id}
                {...row.getRowProps()}
                className="hover:bg-gray-100"
              >
                {row.cells.map((cell) => (
                  <td
                    key={cell.column.id}
                    {...cell.getCellProps()}
                    className="py-2 px-4 border-b border-gray-200 text-sm text-gray-600"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination controls */}
      <div className="pagination flex justify-between items-center mt-2">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'<<'}
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'<'}
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'>'}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="px-2 py-1 border rounded bg-gray-200 text-sm"
          >
            {'>>'}
          </button>
        </div>
        <div>
          <span className="text-sm">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="ml-2 border rounded text-sm"
          >
            {[25, 50, 100].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

const FBTFinancials = () => {
  const { currentUser } = useAuth();
  const [monthlyData, setMonthlyData] = useState({});
  const [originalSummaryData, setOriginalSummaryData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [allPayments, setAllPayments] = useState([]); // New state for all payments
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  // For modals
  const [isMonthModalOpen, setIsMonthModalOpen] = useState(false);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isPaymentsModalOpen, setIsPaymentsModalOpen] = useState(false); // New payments modal

  // File upload state
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [message, setMessage] = useState(null);

  // Date filters
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [paymentsFilterStartDate, setPaymentsFilterStartDate] = useState('');
  const [paymentsFilterEndDate, setPaymentsFilterEndDate] = useState('');

  useEffect(() => {
    const fetchFinancialData = async () => {
      if (!currentUser?.userId) return;

      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/dataServer`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            action: 'getFBTPayments',
            payload: {
              userId: currentUser?.userId,
            },
          }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Data:', data);
          setMonthlyData(data);

          // Generate summary data
          const summary = Object.keys(data).map((monthYear) => {
            const item = data[monthYear];
            return {
              monthYear,
              totalAmount: item.totalAmount,
              totalUserAmount: item.totalUserAmount,
              totalPayment: item.totalPayment || 0,
              delta: item.delta,
              transactions: item.transactions,
              payments: item.payments,
            };
          });

          // Sort summary data
          summary.sort((a, b) => new Date(a.monthYear) - new Date(b.monthYear));

          setOriginalSummaryData(summary);
          setSummaryData(summary);

          // Collect all payments into a single array
          const allPaymentsArray = summary.reduce(
            (acc, item) => [...acc, ...item.payments],
            []
          );
          setAllPayments(allPaymentsArray);
        } else {
          setError(`Server Error: ${await response.text()}`);
        }
      } catch (err) {
        setError(`Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchFinancialData();
  }, [currentUser?.userId]);

  // Handle date filter submit
  // Handle date filter submit
  const handleFilterSubmit = () => {
    let filteredSummary = originalSummaryData;

    if (filterStartDate) {
      const [startYear, startMonth] = filterStartDate.split('-').map(Number);
      const startDate = new Date(startYear, startMonth - 1, 1);

      filteredSummary = filteredSummary.filter((item) => {
        const [itemYear, itemMonth] = item.monthYear.split('-').map(Number);
        const itemDate = new Date(itemYear, itemMonth - 1, 1);
        return itemDate >= startDate;
      });
    }

    if (filterEndDate) {
      const [endYear, endMonth] = filterEndDate.split('-').map(Number);
      const endDate = new Date(endYear, endMonth - 1, 1);

      filteredSummary = filteredSummary.filter((item) => {
        const [itemYear, itemMonth] = item.monthYear.split('-').map(Number);
        const itemDate = new Date(itemYear, itemMonth - 1, 1);
        return itemDate <= endDate;
      });
    }

    setSummaryData(filteredSummary);
  };


  // Handle payments date filter submit
  const handlePaymentsFilterSubmit = () => {
    let filteredPayments = allPayments;

    if (paymentsFilterStartDate) {
      const startDate = new Date(paymentsFilterStartDate);
      filteredPayments = filteredPayments.filter((payment) => {
        const paymentDate = new Date(payment.Date);
        return paymentDate >= startDate;
      });
    }

    if (paymentsFilterEndDate) {
      const endDate = new Date(paymentsFilterEndDate);
      filteredPayments = filteredPayments.filter((payment) => {
        const paymentDate = new Date(payment.Date);
        return paymentDate <= endDate;
      });
    }

    setFilteredPayments(filteredPayments);
  };


  // Export payments to CSV
  const exportPaymentsToCSV = () => {
    const csvContent = [
      ['Date', 'Transaction Type', 'Amount', 'Description'],
      ...filteredPayments.map((p) => [
        format(new Date(p.Date), 'yyyy-MM-dd'),
        p.TransactionType,
        p.Amount.toFixed(2),
        p.Description,
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'payments.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // State for filtered payments in the modal
  const [filteredPayments, setFilteredPayments] = useState([]);

  // Open Payments Modal
  const openPaymentsModal = () => {
    setFilteredPayments(allPayments);
    setIsPaymentsModalOpen(true);
  };

  // Handler when a month is clicked
  const handleMonthClick = (monthYear) => {
    setSelectedMonth(summaryData.find((item) => item.monthYear === monthYear));
    setIsMonthModalOpen(true);
  };

  // Handler when a transaction is clicked
  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
    setIsTransactionModalOpen(true);
  };

  // Prepare data for comparative line chart
  const chartData = summaryData.map((item) => ({
    monthYear: item.monthYear,
    totalUserAmount: item.totalUserAmount,
    totalPayment: item.totalPayment,
    delta: item.delta,
  }));

  // Calculate totals for the summary card
  const totals = React.useMemo(() => {
    const totalAmount = summaryData.reduce((sum, item) => sum + item.totalAmount, 0);
    const totalUserAmount = summaryData.reduce(
      (sum, item) => sum + item.totalUserAmount,
      0
    );
    const totalPayment = summaryData.reduce((sum, item) => sum + item.totalPayment, 0);
    const delta = summaryData.reduce((sum, item) => sum + item.delta, 0);

    return {
      totalAmount,
      totalUserAmount,
      totalPayment,
      delta,
    };
  }, [summaryData]);

  // Columns for summary table
  const summaryColumns = React.useMemo(
    () => [
      {
        Header: 'Month',
        accessor: 'monthYear',
        Cell: ({ value }) => {
          const [year, month] = value.split('-').map(Number);
          const date = new Date(year, month - 1, 1);
          return format(date, 'MMM yyyy');
        },
      },
      {
        Header: 'Total Amount',
        accessor: 'totalAmount',
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        Header: 'Total User Amount',
        accessor: 'totalUserAmount',
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        Header: 'Total Payments',
        accessor: 'totalPayment',
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        Header: 'Delta',
        accessor: 'delta',
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: summaryPage,
    prepareRow: prepareSummaryRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: summaryColumns,
      data: summaryData,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    usePagination
  );

  // Handle file upload
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileSubmit = async () => {
    if (!selectedFile) {
      alert('Please select a file.');
      return;
    }

    setUploadLoading(true);
    setMessage(null);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await fetch(`${apiUrl}/addFBTPayments`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.text();
      console.log('Results: ', result);

      if (response.ok) {
        setMessage('File uploaded and transactions processed successfully.');
        // Optionally, refresh the data
        // fetchFinancialData();
      } else {
        setMessage(`Error: ${result.message}`);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('An error occurred while uploading the file.');
    } finally {
      setUploadLoading(false);
      setSelectedFile(null);
    }
  };

  return (
    <div className="p-6 bg-gray-100">
      <h2 className="text-2xl font-bold mb-4">FBT Financials</h2>

      {/* Filters and Upload Button */}
      <div className="mb-6 flex gap-4 flex-wrap items-end">
        {/* Date Filters */}
        <div className="flex flex-col">
          <label className="font-semibold mb-1">Start Date:</label>
          <input
            type="month"
            value={filterStartDate}
            onChange={(e) => setFilterStartDate(e.target.value)}
            className="border p-2 rounded-lg"
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold mb-1">End Date:</label>
          <input
            type="month"
            value={filterEndDate}
            onChange={(e) => setFilterEndDate(e.target.value)}
            className="border p-2 rounded-lg"
          />
        </div>
        <button
          onClick={handleFilterSubmit}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          Apply Filters
        </button>
        {/* Upload Payments Button */}
        <button
          onClick={() => setIsUploadModalOpen(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          Upload Payments
        </button>
        {/* View All Payments Button */}
        <button
          onClick={openPaymentsModal}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          View All Payments
        </button>
      </div>

      {/* Summary Card */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-6">
        <h3 className="text-xl font-semibold mb-4">Financial Summary</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4 bg-gray-100 rounded-lg">
            <p className="text-sm font-semibold">Total Amount</p>
            <p className="text-lg font-bold">${totals.totalAmount.toFixed(2)}</p>
          </div>
          <div className="p-4 bg-gray-100 rounded-lg">
            <p className="text-sm font-semibold">Total User Amount</p>
            <p className="text-lg font-bold">${totals.totalUserAmount.toFixed(2)}</p>
          </div>
          <div className="p-4 bg-gray-100 rounded-lg">
            <p className="text-sm font-semibold">Total Payments</p>
            <p className="text-lg font-bold">${totals.totalPayment.toFixed(2)}</p>
          </div>
          <div className="p-4 bg-gray-100 rounded-lg">
            <p className="text-sm font-semibold">Delta</p>
            <p className="text-lg font-bold">${totals.delta.toFixed(2)}</p>
          </div>
        </div>
      </div>

      {/* Payments Modal */}
      {isPaymentsModalOpen && (
        <Modal
          isOpen={isPaymentsModalOpen}
          onClose={() => setIsPaymentsModalOpen(false)}
          title="All Payments"
          footer={
            <div className="flex justify-between items-center">
              {/* Filter Section */}
              <div className="flex items-center space-x-2 mr-2">
                <label className="font-semibold text-sm">Start Date:</label>
                <input
                  type="date"
                  value={paymentsFilterStartDate}
                  onChange={(e) => setPaymentsFilterStartDate(e.target.value)}
                  className="border p-2 rounded-lg text-sm"
                />
                <label className="font-semibold text-sm">End Date:</label>
                <input
                  type="date"
                  value={paymentsFilterEndDate}
                  onChange={(e) => setPaymentsFilterEndDate(e.target.value)}
                  className="border p-2 rounded-lg text-sm"
                />
                <button
                  onClick={handlePaymentsFilterSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg text-sm hover:bg-blue-600 transition-colors duration-200"
                >
                  Apply
                </button>
              </div>

              {/* Export Button */}
              <button
                onClick={exportPaymentsToCSV}
                className="ml-auto bg-green-500 text-white px-4 py-2 rounded-lg text-sm hover:bg-green-600 transition-colors duration-200"
              >
                Export to CSV
              </button>
            </div>
          }
        >
          <PaymentsTable payments={filteredPayments} />
        </Modal>
      )}


      {loading ? (
        <Spinner />
      ) : (
        <>
          {/* Comparative Chart */}
          <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <h3 className="text-xl font-semibold mb-4">Financial Overview</h3>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="monthYear"
                  tickFormatter={(value) => {
                    const [year, month] = value.split('-').map(Number);
                    const date = new Date(year, month - 1, 1);
                    return format(date, 'MMM yyyy');
                  }}
                />
                <YAxis />
                <RechartsTooltip
                  formatter={(value, name) => [`$${value.toFixed(2)}`, name]}
                  labelFormatter={(label) => {
                    const [year, month] = label.split('-').map(Number);
                    const date = new Date(year, month - 1, 1);
                    return format(date, 'MMMM yyyy');
                  }}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="totalUserAmount"
                  stroke="#8884d8"
                  name="Total User Amount"
                />
                <Line
                  type="monotone"
                  dataKey="totalPayment"
                  stroke="#82ca9d"
                  name="Total Payments"
                />
                <Line type="monotone" dataKey="delta" stroke="#ff7300" name="Delta" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Summary Table */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">Monthly Summary</h3>
            <table {...getTableProps()} className="min-w-full bg-white">
              <thead className="bg-gray-200">
                {headerGroups.map((headerGroup) => (
                  <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        key={column.id}
                        {...column.getHeaderProps()}
                        className="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-700"
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody key={pageIndex} {...getTableBodyProps()}>
                {summaryPage.map((row) => {
                  prepareSummaryRow(row);
                  return (
                    <tr
                      key={row.id}
                      {...row.getRowProps()}
                      onClick={() => handleMonthClick(row.original.monthYear)}
                      className="cursor-pointer hover:bg-gray-100"
                    >
                      {row.cells.map((cell) => (
                        <td
                          key={cell.column.id}
                          {...cell.getCellProps()}
                          className="py-2 px-4 border-b border-gray-200 text-sm text-gray-600"
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Pagination controls */}
            <div className="pagination flex justify-between items-center mt-2">
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="px-2 py-1 border rounded bg-gray-200 text-sm"
                >
                  {'<<'}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="px-2 py-1 border rounded bg-gray-200 text-sm"
                >
                  {'<'}
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="px-2 py-1 border rounded bg-gray-200 text-sm"
                >
                  {'>'}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="px-2 py-1 border rounded bg-gray-200 text-sm"
                >
                  {'>>'}
                </button>
              </div>
              <div>
                <span className="text-sm">
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="ml-2 border rounded text-sm"
                >
                  {[25, 50, 100].map((size) => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Month Details Modal */}
          {selectedMonth && (
            <Modal
              isOpen={isMonthModalOpen}
              onClose={() => {
                setIsMonthModalOpen(false);
                setSelectedMonth(null);
              }}
              title={`Details for ${format(
                new Date(selectedMonth.monthYear + '-01'),
                'MMMM yyyy'
              )}`}
            >
              <div className="mb-4">
                <div className="p-4 bg-gray-100 rounded-lg">
                  <p>
                    <strong>Total Amount:</strong> ${selectedMonth.totalAmount.toFixed(2)}
                  </p>
                  <p>
                    <strong>Total User Amount:</strong> $
                    {selectedMonth.totalUserAmount.toFixed(2)}
                  </p>
                  <p>
                    <strong>Total Payments:</strong> $
                    {selectedMonth.totalPayment.toFixed(2)}
                  </p>
                  <p>
                    <strong>Delta:</strong> ${selectedMonth.delta.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-6">
                {/* Transactions Table */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="text-lg font-semibold mb-2">Transactions</h4>
                  <TransactionsTable
                    transactions={selectedMonth.transactions.map((t) => ({
                      ...t,
                      TransactionId: t.TransactionId || t.transactionId,
                    }))}
                    onSelectTransaction={handleTransactionClick}
                  />
                </div>
                {/* Payments Table */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="text-lg font-semibold mb-2">Payments</h4>
                  <PaymentsTable payments={selectedMonth.payments || []} />
                </div>
              </div>
            </Modal>
          )}

          {/* Transaction Details Modal */}
          {selectedTransaction && (
            <Modal
              isOpen={isTransactionModalOpen}
              onClose={() => {
                setIsTransactionModalOpen(false);
                setSelectedTransaction(null);
              }}
              title={`Transaction Details`}
            >
              <div className="mb-4">
                <div className="p-4 bg-gray-100 rounded-lg">
                  <p>
                    <strong>Transaction ID:</strong> {selectedTransaction.TransactionId}
                  </p>
                  <p>
                    <strong>Date:</strong>{' '}
                    {format(new Date(selectedTransaction.Date), 'yyyy-MM-dd')}
                  </p>
                  <p>
                    <strong>Location Name:</strong> {selectedTransaction.LocationName}
                  </p>
                  <p>
                    <strong>Location City:</strong> {selectedTransaction.LocationCity}
                  </p>
                  <p>
                    <strong>Total Amount:</strong> $
                    {selectedTransaction.TotalAmount.toFixed(2)}
                  </p>
                  <p>
                    <strong>Total User Amount:</strong> $
                    {selectedTransaction.TotalUserAmount.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="text-lg font-semibold mb-2">Line Items</h4>
                <LineItemsTable lineItems={selectedTransaction.LineItems || []} />
              </div>
            </Modal>
          )}
        </>
      )}

      {/* File Upload Modal */}
      <Modal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        title="Upload FBT Payments"
      >
        <div className="max-w-md mx-auto">
          {message && (
            <div
              className={`p-4 mb-4 text-center rounded ${message.includes('successfully')
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
                }`}
            >
              {message}
            </div>
          )}

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select CSV File
            </label>
            <input
              type="file"
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
            />
            {selectedFile && (
              <p className="mt-2 text-sm text-gray-500">
                Selected file:{' '}
                <span className="font-semibold">{selectedFile.name}</span>
              </p>
            )}
          </div>

          <button
            onClick={handleFileSubmit}
            className={`w-full py-2 px-4 rounded-md text-white ${uploadLoading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
              } transition-colors duration-300`}
            disabled={uploadLoading}
          >
            {uploadLoading ? 'Uploading...' : 'Submit'}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default FBTFinancials;
