import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import Spinner from './Spinner';

const SubTable = ({ columns, data, loading, alertMessage, clearAlert, title, children, infoMessage}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      {loading && <div ><Spinner /></div>}
      {alertMessage && (
        <div className={`alert p-4 rounded ${alertMessage.startsWith('Successfully') ? 'bg-green-200 text-green-600' : 'bg-red-200 text-red-600'} flex justify-between items-center`}>
          {alertMessage}
          <button onClick={clearAlert} className="text-lg font-bold">×</button>
        </div>
      )}
      <div className="p-6"> 
      
      <div className="flex justify-between items-center mb-4">
          <h1 className='subTableHeader'>{title}</h1>
          <div className="flex items-center">
            {children}
          </div>
        </div>
        {infoMessage && (
  <div
    className="bg-blue-100 border-blue-500 text-blue-700 p-2 mb-2 rounded-md"
    role="alert"
  >
    {infoMessage}
  </div>
)}

        <div className="rounded-lg overflow-hidden shadow">
          <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index, array) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider`}
                    >
                      {column.render('Header')}
                      {column.Header !== 'Edit' && (
                        <span style={{ fontSize: '1.5em' }}>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ↓'
                              : ' ↑'
                            : ' ↑↓'}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
              {page.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="px-6 py-4 text-center">
                    No data found
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}                      
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="space-x-2">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="px-4 py-2 rounded text-black">
              {'<<'}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage} className="px-4 py-2 rounded text-black">
              {'<'}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage} className="px-4 py-2 rounded text-black">
              {'>'}
            </button>
            <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="px-4 py-2 rounded text-black">
              {'>>'}
            </button>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
              ({data.length} entries)
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubTable;
