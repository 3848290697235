// import React from 'react';

// const Card = ({ title, subtitle, theme, children }) => {
//   return (
//     <div className="rounded-lg p-4 m-2" style={{
//         flex: '0 0 calc(33.333% - 20px)', // No shrinking
//         boxSizing: 'border-box', // Include padding and border in width
//         borderRadius: '5px',
//         boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
//         background: 'linear-gradient(to bottom, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.9) 50%, rgba(255,255,255,0.9) 100%)'
//     }}>
//       {title && (
//         <div className="rounded-t-lg p-2 text-lg font-bold" style={{
//           textAlign: 'center',
//           background: 'none'
//         }}>
//           {title}
//           {subtitle && (
//             <div className="text-sm font-normal">
//               {subtitle}
//             </div>
//           )}
//         </div>
//       )}
//       <div className="p-4" style={{ textAlign: 'left' }}>
//         {/* Your card content here */}
//         {children}
//       </div>
//     </div>
//   );
// };

// export default Card;

import React from 'react';

const Card = ({ title, subtitle, theme, children }) => {
  return (   
    <div className="h-full p-2">      
      <div >
        {title && (
          <div className="bg-transparent text-center sm:text-left">
            <div className="text-lg font-bold pt-2 pl-2">{title}</div>
            {subtitle && (
              <div className="text-sm font-normal pl-2">{subtitle}</div>
            )}
          </div>
        )}
        <div className="p-4 text-left flex-1">          
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;


