import React, { useState, useEffect, useMemo } from 'react';
import MainTable from './MainTable';
import { useAuth } from './AuthContext';
import { useTheme } from './ThemeContext';
import ToggleSwitch from './ToggleSwitch';
import Spinner from './Spinner';
import PageHeader from './PageHeader';
import MainTableV2 from './MainTableV2'
import MobileHeader from './MobileHeader'


const ContractTable = () => {
  const { theme } = useTheme();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [updating, setUpdating] = useState(false);
  const {currentUser} = useAuth()
  const primaryColor = theme.primary;
  const filterConfig = [
    { key: 'contractId', type: 'number' },
    { key: 'description', type: 'string' }        
  ];

  const clearAlert = () => setAlertMessage(null);

  const updateEnrollment = async (contractId, enrollmentStatus) => {
    setUpdating(true); // Set updating to true when the function starts
  
    try {
      const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/setContractEnrollmentUpdate?', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ contractId, Enrolled: enrollmentStatus, userId: currentUser.userId })
      });
  
      if (response.ok) {
        // Update the local state to reflect the change
        const updatedData = data.map(contract => {
          if (contract.contractId === contractId) {
            contract.Enrolled = enrollmentStatus;
          }
          return contract;
        });
        setData(updatedData);
  
        // Set a success message
        setAlertMessage("Successfully updated the enrollment status.");
      } else {
        // Handle the error and set an error message
        const errorText = await response.text();
        setAlertMessage(`Server Error: ${errorText}`);
      }
    } catch (error) {
      // Handle the exception and set an error message
      setAlertMessage(`Error: ${error.message}`);
    } finally {
      setUpdating(false); // Set updating to false when the function is done
    }
  };
  
  const syncContractsWithEFS = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://efs-getpolicy.azurewebsites.net/api/FuelLink-getContracts?', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tenantId: currentUser.tenantId }) 
      });

      if (response.ok) {
        setAlertMessage("Successfully synced contracts with EFS");
      } else {
        setAlertMessage(`Server Error: ${await response.text()}`);
      }
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchContractLimits = async (tenantId, contractId) => {
    setLoading(true); // Show loading state
    try {
      const response = await fetch('https://efs-getpolicy.azurewebsites.net/api/getContractLimits', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tenantId, contractId })
      });
  
      if (response.ok) {
        const data = await response.json();
        setShowModal({
          show: true,
          data, // Pass the fetched data to the modal
          type: 'contractLimits', // Differentiate modal content
        });
      } else {
        console.error('Failed to fetch contract limits:', await response.text());
      }
    } catch (error) {
      console.error('Error fetching contract limits:', error);
    } finally {
      setLoading(false); // Hide loading state
    }
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getContractsAdmin?', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: currentUser.userId })
        });
  
        if (response.ok) {
          const fetchedData = await response.json();          
          setData(fetchedData.contracts); 
          // console.log("Contracts: ", fetchedData.contracts);        
        } else {
          setAlertMessage(`Server Error: ${await response.text()}`);
        }
      } catch (error) {
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  // useEffect(() => {
  //   console.log("Contract data:", data);
  // }, [data]);

  const filteredData = useMemo(() => {
    if (searchQuery === '') return data;
    return data.filter((row) => {
      return Object.values(row).some((s) => 
        s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [searchQuery, data]);

  // const columns = useMemo(() => [
  //   { Header: 'Contract ID', accessor: 'contractId' },
  //   { Header: 'Description', accessor: 'description' },
  //   { Header: 'Check Fee', accessor: 'checkFee' },    
  //   { Header: 'Currency', accessor: 'currency' },
  //   { Header: 'Issuer ID', accessor: 'issuerId' },
  //   { Header: 'Status', accessor: 'status' },    
  //   { 
  //       Header: 'Enrolled', 
  //       accessor: 'Enrolled',
  //       Cell: ({ row }) => {
  //         const handleToggle = () => {
  //           // Toggle the enrollment status
  //           const newEnrollmentStatus = !row.original.Enrolled;
  //           console.log('New Enrollmetnt Status: ', newEnrollmentStatus);
  //           // Show the modal
  //           setShowModal({
  //             show: true,
  //             contractId: row.original.contractId,
  //             enrollmentStatus: newEnrollmentStatus
  //           });
  //         };
  
  //         return (
  //           <ToggleSwitch isChecked={row.original.Enrolled} onToggle={handleToggle} primaryColor={primaryColor} />

  //         );
  //       }
  //     },
  //     {
  //       Header: 'Actions',
  //       id: 'actions',
  //       Cell: ({row}) => (
  //         <button
  //           onClick={() => fetchContractLimits(currentUser.tenantId, row.original.contractId)}
  //           className="themed-button"
  //         >
  //           View Limits
  //         </button>
  //       )
  //     }      
  //   ], [data]);
  const columns = useMemo(() => {
    const baseColumns = [
        {
            name: 'Contract ID',
            selector: row => row.contractId,
            reorder: true           
        },
        {
            name: 'Description',
            selector: row => row.description,
            reorder: true
        },
        {
            name: 'Check Fee',
            selector: row => `$${parseFloat(row.checkFee).toFixed(2)}`,
            reorder: true,
            hide: 'sm'
        },
        {
            name: 'Currency',
            selector: row => row.currency,
            reorder: true,
             hide: 'sm'
        },
        {
            name: 'Issuer ID',
            selector: row => row.issuerId,
            reorder: true,
             hide: 'sm'
        },
        // {
        //     name: 'Status',
        //     selector: row => row.status,
        //     reorder: true,
        //     cell: row => (
        //         <span
        //             className={`px-3 py-1 rounded-full text-sm font-semibold ${
        //                 row.status === "Active"
        //                     ? "bg-green-200 text-green-800"
        //                     : row.status === "Inactive"
        //                     ? "bg-gray-200 text-gray-800"
        //                     : "bg-yellow-200 text-yellow-800"
        //             }`}
        //         >
        //             {row.status}
        //         </span>
        //     )
        // },
        {
            name: 'Actions',
            selector: row => row.actions,
            reorder: true,
            cell: row => (
                <button
                    onClick={() => fetchContractLimits(currentUser.tenantId, row.contractId)}
                    className="themed-button"
                >
                    Limits
                </button>
            )
        }
    ];

    if (!currentUser.isContractAdmin || currentUser.isAdmin) {
        baseColumns.splice(5, 0, {
            name: 'Enrolled',
            selector: row => row.Enrolled,
            reorder: true,
            cell: row => {
                const handleToggle = () => {
                    const newEnrollmentStatus = !row.Enrolled;
                    setShowModal({
                        show: true,
                        contractId: row.contractId,
                        enrollmentStatus: newEnrollmentStatus
                    });
                };
                return (
                    <ToggleSwitch isChecked={row.Enrolled} onToggle={handleToggle} primaryColor={primaryColor} />
                );
            }
        });
    }

    return baseColumns;
}, [data, currentUser.isContractAdmin, currentUser.isAdmin]);


  return (
    <>
    <MobileHeader theme={theme}/>
    <div className='mobile-container'>
       
    {updating && <Spinner />}    
 
  <PageHeader 
    heading="Manage Your Contracts" 
    context={currentUser.isContractAdmin && !currentUser.isAdmin ? "View contract information" : "Activate contracts by enrolling them, per transaction fees are applicable only to enrolled contracts."}
/>

        <MainTableV2
      columns={columns}
      data={filteredData}
      primaryColor={primaryColor}
      loading={loading}
      alertMessage={alertMessage}
      clearAlert={clearAlert}         
      showModal={showModal}
      setShowModal={setShowModal}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      filterConfig={filterConfig}
    >
    {
  showModal.show && (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded">
        {showModal.type === 'contractLimits' ? (
          <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex m-2">
          <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow">
            <span className="absolute top-0 right-0 p-4">
              <button onClick={() => setShowModal({ show: false })} className="text-gray-900 hover:text-gray-600">
                <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </span>
    
            <h2 className="text-2xl font-bold mb-4">Contract Limits</h2>
            
            <div className="mb-4">
              <p><strong>Status:</strong> {showModal.data.contractStatus}</p>
              <p><strong>Transaction Limit:</strong> ${showModal.data.transLimit}</p>
              <p><strong>Original Limit:</strong> ${showModal.data.origLimit}</p>
              <p><strong>Credit Available:</strong> ${showModal.data.creditAvailable}</p>
              <p><strong>Daily Limit:</strong> ${showModal.data.dailyLimit}</p>
              <p><strong>Daily Available:</strong> ${showModal.data.dailyAvailable}</p>
              <p><strong>Total Available:</strong> ${showModal.data.totalAvailable}</p>
              <p><strong>Max Money Code:</strong> ${showModal.data.maxMoneyCode}</p>
              <p><strong>Unit of Measure:</strong> {showModal.data.uom}</p>
            </div>
    
            <div className="flex justify-end">
              <button
                onClick={() => setShowModal({ show: false })}
                className="themed-button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
        ) : (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">  {/* Darkened background */}
      <div className="bg-white p-4 rounded">
        {
          showModal.enrollmentStatus ? (
            <div>
              <p><strong>Enroll Contract:</strong></p>
              <p>Are you sure you want to enroll this contract? By enrolling, you will connect the contract with the platform and start accumulating fees for all enrolled contracts.</p>
            </div>
          ) : (
            <div>
              <p><strong>Unenroll Contract:</strong></p>
              <p>Are you sure you want to unenroll this contract? Unenrolling will make all the contract's data inaccessible and stop the platform from syncing data with EFS.</p>
            </div>
          )
        }
        <div className="flex justify-end mt-4">
          <button className="px-4 py-2 mr-2" onClick={() => setShowModal({ show: false })}>No, nevermind</button>
          <button 
            className="themed-button" 
            onClick={() => {
              updateEnrollment(showModal.contractId, showModal.enrollmentStatus);
              setShowModal({ show: false });
            }}
          >
            Yes, I'm sure
          </button>
        </div>
      </div>
    </div>
        )}
        <div className="flex justify-end mt-4">
          <button className="px-4 py-2 mr-2" onClick={() => setShowModal({ show: false })}>Close</button>
          {/* Adjust the onClick handler for the confirmation action */}
        </div>
      </div>
    </div>
  )
}
  
<button onClick={syncContractsWithEFS} className="themed-button">
          Sync Contracts With EFS
        </button>

    </MainTableV2>
   </div>
    </>
    
  );
};

export default ContractTable;
