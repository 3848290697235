import React from 'react';

const ToggleSwitch = ({ isChecked, onToggle, primaryColor }) => {
  return (
    <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onToggle}
        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
        style={{
          left: isChecked ? '1.375rem' : '0.25rem',  // Toggle switch position
          //top: '0.125rem',  // Adjusted top position
          transition: 'transform 0.2s ease-in',
          zIndex: '1',
          borderColor: isChecked ? primaryColor : 'gray',
        }}
      />
      <label
        className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer`}
        style={{
          height: '1.5rem',
          backgroundColor: isChecked ? primaryColor : 'gray',
        }}
      ></label>
    </div>
  );
};

export default ToggleSwitch;
