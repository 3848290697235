import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from './ThemeContext';
import Spinner from './Spinner';
import { useAuth } from './AuthContext';
import MobileHeader from './MobileHeader';

function TermsOfService() {
  const { currentUser, updateSignedTOS } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tos, setTos] = useState(null);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [agreed, setAgreed] = useState(false);
  const [location, setLocation] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch Terms of Service
    const fetchTos = async () => {
      setLoading(true);
      const subdomain = window.location.hostname.split('.')[0];
      try {
        const response = await fetch(`${apiUrl}/dataServer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'handleTOS',
            payload: {
              userId: currentUser.userId,
              subDomain: subdomain,
              requestType: 'get'
            }
          }),
        });
        const result = await response.json();
        if (response.ok) {
          setTos(result);
        } else {
          setError(result.error || 'Error fetching Terms of Service');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTos();
    fetchIpAddress();
  }, [currentUser.userId]);

  const fetchIpAddress = async () => {
    const ipInfoToken = 'ad22ffe4eb7ee0';
    try {
      const response = await fetch(`https://ipinfo.io/json?token=${ipInfoToken}`);
      console.log("response: ", response);
      if (!response.ok) throw new Error('Failed to fetch IP address');
      const data = await response.json();
      console.log('Data: ', data);
      setIpAddress(data.ip);
      setLocation({
        latitude: data.loc.split(',')[0],
        longitude: data.loc.split(',')[1],
        city: data.city,
        state: data.region
      });
    } catch (error) {
      console.error('Error fetching IP address and location:', error);
      setIpAddress('0.0.0.0');
      setLocation({
        latitude: '0.0',
        longitude: '0.0',
        city: 'Unknown',
        state: 'Unknown'
      });
    }
  };

  const handleAgree = async () => {
    setLoading(true);
    const subdomain = window.location.hostname.split('.')[0];
    try {
      const locationCityState = `${location.city}, ${location.state}`;
      console.log(
        'Request: ',
        {
          userId: currentUser.userId,
          timestamp: new Date().toISOString(),
          ipAddress: ipAddress,
          location: `${location.latitude}, ${location.longitude}`,
          locationCityState: locationCityState,
          version: tos.Version,
          requestType: 'add',
          subDomain: subdomain,
        }
      );
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'handleTOS',
          payload: {
            userId: currentUser.userId,
            timestamp: new Date().toISOString(),
            ipAddress: ipAddress,
            location: `${location.latitude}, ${location.longitude}`,
            locationCityState: locationCityState,
            version: tos.Version,
            requestType: 'add',
            subDomain: subdomain,
          }
        }),
      });

      if (response.ok) {
        updateSignedTOS();
        navigate('/dashboard');
      } else {
        const result = await response.json();
        setError(result.message || 'An error occurred');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    setAgreed(!agreed);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <MobileHeader theme={theme} />
      <div className="flex-1 overflow-x-hidden mobile-container">
        <div className="flex flex-col items-center justify-center">
          <div className="w-full">
            <h2 className="text-2xl mb-4 text-center">Terms of Service</h2>
            {tos ? (
              <div>
                <div className="overflow-y-auto h-96 p-4 border mb-4 bg-white">
                  <pre className="whitespace-pre-wrap">{tos.Content}</pre>
                </div>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <div className="mb-4">
                  <input
                    type="checkbox"
                    id="agree"
                    checked={agreed}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="agree" className="ml-2">I agree to the Terms of Service</label>
                </div>
                <button
                  onClick={handleAgree}
                  disabled={!agreed}
                  className={`w-full p-2 rounded ${agreed ? 'themed-button' : 'bg-gray-400 text-gray-700'}`}
                >
                  Agree and Continue
                </button>
              </div>
            ) : (
              <p className="text-center">Loading Terms of Service...</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsOfService;
