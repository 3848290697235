import axios from 'axios';

const geocodeAddress = async (address, apiKey) => {
  // console.log(`Geocoding address: ${address} with API key: ${apiKey}`);

  try {
    const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address: address,
        key: apiKey,
      },
    });

    // console.log('Geocoding API response:', response.data);

    if (response.data.status === 'OK') {
      const { lat, lng } = response.data.results[0].geometry.location;
      // console.log(`Geocoding successful: Latitude: ${lat}, Longitude: ${lng}`);
      return { lat, lng };
    } else {
      // console.error('Geocode was not successful for the following reason:', response.data.status);
      return null;
    }
  } catch (error) {
    // console.error('Error fetching geocode:', error.response ? error.response.data : error.message);
    return null;
  }
};

export default geocodeAddress;
