import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import Spinner from './Spinner';
import { useTheme } from './ThemeContext';
import MobileHeader from './MobileHeader';
import ConfirmOrder from './ConfirmOrder';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const CardOrderForm = () => {
    const { currentUser } = useAuth();
    const { theme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
        embossedName: currentUser.subDomain, 
        numberOfCards: '1',
        subDomain: window.location.hostname.split('.')[0],
        CompanyName: currentUser.companyName,
    });
    const [error, setError] = useState(null);
    const [isConfirming, setIsConfirming] = useState(false);

    const subDomain = window.location.hostname.split('.')[0];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const nameRegex = /^[a-zA-Z\s]+$/;        
        const zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$|^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;

        console.log('Zip Input:', formData.zip); // Log the zip input for debugging

        if (!nameRegex.test(formData.firstName)) {
            setError('First Name should only contain letters and spaces.');
            return false;
        }
        if (!nameRegex.test(formData.lastName)) {
            setError('Last Name should only contain letters and spaces.');
            return false;
        }
        if (formData.addressLine1.trim() === '') {
            setError('Address Line 1 is required.');
            return false;
        }
        if (formData.city.trim() === '') {
            setError('City is required.');
            return false;
        }
        if (formData.state.trim() === '') {
            setError('State is required.');
            return false;
        }
        if (formData.zip.trim() === '' || !zipRegex.test(formData.zip)) {
            setError('Please enter a valid Zip/Postal Code.');
            return false;
        }
        if (parseInt(formData.numberOfCards) < 1) {
            setError('You must order at least one card.');
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        if (!validateForm()) return;
        setIsConfirming(true);
    };

    if (loading) {
        return <Spinner />;
    }

    if (isConfirming) {
        return (
            <ConfirmOrder
                formData={formData}
                setLoading={setLoading}
                setIsConfirming={setIsConfirming}
                setError={setError}
            />
        );
    }

    return (
        <>
            <MobileHeader theme={theme} />
            <div className="flex flex-col items-center justify-center mobile-container">
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <div className="mb-4 text-center p-4 bg-blue-100 border border-blue-400 text-blue-700 rounded-md">
                        <p>
                            You will gain full access to your account once you complete the card order form. Want to explore discounts first? You can access the <Link to="/locationfinder" className="text-blue-500 underline">location finder</Link> to see discounts in your area.
                        </p>
                        {subDomain === 'aqha' && (
                        <div className="flex items-center justify-center mt-4">
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-blue-500 mr-2" />
                            <p>
                                <a href="https://calendly.com/dan-fuelcard" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                    Schedule a quick introduction </a>or call us at <a href="tel:+18643103561" className="text-blue-500 underline">(864) 310-3561</a>
                            </p>
                        </div>
                        )}
                    </div>
                    <h2 className="text-2xl mb-4 text-center">Card Order Details</h2>
                    <p className="mb-6 text-center">
                        Complete the form below. Cards will arrive in 5-7 business days.
                    </p>


                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">First Name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Address Line 1</label>
                            <input
                                type="text"
                                name="addressLine1"
                                value={formData.addressLine1}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Address Line 2</label>
                            <input
                                type="text"
                                name="addressLine2"
                                value={formData.addressLine2}
                                onChange={handleChange}
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">State</label>
                                <select
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                                >
                                            <option value="">Select a State</option>
                                            <option value="AB">Alberta</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="BC">British Columbia</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CA">California</option>
                                            <option value="DC">District of Columbia</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IL">Illinois</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="KS">Kansas</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="MB">Manitoba</option>
                                            <option value="MT">Montana</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MI">Michigan</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="NL">Newfoundland</option>
                                            <option value="NB">New Brunswick</option>
                                            <option value="NU">Nunavut</option>
                                            <option value="NT">NW Territories</option>
                                            <option value="NS">Nova Scotia</option>
                                            <option value="NY">New York</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ON">Ontario</option>
                                            <option value="OR">Oregon</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OH">Ohio</option>
                                            <option value="PE">Prince Edward Island</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="QC">Quebec</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SK">Saskatchewan</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WY">Wyoming</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="YT">Yukon</option>
                                </select>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Zip/Postal Code</label>
                                <input
                                    type="text"
                                    name="zip"
                                    value={formData.zip}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Country</label>
                                <select
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                                >
                                    <option value="USA">USA</option>
                                    <option value="CAN">CAN</option>
                                </select>
                            </div>
                        </div>
                        {/* <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Embossed Name on Card (25 Characters MAX)</label>
                            <input
                                type="text"
                                name="embossedName"
                                value={formData.embossedName}
                                onChange={handleChange}
                                maxLength="25"
                                required
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                            />
                        </div> */}
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Number of Cards</label>
                            <select
                                name="numberOfCards"
                                value={formData.numberOfCards}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                            >
                                {[...Array(25).keys()].map((i) => (
                                    <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex justify-center mt-6">
                            <button
                                type="submit"
                                className="w-full md:w-1/2 p-2 rounded themed-button"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CardOrderForm;
