import React, { useState, useContext, useEffect } from 'react';
import { routingNumber } from 'us-bank-account-validator';
import { useTheme } from './ThemeContext';
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import MobileHeader from './MobileHeader';
import checkImage from './images/check_details.gif';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';

const PaymentNewUser = () => {
  const { theme } = useTheme();
  const { currentUser, updateSetupComplete } = useContext(AuthContext);
  const [nameOnAccount, setNameOnAccount] = useState('');
  const [routingNum, setRoutingNum] = useState('');
  const [isRoutingValid, setIsRoutingValid] = useState(null);
  const [acctNum, setAcctNum] = useState('');
  const [acctNumConfirm, setAcctNumConfirm] = useState('');
  const [isAcctMatch, setIsAcctMatch] = useState(true);
  const [balance, setBalance] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);
  const [submitError, setSubmitError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  const subDomain = window.location.hostname.split('.')[0];

  const fetchIpAddress = async () => {
    const ipInfoToken = 'ad22ffe4eb7ee0';
    try {
      const response = await fetch(`https://ipinfo.io/json?token=${ipInfoToken}`);
      console.log("response: ", response);
      if (!response.ok) throw new Error('Failed to fetch IP address');
      const data = await response.json();
      setIpAddress(data.ip);
    } catch (error) {
      console.error('Error fetching IP address and location:', error);
      setIpAddress('0.0.0.0');
    }
  };
  useEffect(() => {
    fetchIpAddress();
  }, []);


  useEffect(() => {
    fetchIpAddress();
    //console.log('currentuser: ', currentUser);
  }, []);

  // const handleRoutingNumChange = (e) => {
  //   const value = e.target.value;
  //   setRoutingNum(value);
  //   const validation = routingNumber(value);
  //   setIsRoutingValid(validation.isValid);
  // };
  const handleRoutingNumChange = (e) => {
    const value = e.target.value;
    setRoutingNum(value);
  };

  const validateRoutingNumberOnBlur = () => {
    const validation = routingNumber(routingNum);
    setIsRoutingValid(validation.isValid);
  };

  const clearRoutingValidationError = () => {
    setIsRoutingValid(null);
  };


  const handleAcctNumChange = (e) => {
    setAcctNum(e.target.value);
  };

  const handleAcctNumConfirmChange = (e) => {
    setAcctNumConfirm(e.target.value);
    setIsAcctMatch(e.target.value === acctNum);
  };

  const handleBalanceChange = (e) => {
    setBalance(e.target.value);
  };

  const handleNameOnAccountChange = (e) => {
    setNameOnAccount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isRoutingValid || !isAcctMatch || !balance || !nameOnAccount) {
      setSubmitError('Please ensure all fields are correctly filled.');
      return;
    }
    setLoading(true);
    const payload = {
      userId: currentUser.userId,
      nameOnAccount,
      routingNum,
      acctNum,
      subDomain,
      balance,
      ipAddress
    };
    try {
      const response = await fetch(`${apiUrl}/submitInitialOrder?`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      console.log("response: ", response);
      const data = await response.json();
      if (response.ok) {
        updateSetupComplete();
        setSuccess(true);
        setSubmitError('');
      } else {
        setSubmitError(data.error || 'Failed to submit payment information.');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitError('An error occurred. Please try again.');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <MobileHeader theme={theme} />
      <div className="flex-1 overflow-x-hidden mobile-container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="flex flex-col items-center justify-center p-2">
            <div className="p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto">
              <img src={theme.logo} alt="Logo" className="w-32 mx-auto mb-4" /> {/* Display the logo */}
              <h2 className="text-2xl font-bold mb-4">Payment Information</h2>
              <p className="text-sm mb-2">
                Below is an example check highlighting where you can find your routing and account numbers.
              </p>
              <img src={checkImage} alt="Check with routing and account number markers" className="w-full mt-4" />
              <p className="my-4">
                To get started, please enter the details of your bank account. This information will be used to debit your account and maintain your card balance.
              </p>
              <form onSubmit={handleSubmit}>
                {submitError && (
                  <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded-md" role="alert">
                    <p className="text-red-500">{submitError}</p>
                  </div>
                )}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Name on Account</label>
                  <input
                    required
                    type="text"
                    value={nameOnAccount}
                    onChange={handleNameOnAccountChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Routing Number</label>
                  <input
                    required
                    type="text"
                    value={routingNum}
                    onChange={handleRoutingNumChange}
                    onBlur={validateRoutingNumberOnBlur}
                    onFocus={clearRoutingValidationError}
                    className={`w-full p-2 border ${isRoutingValid === false ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                    maxLength="9"
                    autoComplete="off"
                  />
                  {isRoutingValid === false && <p className="text-red-500 text-sm mt-1">Invalid routing number</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Account Number</label>
                  <input
                    required
                    type="password"
                    value={acctNum}
                    onChange={handleAcctNumChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    autoComplete="new-password"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Confirm Account Number</label>
                  <input
                    required
                    type="password"
                    value={acctNumConfirm}
                    onChange={handleAcctNumConfirmChange}
                    className={`w-full p-2 border ${isAcctMatch ? 'border-gray-300' : 'border-red-500'} rounded-md`}
                    autoComplete='off'
                  />
                  {!isAcctMatch && <p className="text-red-500 text-sm mt-1">Account numbers do not match</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Desired Balance</label>
                  <input
                    required
                    type="number"
                    value={balance}
                    onChange={handleBalanceChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Minimum $100.00"
                    min="10"
                    increment="1.00"
                  />
                  <p className="text-sm mt-1">
                    <b>This is the initial amount that will be debited and maintained.</b> You can change this amount anytime from the settings.
                  </p>
                </div>
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-2">
                    How it Works <FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
                  </h3>
                  <p className="text-sm mb-2">
                    You will fund your cards with the amount you choose. As you fuel, we will debit your account to reload the card. If you prefer not to reload automatically, you can change this in the settings.
                  </p>
                </div>
                <button
                  type="submit"
                  className="w-full themed-button p-2 rounded-md"
                >
                  {loading ? <Spinner /> : 'Submit'}
                </button>
              </form>
              {success && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto text-center">
                    <h2 className="text-xl font-bold mb-4">Congrats!</h2>
                    <p className="mb-4">{theme.welcomeMessage}</p>
                    <p className="mb-4">
                      You have successfully funded your account. Remember, it takes 2-3 business days for the funds to be available. You can monitor your account balance and ACH transactions from the <Link to="/useraccounting" className="text-blue-500 underline">accounting tab</Link>.
                    </p>
                    <p className="mb-4">
                      You can manage your account settings in the <Link to="/settings" className="text-blue-500 underline">settings tab</Link>.
                    </p>
                    <button
                      className="w-full p-2 bg-blue-500 text-white rounded-md"
                      style={{ backgroundColor: theme.primary, color: theme.BtnTxtPrimaryColor }}
                      onClick={() => window.location.href = '/dashboard'}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              )}


            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentNewUser;
