import React from "react";
import Card from "./Card";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomLabel = (props) => {
  const { x, y, width, height, value } = props;

  const formattedValue = `$${value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;

  // Check if the label fits inside the bar
  const insideBar = width > 40; // Adjust this threshold as needed
  const xPos = insideBar ? x + width - 5 : x + width + 10; // Adjust position based on bar width
  const fillColor = insideBar ? 'white' : 'black';
  const textAnchor = insideBar ? 'end' : 'start';

  return (
    <text 
      x={xPos}
      y={y + height / 2}
      fill={fillColor}
      textAnchor={textAnchor}
      fontSize={12}
      dominantBaseline="middle"
    >
      {formattedValue}
    </text>
  );
};



const filterLast4Weeks = (data) => {
  // Assuming data is sorted by week in ascending order
  return data.slice(-4); // Get the last 4 items
};

const WeeklyAmountChart = ({ data, theme }) => {
  const filteredData = filterLast4Weeks(data);

  return (
    <>
      <div>
        <Card title="Fuel Cost by Week" theme={theme}>
          {filteredData && filteredData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={filteredData}
                layout="vertical" // Set layout to vertical
                margin={{
                  top: 5,
                  right: 20,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  type="number"
                  tick={{ fontSize: 12 }}
                  tickFormatter={(tickItem) =>
                    `$${tickItem.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  }
                />
                <YAxis
                  type="category"
                  dataKey="week"
                  tick={{ fontSize: 12 }}
                  tickFormatter={(tickItem) => {
                    const weekData = filteredData.find((d) => d.week === tickItem);
                    if (weekData) {
                      const startDate = new Date(weekData.weeklyAmount.startDate);
                      const endDate = new Date(weekData.weeklyAmount.endDate);
                      const formatDate = (date) =>
                        `${
                          date.getMonth() + 1
                        }/${date.getDate()}/${date.getFullYear()}`;
                      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
                    }
                    return tickItem;
                  }}
                />
                <Tooltip
                  formatter={(value) =>
                    `$${value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  }
                  labelFormatter={() => ""}
                />
                <Legend />
                <Bar
                  dataKey="weeklyAmount.amount"
                  name="Weekly Cost"
                  fill={theme.primary}
                  label={<CustomLabel />}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div className="text-center mt-4">No Data Available</div>
          )}
        </Card>
      </div>
    </>
  );
};

export default WeeklyAmountChart;


// import React from "react";
// import Card from "./Card";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// const CustomLabel = (props) => {
//   const { x, y, width, height, value } = props;

//   const formattedValue = `$${value.toLocaleString('en-US', {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   })}`;

//   const insideBar = width > 20; // Adjust threshold for vertical bars
//   const xPos = insideBar ? x + width / 2 : x - 10;
//   const fontSize = insideBar ? 12 : 16;

//   return (
//     <text 
//       x={xPos}
//       y={y - height / 2}
//       fill={insideBar ? 'white' : 'black'}
//       textAnchor="middle"
//       fontSize={fontSize}
//       dominantBaseline={insideBar ? 'central' : 'auto'}
//     >
//       {formattedValue}
//     </text>
//   );
// };

// const filterLast4Weeks = (data) => {
//   // Assuming data is sorted by week in ascending order
//   return data.slice(-4); // Get the last 4 items
// };

// const WeeklyAmountChart = ({ data, theme }) => {
//   const filteredData = filterLast4Weeks(data);

//   return (
//     <>
//       <div>
//         <Card title="Fuel Cost by Week" theme={theme}>
//           {filteredData && filteredData.length > 0 ? (
//             <ResponsiveContainer width="100%" height={300}>
//               <BarChart
//                 data={filteredData}
//                 layout="vertical" // Set layout to vertical
//                 margin={{
//                   top: 5,
//                   right: 20,
//                   left: 20,
//                   bottom: 5,
//                 }}
//               >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis
//                   type="number"
//                   tick={{ fontSize: 12 }}
//                   tickFormatter={(tickItem) =>
//                     `$${tickItem.toLocaleString("en-US", {
//                       minimumFractionDigits: 2,
//                       maximumFractionDigits: 2,
//                     })}`
//                   }
//                 />
//                 <YAxis
//                   type="category"
//                   dataKey="week"
//                   tick={{ fontSize: 12 }}
//                   tickFormatter={(tickItem) => {
//                     const weekData = filteredData.find((d) => d.week === tickItem);
//                     if (weekData) {
//                       const startDate = new Date(weekData.weeklyAmount.startDate);
//                       const endDate = new Date(weekData.weeklyAmount.endDate);
//                       const formatDate = (date) =>
//                         `${
//                           date.getMonth() + 1
//                         }/${date.getDate()}/${date.getFullYear()}`;
//                       return `${formatDate(startDate)} - ${formatDate(endDate)}`;
//                     }
//                     return tickItem;
//                   }}
//                 />
//                 <Tooltip
//                   formatter={(value) =>
//                     `$${value.toLocaleString("en-US", {
//                       minimumFractionDigits: 2,
//                       maximumFractionDigits: 2,
//                     })}`
//                   }
//                   labelFormatter={() => ""}
//                 />
//                 <Legend />
//                 <Bar
//                   dataKey="weeklyAmount.amount"
//                   name="Weekly Cost"
//                   fill={theme.primary}
//                   label={<CustomLabel />}
//                 />
//               </BarChart>
//             </ResponsiveContainer>
//           ) : (
//             <div className="text-center mt-4">No Data Available</div>
//           )}
//         </Card>
//       </div>
//     </>
//   );
// };

// export default WeeklyAmountChart;
