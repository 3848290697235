import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import SubTableV2 from './SubTableV2';
import Spinner from './Spinner';
import { useSelector } from 'react-redux';

const CardGroupsAddCardModal = ({ isOpen, onClose, onCardsAdded, cardGroupId}) => {
    const { currentUser } = useAuth();
    const [cards, setCards] = useState([]);
    const [selectedCards, setSelectedCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [search, setSearch] = useState('');
    const [policyNumber, setPolicyNumber] = useState(''); 
    const [hasGroupConflict, setHasGroupConflict] = useState(false);
    
    const apiUrl= process.env.REACT_APP_API_URL;


    const policies = useSelector(state => state.policies.activePolicies);

    useEffect(() => {
        if (isOpen) {
            fetchCards();
        }
    }, [isOpen]);

    const fetchCards = async () => {
        setLoading(true);
        setError('');
        try {
            // Ensure currentUser and userId are available
            if (!currentUser || !currentUser.userId) {
                throw new Error("User information is incomplete.");
            }
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: 'getCardDataAdmin',
                    payload: { userId: currentUser.userId }
                })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            const data = await response.json();
            console.log('Data: ', data);
            setCards(data);
        } catch (error) {
            setError(error.message);
            console.error('There was an error!', error);
        } finally {
            setLoading(false);
        }
    };

    const filteredCards = cards
    .filter(card => !policyNumber || card.PolicyNumber === parseInt(policyNumber, 10)) // First filter by PolicyNumber if selected
    .filter(card => {
        return (
            card.CardNumber.toLowerCase().includes(search.toLowerCase()) ||
            (card.CardSubfleet && card.CardSubfleet.toLowerCase().includes(search.toLowerCase())) ||
            (card.PolicySubfleet && card.PolicySubfleet.toLowerCase().includes(search.toLowerCase())) ||
            (card.groupName && card.groupName.toLowerCase().includes(search.toLowerCase()))
        );
    });


    const handleSelectCard = (cardNumber) => {
        const newSelectedCards = selectedCards.includes(cardNumber) 
            ? selectedCards.filter(c => c !== cardNumber)
            : [...selectedCards, cardNumber];
        setSelectedCards(newSelectedCards);
    
        // Check if any selected cards are already in a group
        const anyGroupConflict = newSelectedCards.some(cardNum => {
            const card = cards.find(c => c.CardNumber === cardNum);
            return card && card.groupName;
        });
        setHasGroupConflict(anyGroupConflict);
    };

    const getColumns = (selectedCards, handleSelectCard) => [
        {
            name: 'Select',
            selector: row => row.CardNumber,
            cell: row => (
                <input 
                    type="checkbox" 
                    checked={selectedCards.includes(row.CardNumber)} 
                    onChange={() => handleSelectCard(row.CardNumber)}
                />
            ),
            ignoreRowClick: true,            
        },
        {
            name: 'Card Number',
            selector: row => row.CardNumber,
            sortable: true,
        },
        {
            name: 'Card Subfleet',
            selector: row => row.CardSubfleet || '',
            sortable: true,
        },
        {
            name: 'Policy Subfleet',
            selector: row => row.PolicySubfleet || '',
            sortable: true,
        },
        {
            name: 'Card Group',
            selector: row => row.groupName || 'Not Grouped',
            sortable: true,
        },
    ];

    const handleSubmit = async () => {
        setLoading(true);        
    
        try {
            const payload = {
                userId: currentUser.userId,
                cardNumbers: selectedCards,
                cardGroupId: cardGroupId
            };
    
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ action: 'updateCardGroup', payload })
            });
    
            if (!response.ok) {
                throw new Error(`Failed to update card group: ${response.statusText}`);
            }
    
            // Optionally, parse and use the response data
            const responseData = await response.json();
            console.log('Update response:', responseData);
    
            const updatedCards = cards.filter(card => selectedCards.includes(card.CardNumber));
            onCardsAdded(updatedCards);
            onClose(); 
        } catch (error) {
            setError(`Request failed: ${error.message}`);
            console.error('Error during card group update:', error);
        } finally {
            setLoading(false);
        }
    };
    

    if (!isOpen) return null;

    const columns = getColumns(selectedCards, handleSelectCard);

    return (
        <div className="mobile-container fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-md bg-white sm:w-11/12 md:w-2/3 lg:w-1/2">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Add Cards to Group</h3>
                {loading && <Spinner />}                
                <div className="bg-white p-4 border border-gray-300 shadow mb-4 rounded-lg">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                        <label className="block text-sm font-medium text-gray-700">Search</label>
                            <input 
                                type="text" 
                                placeholder="Search cards..." 
                                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Policy Number</label>
                            <select
                                id="policyNumber"
                                value={policyNumber}
                                onChange={(e) => setPolicyNumber(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                            >
                                <option value="">Select A Policy</option>
                                {policies.map(policy => (
                                    <option key={policy.PolicyNumber} value={policy.PolicyNumber}>
                                        {policy.PolicyNumber} - {policy.Description}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        {selectedCards.length > 0 && (
                            <div className="text-sm font-medium text-gray-700">
                                {selectedCards.length} card(s) selected
                            </div>
                        )}
                        {hasGroupConflict && (
                    <div className="text-red-500 mb-2">
                        One or more of the selected cards are already assigned to another card group. Cards can only be attached to one group at a time, this will remove the card(s) from their current card group.
                    </div>
                )}
                    </div>
                </div>
                <SubTableV2
                    columns={columns}
                    data={filteredCards}
                    loading={loading}
                    alertMessage={error}
                    clearAlert={() => setError('')}
                    title="Select Cards"
                />
                <div className="flex justify-end space-x-2 mt-4">
                    <button onClick={handleSubmit} className="themed-button py-2 px-4 rounded">
                        Add Selected Cards
                    </button>
                    <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
    
};

export default CardGroupsAddCardModal;
