import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, LoadScriptNext, InfoWindow, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import PageHeader from "./PageHeader";
import { useTheme } from "./ThemeContext";
import { useAuth } from "./AuthContext";
import Spinner from "./Spinner";
import MobileHeader from './MobileHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faArrowDown, faArrowUp, faTimes, faMapMarkerAlt, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const googleMapsLibraries = ["places"];

const LocationFinderV2 = () => {
  const { currentUser } = useAuth();
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({});
  const [radius, setRadius] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const { theme } = useTheme();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [sortCriteria, setSortCriteria] = useState({ field: 'price', ascending: true });
  const [userLocation, setUserLocation] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 39.8283, lng: -98.5795 });
  const [mapZoom, setMapZoom] = useState(4);
  const apiUrl = process.env.REACT_APP_API_URL;
  const ipInfoToken = 'ad22ffe4eb7ee0';
  const defaultMapStyles = [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [{ color: '#000000' }],
    },
    // {
    //   featureType: "road.highway",
    //   elementType: "geometry",
    //   stylers: [
    //     { color: `rgba(${parseInt(theme.primary.slice(1, 3), 16)}, ${parseInt(theme.primary.slice(3, 5), 16)}, ${parseInt(theme.primary.slice(5, 7), 16)}, 0.7)` },
    //     { weight: 1 } 
    //   ],
    // },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [{ color: theme.primary }],
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        { "color": "#b0e0e6" },
        { "lightness": 40 },
        { "saturation": -30 }
      ]
    },
  ];

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const response = await axios.get(`https://ipinfo.io/json?token=${ipInfoToken}`);
        const [lat, lng] = response.data.loc.split(',');
        setUserLocation({ lat: parseFloat(lat), lng: parseFloat(lng) });
      } catch (error) {
        console.error("Error getting user location from IPInfo:", error);
      }
    };

    fetchUserLocation();
  }, []);

  useEffect(() => {
    setCoordinates({});
  }, [address]);

  useEffect(() => {
    const isValid = address.trim() !== "" && radius > 0 && radius <= 3500;
    setIsFormValid(isValid);
  }, [address, radius]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const toRad = (x) => (x * Math.PI) / 180;
    const R = 3958.8; // Radius of the Earth in miles

    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in miles
  };

  const fetchFuelStops = async () => {
    if (radius > 3500) {
      alert("Radius should be 3500 or below.");
      return;
    }

    setLoading(true);
    let currentCoordinates = coordinates;

    if (!isValidCoordinates && address) {
      try {
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);
        setCoordinates(latLng);
        currentCoordinates = latLng;
        setMapCenter(latLng); // Update the map center
        setMapZoom(10); // Set the zoom level after search        
      } catch (error) {
        console.error("Error geocoding address:", error);
        setLoading(false);
        return;
      }
    }

    const data = {
      latitude: currentCoordinates.lat,
      longitude: currentCoordinates.lng,
      searchRange: radius,
      UserId: currentUser.userId,
    };

    try {
      const response = await fetch(`${apiUrl}/getLocationsV2?`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.error("Fetch failed with status:", response.status, response.statusText);
        setLoading(false);
        return;
      }

      const locationsData = await response.json();

      if (userLocation) {
        locationsData.forEach((location) => {
          location.distance = calculateDistance(
            userLocation.lat,
            userLocation.lng,
            parseFloat(location.latitude),
            parseFloat(location.longitude)
          ).toFixed(2);
        });
      }
      setLocations(locationsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching fuel stops:", error);
      setLoading(false);
    }
  };

  const defaultCenter = { lat: 39.8283, lng: -98.5795 };

  const isValidCoordinates =
    coordinates &&
    typeof coordinates.lat === "number" &&
    typeof coordinates.lng === "number";

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const handleSort = (field) => {
    const isAscending = sortCriteria.field === field ? !sortCriteria.ascending : true;
    setSortCriteria({ field, ascending: isAscending });
  };

  const sortedLocations = [...locations].sort((a, b) => {
    let aValue, bValue;

    if (sortCriteria.field === 'price') {
      aValue = parseFloat(a.userPrice) || Infinity;
      bValue = parseFloat(b.userPrice) || Infinity;
    } else if (sortCriteria.field === 'distance') {
      aValue = parseFloat(a.distance) || Infinity;
      bValue = parseFloat(b.distance) || Infinity;
    }

    return sortCriteria.ascending ? aValue - bValue : bValue - aValue;
  });

  const handleMapLoad = useCallback((map) => {
    setMapInstance(map);
  }, []);

  const renderMarkers = () => {
    return sortedLocations.map((location) => {
      if (!location.retailPrice || location.retailPrice === "NA") return null; // Skip if there's no price

      const markerIcon = theme.markerIcon ? {
        url: theme.markerIcon,
        labelOrigin: new window.google.maps.Point(15, 45),
        scaledSize: new window.google.maps.Size(30, 30)
      } : {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path fill="${theme.primary}" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
          </svg>
        `)}`,
        labelOrigin: new window.google.maps.Point(12, 45),
        scaledSize: new window.google.maps.Size(30, 30)
      };

      return (
        <Marker
          key={`marker-${location.locationID}`}
          position={{
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
          }}
          icon={markerIcon}
          onClick={() => setSelectedLocation(location)}
          label={{
            text: location.userPrice === "TBD" ? `$${parseFloat(location.retailPrice).toFixed(3)}` : `$${parseFloat(location.userPrice).toFixed(3)}`,
            color: "#000000",
            fontSize: "14px",
            fontWeight: "bold",
            className: "price-label",
            background: theme.primary, // Add background color
            borderRadius: "12px", // Add border-radius for pill effect
            padding: "2px 4px" // Add padding for pill effect
          }}
        />
      );
    });
  };

  const renderInfoWindow = () => {
    if (!selectedLocation) return null;

    const savings = (parseFloat(selectedLocation.retailPrice) - parseFloat(selectedLocation.userPrice)).toFixed(2);
    const price = isNaN(parseFloat(selectedLocation.userPrice)) ? "$0.00" : `$${parseFloat(selectedLocation.userPrice).toFixed(3)}`;
    const retailPrice = isNaN(parseFloat(selectedLocation.retailPrice)) ? "N/A" : `$${parseFloat(selectedLocation.retailPrice).toFixed(3)}`;
    const discount = isNaN(savings) ? "Pricing Unavailable" : `${savings} Off`;
    const distance = selectedLocation.distance ? `${selectedLocation.distance}` : "";
    const modifiedOn = new Date(selectedLocation.modifiedOn).toLocaleString();

    return (
      <InfoWindow
        position={{
          lat: parseFloat(selectedLocation.latitude),
          lng: parseFloat(selectedLocation.longitude),
        }}
        onCloseClick={() => setSelectedLocation(null)}
        options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
      >
        <div style={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', maxWidth: '350px' }}>
          <div>
            <p className="text-xs text-gray-500 mb-2">
              *Prices may vary and are subject to change. We strive to provide accurate information, but discrepancies may occur.
            </p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg shadow w-full">
            <div className="font-semibold text-lg">{selectedLocation.locationName}</div>
            <div className="text-sm mb-2">
              <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(selectedLocation.addr2 + ', ' + selectedLocation.city + ', ' + selectedLocation.state + ' ' + selectedLocation.zip)}${userLocation ? `&origin=${userLocation.lat},${userLocation.lng}` : ''}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                Directions
              </a>
            </div>
            <div className="text-green-600 font-bold text-lg">{price}</div>
            <div className="text-sm text-gray-500 mb-2">Your Price</div>
            <div className="text-red-600 text-lg">{retailPrice}</div>
            <div className="text-sm text-gray-500 mb-2">Retail Price</div>
            <div className="text-sm font-bold mb-2">{discount}</div>
            <div>{selectedLocation.addr1}</div>
            <div>{selectedLocation.addr2}, {selectedLocation.city}, {selectedLocation.state} {selectedLocation.zip}</div>
            {distance && (
              <div className="text-sm flex items-center font-bold mt-2">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" style={{ color: theme.primary }} />
                {distance} miles away
              </div>
            )}
            <div className="text-xs text-gray-400 mt-2">Last Price Update: {modifiedOn}</div>
          </div>
        </div>
      </InfoWindow>
    );
  };

  return (
    <>
      <MobileHeader theme={theme} />
      <div className="mobile-container flex flex-col h-screen">
        <PageHeader
          heading="Location Finder"
          context="Search for fuel stops based on city and state. Customize the search radius and view the results on the map."
        />
        {loading && <Spinner />}
        <div className="flex flex-col sm:flex-row flex-1 overflow-hidden">
          <div className={`flex-1 p-4 ${isPanelOpen ? 'hidden sm:block sm:w-3/4' : 'w-full'}`}>
            <LoadScriptNext
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              libraries={googleMapsLibraries}
            >
              <div className="relative pt-1">
                <button onClick={togglePanel} className="absolute top-0 left-0 flex items-center text-blue-500 p-2 z-10">
                  <FontAwesomeIcon icon={faList} className="mr-2" />
                  {isPanelOpen ? "Hide List View" : "Show List View"}
                </button>
                <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-4 mt-12">
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="relative w-full">
                        <input
                          {...getInputProps({ placeholder: "Search City/State" })}
                          className="w-full p-2 border-2 border-gray-300 rounded"
                          required
                        />
                        <div className="absolute inset-x-0 top-full bg-white border z-10">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => (
                            <div
                              {...getSuggestionItemProps(suggestion)}
                              key={suggestion.placeId}
                              className="p-2 hover:bg-gray-100 cursor-pointer"
                            >
                              {suggestion.description}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <div className="flex items-center w-full space-x-2">
                    <input
                      type="number"
                      max="3500"
                      value={radius}
                      onChange={(e) => {
                        if (e.target.value <= 3500) {
                          setRadius(e.target.value);
                        }
                      }}
                      placeholder="Radius"
                      className="p-2 border-2 border-gray-300 rounded flex-grow"
                      required
                    />
                    <span className="text-gray-600 whitespace-nowrap">miles</span>
                  </div>
                  <button
                    onClick={fetchFuelStops}
                    className="themed-button font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full sm:w-auto"
                    disabled={!isFormValid}
                  >
                    Search
                  </button>
                </div>

                <GoogleMap
                  onLoad={handleMapLoad}
                  center={mapCenter}
                  zoom={mapZoom}
                  mapContainerStyle={{ width: "100%", height: "calc(100vh - 250px)" }}
                  options={{ styles: defaultMapStyles }}
                >
                  {renderMarkers()}
                  {renderInfoWindow()}
                </GoogleMap>
              </div>
            </LoadScriptNext>
          </div>

          {isPanelOpen && (
            <div className="w-full sm:w-1/4 max-h-screen overflow-y-auto bg-white p-4 rounded-lg shadow-md sm:block">
              <div className="flex justify-between mb-4">
                <div className="flex items-center">
                  <span className="mr-2">Sort By:</span>
                  <button onClick={() => handleSort('price')} className="flex items-center text-blue-500 mr-4">
                    <FontAwesomeIcon icon={sortCriteria.field === 'price' && sortCriteria.ascending ? faArrowUp : faArrowDown} className="mr-1" />
                    Best Price
                  </button>
                  <button onClick={() => handleSort('distance')} className="flex items-center text-blue-500">
                    <FontAwesomeIcon icon={sortCriteria.field === 'distance' && sortCriteria.ascending ? faArrowUp : faArrowDown} className="mr-1" />
                    Closest
                  </button>
                </div>
                <button onClick={togglePanel} className="text-blue-500">
                  <FontAwesomeIcon icon={faTimes} />
                </button>


              </div>
              <div>
                <p className="text-xs text-gray-500 mb-2">
                  *Prices may vary and are subject to change. We strive to provide accurate information, but discrepancies may occur.
                </p>
              </div>
              <div className="flex flex-wrap max-h-full overflow-y-auto">

                {sortedLocations.map((location) => {
                  const savings = (parseFloat(location.retailPrice) - parseFloat(location.userPrice)).toFixed(2);
                  const price = isNaN(parseFloat(location.userPrice)) ? "$0.00" : `$${parseFloat(location.userPrice).toFixed(3)}`;
                  const retailPrice = isNaN(parseFloat(location.retailPrice)) ? "N/A" : `$${parseFloat(location.retailPrice).toFixed(3)}`;
                  const discount = isNaN(savings) ? "Pricing Unavailable" : `$${savings} Off`;
                  const distance = location.distance ? `${location.distance}` : "";
                  const modifiedOn = new Date(location.modifiedOn).toLocaleString();

                  return (
                    <div className="w-full p-2">
                      <div className="bg-gray-100 p-4 rounded-lg shadow flex flex-col">
                        <div className="flex w-full">
                          <div className="w-1/4 text-center">
                            <div className="text-green-600 font-bold text-lg">{price}</div>
                            <div className="text-sm text-gray-500">Your Price</div>
                            <div className="text-sm mt-2 font-bold">{discount}</div>
                            <div className="text-red-600 text-lg mt-1">{retailPrice}</div>
                            <div className="text-sm text-gray-500">Retail Price</div>
                          </div>
                          <div className="w-3/4 pl-4">
                            <div className="font-semibold">{location.locationName}</div>
                            <div className="text-sm">
                              <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(location.addr2 + ', ' + location.city + ', ' + location.state + ' ' + location.zip)}${userLocation ? `&origin=${userLocation.lat},${userLocation.lng}` : ''}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                Directions
                              </a>
                            </div>
                            <div>{location.addr1}</div>
                            <div>{location.addr2}, {location.city}, {location.state} {location.zip}</div>
                            {distance && (
                              <div className="text-sm flex items-center font-bold">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" style={{ color: theme.primary }} />
                                {distance} miles away
                              </div>
                            )}

                          </div>
                        </div>
                        <div className="text-xs text-center text-gray-400 mt-2 w-full">Last Price Update: {modifiedOn}</div>
                      </div>
                    </div>

                  );

                })}
              </div>

            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LocationFinderV2;
