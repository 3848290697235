import React, { useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { setCardData, updateCardData } from "./cardSlice";
import SubTable from "./SubTable";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import MobileHeader from './MobileHeader';
import { useTheme } from './ThemeContext';

const CardsAdminEditAdd = () => {
  const { currentUser } = useAuth();
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("success");
  const clearAlert = () => setAlertMessage(null);
  const cardData = useSelector((state) => state.card.cardData);
  const cardPrompts = useSelector((state) => state.card.cardData.combinedPrompts);  
  const policies = useSelector((state) => state.policies.policies);
  const combinedPrompts = useSelector(
    (state) => state.card.cardData.combinedPrompts
  );
  const combinedLimits = useSelector(
    (state) => state.card.cardData.combinedLimits
  );
  const [formData, setFormData] = useState({
    CardNumber: "",
    DriverId: "",
    DriverName: "",
    UnitNumber: "",
    CardStatus: "",
    HandEnter: "",
    Information: "",
    PolicyNumber:"",
  });

  

  // useEffect(() => {
  //   if (!cardData || Object.keys(cardData).length === 0) {
  //     navigate("/usercards");
  //   }
  // }, [cardData, navigate]);

  useEffect(() => {
    //const storedCardNumber = sessionStorage.getItem("editingCardNumber"); // Get card number from sessionStorage
    const currentCardNumber = cardData?.CardNumber; // Get card number from Redux store

    if (!currentCardNumber) {
      navigate(-1); // Go back to the previous page
      return; // Exit the effect early
    }

    // if (currentCardNumber == null) {
    //   navigate('/card_admin');
    //   return; // Exit the effect early
    // }
  
    // Run the fetch if there is no cardData or if the stored number doesn't match the current number
    if (!cardPrompts ||!cardData || Object.keys(cardData).length === 0 ) {
      setIsLoading(true);
      const payload = {
        userId: currentUser.userId,
        cardNumber: currentCardNumber,
        policyNumber: cardData?.PolicyNumber,
      };
  
      fetch(
        `https://efs-getpolicy.azurewebsites.net/api/FuelLink-getCardAdmin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          dispatch(updateCardData(data));
          // sessionStorage.setItem("editingCardNumber", cardData.CardNumber);
          // console.log("Card Data: ", data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    } else {
    }
  }, [dispatch, currentUser, cardData]);

  useEffect(() => {
    // console.log("CardData: ", cardData);
  },[cardData]);

  // useEffect(() => {
  //   // Update formData based on Redux store
  //   if (cardData && cardData.cardDetails && cardData.cardDetails.result) {
  //     const { cardNumber, header } = cardData.cardDetails.result;
  //     setFormData({
  //       CardNumber: cardNumber,
  //       DriverId: cardData.DriverId || "",
  //       DriverName: cardData.DriverName || "",
  //       UnitNumber: cardData.UnitNumber || "",
  //       PolicyNumber: header.policyNumber,
  //       CrossReference: header.companyXRef || "",
  //       CardStatus: header.status,
  //       HandEnter: header.handEnter,
  //       Information: header.infoSource,
  //     });
  //   }
  // }, [cardData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedCardData;

    if (["DriverId", "DriverName", "UnitNumber"].includes(name)) {
      // If the changed field is one of the new fields, update the cardData directly
      updatedCardData = {
        ...cardData,
        [name]: value,
      };
    } else {
      // Otherwise, update the nested cardDetails property
      updatedCardData = {
        ...cardData,
        cardDetails: {
          ...cardData.cardDetails,
          result: {
            ...cardData.cardDetails.result,
            header: { ...cardData.cardDetails.result.header, [name]: value },
          },
        },
      };
    }

    dispatch(setCardData(updatedCardData));
  };

  const handlePromptEdit = (rowData) => {
    navigate("/EditCardPrompts", { state: { rowData } });
  };

  // Define function to handle editing of limits
  const handleLimitsEdit = (rowData) => {
    navigate("/EditCardLimits", { state: { rowData } });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(cardData);
    const payload = {
      userId: currentUser.userId,
      result: {
        cardNumber: cardData.CardNumber,
        header: cardData.cardDetails.result.header,
        infos: cardData.combinedPrompts || [],
        limits: cardData.combinedLimits || [],
        locationGroups: cardData.cardDetails.result.locationGroups || [],
        locations: cardData.cardDetails.result.locations || [],
        timeRestrictions: cardData.cardDetails.result.timeRestrictions || [],
      },
    };
    // console.log("Payload: ", payload);
    try {
      const response = await fetch(
        `https://efs-getpolicy.azurewebsites.net/api/fuellink-efsSetCardData?`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      
      if (result && result.data) {
        // Dispatch the update to Redux
        dispatch(updateCardData({
          ...cardData, // Spread the existing cardData
          ...result.data // Override with the new data from the result
        }));
        // Set alert message for successful update
        setAlertType("success");
        setAlertMessage("Successfully updated the card data.");
        // console.log("Response: ", result.data);
      }
    } catch (error) {
      setAlertType("error");
      setAlertMessage(`Failed to update card data: ${error.message}`);
      console.error('Submission failed', error);
    } finally {
      setIsLoading(false);
    }
  };

  const currentPolicyNumber = cardData?.cardDetails?.result?.header?.policyNumber;
  const currentPolicyNumberInt = parseInt(currentPolicyNumber, 10);
  const isPolicyNumberInList = policies.some(policy => parseInt(policy.PolicyNumber, 10) === currentPolicyNumberInt);

  const combinedPromptsColumns = React.useMemo(
    () => [
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Info ID",
        accessor: "infoId",
      },
      {
        Header: "Length Check",
        accessor: "lengthCheck",
      },
      {
        Header: "Match Value",
        accessor: "matchValue",
      },
      {
        Header: "Maximum",
        accessor: "maximum",
      },
      {
        Header: "Minimum",
        accessor: "minimum",
      },
      {
        Header: "Report Value",
        accessor: "reportValue",
      },
      {
        Header: "Validation Type",
        accessor: "validationType",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Edit",
        Cell: ({ row }) =>
          row.original.Source === "Card" ? (
            <button
              onClick={() => handlePromptEdit(row.original)}
              className="subTableEdit"
              disabled={!isPolicyNumberInList}
            >
              Edit
            </button>
          ) : null,
      },
    ],
    [isPolicyNumberInList]
  );

  // Define columns for the combinedLimits subtable
  const combinedLimitsColumns = React.useMemo(
    () => [
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Limit Id",
        accessor: "limitId",
      },
      {
        Header: "Amount",
        accessor: "limit",
      },
      {
        Header: "Hours",
        accessor: "hours",
      },
      {
        Header: "Auto Roll Map",
        accessor: "autoRollMap",
      },
      {
        Header: "Auto Roll Max",
        accessor: "autoRollMax",
      },
      {
        Header: "Min Hours",
        accessor: "minHours",
      },
      {
        Header: "Edit",
        Cell: ({ row }) =>
          row.original.Source === "Card" ? (
            <button
              onClick={() => handleLimitsEdit(row.original)}
              className="subTableEdit"
              disabled={!isPolicyNumberInList}
            >
              Edit
            </button>
          ) : null,
      },
    ],
    [isPolicyNumberInList]
  );

  return (
    <div className="mobile-container mx-auto px-4 sm:px-6 lg:px-8">
    <MobileHeader theme={theme} />
      {isLoading && <Spinner />}
      {alertMessage && (
        <div
          className={`alert p-4 rounded ${
            alertType === "success"
              ? "bg-green-200 text-green-600"
              : "bg-red-200 text-red-600"
          } flex justify-between items-center`}
        >
          {alertMessage}
          <button onClick={clearAlert} className="text-lg font-bold">
            ×
          </button>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-2 m-8">
        {/* Card Number */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Card Number
          </label>
          <input
            type="text"
            name="CardNumber"
            onChange={handleChange}
            value={cardData.CardNumber}
            className="mt-1 p-2 w-full border rounded-md"
            readOnly
          />
        </div>

        <div>
  <label className="block text-sm font-medium text-gray-700">
    Policy#
  </label>
  <select
    name="policyNumber"
    onChange={handleChange}
    value={currentPolicyNumber || ""}
    className="mt-1 p-2 w-full border rounded-md"    
  >
    {currentPolicyNumber && !isPolicyNumberInList && (
      <option value={currentPolicyNumber} disabled>
        {currentPolicyNumber} (Contract Not Enrolled)
      </option>
    )}
    <option value="" disabled={!currentPolicyNumber}>
      Select a policy
    </option>
    {policies &&
      policies.map((policy, index) => (
        <option key={policy.ID} value={policy.PolicyNumber}>
          {policy.PolicyNumber} - {policy.Description}
        </option>
      ))}
  </select>
</div>

         {/* Card Status */}
         <div>
          <label className="block text-sm font-medium text-gray-700">
            Card Status
          </label>
          <select
            name="status"
            onChange={handleChange}
            value={cardData?.cardDetails?.result?.header?.status || ""}
            className="mt-1 p-2 w-full border rounded-md"
            disabled={!isPolicyNumberInList}
          >
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
            <option value="HOLD">Hold</option>
          </select>
        </div>

        <div>
        <label className="block text-sm font-medium text-gray-700">
          Cross Reference
        </label>
        <input
          type="text"
          name="companyXRef"
          onChange={handleChange}
          value={cardData?.cardDetails?.result?.header?.companyXRef || ""}
          className="mt-1 p-2 w-full border rounded-md"
          disabled={!isPolicyNumberInList}
        />
      </div>

        <div>
  <label className="block text-sm font-medium text-gray-700">
    Driver Id
  </label>
  <p className="text-xs text-gray-500 mt-1">Add a card prompt for 'Driver ID' (DRID) to update this value.</p>
  <input
    type="text"
    name="DriverId"
    readOnly
    value={cardData.DriverId || ""}
    className="mt-1 p-2 w-full border rounded-md bg-stone-100 cursor-not-allowed"
    title="Add a card prompt for 'Driver ID' (DRID) to update this value."
  />
  
</div>

<div>
  <label className="block text-sm font-medium text-gray-700">
    Driver Name
  </label>
  <p className="text-xs text-gray-500 mt-1">Add a card prompt for 'Driver Name' (NAME) to update this value.</p>
  <input
    type="text"
    name="DriverName"
    readOnly
    value={cardData.DriverName || ""}
    className="mt-1 p-2 w-full border rounded-md bg-stone-100 cursor-not-allowed"
    title="Add a card prompt for 'Driver Name' (NAME) to update this value."
  />
  
</div>

<div>
  <label className="block text-sm font-medium text-gray-700">
    Unit #
  </label>
  <p className="text-xs text-gray-500 mt-1">Add a card prompt for 'Unit #' (UNIT) to update this value.</p>
  <input
    type="text"
    name="UnitNumber"
    readOnly
    value={cardData.UnitNumber || ""}
    className="mt-1 p-2 w-full border rounded-md bg-stone-100 cursor-not-allowed"
    title="Add a card prompt for 'Unit #' (UNIT) to update this value."
  />
  
</div>
     

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Hand Enter
          </label>
          <select
            name="handEnter"
            onChange={handleChange}
            value={cardData?.cardDetails?.result?.header?.handEnter || ""}
            className="mt-1 p-2 w-full border rounded-md"
            disabled={!isPolicyNumberInList}
          >
            <option value="ALLOW">Allow Hand Enter</option>
            <option value="DISALLOW">Disallow Hand Enter</option>
            <option value="POLICY">Policy</option>
          </select>
        </div>   

        {/* Information */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Information Source (Prompts)
          </label>
          <p className="text-xs text-gray-500 mt-1">Select the source for prompts. If 'Both' is selected then the card
              prompts will always override the policy prompts.</p>
          <select
            name="infoSource"
            onChange={handleChange}
            value={cardData?.cardDetails?.result?.header?.infoSource || ""}
            className="mt-1 p-2 w-full border rounded-md"
            disabled={!isPolicyNumberInList}
          >
            <option value="Card">Card</option>
            <option value="Policy">Policy</option>
            <option value="Both">Both</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Limits Source
          </label>
          <p className="text-xs text-gray-500 mt-1">Select the source for limits. If 'Both' is selected then the card
              limits will always override the policy limits.</p>
          <select
            name="limitSource"
            onChange={handleChange}
            value={cardData?.cardDetails?.result?.header?.limitSource || ""}
            className="mt-1 p-2 w-full border rounded-md"
            disabled={!isPolicyNumberInList}
          >
            <option value="Card">Card</option>
            <option value="Policy">Policy</option>
            <option value="Both">Both</option>
          </select>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="mt-4 px-4 py-2 text-white rounded themed-button"
        >
          Update Card
        </button>
      </form>
      <div className="mt-8">
      <div className="my-4">
          <div
            className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded-md"
            role="alert"
          >
            <p className="font-bold">Note</p>            
            <p>
               Add policy prompts and limits from the 'Policies' section in your Admin Settings. 'INFO_POOL' type prompts cannot be managed here, you'll need to manage these in the EFS Manager if desired. To change the value of an 'INFO_POOL', delete the 'INFO_POOL' and create a new prompt.
            </p>
          </div>
        </div>
        {combinedPrompts && (
          <SubTable
            columns={combinedPromptsColumns}
            data={combinedPrompts}
            title="Prompts"
            infoMessage="You must click 'Update Card' to save your changes!"            
          >
            <button
              className="themed-button"
              onClick={() =>
                navigate("/EditCardPrompts", { state: { isCreating: true } })
              }
              disabled={!isPolicyNumberInList}
            >
              New Prompt
            </button>
          </SubTable>
        )}
      </div>
      <div className="mt-8">
        {combinedLimits && (
          <SubTable
            columns={combinedLimitsColumns}
            data={combinedLimits}
            title="Limits"
            infoMessage="You must click 'Update Card' to save your changes!"            
          >
            <button
              className="themed-button"
              onClick={() =>
                navigate("/EditCardLimits", { state: { isCreating: true } })
              }
              disabled={!isPolicyNumberInList}
            >
              New Limit
            </button>
          </SubTable>
        )}
      </div>
    </div>
  );
};

export default CardsAdminEditAdd;
