import React from 'react';

const ConfirmDeleteModal = ({ onClose, onConfirm, invoice }) => {
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center modal">
            <div className="bg-white p-5 rounded-lg shadow-lg relative modal-content">
                <button onClick={onClose} className="absolute top-0 right-0 mt-2 mr-2 text-lg font-semibold text-gray-700 hover:text-gray-900">
                    &times;
                </button>
                <h2 className="text-lg font-bold mb-4">Are You Sure?</h2>
                <p>This action cannot be undone.</p>
                <div className="mt-4 flex justify-end">
                    <button onClick={onClose} className="px-4 py-2 mr-2 rounded bg-gray-500 hover:bg-gray-600 text-white">No, Nevermind</button>
                    <button onClick={() => onConfirm(invoice)} className="px-4 py-2 rounded themed-button">Yes, I'm Sure</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDeleteModal;
