import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from './ThemeContext';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { setTenantId } from './firebase';

function Registration() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [numCards, setNumCards] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [subdomain] = useState(window.location.hostname.split('.')[0]);
  // const subdomain = 'aqha';
  const apiUrl= process.env.REACT_APP_API_URL;

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (phoneNumber.length !== 12) {
      setError('Please enter a valid phone number in the format 123-456-7890.');
      setLoading(false);
      return;
    }

    if (!isPasswordStrong(password)) {
      setError('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a digit, and a special character.');
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'createUser',
          payload: {
            firstName,
            lastName,
            companyName,
            email,
            phoneNumber,
            password,
            subdomain,
          },
        }),
      });

      const result = await response.json();
      console.log(result);
      if (response.ok) {
        const auth = getAuth();
        setTenantId(result.tenantId);  // Set the tenantId before signing in
        auth.tenantId = result.tenantId;  // Explicitly set the tenantId on the auth instance
        await signInWithCustomToken(auth, result.customToken);
        navigate('/dashboard');
      } else {
        console.log(result.error);
        setError(result.error || 'An error occurred');
      }
    } catch (err) {
      console.log(err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const isPasswordStrong = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  const logoClass = theme.logoSize === 'small' ? 'w-1/4' : theme.logoSize === 'medium' ? 'w-4/5' : 'w-full';

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {loading && <Spinner />}
      <div className="flex-1 flex items-center justify-center bg-white">
          <div className="text-center justify-center">
            <img src={theme.loginImage} alt="Logo" className={`${logoClass} h-auto mx-auto`} />
            {theme.onBehalfOf && (
              <p className="mt-2 text-sm text-gray-700">
                A FuelLink Portal created for <strong>{theme.onBehalfOf}</strong>. <a href="https://www.fuellink.net" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Learn more</a>
              </p>
            )}
          </div>
        </div>
      <div className="flex-1 flex items-center justify-center p-4" style={{ backgroundColor: theme.loginPrimaryBG }}>
        <div
          style={{
            background: 'rgba(245, 245, 245, 0.9)',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
          }}
          className="p-8 rounded-lg w-96"
        >
          <h2 className="text-2xl mb-1 text-center">Sign Up Today!</h2>
          <p className="mb-4 text-center">Create your account to access exclusive fuel card benefits.</p>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleRegister}>
            <div className="mb-4">
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Company Name (optional)"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="w-full p-2 border rounded"
              />
            </div>            
            <div className="mb-4">
              <input
                type="tel"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="password"
                placeholder="Create Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            {/* <div className="mb-4">
                <label>Number of Cards?</label>
              <select
                value={numCards}
                onChange={(e) => setNumCards(e.target.value)}
                className="w-full p-2 border rounded"
                required
              >
                {[...Array(20).keys()].map((i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div> */}
            <button
              type="submit"              
              className="w-full themed-login-button text-white p-2 rounded mb-4"
            >
              Register
            </button>
          </form>
          <div className="text-center">
            <div>
              <Link to="/" className="text-blue-500 hover:underline">Already have an account? Login</Link>
            </div>
            <div>
              <Link to="/signup" className="text-blue-500 hover:underline">Redeem an Invitation Code</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;
