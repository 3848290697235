import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import MainTableV2 from './MainTableV2';
import { useNavigate } from 'react-router-dom';
import PageHeader from './PageHeader';
import MobileHeader from './MobileHeader';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ToggleSwitch from './ToggleSwitch';

const UserUserTable = () => {
  const { theme } = useTheme();
  const primaryColor = theme.primary;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Initially set to true
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const clearAlert = () => setAlertMessage(null);
  const apiUrl= process.env.REACT_APP_API_URL;


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/dataServer`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            action: 'getUsers',
            payload: { userId: currentUser?.userId }
          })
        });

        if (response.ok) {
          const fetchedData = await response.json();
          // console.log(fetchedData);
          setData(fetchedData || []);                    
        } else {
          setAlertMessage(`Server Error: ${await response.text()}`);
        }
      } catch (error) {
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchData();
  }, [currentUser]);

  const handleEditClick = (row) => {
    navigate(`/EditUserForm/${row.RecordId}`, { state: { rowData: row } });
  };

  const handleToggleDisable = async (userId, isDisabled, tenantId) => {
    setLoading(true);
    try {
      const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/disableUser', { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, isDisabled, tenantId })
      });

      if (response.ok) {
        setData(prevData =>
          prevData.map(user =>
            user.UserId === userId ? { ...user, isActive: !isDisabled } : user
          )
        );
        setAlertMessage(`Successfully ${isDisabled ? 'disabled' : 'enabled'} user`);
        setLoading(false);
      } else {
        setAlertMessage(`Server Error: ${await response.text()}`);
        setLoading(false);
      }
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
      setLoading(false);
    }
  };

  const filteredData = useMemo(() => {
    if (searchQuery === '') return data;
    return data.filter((row) => {
      return Object.values(row).some((s) =>
        s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [searchQuery, data]);

  const columns = useMemo(() => [
    {
      name: 'First Name',
      selector: row => row.FirstName,
      sortable: true,
      reorder: true
    },
    {
      name: 'Last Name',
      selector: row => row.LastName,
      sortable: true,
      reorder: true
    },
    {
      name: 'Email',
      selector: row => row.Email,
      sortable: true,
      reorder: true
    },
    {
      name: 'Phone',
      selector: row => row.Phone,
      sortable: true,
      reorder: true
    },
    {
        name: 'Disabled',
        cell: row => (
          row.UserId !== 'Pending' && row.UserId !== currentUser.userId && (
            <ToggleSwitch
              isChecked={!row.isActive}
              onToggle={() => handleToggleDisable(row.UserId, row.isActive, row.TenantId)}
              primaryColor={theme.primary}
            />
          )
        ),
        ignoreRowClick: true,
      },
      {
              name: 'Edit',
              cell: row => (
                <button
                  className="themed-button"
                  onClick={() => handleEditClick(row)}
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </button>
              ),
              ignoreRowClick: true,
            }    
  ], [handleEditClick, theme.primary, currentUser.userId]);

  const filterConfig = [
    { key: 'FirstName', type: 'string' },
    { key: 'LastName', type: 'string' },
    { key: 'Email', type: 'string' },
    { key: 'Phone', type: 'string' },
  ];

  return (
    <>
      <MobileHeader theme={theme} />
      <div className='mobile-container'>
        <PageHeader
          heading="User Management"
          context="Manage user accounts"
        />

        {loading ? (
          <div className="flex items-center justify-center h-full">
            <Spinner />
          </div>
        ) : (
          <MainTableV2
            columns={columns}
            data={filteredData}
            primaryColor={primaryColor}
            loading={loading}
            alertMessage={alertMessage}
            clearAlert={clearAlert}
            title="Users"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filterConfig={filterConfig}
          >
            <button 
              className="themed-button" 
              onClick={() => navigate('/adduser')}
            >
              Add New User
            </button>
          </MainTableV2>
        )}
      </div>
    </>
  );
};

export default UserUserTable;