import React from 'react';

const VersionOutdatedBanner = ({theme}) => {
  const refreshPage = () => {
    // Force reload from the server
    window.location.reload(true);
  };  

  return (
    <div style={{
      backgroundColor: theme.secondary,
      color: theme.primaryText,
      padding: '10px',
      textAlign: 'center',
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 1000,
      opacity: 0.9,
    }}>
      <p className='mb-2'>Your application is out of date. Please refresh to get the latest version.</p>
      <button
      onClick={refreshPage}
      className='themed-button'
      >Refresh Now</button>
    </div>
  );
};

export default VersionOutdatedBanner;