import React, { useMemo, useEffect, useState } from 'react';
import SubTableV2 from "./SubTableV2";

function TransactionDetailsModal({ isOpen, details, onClose }) {
    const [formattedDetails, setFormattedDetails] = useState(null);

    useEffect(() => {
        if (details) {
            const lineItemsData = details.lineItems.map(item => ({
                ...item,
                RetailPPU: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                }).format(item.RetailPPU),
                UserPPU: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                }).format(item.UserPPU),
                UserAmount: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(item.UserAmount),
                UserTransactionFee: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(item.UserTransactionFee),
                Discount: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(item.UserDiscount) //(item.RetailPPU * item.Quantity - item.UserAmount) + item.UserTransactionFee
            }));

            const taxesData = details.transLineTaxes.map(tax => ({
                ...tax,
                amount: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(tax.amount)
            }));

            setFormattedDetails({ ...details, lineItems: lineItemsData, transLineTaxes: taxesData });
        }
    }, [details]);

    const lineItemsColumns = useMemo(() => [
        {
            name: 'Category',
            selector: row => row.Category,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Retail Price',
            selector: row => row.RetailPPU,
            sortable: true,
        },
        {
            name: 'Paid Price',
            selector: row => row.UserPPU,
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: row => row.Quantity,
            sortable: true,
        },
        {
            name: 'Trans Fee',
            selector: row => row.UserTransactionFee,
            sortable: true,
        },
        {
            name: 'Discount',
            selector: row => row.Discount,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => row.UserAmount,
            sortable: true,
        }
    ], []);

    const transLineTaxesColumns = useMemo(() => [
        {
            name: 'Tax Description',
            selector: row => row.taxDescription,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => row.amount, // Assuming already formatted as currency
            sortable: true,
        }
    ], []);

    const transactionInfosColumns = useMemo(() => [
        {
            name: 'Type',
            selector: row => row.Type,
            sortable: true,
        },
        {
            name: 'Value',
            selector: row => row.Value,
            sortable: true,
        }
    ], []);



    if (!isOpen || !formattedDetails) { 
        return null;
    }

    return (
        <div className="transaction-modal">
            <div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>
            <div className="transaction-modal-content mx-2 mt-14 sm:mt-24 md:mt-0 lg:mt-0">
                <button className="absolute top-2 right-2 themed-button" onClick={onClose}>Close</button>
                <h3 className="text-xl font-bold mb-4">Transaction Details</h3>


                <div className="mb-4">
                    <p><strong>Transaction ID:</strong> {details.transactions.TransactionId}</p>
                    <p><strong>POS Date:</strong> {new Date(details.transactions.POSDate).toLocaleDateString()}</p>
                    <p><strong>Transaction Date:</strong> {new Date(details.transactions.TransactionDate).toLocaleDateString()}</p>
                    <p><strong>Authorization Code:</strong> {details.transactions.AuthCode}</p>
                    <p><strong>Card Number:</strong> {details.transactions.CardNumber}</p>
                    <p><strong>Hand Entered:</strong> {details.transactions.HandEntered ? 'Yes' : 'No'}</p>
                    <p><strong>Location ID:</strong> {details.transactions.LocationId}</p>
                    <p><strong>Chain ID:</strong> {details.transactions.chainId}</p>
                    <p><strong>Location Name:</strong> {details.transactions.locationName}</p>
                    <p><strong>Location State:</strong> {details.transactions.locationState}</p>
                </div>

                <hr className="mb-4" />

                <SubTableV2
                    title="Line Items"
                    columns={lineItemsColumns}
                    data={formattedDetails.lineItems}
                />
                <SubTableV2
                    title="Taxes Paid (Included in the amount)"
                    columns={transLineTaxesColumns}
                    data={formattedDetails.transLineTaxes}  // Use formatted data for taxes
                />
                <SubTableV2
                    title="Prompts / Information"
                    columns={transactionInfosColumns}
                    data={details.transactionInfos}
                />


                <button className="mt-4 themed-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

export default TransactionDetailsModal;
