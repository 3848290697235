import React, { useState } from 'react';
import AchPaymentModal from './AchPaymentModal';
import { useAuth } from './AuthContext';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const BalanceCard = ({ theme, lowBalance, zeroBalance }) => {
  const [showModal, setShowModal] = useState(false);
  const { currentUser, refreshUserBalance } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const formattedBalance = currentUser.balance.toFixed(2);
  const navigate = useNavigate();

  const handleRefreshClick = async () => {
    setIsLoading(true);
    await refreshUserBalance();
    setIsLoading(false);
  };

  const handleButtonClick = () => {
    if (currentUser && !currentUser.SetupComplete) {
      navigate('/paymentinfo');
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {currentUser.hasSubaccounts && currentUser.isPolicyAdmin && !currentUser.cardGroupID && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 my-4 rounded-md shadow-sm">
          <p className="font-semibold"><FontAwesomeIcon icon={faCircleInfo} /> PRO TIP: Funding Your Account</p>
          <p>
            Accounts that utilize subaccounts must be funded individually. Select the account from the dropdown above to fund the account.
          </p>
        </div>
      )}
      <div
        style={{
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
          background: 'linear-gradient(to bottom, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.9) 50%, rgba(255,255,255,0.9) 100%)'
        }}
        className={`rounded-lg p-4 mb-4 flex justify-between items-center w-full`}
      >
        <div className="flex-1">
          <span className="text-xl font-semibold">
            {currentUser.hasSubaccounts && !currentUser.cardGroupID ? 'Policy Limit' : currentUser.prePay ? 'Available Balance' : 'Remaining Credit'}
          </span>
          <div className="flex items-center">
            <button onClick={handleRefreshClick} className="mr-2 text-gray-700 hover:text-gray-900" title="Refresh Balance">
              <FontAwesomeIcon icon={faSync} />
            </button>
            <span className="text-3xl font-bold">${formattedBalance}</span>
          </div>
        </div>
        {(currentUser?.cardGroupID || (!currentUser?.cardGroupID && !currentUser.hasSubaccounts)) && (
          <div className="flex-1 text-right">
            {isLoading ? (
              <Spinner />
            ) : (
              <button
                className={`themed-button rounded-lg px-6 py-2 font-semibold ${lowBalance || zeroBalance ? 'throb' : ''}`}
                onClick={handleButtonClick}
              >
                {currentUser && currentUser.prePay ? 'Fund Account' : 'Make A Payment'}
              </button>
            )}
          </div>
        )}
        <AchPaymentModal isOpen={showModal} onClose={handleCloseModal} />
      </div>
    </>
  );
};

export default BalanceCard;
