import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPrint, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import Spinner from './Spinner';
import InvoicePaymentModal from './InvoicePaymentModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51M10O5Ic8rWsw14ZeB3eCakO0K4jTJapqectgPeAzOVzd2BQIKz5ZBJEvCAI5t1P7f3z87J3VKwZuipxhQD6rRTH0076xiVbQV');

const PDFViewer = () => {
  const canvasRef = useRef(null);
  const location = useLocation();
  const { currentUser } = useAuth();
  const { invoiceNumber, invoiceDate, transactionId, InvoiceStatus, TransactionIdRef } = location.state;
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showInvoicePaymentModal, setShowInvoicePaymentModal] = useState(true);
  const invoiceAmount = location.state.invoiceAmount;
  const [clientSecret, setClientSecret] = useState(null);
  const [PaymentIntentId, setPaymentIntentId] = useState(null);


  const centsAmount = Math.round(invoiceAmount * 100);

  const createPaymentIntent = async (userId, centsAmount, invoiceNumber) => {
    try {
        const response = await fetch('https://fuellink-stripe.azurewebsites.net/api/createPaymentIntentAmount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                paymentInfo: {
                    amount: centsAmount,
                    invoices: [invoiceNumber]
                }
            }),
        });

        const contentType = response.headers.get("Content-Type");
        let data;

        if (contentType && contentType.includes("application/json")) {
            data = await response.json();
        } else {
            data = await response.text();
        }
  
        console.log("response: ", data);

        if (!response.ok) {
            const errorMessage = data.message || data || "Failed to create payment intent. Please contact your card provider or try again later.";
            // alert(`Error: ${errorMessage}`);
            throw new Error(errorMessage);
        }
        return data;
    } catch (error) {
        console.error('Error creating payment intent:', error);
        alert(`Error: ${error.message || error}. Please contact your card provider or try again later.`);
        return null; // Return null in case of an error
    }
};


  useEffect(() => {
    setLoading(true);
    // Set worker URL
    pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.js`;

    console.log('Transaction ID:', transactionId);

    // Prepare the request body
    const requestBody = JSON.stringify({
      TenantId: currentUser.tenantId,
      TransactionId: transactionId,
      PolicyNumber: currentUser.policyNumber,
      InvoiceNumber: invoiceNumber,
      InvoiceDate: invoiceDate,
      InvoiceStatus: InvoiceStatus,
      TransactionIdRef: TransactionIdRef,
    });

    // Fetch PDF blob from Azure Function App
    fetch('https://fuellink-db-ui.azurewebsites.net/api/generateInvoicePdf?', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: requestBody,
})
.then(response => {
  console.log('Server Response:', response);  // Log the raw server response
  return response.blob();
})
.then(blob => {
    const newPdfBlobUrl = URL.createObjectURL(blob);
    setPdfBlobUrl(newPdfBlobUrl);

  // Fetch PDF and render it
  const loadingTask = pdfjsLib.getDocument(newPdfBlobUrl); // Use newPdfBlobUrl here
  loadingTask.promise.then((pdf) => {
    pdf.getPage(1).then((page) => {
      const scale = 1.5;
      const viewport = page.getViewport({ scale });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      page.render(renderContext);
      setLoading(false);
    });
  });
});
  }, [invoiceNumber, invoiceDate, transactionId, currentUser]);

  const handleDownload = () => {
    if (pdfBlobUrl) {
      const link = document.createElement('a');
      link.href = pdfBlobUrl;
      link.download = 'invoice.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handlePrint = () => {
    if (pdfBlobUrl) {
      const printWindow = window.open();
      printWindow.document.write('<html><head><title>Print PDF</title></head><body>');
      printWindow.document.write('<iframe width="100%" height="100%" src="' + pdfBlobUrl + '"></iframe>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();      
    }
  };
  const handlePay = async () => {
    console.log("handlePay called");
    setLoading(true);
    const clientSecretFromAPI = await createPaymentIntent(currentUser.userId, centsAmount, invoiceNumber);
    setClientSecret(clientSecretFromAPI.clientSecret);
    setPaymentIntentId(clientSecretFromAPI.paymentIntentId);
    console.log(clientSecretFromAPI);
    setShowInvoicePaymentModal(true);
    setLoading(false);
};

  return (
    <>
   
   {showInvoicePaymentModal && clientSecret && (
    <Elements stripe={stripePromise } options={{ clientSecret: clientSecret }}>
        <InvoicePaymentModal 
            isOpen={setShowInvoicePaymentModal} 
            onClose={() => setShowInvoicePaymentModal(false)} 
            totalAmount={invoiceAmount}
            userid={currentUser.userId}
            paymentIntentId={PaymentIntentId}
            centsAmount={centsAmount}
        />
    </Elements>
)}
    <div className="container mx-auto p-4">
    

      <div className="flex justify-start space-x-4 mb-8">
        <button onClick={handlePrint} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faPrint} className="mr-2" />
          Print Invoice
        </button>
        <button onClick={handleDownload} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faDownload} className="mr-2" />
          Download Invoice
        </button>
        {(InvoiceStatus === 'Open' || InvoiceStatus === 'Overdue') && (
          <button onClick={handlePay} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
            <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
            Pay Invoice
          </button>
        )}
      </div>
      {loading && <Spinner />}
      <canvas ref={canvasRef}></canvas>
    </div>
    </>
  );
};

export default PDFViewer;
