import React from 'react';
import { useNavigate } from 'react-router-dom';



const FuelingLocations = () => {
    const navigate = useNavigate();
  return (
    <div className="max-w-7xl mx-auto p-6 sm:p-10">
      <h1 className="text-4xl font-bold mb-8">Extensive Fueling Network</h1>
      <div className="mb-10">
        <p className="mb-4"><strong>Widespread Coverage:</strong> Access discounts at over 4,000 fueling locations nationwide.</p>
        <p className="mb-4"><strong>Trusted Partners:</strong> Includes top truck stop chains like AmBest, Love's, Petro, TA, Road Ranger, Maverik, Casey's, and Racetrac.</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Headliner Logos */}
        <div className="col-span-1 flex justify-center items-center">
          <img src="https://cdn.ta-petro.com/media/bn0izr2u/ta-tri-logo-primary.svg" alt="TA/Petro Locations" className="object-contain h-32 mx-auto" />
        </div>
        <div className="col-span-1 flex justify-center items-center">
          <img src="https://asset.brandfetch.io/id3bAbfcTb/idPe-RTYGZ.svg?updated=1668079206994" alt="Loves Locations" className="object-contain h-32 mx-auto" />
        </div>
        <div className="col-span-1 flex justify-center items-center">
          <img src="https://am-best.com/Portals/_default/Skins/AMBEST/images/ambest-logo.png" alt="AmBest Locations" className="object-contain h-32 mx-auto" />
        </div>
      </div>
      <hr className="my-8 border-gray-300" />
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {/* Smaller Logos */}
        <div className="flex justify-center items-center">
          <img src="https://www.racetrac.com/images/logo-racetrac-dynamic.png" alt="Racetrac Locations" className="object-contain h-24 mx-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src="https://petroleumwholesale.com/wp-content/uploads/2019/02/logo-128x88.png" alt="Petro Wholesale Locations" className="object-contain h-24 mx-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src="https://www.quarlesinc.com/wp-content/uploads/2023/12/Quarles-logo-color-rgb-137x29px.svg" alt="Quarles Locations" className="object-contain h-24 mx-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src="https://asset.brandfetch.io/idnc6TYZIj/idOVelfTwu.svg?updated=1653033099524" alt="Casey's Locations" className="object-contain h-24 mx-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src="https://www.sappbros.net/assets/images/common/logo.png" alt="Sapp Bros Locations" className="object-contain h-24 mx-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src="https://asset.brandfetch.io/idf93SJaRM/idlfDPy63t.svg?updated=1689929442215" alt="Maverik Locations" className="object-contain h-24 mx-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src="https://asset.brandfetch.io/idLsFigDzG/idATkLAWk6.png?updated=1716191262268" alt="Road Ranger Locations" className="object-contain h-24 mx-auto" />
        </div>
        <div className="flex justify-center items-center">
          <img src="https://asset.brandfetch.io/idm8N2vJGR/idSjj2zDBy.svg?updated=1717129762588" alt="Speedway Locations" className="object-contain h-24 mx-auto" />
        </div>
      </div>
      <button
        onClick={() => navigate(-1)}
        className="themed-button py-2 px-4 mb-4 mt-20"
      >
        Back
      </button>
    </div>
  );
};

export default FuelingLocations;


// import React from 'react';

// const FuelingLocations = () => {
//   return (
//     <div className="max-w-7xl mx-auto p-6 sm:p-10">
//       <h1 className="text-4xl font-bold mb-8">Extensive Fueling Network</h1>
//       <div className="mb-10">
//         <p className="mb-4"><strong>Widespread Coverage:</strong> Access discounts at over 4,000 fueling locations nationwide.</p>        
//         <p className="mb-4"><strong>Trusted Partners:</strong> Includes top truck stop chains like AmBest, Love's,, Petro, TA, Road Ranger, Maverik, Casey's, and Racetrac.</p>
//       </div>
//       <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
//         <img src="https://cdn.ta-petro.com/media/bn0izr2u/ta-tri-logo-primary.svg" alt="TA/Petro Locations" className="object-contain h-24 mx-auto" />
//         <img src="https://www.loves.com/-/media/Images/Logos/logo_top.ashx" alt="Loves Locations" className="object-contain h-24 mx-auto" />
//         <img src="https://am-best.com/Portals/_default/Skins/AMBEST/images/ambest-logo.png" alt="AmBest Locations" className="object-contain h-24 mx-auto" />
//         <img src="https://www.racetrac.com/images/logo-racetrac-dynamic.png" alt="Racetrac Locations" className="object-contain h-24 mx-auto" />
//         <img src="https://petroleumwholesale.com/wp-content/uploads/2019/02/logo-128x88.png" alt="Petro Wholesale Locations" className="object-contain h-24 mx-auto" />
//         <img src="https://www.quarlesinc.com/wp-content/uploads/2023/12/Quarles-logo-color-rgb-137x29px.svg" alt="Quarles Locations" className="object-contain h-24 mx-auto" />
//         <img src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQzHemohEJSwacVFfMKWlptPf7iznwzcBhfwW7QGJmxfatMBYfs" alt="Casey's Locations" className="object-contain h-24 mx-auto" />
//         <img src="https://www.sappbros.net/assets/images/common/logo.png" alt="Sapp Bros Locations" className="object-contain h-24 mx-auto" />
//         <img src="https://maverik.com/media/images/maverik-logo.png" alt="Maverik Locations" className="object-contain h-24 mx-auto" />
//         <img src="https://149516224.v2.pressablecdn.com/wp-content/uploads/2023/05/Road-Ranger-Logo-1200-300x172.jpg" alt="Road Ranger Locations" className="object-contain h-24 mx-auto" />
//       </div>
//     </div>
//   );
// };

// export default FuelingLocations;
