import React, { useEffect, useRef } from 'react';

const InfoWindow = ({ google, map, position, content, onCloseClick }) => {
  const infoWindowRef = useRef(null);

  useEffect(() => {
    if (google && google.maps && map && position && !infoWindowRef.current) {
      infoWindowRef.current = new google.maps.InfoWindow({
        content,
        position,
      });

      infoWindowRef.current.addListener('closeclick', onCloseClick);
      infoWindowRef.current.open(map);
    }

    return () => {
      if (infoWindowRef.current) {
        infoWindowRef.current.close();
        infoWindowRef.current = null;
      }
    };
  }, [google, map, position, content, onCloseClick]);

  return null;
};

export default InfoWindow;
