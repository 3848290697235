import React from 'react';

const TankHowTo = () => {
  const screenshots = [
    '/path/to/screenshot1.jpg',
    '/path/to/screenshot2.jpg',
    '/path/to/screenshot3.jpg'
  ];

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-4xl mx-auto mt-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
        Fund Your Fuel Cards Through Tank Payments
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        {screenshots.map((src, index) => (
          <div key={index} className="w-full flex flex-col items-center">
            <img 
              src={src} 
              alt={`Screenshot ${index + 1}`} 
              className="rounded-lg shadow-lg border border-gray-300"
            />
          </div>
        ))}
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">Instructions:</h3>
        <ol className="list-decimal list-inside space-y-2 text-gray-600">
          <li>Navigate to the "Transfer" tab and click "Fuel Card".</li>
          <li>Enter the amount to add along with an optional memo for recordkeeping.</li>
          <li>Tap "Submit Transfer".</li>
        </ol>
      </div>
    </div>
  );
};

export default TankHowTo;
