import React, { useState, useEffect } from "react";
import { useTheme } from "./ThemeContext";
import Spinner from "./Spinner";
//import { useSelector } from "react-redux";
import PageHeader from "./PageHeader";
import { useAuth } from "./AuthContext";

const InviteContractUser = () => {
  const {currentUser} = useAuth();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
//const policies = useSelector((state) => state.policies.activePolicies);
  const [contracts, setContracts] = useState([]);


  const { theme } = useTheme();

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getContractsAdmin?', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: currentUser.userId }) 
        });
        if (response.ok) {
          const data = await response.json();
          setContracts(data.contracts);
        } else {
          throw new Error('Failed to fetch contracts');
        }
      } catch (error) {
        console.error('Error fetching contracts:', error);
      }
    };
  
    fetchContracts();
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // if (policyNumber < 1 || policyNumber > 500) {
    //   setError("Policy number must be a whole number between 1 and 500.");
    //   return;
    // }

    const payload = {
      TenantId: theme.tenantId,
      UserId: "Pending",
      Email: email,
      ContractNumber: contractNumber,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
    };

    try {
      const response = await fetch(
        "https://fuellink-db-ui.azurewebsites.net/api/inviteContractUser?",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const responseData = await response.json();
          setSuccessMessage(responseData.message);
        } else {
          const textData = await response.text();
          setSuccessMessage(textData);
        }
        // Clear the form fields
        setEmail("");
        setContractNumber("");
        setFirstName("");
        setLastName("");
        setPhone("");
      } else {
        const responseData = await response.text();
        setError("Failed to submit form: " + responseData);
      }
    } catch (err) {
      setError("An error occurred.");
    }
    setIsLoading(false);
  };

  return (
    <>
      <PageHeader
        heading="Invite a Contract User"
        context="Contract users are granted administrative privileges unique to their respective contracts."
      />
      <div className="flex flex-col items-center justify-center mt-16">
        <div className="flex flex-col items-center justify-center mt-2"></div>

        {isLoading && <Spinner />}
        {successMessage && (
          <div className="mb-4 text-green-500">{successMessage}</div>
        )}
        {error && <div className="mb-4 text-red-500">{error}</div>}

        <form
          className="w-1/3 p-6 rounded shadow-lg bg-gradient-to-b from-white via-gray-100 to-white"
          onSubmit={handleSubmit}
        >
          <h1 className="text-2xl mb-4">Invite a Contract User</h1>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              className="w-full px-3 py-2 mt-1 text-gray-900 border rounded-md focus:outline-none focus:border-blue-500"
              type="text"
              id="firstName"
              name="firstName"
              value={firstName}
              required
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              className="w-full px-3 py-2 mt-1 text-gray-900 border rounded-md focus:outline-none focus:border-blue-500"
              type="text"
              id="lastName"
              name="lastName"
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="phone"
            >
              Phone
            </label>
            <input
              className="w-full px-3 py-2 mt-1 text-gray-900 border rounded-md focus:outline-none focus:border-blue-500"
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full px-3 py-2 mt-1 text-gray-900 border rounded-md focus:outline-none focus:border-blue-500"
              type="email"
              id="email"
              name="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-4">
  <label className="block text-sm font-medium text-gray-600" htmlFor="contract">
    Contract
  </label>
  <select
    className="w-full px-3 py-2 mt-1 text-gray-900 border rounded-md focus:outline-none focus:border-blue-500"
    id="contract"
    name="contract"
    value={contractNumber}  
    required
    onChange={(e) => setContractNumber(e.target.value)}  
  >
    <option value="" disabled>
      Select a contract
    </option>
    {contracts.map((contract, index) => (
      <option key={contract.id} value={contract.contractId}>
        {contract.contractId} - {contract.description}
      </option>
    ))}
  </select>
</div>


          <button
            className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
            style={{ backgroundColor: theme.primary }}
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default InviteContractUser;
