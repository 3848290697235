// useIdleTimeout.js
import { useState, useContext, useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { AuthContext } from './AuthContext'; // Adjust the path as necessary

const useIdleTimeout = ({ currentUser, idleTime = 300000, promptTime = 120000 }) => {
    const [showModal, setShowModal] = useState(false);
    const { logout } = useContext(AuthContext);
    const promptTimeoutRef = useRef(null);

    const handleOnIdle = () => {
        setShowModal(true);
        idleTimer.pause(); // Pause the idle timer
        promptTimeoutRef.current = setTimeout(() => {
            handleLogout();
        }, promptTime); // Logout after promptTime milliseconds
    };

    const handleStayLoggedIn = () => {
        clearTimeout(promptTimeoutRef.current);
        setShowModal(false);
        idleTimer.reset();
        idleTimer.resume();
    };

    const handleLogout = () => {
        clearTimeout(promptTimeoutRef.current);
        setShowModal(false);
        logout();
    };

    const idleTimer = useIdleTimer({
        timeout: idleTime,
        onIdle: handleOnIdle,
        debounce: 500,
        capture: true,
        events: ['mousemove', 'keydown', 'wheel', 'mousedown', 'touchstart', 'touchmove'],
        enabled: !!currentUser, // Only enable when currentUser exists
        crossTab: false,
    });

    useEffect(() => {
        if (!currentUser) {
            // Ensure modal is not shown when user is not logged in
            setShowModal(false);
            clearTimeout(promptTimeoutRef.current);
            idleTimer.reset();
        }
    }, [currentUser, idleTimer]);

    return {
        showModal,
        handleStayLoggedIn,
        handleLogout,
    };
};

export default useIdleTimeout;