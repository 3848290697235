import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import PageHeader from './PageHeader'; 
import { useAuth } from './AuthContext';

function AccountSettings() {
    const {currentUser} = useAuth();
    const handleLinkBankAccount = () => {
        // Call the Azure Function to get the Stripe URL
        fetch('https://fuellink-stripe.azurewebsites.net/api/createNewConnectedAccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: currentUser.userId })  // Pass the userId in the body
        })
        .then(response => response.json())
        .then(data => {
            if (data && data.url) {
                window.location.href = data.url;
            }
        });
    }

    const handleAccessStripeDashboard = () => {
        fetch('/get-dashboard-link?userId=' + currentUser.userId) // Replace with your backend endpoint
            .then(response => response.json())
            .then(data => {
                if (data && data.url) {
                    window.location.href = data.url;
                }
            });
    }
    

    return (
        <div className="container mx-auto p-4">
            <PageHeader
                heading="Account Settings"
                context="Edit your account settings"
            />

            <div className="mt-4">
                <button 
                    onClick={handleLinkBankAccount} 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                >
                    <FontAwesomeIcon icon={faLink} className="mr-2" />
                    Bank Account Info
                </button>
            </div>
        </div>
    );
}

export default AccountSettings;
