import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import Spinner from './Spinner';
import { useTheme } from './ThemeContext';
import { useNavigate } from 'react-router-dom';
import MobileHeader from './MobileHeader';

const ConfirmOrder = ({ formData, setIsConfirming, setError }) => {
    const { currentUser, updateHasOrderedCards } = useAuth();
    const [loading, setLoading] = useState(false);
    const { theme } = useTheme();
    const navigate = useNavigate();
    const apiUrl= process.env.REACT_APP_API_URL;
    const subdomain = window.location.hostname.split('.')[0];

    const handleConfirm = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/orderCards?`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    userId: currentUser.userId,
                    subDomain: subdomain, 
                }),
            });

            if (response.ok) {
                updateHasOrderedCards();
                navigate('/dashboard');
            } else {
                const result = await response.text();
                console.log('Error:', result);
                setError(result.error || 'An error occurred');
                setIsConfirming(false);
            }
        } catch (err) {
            setError('Error submitting order: ' + err.message);
            setIsConfirming(false);
        } finally {
            setLoading(false);
        }
    };

    // const handleConfirm = async () => {
    //     setLoading(true);
    //     try {
    //         // Log the request data instead of making the API call
    //         console.log('Request data:', {
    //             ...formData,
    //             userId: currentUser.userId,
    //         });
    
    //         // Simulate a successful API call by updating HasOrderedCards
    //         updateHasOrderedCards();
    //         navigate('/dashboard');
    //     } catch (err) {
    //         setError('Error submitting order: ' + err.message);
    //         setIsConfirming(false);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleEdit = () => {
        setIsConfirming(false);
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
        <MobileHeader theme={theme} />
        <div className="flex flex-col items-center justify-center mobile-container">
            <div className="bg-white p-8 rounded-lg shadow-md m-4 w-full lg:w-1/3">
                <h2 className="text-2xl mb-4 text-center">Confirm Your Order</h2>
                <div className="mb-4">
                    <p><strong>First Name:</strong> {formData.firstName}</p>
                    <p><strong>Last Name:</strong> {formData.lastName}</p>
                    <p><strong>Address Line 1:</strong> {formData.addressLine1}</p>
                    <p><strong>Address Line 2:</strong> {formData.addressLine2}</p>
                    <p><strong>City:</strong> {formData.city}</p>
                    <p><strong>State:</strong> {formData.state}</p>
                    <p><strong>Zip:</strong> {formData.zip}</p>
                    <p><strong>Country:</strong> {formData.country}</p>
                    {/* <p><strong>Embossed Name on Card:</strong> {formData.embossedName}</p> */}
                    <p><strong>Number of Cards:</strong> {formData.numberOfCards}</p>
                </div>
                <div className="flex justify-between">
                    <button
                        onClick={handleEdit}
                        className="w-1/2 p-2 rounded bg-gray-500 text-white mr-2"
                    >
                        Edit
                    </button>
                    <button
                        onClick={handleConfirm}
                        className="w-1/2 p-2 rounded themed-button"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    </>
    );
};

export default ConfirmOrder;
