import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const CampaignsAddEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const policies = useSelector((state) => state.policies.activePolicies);

  // If editing, get the campaignId from the location state
  const campaignId = location.state?.campaignId || null;

  // State variables
  const [campaignType, setCampaignType] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [templates, setTemplates] = useState([]);
  const [accountBalanceCondition, setAccountBalanceCondition] = useState('');
  const [accountBalanceAmount, setAccountBalanceAmount] = useState('');
  const [inactiveDays, setInactiveDays] = useState('');
  const [cardsSentStatus, setCardsSentStatus] = useState('');
  const [transactionCountCondition, setTransactionCountCondition] = useState('');
  const [transactionCount, setTransactionCount] = useState('');
  const [sendTimes, setSendTimes] = useState('');
  const [intervalDays, setIntervalDays] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [policyNumber, setPolicyNumber] = useState('');

  // State for validation errors
  const [errors, setErrors] = useState({});

  // State for checkboxes and options
  const [onAccountCreation, setOnAccountCreation] = useState(false);
  const [onCardOrder, setOnCardOrder] = useState(false);
  const [afterInactiveDaysChecked, setAfterInactiveDaysChecked] = useState(false);
  const [afterInactiveDays, setAfterInactiveDays] = useState('');
  const [sendToAllAccounts, setSendToAllAccounts] = useState(false);

  // New state variables to track which conditions are active
  const [inactiveDaysChecked, setInactiveDaysChecked] = useState(false);
  const [accountBalanceChecked, setAccountBalanceChecked] = useState(false);
  const [transactionCountChecked, setTransactionCountChecked] = useState(false);
  const [cardsSentStatusChecked, setCardsSentStatusChecked] = useState(false);

  // Reset form when campaign type changes
  const resetFields = () => {
    setAccountBalanceCondition('');
    setAccountBalanceAmount('');
    setInactiveDays('');
    setCardsSentStatus('');
    setTransactionCountCondition('');
    setTransactionCount('');
    setSendTimes('');
    setIntervalDays('');
    setOnAccountCreation(false);
    setOnCardOrder(false);
    setAfterInactiveDays('');
    setAfterInactiveDaysChecked(false);
    setSendToAllAccounts(false);
    setInactiveDaysChecked(false);
    setAccountBalanceChecked(false);
    setTransactionCountChecked(false);
    setCardsSentStatusChecked(false);
    setErrors({});
  };

  // Helper function to display an alert
  const showAlert = (message, type) => {
    setAlert({ show: true, message, type });
  };

  // Function to fetch templates (for dropdown)
  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action: 'getEmailTemplates', payload: { userId: currentUser?.userId } }),
      });
      const result = await response.json();
      if (response.ok && result.success) {
        setTemplates(result.templates);
      } else {
        showAlert(result.message || 'Error fetching templates.', 'error');
      }
    } catch (error) {
      showAlert('Error occurred while fetching templates.', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch existing campaign details for editing
  const fetchCampaignDetails = async () => {
    if (!campaignId) return;
    console.log('Fetching campaign details for ID:', campaignId);
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action: 'getCampaignById', payload: { campaignId, userId: currentUser?.userId } }),
      });
      const result = await response.json();
      if (response.ok && result.success) {
        const campaign = result.campaign;
        setCampaignType(campaign.campaignType);
        setTemplateId(campaign.templateId);
        setPolicyNumber(campaign.PolicyNumber || '');

        // Set condition flags based on whether the values are not null.
        setInactiveDaysChecked(campaign.inactiveDays !== null);
        setInactiveDays(campaign.inactiveDays || '');
        setAccountBalanceChecked(campaign.accountBalanceAmount !== null);
        setAccountBalanceCondition(campaign.accountBalanceCondition || '');
        setAccountBalanceAmount(campaign.accountBalanceAmount || '');
        setTransactionCountChecked(campaign.transactionCount !== null);
        setTransactionCountCondition(campaign.transactionCountCondition || '');
        setTransactionCount(campaign.transactionCount || '');
        setCardsSentStatusChecked(campaign.cardsSentStatus !== null);
        setCardsSentStatus(campaign.cardsSentStatus || '');

        setSendTimes(campaign.sendTimes || '');
        setIntervalDays(campaign.intervalDays || '');

        setOnAccountCreation(campaign.onAccountCreation);
        setOnCardOrder(campaign.onCardOrder);
        setAfterInactiveDaysChecked(campaign.afterInactiveDays !== null);
        setAfterInactiveDays(campaign.afterInactiveDays || '');
        setSendToAllAccounts(campaign.sendToAllAccounts);
      } else {
        showAlert(result.message || 'Error fetching campaign details.', 'error');
      }
    } catch (error) {
      showAlert('Error occurred while fetching campaign details.', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Fetch templates on component mount and when window gains focus
  useEffect(() => {
    fetchTemplates();
    if (campaignId) fetchCampaignDetails();

    const handleFocus = () => {
      fetchTemplates();
    };

    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [campaignId]);

  // Handle campaign type change
  const handleCampaignTypeChange = (e) => {
    setCampaignType(e.target.value);
    resetFields(); // Reset form when campaign type changes

    if (e.target.value === 'Welcome Email') {
        setOnAccountCreation(true);
      } else {
        setOnAccountCreation(false);
      }
  };

  // Validation function
  const validateForm = () => {
    let newErrors = {};
    if (!campaignType) {
      newErrors.campaignType = 'Campaign type is required.';
    }
    if (!templateId) {
      newErrors.templateId = 'Email template is required.';
    }
    if (currentUser.isAdmin && !policyNumber) {
      newErrors.policyNumber = 'Policy selection is required for admins.';
    }

    // At least one option must be selected, depending on campaign type
    switch (campaignType) {
      case 'Funding Instructions':
        if (!onAccountCreation && !onCardOrder && !afterInactiveDaysChecked) {
          newErrors.fundingInstructions = 'At least one option must be selected.';
        } else {
          // If 'After Inactive Days' is checked, ensure 'afterInactiveDays' is filled
          if (afterInactiveDaysChecked) {
            if (!afterInactiveDays) {
              newErrors.afterInactiveDays = 'Please enter the number of inactive days.';
            } else if (parseInt(afterInactiveDays, 10) <= 0) {
              newErrors.afterInactiveDays = 'Inactive days must be greater than zero.';
            }
          }
        }
        break;
      case 'General Marketing':
        if (!inactiveDaysChecked && !accountBalanceChecked && !sendToAllAccounts) {
          newErrors.generalMarketing = 'At least one option must be selected.';
        } else {
          if (inactiveDaysChecked) {
            if (!inactiveDays) {
              newErrors.inactiveDays = 'Please enter the number of inactive days.';
            } else if (parseInt(inactiveDays, 10) <= 0) {
              newErrors.inactiveDays = 'Inactive days must be greater than zero.';
            }
          }
          if (accountBalanceChecked) {
            if (!accountBalanceCondition || accountBalanceCondition === '') {
              newErrors.accountBalanceCondition = 'Please select a balance condition.';
            }
            if (!accountBalanceAmount) {
              newErrors.accountBalanceAmount = 'Please enter the account balance amount.';
            } else if (parseFloat(accountBalanceAmount) < 0) {
              newErrors.accountBalanceAmount = 'Account balance cannot be negative.';
            }
          }
        }
        break;
      case 'Inactive Account Reminder':
        if (!sendTimes || !intervalDays) {
          newErrors.sendTimes = 'Send times and interval days are required.';
        } else {
          if (parseInt(sendTimes, 10) <= 0) {
            newErrors.sendTimes = 'Send times must be greater than zero.';
          }
          if (parseInt(intervalDays, 10) <= 0) {
            newErrors.intervalDays = 'Interval days must be greater than zero.';
          }
        }

        if (
          !inactiveDaysChecked &&
          !accountBalanceChecked &&
          !transactionCountChecked &&
          !cardsSentStatusChecked
        ) {
          newErrors.inactiveAccountReminder = 'At least one condition must be selected.';
        } else {
          if (inactiveDaysChecked) {
            if (!inactiveDays) {
              newErrors.inactiveDays = 'Please enter the number of inactive days.';
            } else if (parseInt(inactiveDays, 10) <= 0) {
              newErrors.inactiveDays = 'Inactive days must be greater than zero.';
            }
          }
          if (accountBalanceChecked) {
            if (!accountBalanceCondition || accountBalanceCondition === '') {
              newErrors.accountBalanceCondition = 'Please select a balance condition.';
            }
            if (!accountBalanceAmount) {
              newErrors.accountBalanceAmount = 'Please enter the account balance amount.';
            } else if (parseFloat(accountBalanceAmount) < 0) {
              newErrors.accountBalanceAmount = 'Account balance cannot be negative.';
            }
          }
          if (transactionCountChecked) {
            if (!transactionCountCondition || transactionCountCondition === '') {
              newErrors.transactionCountCondition = 'Please select a transaction count condition.';
            }
            if (!transactionCount) {
              newErrors.transactionCount = 'Please enter the transaction count.';
            } else if (parseInt(transactionCount, 10) < 0) {
              newErrors.transactionCount = 'Transaction count cannot be negative.';
            }
          }
          if (cardsSentStatusChecked) {
            if (!cardsSentStatus || cardsSentStatus === '') {
              newErrors.cardsSentStatus = 'Please select a cards sent status.';
            }
          }
        }
        break;
      case 'Welcome Email':
        // No additional validation needed
        break;
      default:
        break;
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission (create or update)
  const handleSaveCampaign = async () => {
    if (!validateForm()) return;

    setLoading(true);

    const campaignPayload = {
      campaignId,
      campaignType,
      templateId,
      policyNumber: currentUser.isAdmin ? policyNumber : currentUser.policyNumber,
      conditions: {
        inactiveDays: inactiveDaysChecked ? parseInt(inactiveDays, 10) : null,
        accountBalance: accountBalanceChecked
          ? {
              condition: accountBalanceCondition,
              amount: parseFloat(accountBalanceAmount),
            }
          : null,
        transactionCount: transactionCountChecked
          ? {
              condition: transactionCountCondition,
              count: parseInt(transactionCount, 10),
            }
          : null,
        cardsSentStatus: cardsSentStatusChecked ? cardsSentStatus : null,
      },
      sendTimes: sendTimes ? parseInt(sendTimes, 10) : null,
      intervalDays: intervalDays ? parseInt(intervalDays, 10) : null,
      onAccountCreation,
      onCardOrder,
      afterInactiveDays: afterInactiveDaysChecked ? parseInt(afterInactiveDays, 10) : null,
      sendToAllAccounts,
      userId: currentUser?.userId,
    };
    console.log('Campaign payload:', campaignPayload);
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action: campaignId ? 'updateCampaign' : 'addCampaign', payload: campaignPayload }),
      });

      const result = await response.json();
      if (response.ok && result.success) {
        showAlert('Campaign saved successfully.', 'success');
        if (!campaignId) {
            setTimeout(() => navigate('/marketing'), 1500);
            }
      } else {
        showAlert(result.message || 'Error saving campaign.', 'error');
      }
    } catch (error) {
      showAlert('Error occurred while saving campaign.', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Conditional fields based on campaign type
  const renderCampaignFields = () => {
    switch (campaignType) {
      case 'Funding Instructions':
        return (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Send On:
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 text-gray-500 cursor-pointer"
                  title="Select when to send the funding instructions email."
                />
              </label>
              <div className="flex flex-col ml-4">
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => setOnAccountCreation(e.target.checked)}
                      checked={onAccountCreation}
                      className="form-checkbox"
                    />
                    <span className="ml-2">Account Creation</span>
                  </label>
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => setOnCardOrder(e.target.checked)}
                      checked={onCardOrder}
                      className="form-checkbox"
                    />
                    <span className="ml-2">Card Order</span>
                  </label>
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setAfterInactiveDaysChecked(e.target.checked);
                        if (!e.target.checked) {
                          setAfterInactiveDays('');
                        }
                      }}
                      checked={afterInactiveDaysChecked}
                      className="form-checkbox"
                    />
                    <span className="ml-2">
                      After{' '}
                      <input
                        type="number"
                        min="1"
                        value={afterInactiveDays}
                        onChange={(e) => setAfterInactiveDays(e.target.value)}
                        className="w-16 px-2 py-1 border border-gray-300 rounded-md ml-1 mr-1"
                        disabled={!afterInactiveDaysChecked}
                      />{' '}
                      days inactive
                    </span>
                  </label>
                </div>
                {afterInactiveDaysChecked && errors.afterInactiveDays && (
                  <p className="text-red-500 text-sm mt-1 ml-6">{errors.afterInactiveDays}</p>
                )}
              </div>
              {errors.fundingInstructions && (
                <p className="text-red-500 text-sm mt-1">{errors.fundingInstructions}</p>
              )}
            </div>
          </>
        );
      case 'General Marketing':
        return (
          <>          
            <div className="mb-4">
              <p className="block text-sm font-medium text-red-500 mb-2">General Marketing Campaigns Will Only Run Once.</p>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Target Accounts:
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 text-gray-500 cursor-pointer"
                  title="Select criteria for targeting accounts."
                />
              </label>
              <div className="flex flex-col ml-4">
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setInactiveDaysChecked(e.target.checked);
                        if (!e.target.checked) {
                          setInactiveDays('');
                        }
                      }}
                      checked={inactiveDaysChecked}
                      className="form-checkbox"
                    />
                    <span className="ml-2">
                      Inactive For{' '}
                      <input
                        type="number"
                        min="1"
                        value={inactiveDays}
                        onChange={(e) => setInactiveDays(e.target.value)}
                        className="w-16 px-2 py-1 border border-gray-300 rounded-md ml-1 mr-1"
                        disabled={!inactiveDaysChecked}
                      />{' '}
                      days
                    </span>
                  </label>
                  {inactiveDaysChecked && errors.inactiveDays && (
                    <p className="text-red-500 text-sm mt-1 ml-6">{errors.inactiveDays}</p>
                  )}
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setAccountBalanceChecked(e.target.checked);
                        if (!e.target.checked) {
                          setAccountBalanceAmount('');
                          setAccountBalanceCondition('');
                        }
                      }}
                      checked={accountBalanceChecked}
                      className="form-checkbox"
                    />
                    <span className="ml-2">
                      Accounts With Balance{' '}
                      <select
                        value={accountBalanceCondition}
                        onChange={(e) => setAccountBalanceCondition(e.target.value)}
                        className="px-2 py-1 border border-gray-300 rounded-md ml-1 mr-1"
                        disabled={!accountBalanceChecked}
                      >
                        <option value="">Select Condition</option>
                        <option value="Equal To">Equal To</option>
                        <option value="Greater Than">Greater Than</option>
                        <option value="Less Than">Less Than</option>
                      </select>
                      <input
                        type="number"
                        min="0"
                        value={accountBalanceAmount}
                        onChange={(e) => setAccountBalanceAmount(e.target.value)}
                        className="w-16 px-2 py-1 border border-gray-300 rounded-md ml-1 mr-1"
                        disabled={!accountBalanceChecked}
                      />
                    </span>
                  </label>
                  {accountBalanceChecked && errors.accountBalanceCondition && (
                    <p className="text-red-500 text-sm mt-1 ml-6">{errors.accountBalanceCondition}</p>
                  )}
                  {accountBalanceChecked && errors.accountBalanceAmount && (
                    <p className="text-red-500 text-sm mt-1 ml-6">{errors.accountBalanceAmount}</p>
                  )}
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => setSendToAllAccounts(e.target.checked)}
                      checked={sendToAllAccounts}
                      className="form-checkbox"
                    />
                    <span className="ml-2">All Accounts</span>
                  </label>
                </div>
              </div>
              {errors.generalMarketing && (
                <p className="text-red-500 text-sm mt-1">{errors.generalMarketing}</p>
              )}
            </div>
          </>
        );
      case 'Inactive Account Reminder':
        return (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Conditions:
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 text-gray-500 cursor-pointer"
                  title="Select conditions for the inactive account reminder."
                />
              </label>
              <div className="flex flex-col ml-4">
                {/* Inactive Days */}
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setInactiveDaysChecked(e.target.checked);
                        if (!e.target.checked) {
                          setInactiveDays('');
                        }
                      }}
                      checked={inactiveDaysChecked}
                      className="form-checkbox"
                    />
                    <span className="ml-2">
                      Accounts Inactive For{' '}
                      <input
                        type="number"
                        min="1"
                        value={inactiveDays}
                        onChange={(e) => setInactiveDays(e.target.value)}
                        className="w-16 px-2 py-1 border border-gray-300 rounded-md ml-1 mr-1"
                        disabled={!inactiveDaysChecked}
                      />{' '}
                      days
                    </span>
                  </label>
                  {inactiveDaysChecked && errors.inactiveDays && (
                    <p className="text-red-500 text-sm mt-1 ml-6">{errors.inactiveDays}</p>
                  )}
                </div>
                {/* Account Balance */}
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setAccountBalanceChecked(e.target.checked);
                        if (!e.target.checked) {
                          setAccountBalanceAmount('');
                          setAccountBalanceCondition('');
                        }
                      }}
                      checked={accountBalanceChecked}
                      className="form-checkbox"
                    />
                    <span className="ml-2">
                      Accounts With Balance{' '}
                      <select
                        value={accountBalanceCondition}
                        onChange={(e) => setAccountBalanceCondition(e.target.value)}
                        className="px-2 py-1 border border-gray-300 rounded-md ml-1 mr-1"
                        disabled={!accountBalanceChecked}
                      >
                        <option value="">Select Condition</option>
                        <option value="Equal To">Equal To</option>
                        <option value="Greater Than">Greater Than</option>
                        <option value="Less Than">Less Than</option>
                      </select>
                      <input
                        type="number"
                        min="0"
                        value={accountBalanceAmount}
                        onChange={(e) => setAccountBalanceAmount(e.target.value)}
                        className="w-16 px-2 py-1 border border-gray-300 rounded-md ml-1 mr-1"
                        disabled={!accountBalanceChecked}
                      />
                    </span>
                  </label>
                  {accountBalanceChecked && errors.accountBalanceCondition && (
                    <p className="text-red-500 text-sm mt-1 ml-6">{errors.accountBalanceCondition}</p>
                  )}
                  {accountBalanceChecked && errors.accountBalanceAmount && (
                    <p className="text-red-500 text-sm mt-1 ml-6">{errors.accountBalanceAmount}</p>
                  )}
                </div>
                {/* Transaction Count */}
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setTransactionCountChecked(e.target.checked);
                        if (!e.target.checked) {
                          setTransactionCount('');
                          setTransactionCountCondition('');
                        }
                      }}
                      checked={transactionCountChecked}
                      className="form-checkbox"
                    />
                    <span className="ml-2">
                      Transaction Count{' '}
                      <select
                        value={transactionCountCondition}
                        onChange={(e) => setTransactionCountCondition(e.target.value)}
                        className="px-2 py-1 border border-gray-300 rounded-md ml-1 mr-1"
                        disabled={!transactionCountChecked}
                      >
                        <option value="">Select Condition</option>
                        <option value="Greater Than">Greater Than</option>
                        <option value="Less Than">Less Than</option>
                        <option value="Equal To">Equal To</option>
                      </select>
                      <input
                        type="number"
                        min="0"
                        value={transactionCount}
                        onChange={(e) => setTransactionCount(e.target.value)}
                        className="w-16 px-2 py-1 border border-gray-300 rounded-md ml-1 mr-1"
                        disabled={!transactionCountChecked}
                      />
                    </span>
                  </label>
                  {transactionCountChecked && errors.transactionCountCondition && (
                    <p className="text-red-500 text-sm mt-1 ml-6">{errors.transactionCountCondition}</p>
                  )}
                  {transactionCountChecked && errors.transactionCount && (
                    <p className="text-red-500 text-sm mt-1 ml-6">{errors.transactionCount}</p>
                  )}
                </div>
                {/* Cards Sent Status */}
                <div className="mb-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setCardsSentStatusChecked(e.target.checked);
                        if (!e.target.checked) {
                          setCardsSentStatus('');
                        }
                      }}
                      checked={cardsSentStatusChecked}
                      className="form-checkbox"
                    />
                    <span className="ml-2">
                      Accounts{' '}
                      <select
                        value={cardsSentStatus}
                        onChange={(e) => setCardsSentStatus(e.target.value)}
                        className="px-2 py-1 border border-gray-300 rounded-md ml-1"
                        disabled={!cardsSentStatusChecked}
                      >
                        <option value="">Select Status</option>
                        <option value="Yes">With</option>
                        <option value="No">Without</option>
                      </select>{' '}
                      Cards Sent
                    </span>
                  </label>
                  {cardsSentStatusChecked && errors.cardsSentStatus && (
                    <p className="text-red-500 text-sm mt-1 ml-6">{errors.cardsSentStatus}</p>
                  )}
                </div>
              </div>
              {errors.inactiveAccountReminder && (
                <p className="text-red-500 text-sm mt-1">{errors.inactiveAccountReminder}</p>
              )}
            </div>
            {/* Send Times and Interval Days */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Send Every X Days
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 text-gray-500 cursor-pointer"
                  title="Frequency to send the email."
                />
              </label>
              <input
                type="number"
                min="1"
                value={intervalDays}
                onChange={(e) => setIntervalDays(e.target.value)}
                placeholder="Days Interval"
                className="block w-32 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
              {errors.intervalDays && (
                <p className="text-red-500 text-sm mt-1">{errors.intervalDays}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Number of Times to Send Before Retiring
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 text-gray-500 cursor-pointer"
                  title="Total number of times to send the email."
                />
              </label>
              <input
                type="number"
                min="1"
                value={sendTimes}
                onChange={(e) => setSendTimes(e.target.value)}
                placeholder="Times"
                className="block w-32 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
              {errors.sendTimes && (
                <p className="text-red-500 text-sm mt-1">{errors.sendTimes}</p>
              )}
            </div>
          </>
        );
      case 'Welcome Email':
        return (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Send On Account Creation
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 text-gray-500 cursor-pointer"
                  title="This email will be sent when an account is created."
                />
              </label>
              <input type="checkbox" checked={true} disabled className="form-checkbox" />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-8">
      <div className="text-end mb-4">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-gray-700 text-sm mr-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
          Go Back
        </button>
      </div>

      <h1 className="text-2xl font-bold mb-6">
        {campaignId ? 'Edit Campaign' : 'Create New Campaign'}
      </h1>

      {/* Alert div */}
      {alert.show && (
        <div
          className={`mb-4 p-4 rounded-md ${
            alert.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          } flex justify-between`}
        >
          <span>{alert.message}</span>
          <button
            onClick={() => setAlert({ show: false, message: '', type: '' })}
            className="text-lg font-bold"
          >
            &times;
          </button>
        </div>
      )}

      {/* Campaign Type Dropdown */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Campaign Type:
        </label>
        <select
          value={campaignType}
          onChange={handleCampaignTypeChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
        >
          <option value="">Select Campaign Type</option>
          <option value="Funding Instructions">Funding Instructions</option>
          <option value="General Marketing">General Marketing</option>
          <option value="Inactive Account Reminder">Inactive Account Reminder</option>
          <option value="Welcome Email">Welcome Email</option>
        </select>
        {errors.campaignType && (
          <p className="text-red-500 text-sm mt-1">{errors.campaignType}</p>
        )}
      </div>

      {/* Email Template Dropdown */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Email Template:
        </label>
        <select
          value={templateId}
          onChange={(e) => setTemplateId(e.target.value)}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
        >
          <option value="">Select Email Template</option>
          {templates.map((template) => (
            <option key={template.Id} value={template.Id}>
              {template.name}
            </option>
          ))}
        </select>
        {errors.templateId && (
          <p className="text-red-500 text-sm mt-1">{errors.templateId}</p>
        )}

        {/* Link to Create New Email Template */}
        <div className="mt-2">
          <Link to="/emaileditor" className="text-blue-500 hover:underline">
            Create New Email Template
          </Link>
        </div>
      </div>

      {currentUser.isAdmin && (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Policy:</label>
          <select value={policyNumber} onChange={(e) => setPolicyNumber(e.target.value)} className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            <option value="">Select A Policy</option>
            {policies.map(policy => (
              <option key={policy.PolicyNumber} value={policy.PolicyNumber}>
                {policy.PolicyNumber} - {policy.Description}
              </option>
            ))}
          </select>
          {errors.policyNumber && <p className="text-red-500 text-sm mt-1">{errors.policyNumber}</p>}
        </div>
      )}

      {/* Conditional Fields */}
      {renderCampaignFields()}

      {/* Submit Button */}
      <div className="text-right mb-4">
        <button onClick={handleSaveCampaign} className="themed-button">
          {campaignId ? 'Update Campaign' : 'Create Campaign'}
        </button>
      </div>

      {/* Loading Spinner */}
      {loading && <Spinner />}
    </div>
  );
};

export default CampaignsAddEdit;
