import React, { useEffect, useState, useMemo } from 'react';
import MainTableV2 from './MainTableV2';
import Spinner from './Spinner';
import { useAuth } from './AuthContext';
import AddCardGroupModal from './AddCardGroupModal';
import { useTheme } from './ThemeContext';
import MobileHeader from './MobileHeader';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCardGroupData } from './cardGroupSlice';
import PageHeader from './PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';


const AccountManagerPolicyAdmin = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const primaryColor = theme.primary;
    const [cardGroups, setCardGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const dispatch = useDispatch();
    const apiUrl = process.env.REACT_APP_API_URL;


    const fetchCardGroups = async () => {
        setLoading(true);
        setError(null);
        try {
            // Ensure currentUser and userId are available
            if (!currentUser || !currentUser.userId) {
                throw new Error("User information is incomplete.");
            }
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: 'getCardGroupsAdmin',
                    payload: { 
                        userId: currentUser.userId,
                        policyNumber: currentUser.policyNumber
                     }
                })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            const data = await response.json();            
            setCardGroups(data);
        } catch (error) {
            setError('Failed to load data: ' + error.message);            
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchCardGroups();
    }, [currentUser.userId]);


    const handleEditClick = (rowData) => {
        dispatch(setCardGroupData(rowData));
        navigate('/editcardgroup');
    };

    const columns = useMemo(() => [
        {
            name: 'Account #',
            selector: row => row.cardGroup.AccountNumber,
            sortable: true,
            reorder: true
        },
        {
            name: 'Account Name',
            selector: row => row.cardGroup.groupName,
            sortable: true,
            reorder: true
        },
        {
            name: 'Description',
            selector: row => row.cardGroup.description,
            sortable: true,
            reorder: true
        },
        {
            name: 'Balance',
            selector: row => `$${row.cardGroup.AccountBalance}`,
            sortable: true,
            reorder: true
        },
        {
            name: 'Edit',
            cell: row => (
                <button
                    className="themed-button"
                    onClick={() => handleEditClick(row)}
                >
                    Edit
                </button>
            ),
            ignoreRowClick: true,
        }
    ], [handleEditClick]);

    const handleAddCardGroup = (newGroup) => {
        if (newGroup.cardGroup) {
            setCardGroups(cardGroups => [...cardGroups, newGroup]);
            setSuccessMessage("Successfully added the Account!");
        } else {
            setSuccessMessage("Error adding account. Try Again.");
        }
    };

    const clearSuccessMessage = () => setSuccessMessage("");

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
            }, 7000);

            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    const filterConfig = [
        { key: 'AccountNumber', type: 'number' },
        { key: 'groupName', type: 'string' },
        { key: 'description', type: 'string' },
        { key: 'PolicyNumber', type: 'number' }
    ];

    const filteredData = useMemo(() => {
        if (!searchQuery) return cardGroups;
        return cardGroups.filter(group => {
            return filterConfig.some(({ key, type }) => {
                const value = group.cardGroup[key];
                if (!value) return false;
                if (type === 'number') {
                    return value.toString().includes(searchQuery);
                }
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            });
        });
    }, [searchQuery, cardGroups]);

    return (
        <>
            <MobileHeader theme={theme} />
            <div className='mobile-container'>
                {loading && <Spinner />}
                <PageHeader
                    heading="Account Settings"
                    context="Manage your accounts, cards, and users."
                />
                {/* Info Notice */}
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 my-4 rounded-md shadow-sm">
                    <p className="font-semibold"><FontAwesomeIcon icon={faCircleInfo} /> PRO TIP: About Accounts</p>
                    <p>
                        Accounts allow you to group your users and cards into "Accounts." Each account maintains its own balance, funds cannot be moved between accounts and must be funded seperately.
                    </p>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}

                <MainTableV2

                    columns={columns}
                    data={filteredData}
                    primaryColor={primaryColor}
                    loading={loading}
                    alertMessage={successMessage}
                    clearAlert={clearSuccessMessage}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    filterConfig={filterConfig}
                    isNested={true}
                    children={
                        <>
                            <div className="col-span-1"></div>  {/* Empty div for the first column */}
                            <div className="col-span-1 flex justify-end">
                                <button onClick={() => setIsModalOpen(true)} className="themed-button rounded-md px-4 py-2">
                                    Create New Account
                                </button>
                            </div>
                        </>
                    }
                />
                {isModalOpen && (
                    <AddCardGroupModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onSave={handleAddCardGroup}

                    />
                )}
            </div>
        </>
    );
};

export default AccountManagerPolicyAdmin;