import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import { Link } from 'react-router-dom';
import PageHeader from './PageHeader';
import MainTableV2 from './MainTableV2'
import MobileHeader from './MobileHeader'

const DiscountGroupTable = () => {
  const { theme } = useTheme();
  const primaryColor = theme.primary;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { currentUser } = useAuth();
  const clearAlert = () => setAlertMessage(null);
  const filterConfig = [
    { key: 'DiscountGroupName', type: 'string' }
  ];
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/dataServer?`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            payload: {
              userId: currentUser.userId
            },
            action: 'getDiscountGroups',
          })
        });

        if (response.ok) {
          const fetchedData = await response.json();
          console.log("Fetched Data:", fetchedData);         
          setData(fetchedData || []);
        } else {
          setAlertMessage('Error: Unable to fetch data. Try again.');
        }
      } catch (error) {
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  const filteredData = useMemo(() => {
    if (searchQuery === '') return data;
    return data.filter((row) => {
      return Object.values(row).some((s) =>
        s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [searchQuery, data]);

  const columns = useMemo(() => [
    {
      name: 'Discount Group Name',
      selector: row => row.DiscountGroupName,
      reorder: true,
      sortable: true,
    },
    {
      name: 'Percentage?',
      selector: row => row.ApplyAsPercent,
      reorder: true,
      sortable: true,
      cell: row => (row.ApplyAsPercent === true ? 'Yes' : 'No'),
    },
    {
      name: 'Transaction Fee',
      selector: row => `$${parseFloat(row.TransactionFee).toFixed(2)}`,
      reorder: true,
      sortable: true,
    },
    {
      name: 'Pilot FJ',
      selector: row => row.ApplyAsPercent ? `${parseFloat(row.PilotFJ).toFixed(2)}%` : `$${parseFloat(row.PilotFJ).toFixed(2)}`,
      reorder: true,
      hide: 'sm',
    },
    {
      name: 'Loves',
      selector: row => row.ApplyAsPercent ? `${parseFloat(row.Loves).toFixed(2)}%` : `$${parseFloat(row.Loves).toFixed(2)}`,
      reorder: true,
      hide: 'sm',
    },
    {
      name: 'TA/Petro',
      selector: row => row.ApplyAsPercent ? `${parseFloat(row.TAPetro).toFixed(2)}%` : `$${parseFloat(row.TAPetro).toFixed(2)}`,
      reorder: true,
      hide: 'sm',
    },
    {
      name: 'Sapp Brothers',
      selector: row => row.ApplyAsPercent ? `${parseFloat(row.SappBrothers).toFixed(2)}%` : `$${parseFloat(row.SappBrothers).toFixed(2)}`,
      reorder: true,
      hide: 'sm',
    },
    {
      name: 'Edit',
      selector: row => row.Edit,
      reorder: true,
      cell: row => {
        if (!currentUser.isContractAdmin || (currentUser.isContractAdmin && currentUser.ContractId === row.ContractId)) {
          return (
            <Link className={'themed-button'} to={`/DiscountGroupsForm?id=${row.ID}`}>
              Edit
            </Link>
          );
        }
        return null;
      },
    },
  ], [currentUser]);

  // const navigate = useNavigate();
  return (
    <>
      <MobileHeader theme={theme} />
      <div className='mobile-container'>
        {/* Information Div */}
        <PageHeader
          heading="Discount Groups"
          context="Manage per-gallon discounts for your fuel network users."
        />
        {currentUser.isContractAdmin && (
          <div className="my-4">
            <div
              className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded-md"
              role="alert"
            >
              <p className="font-bold">Note</p>
              <p>
                As a contract administrator, you have the privilege to create new discount groups and modify those associated with your contract. While discount groups outside your contract are not editable, you have the flexibility to incorporate them into your policies as needed.
              </p>

            </div>
          </div>

        )}


        <MainTableV2
          columns={columns}
          data={filteredData}
          primaryColor={primaryColor}
          loading={loading}
          alertMessage={alertMessage}
          clearAlert={clearAlert}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filterConfig={filterConfig}
        >
          <div className="col-span-1"></div>  {/* Empty div for the first column */}
          <div className="col-span-1 flex justify-end">
            <Link className="themed-button" to="/DiscountGroupsForm">New Discount Group</Link>
          </div>

        </MainTableV2>
      </div>
    </>
  );
};

export default DiscountGroupTable;
