import React, { useState, useEffect } from "react";

const EditInvoiceModal = ({ invoice, onClose, onSave }) => {
  const [editedInvoice, setEditedInvoice] = useState({ ...invoice });

  useEffect(() => {
    if (invoice) {
      setEditedInvoice({
        ...invoice,
        InvoiceDate: formatDate(invoice.InvoiceDate),
        DueDate: formatDate(invoice.DueDate)
      });
    }
  }, [invoice]);

  const handleChange = (e) => {
    setEditedInvoice({ ...editedInvoice, [e.target.name]: e.target.value });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Format to YYYY-MM-DD
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center modal">
      <div className="bg-white p-8 rounded-lg shadow-lg relative w-1/3 modal-content">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 mt-2 mr-2 text-2xl font-semibold text-gray-700 hover:text-gray-900"
        >
          &times; {/* Close Button */}
        </button>

        <h2 className="text-xl font-bold mb-4">Edit Invoice</h2>

        {/* Invoice ID */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Invoice ID</label>
          <input type="text" name="InvoiceID" value={editedInvoice.InvoiceID || ""} readOnly className="mt-1 p-2 w-full border rounded-md bg-gray-100 text-gray-600" />
        </div>

        {/* Transaction ID */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">EFS Transaction ID</label>
          <input type="text" name="TransactionID" value={editedInvoice.TransactionID || ""} readOnly className="mt-1 p-2 w-full border rounded-md bg-gray-100 text-gray-600" />
        </div>

        {/* Policy Number */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Policy Number</label>
          <input type="text" name="PolicyNumber" value={editedInvoice.PolicyNumber || ""} readOnly className="mt-1 p-2 w-full border rounded-md bg-gray-100 text-gray-600" />
        </div>

       {/* Invoice Date */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Invoice Date</label>
        <input type="date" name="InvoiceDate" value={editedInvoice.InvoiceDate || ""} onChange={handleChange} className="mt-1 p-2 w-full border rounded-md" />
      </div>

      {/* Due Date */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Due Date</label>
        <input type="date" name="DueDate" value={editedInvoice.DueDate || ""} onChange={handleChange} className="mt-1 p-2 w-full border rounded-md" />
      </div>

       {/* Invoice Status */}
<div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">Invoice Status</label>
  <select 
    name="InvoiceStatus" 
    value={editedInvoice.InvoiceStatus || ""} 
    onChange={handleChange} 
    className="mt-1 p-2 w-full border rounded-md"
  >
    <option value="Open">Open</option>
    <option value="Overdue">Overdue</option>
    <option value="Paid">Paid</option>
  </select>
</div>


        {/* Amount */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Amount</label>
          <input type="number" name="RemainingAmount" value={editedInvoice.RemainingAmount || ""} onChange={handleChange} className="mt-1 p-2 w-full border rounded-md" />
        </div>        

        <button onClick={() => onSave(editedInvoice)} className="mt-4 px-4 py-2 text-white rounded themed-button">
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default EditInvoiceModal;
