import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCardInfos, selectCardData, deleteCardInfo } from './cardSlice';
import Spinner from './Spinner';
import { useTheme } from './ThemeContext';
import MobileHeader from './MobileHeader';


const CardPromptForm = () => {
  const { theme } = useTheme();
  const location = useLocation();  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const cardData = useSelector(selectCardData); 
  const infos = cardData.cardDetails?.result?.infos || [];  
  const existingInfoIds = Array.isArray(infos) ? new Set(infos.map(info => info.infoId)) : new Set();
  const rowData = location.state?.rowData || {};
  const isEditing = Boolean(rowData.infoId); 
  const isCreating = location.state?.isCreating || false; 
  
  const [infoData, setInfoData] = useState(
    isCreating
      ? {          
          infoId: '',
          lengthCheck: false,
          matchValue: '',
          maximum: '',
          minimum: '',
          reportValue: '',
          validationType: '',
          value: '',
        }
      : {          
          infoId: rowData.infoId,
          lengthCheck: rowData.lengthCheck,
          matchValue: rowData.matchValue,
          maximum: rowData.maximum,
          minimum: rowData.minimum,
          reportValue: rowData.reportValue,
          validationType: rowData.validationType,
          value: rowData.value,
        }
  );
  const infoIdOptions = [
    { key: 'BLID', value: 'Billing ID' },
    { key: 'BDAY', value: 'Birthday' },
    { key: 'CRDR', value: 'Card Description' },
    { key: 'CNTN', value: 'Control Number' },
    { key: 'DRID', value: 'Driver ID' },
    { key: 'NAME', value: 'Driver Name' },
    { key: 'DLIC', value: 'Driver’s License #' },
    { key: 'DLST', value: 'Driver’s License State' },
    { key: 'EXPT', value: 'Expense Type' },
    { key: 'FSTI', value: 'First Initial' },
    { key: 'GLCD', value: 'GL Code' },
    { key: 'HBRD', value: 'Hubometer' },
    { key: 'LSTN', value: 'Last Name' },
    { key: 'LICN', value: 'License #' },
    { key: 'LCST', value: 'License State' },
    { key: 'ODRD', value: 'Odometer' },
    { key: 'OINV', value: 'Original Invoice' },
    { key: 'PPIN', value: 'Personal Identifier' },
    { key: 'PONB', value: 'Purchase Order #' },
    { key: 'HRRD', value: 'Reefer Hour Reading' },
    { key: 'RTMP', value: 'Reefer Temperature' },
    { key: 'SSUB', value: 'Sub Fleet Identifier' },
    { key: 'TLOC', value: 'Terminal Location' },
    { key: 'TRLR', value: 'Trailer #' },
    { key: 'TRIP', value: 'Trip #' },
    { key: 'UNIT', value: 'Unit #' }
  ];  
  const validationTypeOptions = [
    // { value: 'ALPHABETIC', label: 'Alphabetic' },
  // { value: 'ALPHA_NUMERIC', label: 'Alpha Numeric' },
  // { value: 'NUMERIC', label: 'Numeric' },
  { value: 'REPORT_ONLY', label: 'REPORT_ONLY (For Reporting Only)' },
  { value: 'EXACT_MATCH', label: 'EXACT_MATCH (Entered At Pump)' },
  //{ value: 'INFO_POOL', label: 'INFO_POOL (Cannot edit but you can delete.)' },
  // { value: 'ACCRUAL_CHECK', label: 'Accrual Check' }
  ];  

  const isDisabled = infoData.validationType === "INFO_POOL";
  const isRequiredReport = infoData.validationType === "REPORT_ONLY";
  const isRequiredMatch = infoData.validationType === "EXACT_MATCH";
  const isLengthCheck = infoData.lengthCheck === "true" || infoData.lengthCheck === true;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfoData({
      ...infoData,
      [name]: value,
    });
  };
  
  
  
  const handleDelete = () => {
    const infosArray = cardData.cardDetails?.result?.infos || [];
    const updatedInfos = infosArray.filter(info => info.infoId !== infoData.infoId);
  
    const combinedPromptsArray = cardData.combinedPrompts || [];
    const updatedCombinedPrompts = combinedPromptsArray.filter(
      info => !(info.infoId === infoData.infoId && info.Source === 'Card')
    );
  
    console.log("Before Delete, infosArray:", infosArray);
    console.log("After Delete, updatedInfos:", updatedInfos);
    console.log("Before Delete, combinedPromptsArray:", combinedPromptsArray);
    console.log("After Delete, updatedCombinedPrompts:", updatedCombinedPrompts);
  
    // Dispatch the new delete action
    dispatch(deleteCardInfo({ updatedInfos, updatedCombinedPrompts }));
  
    console.log("After Dispatch, cardData:", cardData);  
  
    navigate(-1);
  }; 
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Make sure you get the infos array here, and provide a default empty array if it doesn't exist.
    let infosArray = cardData.cardDetails?.result?.infos || [];
  
    // If infosArray is not an array, convert it into an array.
    if (!Array.isArray(infosArray)) {
      infosArray = [infosArray];
    }
  
    let updatedInfos;
  
    // Find an existing info object that has the same infoId as the one in the form.
    const existingInfo = infosArray.find(info => info.infoId === infoData.infoId);
  
    // If an existing info object is found, update it. Otherwise, add a new info object.
    if (existingInfo) {
      updatedInfos = infosArray.map((info) =>
        info.infoId === infoData.infoId ? infoData : info
      );
    } else {
      updatedInfos = [...infosArray, infoData];
    }
  
    // Dispatch your updateCardInfos action here.
    dispatch(updateCardInfos(infoData));
  
    navigate(-1);
  };
  
  
  
  
  
  
  return (
    <div className='mobile-container'>
      <MobileHeader theme={theme} />
                {isLoading && (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 9999,
        }}
      >
        <Spinner />
      </div>
    )}
    {isEditing && (
        <div className="text-right mb-4 m-8">
          <button
            type="button"
            onClick={handleDelete}
            className="px-4 py-2 text-white rounded bg-red-500"
          >
            Delete Prompt
          </button>
        </div>
      )}
    <form onSubmit={handleSubmit} className="space-y-4 m-8">
  <div>
        <label className="block text-sm font-medium text-gray-700">
          Prompt ID
        </label>
        <select
    type="text"
    name="infoId"
    value={infoData.infoId}
    onChange={handleChange}
    className="mt-1 p-2 w-full border rounded-md"
    disabled={isEditing}
    required
  >
    <option value="" disabled>Select an Info ID</option>
    {infoIdOptions.map((option) => (
      <option 
        key={option.key} 
        value={option.key}
        disabled={existingInfoIds.has(option.key) && option.key !== infoData.infoId}
      >
        {option.value}
      </option>
    ))}
  </select>
      </div>

      <div>
  <label className="block text-sm font-medium text-gray-700">Validation Type</label>
  <p className="text-xs text-gray-500">The validation type for the prompt. NOTE: Use EXACT_MATCH to prompt and validate at the pump, use REPORT_ONLY for reporting prompts only. These are most commonly used at the card level.</p>
  <select
    name="validationType"
    value={infoData.validationType}
    onChange={handleChange}
    className="mt-1 p-2 w-full border rounded-md"
    disabled={isDisabled}
    required
  >
    <option value="" disabled>Select a Validation Type</option>
    {validationTypeOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
    {infoData.validationType === "INFO_POOL" && (
      <option value="INFO_POOL">INFO_POOL (Cannot edit but you can delete.)</option>
    )}
  </select>
</div>

<div>
  <label className="block text-sm font-medium text-gray-700">
    Length Check (Optional)
  </label>
  <p className="text-xs text-gray-500">This setting determines whether a length validation is enforced for a prompt input. When enabled, it ensures that the entered value meets a specified character length requirement. For instance, if truck numbers are consistently three digits, this check will verify that the input at the pump is exactly three digits long. Should the input fall short or exceed this length, the transaction will be declined.</p>
  <select
    name="lengthCheck"
    value={infoData.lengthCheck}
    onChange={handleChange}
    className="mt-1 p-2 w-full border rounded-md"
    disabled={isDisabled}
  >
    <option value="">Select option</option>
    <option value="true">True</option>
    <option value="false">False</option>
  </select>
</div>
  

  <div>
    <label className="block text-sm font-medium text-gray-700">Maximum</label>
    <p className="text-xs text-gray-500">The maximum value. Only required if Length Check is true.</p>
    <input
  type="number"
  name="maximum"
  value={infoData.maximum}
  onChange={handleChange}
  className="mt-1 p-2 w-full border rounded-md"
  disabled={isDisabled}
  required={isLengthCheck}  
/>
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700">Minimum</label>
    <p className="text-xs text-gray-500">The minimum value. Only required if Length Check is true.</p>
    <input
      type="number"
      name="minimum"
      value={infoData.minimum}
      onChange={handleChange}
      className="mt-1 p-2 w-full border rounded-md"
      disabled={isDisabled}
      required={isLengthCheck}
    />
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700">Report Value</label>
    <p className="text-xs text-gray-500">If a prompt is Report Only this is the report value. For example, this is the Driver ID #, Unit #, etc., that will be reported.</p>
    <input
      type="text"
      name="reportValue"
      value={infoData.reportValue || ''}
      onChange={handleChange}
      className="mt-1 p-2 w-full border rounded-md"
      disabled={isDisabled}
      required={isRequiredReport}
      maxLength={24}
    />
  </div>
  <div>
    <label className="block text-sm font-medium text-gray-700">Match Value</label>
    <p className="text-xs text-gray-500">If a prompt is Exact Match this is the value. For example, this is the Driver ID #, Unit #, etc.</p>
    <input
      type="text"
      name="matchValue"
      value={infoData.matchValue || ''}
      onChange={handleChange}
      className="mt-1 p-2 w-full border rounded-md"
      disabled={isDisabled}
      required={isRequiredMatch}
      maxLength={24}
    />
  </div>

  

{/* For the accrual check method for odometer or hubometer, this is the accrual value. For all other info ids/validation type combos just leave as <value/> or <value>0</value> */}
  {/* <div>
    <label className="block text-sm font-medium text-gray-700">Value</label>
    <input
      type="text"
      name="value"
      value={infoData.value}
      onChange={handleChange}
      className="mt-1 p-2 w-full border rounded-md"
      disabled={isDisabled}
    />
  </div> */}

  <button
  type="submit"
  className="mt-4 px-4 py-2 text-white rounded themed-button"
>
  {isCreating ? "Add Prompt" : "Update Prompt"}
</button>

</form>
</div>

  );
};

export default CardPromptForm;
