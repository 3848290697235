import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTachometerAlt,
  faCreditCard,
  faChartBar,
  faCog,
  faMapMarkerAlt,
  faCalculator,
  faGasPump,
  faSignOutAlt,
  faQuestionCircle,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useSelector } from 'react-redux';

const MobileHeader = ({ theme }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [selectedCardGroup, setSelectedCardGroup] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);
  const userRoles = currentUser?.Roles || [];
  const isAdmin = currentUser?.isAdmin;
  const isContractAdmin = currentUser?.isContractAdmin;
  const isPolicyAdmin = currentUser?.isPolicyAdmin;
  
  const policies = useSelector((state) => state.policies.activePolicies);
  const cardGroups = useSelector((state) => state.policies.cardGroups);

  const apiUrl = process.env.REACT_APP_API_URL;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setIsMenuOpen(false); // Close the menu after navigation
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
    setIsMenuOpen(false); // Close the menu after logout
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.cardGroupID) {
        setSelectedCardGroup(currentUser.cardGroupID);
        setSelectedPolicy(null);
      } else {
        setSelectedPolicy(currentUser.policyNumber || "");
        setSelectedCardGroup(null);
      }
    }
  }, [currentUser?.policyNumber, currentUser?.cardGroupID]);

  const handlePolicyChange = async (e) => {
    const newSelectedValue = e.target.value;

    const selectedCardGroup = cardGroups.find(group => group.AccountNumber.toString() === newSelectedValue);
    const selectedPolicy = selectedCardGroup 
      ? policies.find(policy => policy.PolicyNumber === selectedCardGroup.PolicyNumber) 
      : policies.find(policy => policy.PolicyNumber.toString() === newSelectedValue);

    const policyNumber = selectedPolicy ? selectedPolicy.PolicyNumber : null;
    const cardGroupID = selectedCardGroup ? selectedCardGroup.cardGroupID : null;
    // setSelectedPolicy(selectedPolicy ? selectedPolicy.PolicyNumber : null);
    // setSelectedCardGroup(selectedCardGroup ? selectedCardGroup.cardGroupID : null);

    if (policyNumber === null) {
      console.error('No valid policy number selected');
      return;
    }

    setSelectedPolicy(policyNumber);
    setSelectedCardGroup(cardGroupID);

    try {
        const response = await fetch(`${apiUrl}/dataServer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                action: 'updateAdminPolicyAndCardGroup',
                payload: {
                    userId: currentUser.userId,
                    selectedPolicy: selectedPolicy ? selectedPolicy.PolicyNumber : null,
                    selectedCardGroup: selectedCardGroup ? selectedCardGroup.cardGroupID : null,
                },
            }),
        });

        if (response.ok) {
            window.location.reload();
        } else {
            console.error('Failed to update Policy Number');
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
  };

  return (
    <div className='header-parent'>
    <div className="mobile-header md:hidden">
      {/* Invisible placeholder to balance the header */}
      <div className="header-placeholder"></div>

      {/* Centered logo and company name */}
      <div className="header-center">
        <img
          src={theme.logo}
          alt="Company Logo"
          className="header-logo"
        />
      </div>

      {/* Hamburger menu */}
      <FontAwesomeIcon
        icon={faBars}
        onClick={toggleMenu}
        className="text-lg cursor-pointer"
        style={{ marginRight: '15px' }}
      />
      </div>
      
      {isMenuOpen && (
        <div className="mobile-menu">
          <ul className="text-lg">
            {/* Admin Dropdown for Policies/Card Groups */}
            {(isAdmin || isContractAdmin || isPolicyAdmin) && (
              <li className="p-2">
                <label className="block text-sm font-medium text-gray-700">Account:</label>
                <select
                  value={selectedCardGroup ? cardGroups.find(group => group.cardGroupID === selectedCardGroup)?.AccountNumber : selectedPolicy}
                  onChange={handlePolicyChange}
                  className="w-full bg-white rounded-md p-2 mt-1 border border-gray-300"
                >
                  <option value="">Select a policy</option>
                  {policies.map(policy => (
                    <option key={policy.ID} value={policy.PolicyNumber}>
                      {isPolicyAdmin ? policy.CompanyName || policy.Description : `${policy.PolicyNumber} - ${policy.CompanyName || policy.Description}`}
                    </option>
                  ))}
                  {cardGroups.map(group => (
                    <option key={group.cardGroupID} value={group.AccountNumber}>
                      {group.groupName} ({group.AccountNumber})
                    </option>
                  ))}
                </select>
              </li>
            )}

            {/* Other menu items */}
            {!userRoles.includes('Driver') && (
              <>
                <li className={`menu-item ${location.pathname === "/dashboard" ? "selected" : ""}`} onClick={() => handleNavigate("/dashboard")}>
                  <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
                  Dashboard
                </li>
                <li className={`menu-item ${location.pathname === "/usercards" ? "selected" : ""}`} onClick={() => handleNavigate("/usercards")}>
                  <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                  Fuel Cards
                </li>
                <li className={`menu-item ${location.pathname === "/userusers" ? "selected" : ""}`} onClick={() => handleNavigate("/userusers")}>
                  <FontAwesomeIcon icon={faUsers} className="mr-2" />
                  Users
                </li>
                <li className={`menu-item ${location.pathname === "/usertransactions" ? "selected" : ""}`} onClick={() => handleNavigate("/usertransactions")}>
                  <FontAwesomeIcon icon={faGasPump} className="mr-2" />
                  Fuel Transactions
                </li>
                <li className={`menu-item ${location.pathname === "/useraccounting" ? "selected" : ""}`} onClick={() => handleNavigate("/useraccounting")}>
                  <FontAwesomeIcon icon={faCalculator} className="mr-2" />
                  Accounting
                </li>
                <li className={`menu-item ${location.pathname === "/userreporting" ? "selected" : ""}`} onClick={() => handleNavigate("/userreporting")}>
                  <FontAwesomeIcon icon={faChartBar} className="mr-2" />
                  Reports
                </li>
              </>
            )}
            <li className={`menu-item ${location.pathname === "/locationfinder" ? "selected" : ""}`} onClick={() => handleNavigate("/locationfinder")}>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              Locations
            </li>
            <li className={`menu-item ${location.pathname === "/faq" ? "selected" : ""}`} onClick={() => handleNavigate("/faq")}>
              <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
              FAQ
            </li>
            <li className={`menu-item ${location.pathname === "/settings" ? "selected" : ""}`} onClick={() => handleNavigate("/settings")}>
              <FontAwesomeIcon icon={faCog} className="mr-2" />
              Settings
            </li>
            <li className="menu-item" onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Logout
            </li>
            {(currentUser.hasSubaccounts && (currentUser.isAdmin || currentUser.isPolicyAdmin)) && (
                <li
                  className={`menu-item mb-2 ${location.pathname === '/admin-settings' ? 'selected' : ''
                    }`}
                  onClick={() => handleNavigate('/accountmanager')}
                  style={{ color: theme.menuText }}
                >
                  <FontAwesomeIcon icon={faCog} className="mr-2" />
                  Account Settings
                </li>
              )}
            {(isAdmin || isContractAdmin) && (
              <li className={`menu-item ${location.pathname === '/admin-settings' ? 'selected' : ''}`} onClick={() => handleNavigate('/admin-settings')}>
                <FontAwesomeIcon icon={faCog} className="mr-2" />
                Admin Settings
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;




// import React, { useState, useContext, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faBars,
//   faTachometerAlt,
//   faCreditCard,
//   faChartBar,
//   faCog,
//   faMapMarkerAlt,
//   faCalculator,
//   faGasPump,
//   faSignOutAlt,
//   faQuestionCircle,
//   faUsers,
// } from '@fortawesome/free-solid-svg-icons';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { AuthContext } from './AuthContext';
// import { useSelector } from 'react-redux';


// const MobileHeader = ({ theme }) => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { currentUser, logout } = useContext(AuthContext);
//   const userRoles = currentUser?.Roles || [];
//   const [selectedPolicy, setSelectedPolicy] = useState(null);
//   const [selectedCardGroup, setSelectedCardGroup] = useState(null);

//   const {isAdmin, isContractAdmin, isPolicyAdmin} = currentUser;

//   const policies = useSelector((state) => state.policies.activePolicies);
//   const cardGroups = useSelector((state) => state.policies.cardGroups);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const handleNavigate = (path) => {
//     navigate(path);
//     setIsMenuOpen(false); // Close the menu after navigation
//   };

//   const handleLogout = async () => {
//     await logout();
//     navigate('/');
//     setIsMenuOpen(false); // Close the menu after logout
//   };

//   useEffect(() => {
//     if (currentUser) {
//       setSelectedPolicy(currentUser.policyNumber || "");
//       setSelectedCardGroup(currentUser.cardGroupID || "");
//     }
//   }, [currentUser]);

//   const handlePolicyChange = (e) => {
//     const selectedValue = e.target.value;
//     const selectedCardGroup = cardGroups.find(group => group.AccountNumber.toString() === selectedValue);
//     const selectedPolicy = selectedCardGroup 
//       ? policies.find(policy => policy.PolicyNumber === selectedCardGroup.PolicyNumber) 
//       : policies.find(policy => policy.PolicyNumber.toString() === selectedValue);
      
//     setSelectedPolicy(selectedPolicy?.PolicyNumber || "");
//     setSelectedCardGroup(selectedCardGroup?.cardGroupID || "");
//   };

//   return (
    // <div className='header-parent'>
    //   <div className="mobile-header md:hidden">
    //     {/* Invisible placeholder to balance the header */}
    //     <div className="header-placeholder"></div>

    //     {/* Centered logo and company name */}
    //     <div className="header-center">
    //       <img
    //         src={theme.logo}
    //         alt="Company Logo"
    //         className="header-logo"
    //       />
    //     </div>

    //     {/* Hamburger menu */}
    //     <FontAwesomeIcon
    //       icon={faBars}
    //       onClick={toggleMenu}
    //       className="text-lg cursor-pointer"
    //       style={{ marginRight: '15px' }}
    //     />
      
//       {/* Admin Dropdown for Policies/Card Groups */}
//       {(isAdmin || isContractAdmin || isPolicyAdmin) && (
//         <div className="w-full p-4 bg-gray-100">
//           <label className="mr-2 text-sm font-medium text-gray-700">Account:</label>
//           <select
//             value={selectedCardGroup || selectedPolicy}
//             onChange={handlePolicyChange}
//             className="w-full bg-gray-100 rounded-md p-2"
//           >
//             <option value="">Select a policy</option>
//             {policies.map(policy => (
//               <option key={policy.ID} value={policy.PolicyNumber}>
//                 {isPolicyAdmin ? policy.CompanyName || policy.Description : `${policy.PolicyNumber} - ${policy.CompanyName || policy.Description}`}
//               </option>
//             ))}
//             {cardGroups.map(group => (
//               <option key={group.cardGroupID} value={group.AccountNumber}>
//                 {group.groupName} ({group.AccountNumber})
//               </option>
//             ))}
//           </select>
//         </div>
        
//       )}
//       {isMenuOpen && (
//         <div className="mobile-menu">
//           <ul className="text-lg">
//           {!userRoles.includes('Driver') && (
//             <>
//             <li className={`menu-item ${location.pathname === "/dashboard" ? "selected" : ""}`}
//                 onClick={() => handleNavigate("/dashboard")}>
//               <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
//               Dashboard
//             </li>
//             <li className={`menu-item ${location.pathname === "/usercards" ? "selected" : ""}`}
//                 onClick={() => handleNavigate("/usercards")}>
//               <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
//               Fuel Cards
//             </li>
//             <li
//               className={`menu-item mb-2 ${location.pathname === "/userusers" ? "selected" : ""
//                 }`}
//               onClick={() => handleNavigate("/userusers")}
//               style={{ color: theme.menuText }}
//             >
//               <FontAwesomeIcon icon={faUsers} className="mr-2" />
//               Users
//             </li>
//             <li className={`menu-item ${location.pathname === "/usertransactions" ? "selected" : ""}`}
//                 onClick={() => handleNavigate("/usertransactions")}>
//               <FontAwesomeIcon icon={faGasPump} className="mr-2" />
//               Fuel Transactions
//             </li>
//             <li className={`menu-item ${location.pathname === "/useraccounting" ? "selected" : ""}`}
//                 onClick={() => handleNavigate("/useraccounting")}>
//               <FontAwesomeIcon icon={faCalculator} className="mr-2" />
//               Accounting
//             </li>
//             <li className={`menu-item ${location.pathname === "/userreporting" ? "selected" : ""}`}
//                 onClick={() => handleNavigate("/userreporting")}>
//               <FontAwesomeIcon icon={faChartBar} className="mr-2" />
//               Reports
//             </li>
//             </>
//             )}
//             <li className={`menu-item ${location.pathname === "/locationfinder" ? "selected" : ""}`}
//                 onClick={() => handleNavigate("/locationfinder")}>
//               <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
//               Locations
//             </li>            
//             <li className={`menu-item ${location.pathname === "/faq" ? "selected" : ""}`}
//                 onClick={() => handleNavigate("/faq")}>
//               <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
//               FAQ
//             </li>
//             <li className={`menu-item ${location.pathname === "/settings" ? "selected" : ""}`} 
//                 onClick={() => handleNavigate("/settings")}>
//               <FontAwesomeIcon icon={faCog} className="mr-2" />
//               Settings
//             </li>
//             <li className="menu-item" onClick={handleLogout}>
//               <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
//               Logout
//             </li>
//             {(currentUser?.isAdmin || currentUser?.isContractAdmin) && (
//               <li className={`menu-item ${location.pathname === '/admin-settings' ? 'selected' : ''}`}
//                   onClick={() => handleNavigate('/admin-settings')}>
//                 <FontAwesomeIcon icon={faCog} className="mr-2" />
//                 Admin Settings
//               </li>
//             )}
//           </ul>
//         </div>
//       )}
//     </div>
//     </div>
//   );
// };

// export default MobileHeader;
