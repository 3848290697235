import React from 'react';
import { useNavigate } from 'react-router-dom';

// // const AdminSettingsCard = ({ title, description, theme, url }) => {
// //     const navigate = useNavigate();
// //   return (
// //     <div className="rounded-lg p-4 m-2" style={{
// //         flex: '0 0 calc(33.333% - 20px)',
// //         boxSizing: 'border-box',
// //         borderRadius: '5px',
// //         boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
// //         background: 'linear-gradient(to bottom, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.9) 50%, rgba(255,255,255,0.9) 100%)'
// //     }}>

// //       <div className="p-4" style={{ textAlign: 'center' }}>
// //         <h2 className="text-2xl mb-4 font-bold">{title}</h2>
// //         <p className="mb-4">{description}</p>
// //         <button onClick={() => navigate(url)}
// //           className="themed-button w-1/2" 
          
// //         >
// //           Go
// //         </button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default AdminSettingsCard;

// import React from 'react';
// import { useNavigate } from 'react-router-dom';



// const AdminSettingsCard = ({ title, description, theme, url }) => {
//     const navigate = useNavigate();
    
//     return (
      
      
//         <div className="rounded-lg p-4 m-2 bg-white shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out flex flex-col items-center justify-center"
//              style={{
//                  flex: '1 0 100%', // Full width on smaller screens
//                  maxWidth: 'calc(33.333% - 20px)', // Limit max width on larger screens
//                  background: 'linear-gradient(to bottom, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.9) 50%, rgba(255,255,255,0.9) 100%)'
//              }}
//         >
//             <h2 className="text-xl md:text-2xl mb-4 font-bold text-center">{title}</h2>
//             <p className="mb-4 text-center">{description}</p>
//             <button onClick={() => navigate(url)}
//                     className="themed-button w-full md:w-1/2 text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 rounded-lg py-2"
//             >
//                 Go
//             </button>
//         </div>
        
//     );
// }; 

// export default AdminSettingsCard;

const AdminSettingsCard = ({ title, description, theme, url }) => {
    const navigate = useNavigate();
    
    return (
        <div className='w-full md:w-1/3 p-2'>
        <div className="flex flex-col items-center justify-center p-4 bg-white shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out rounded-lg"
        
             style={{
                 background: 'linear-gradient(to bottom, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.9) 50%, rgba(255,255,255,0.9) 100%)'
             }}
        >
            <h2 className="text-xl md:text-2xl mb-4 font-bold text-center">{title}</h2>
            <p className="mb-4 text-center">{description}</p>
            <button onClick={() => navigate(url)}
                    className="themed-button w-full md:w-1/2 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 rounded-lg py-2"
            >
                Go
            </button>
        </div>
        </div>
    );
}; 

export default AdminSettingsCard;
