// import { configureStore } from '@reduxjs/toolkit';
// import userReducer from './userSlice';
// import policiesReducer from './policiesSlice';
// import policyAdminEditReducer from './policyAdminEditSlice';
// import cardReducer from './cardSlice';

// export const store = configureStore({
//   reducer: {
//     user: userReducer,
//     policies: policiesReducer,
//     policyAdminEdit: policyAdminEditReducer,
//     card: cardReducer,
//   },
// });

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import policiesReducer from './policiesSlice';
import policyAdminEditReducer from './policyAdminEditSlice';
import cardReducer from './cardSlice';
import cardGroupReducer from './cardGroupSlice'; 

export const store = configureStore({
  reducer: {
    user: userReducer,
    policies: policiesReducer,
    policyAdminEdit: policyAdminEditReducer,
    card: cardReducer,
    cardGroup: cardGroupReducer,
  },
});
