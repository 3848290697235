import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  useLoadScript,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import "./ECapitalLocationFinder.css";
import Spinner from "./Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faArrowDown,
  faArrowUp,
  faTimes,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const googleMapsLibraries = ["places"];

const ECapitalLocationFinder = () => { 
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({});
  const [radius, setRadius] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [sortCriteria, setSortCriteria] = useState({
    field: "price",
    ascending: true,
  });
  const [userLocation, setUserLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 39.8283,
    lng: -98.5795,
  });
  const [mapZoom, setMapZoom] = useState(4);
  const [mapInstance, setMapInstance] = useState(null);
  
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const ipInfoToken = "ad22ffe4eb7ee0";
  
  const theme = {
    primary: "#f7901e",    
  };
  
  const hardcodedUserId = "4HpQCMjHJcbxhpAtMzbHkZIvncf2"; 
 
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries,
  });
  
  useEffect(() => {
    const params = queryString.parse(location.search);
    const code = params.code;   
    
    if (code === "e31119c7-66ea-4726-9338-ef2a1aa2bbc7") {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [location]);
  
  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const response = await axios.get(
          `https://ipinfo.io/json?token=${ipInfoToken}`
        );
        const [lat, lng] = response.data.loc.split(",");
        setUserLocation({ lat: parseFloat(lat), lng: parseFloat(lng) });
      } catch (error) {
        console.error("Error getting user location from IPInfo:", error);
      }
    };

    fetchUserLocation();
  }, []);

  useEffect(() => {
    setCoordinates({});
  }, [address]);

  useEffect(() => {
    const isValid = address.trim() !== "" && radius > 0 && radius <= 3500;
    setIsFormValid(isValid);
  }, [address, radius]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const toRad = (x) => (x * Math.PI) / 180;
    const R = 3958.8; // Radius of the Earth in miles

    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in miles
  };

  const fetchFuelStops = async () => {
    if (radius > 3500) {
      alert("Radius should be 3500 or below.");
      return;
    }

    if (!isAuthorized) {
      alert("Unauthorized access.");
      return;
    }

    setLoading(true);
    let currentCoordinates = coordinates;

    if (!isValidCoordinates && address) {
      try {
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);
        setCoordinates(latLng);
        currentCoordinates = latLng;
        setMapCenter(latLng); 
        setMapZoom(10); 
      } catch (error) {
        console.error("Error geocoding address:", error);
        setLoading(false);
        return;
      }
    }

    const data = {
      latitude: currentCoordinates.lat,
      longitude: currentCoordinates.lng,
      searchRange: radius,
      UserId: hardcodedUserId,
    };

    try {
      const response = await fetch(`${apiUrl}/getLocationsV2`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.error(
          "Fetch failed with status:",
          response.status,
          response.statusText
        );
        setLoading(false);
        return;
      }

      const locationsData = await response.json();

      if (userLocation) {
        locationsData.forEach((location) => {
          location.distance = calculateDistance(
            userLocation.lat,
            userLocation.lng,
            parseFloat(location.latitude),
            parseFloat(location.longitude)
          ).toFixed(2);
        });
      }
      setLocations(locationsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching fuel stops:", error);
      setLoading(false);
    }
  };

  const isValidCoordinates =
    coordinates &&
    typeof coordinates.lat === "number" &&
    typeof coordinates.lng === "number";

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  
  useEffect(() => {
    if (mapInstance) {      
      window.google.maps.event.trigger(mapInstance, "resize");      
      mapInstance.setCenter(mapCenter);
    }
  }, [isPanelOpen, mapInstance, mapCenter]);

  const handleSort = (field) => {
    const isAscending =
      sortCriteria.field === field ? !sortCriteria.ascending : true;
    setSortCriteria({ field, ascending: isAscending });
  };

  const sortedLocations = [...locations].sort((a, b) => {
    let aValue, bValue;

    if (sortCriteria.field === "price") {
      aValue = parseFloat(a.userPrice) || Infinity;
      bValue = parseFloat(b.userPrice) || Infinity;
    } else if (sortCriteria.field === "distance") {
      aValue = parseFloat(a.distance) || Infinity;
      bValue = parseFloat(b.distance) || Infinity;
    }

    return sortCriteria.ascending ? aValue - bValue : bValue - aValue;
  });

  const handleMapLoad = useCallback((map) => {
    setMapInstance(map);
  }, []);

  const renderMarkers = () => {
    return sortedLocations.map((location) => {
      if (!location.retailPrice || location.retailPrice === "NA") return null;

      const markerIcon = theme.markerIcon
        ? {
            url: theme.markerIcon,
            labelOrigin: new window.google.maps.Point(15, 45),
            scaledSize: new window.google.maps.Size(30, 30),
          }
        : {
            url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path fill="${theme.primary}" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
                      </svg>
                    `)}`,
            labelOrigin: new window.google.maps.Point(12, 45),
            scaledSize: new window.google.maps.Size(30, 30),
          };

      return (
        <Marker
          key={`marker-${location.locationID}`}
          position={{
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
          }}
          icon={markerIcon}
          onClick={() => setSelectedLocation(location)}
          label={{
            text:
              location.userPrice === "TBD"
                ? `$${parseFloat(location.retailPrice).toFixed(3)}`
                : `$${parseFloat(location.userPrice).toFixed(3)}`,
            color: "#000000",
            fontSize: "14px",
            fontWeight: "bold",
            className: "price-label",
            background: "#5d727e",
            borderRadius: "12px",
            padding: "2px 4px",
          }}
        />
      );
    });
  };

  const renderInfoWindow = () => {
    if (!selectedLocation) return null;

    const savings = (
      parseFloat(selectedLocation.retailPrice) -
      parseFloat(selectedLocation.userPrice)
    ).toFixed(2);
    const price = isNaN(parseFloat(selectedLocation.userPrice))
      ? "$0.00"
      : `$${parseFloat(selectedLocation.userPrice).toFixed(3)}`;
    const retailPrice = isNaN(parseFloat(selectedLocation.retailPrice))
      ? "N/A"
      : `$${parseFloat(selectedLocation.retailPrice).toFixed(3)}`;
    const discount = isNaN(savings)
      ? "Pricing Unavailable"
      : `${savings} Off`;
    const distance = selectedLocation.distance
      ? `${selectedLocation.distance}`
      : "";
    const modifiedOn = new Date(selectedLocation.modifiedOn).toLocaleString();

    return (
      <InfoWindow
        position={{
          lat: parseFloat(selectedLocation.latitude),
          lng: parseFloat(selectedLocation.longitude),
        }}
        onCloseClick={() => setSelectedLocation(null)}
        options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
      >
        <div className="info-window-content">
          <p className="price-note">
            *Prices may vary and are subject to change. We strive to provide
            accurate information, but discrepancies may occur.
          </p>
          <div className="info-window-details">
            <h2>{selectedLocation.locationName}</h2>
            <p className="directions-link">
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                  selectedLocation.addr2 +
                    ", " +
                    selectedLocation.city +
                    ", " +
                    selectedLocation.state +
                    " " +
                    selectedLocation.zip
                )}${
                  userLocation
                    ? `&origin=${userLocation.lat},${userLocation.lng}`
                    : ""
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Directions
              </a>
            </p>
            <div className="price-info">
              <div className="user-price">{price}</div>
              <div className="price-label">Your Price</div>
              <div className="retail-price">{retailPrice}</div>
              <div className="price-label">Retail Price</div>
              <div className="discount">{discount}</div>
            </div>
            <p className="address">
              {selectedLocation.addr1}, {selectedLocation.city},{" "}
              {selectedLocation.state} {selectedLocation.zip}
            </p>
            {distance && (
              <p className="distance">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="mr-1"
                  style={{ color: theme.primary }}
                />
                {distance} miles away
              </p>
            )}
            <p className="last-update">Last Price Update: {modifiedOn}</p>
          </div>
        </div>
      </InfoWindow>
    );
  };
  
  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    const position = {
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude),
    };
    setMapCenter(position);
    setMapZoom(14); 
    if (mapInstance) {
      mapInstance.panTo(position);
      mapInstance.setZoom(14);
    }
  };
  
  if (loadError) {
    return <div>Error loading maps</div>;
  }
 
  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <>
      {!isAuthorized ? (
        <div className="unauthorized">
          <h1>Unauthorized Access</h1>
          <p>You do not have permission to access this page.</p>
        </div>
      ) : (
        <div className="location-finder">
          {/* Header */}
          <header className="header">
            <div>
              <img
                src="/logos/ecapital_logo.png"
                alt="Client Logo"
                className="logo"
              />
            </div>
          </header>

          {/* Main Content */}
          <div className="main-content">
            {/* Search Form */}
            <div className="search-form">
              <div className="search-form-inner">
                <div className="autocomplete">
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Search City/State",
                          })}
                          className="input"
                          required
                        />
                        <div className="autocomplete-dropdown">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => (
                            <div
                              {...getSuggestionItemProps(suggestion)}
                              key={suggestion.placeId}
                              className={`suggestion ${
                                suggestion.active ? "active" : ""
                              }`}
                            >
                              {suggestion.description}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <input
                  type="number"
                  max="3500"
                  value={radius}
                  onChange={(e) => {
                    if (e.target.value <= 3500) {
                      setRadius(e.target.value);
                    }
                  }}
                  placeholder="Radius (miles)"
                  className="input radius-input"
                  required
                />
                <button
                  onClick={fetchFuelStops}
                  className="themed-button1"
                  disabled={!isFormValid}
                >
                  Search
                </button>
                <button onClick={togglePanel} className="list-toggle-button">
                  <FontAwesomeIcon icon={faList} className="mr-2" />
                  {isPanelOpen ? "Hide List View" : "Show List View"}
                </button>
              </div>
            </div>

            {/* Loading Spinner */}
            {loading && <Spinner />}

            {/* Map and List */}
            <div className="content">
              {/* Map */}
              <div
                className={`map-container ${
                  isPanelOpen ? "map-container-reduced" : ""
                }`}
              >
                <GoogleMap
                  onLoad={handleMapLoad}
                  center={mapCenter}
                  zoom={mapZoom}
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                >
                  {renderMarkers()}
                  {renderInfoWindow()}
                </GoogleMap>
              </div>

              {/* List Panel */}
              {isPanelOpen && (
                <div className="list-panel">
                  <div className="list-header">
                    <div className="sort-options">
                      <span>Sort By:</span>
                      <button
                        onClick={() => handleSort("price")}
                        className="sort-button"
                      >
                        <FontAwesomeIcon
                          icon={
                            sortCriteria.field === "price" &&
                            sortCriteria.ascending
                              ? faArrowUp
                              : faArrowDown
                          }
                          className="mr-1"
                        />
                        Best Price
                      </button>
                      <button
                        onClick={() => handleSort("distance")}
                        className="sort-button"
                      >
                        <FontAwesomeIcon
                          icon={
                            sortCriteria.field === "distance" &&
                            sortCriteria.ascending
                              ? faArrowUp
                              : faArrowDown
                          }
                          className="mr-1"
                        />
                        Closest
                      </button>
                    </div>
                    <button onClick={togglePanel} className="btn btn-close">
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                  <div className="price-note">
                    <p>
                      *Prices may vary and are subject to change. We strive to
                      provide accurate information, but discrepancies may occur.
                    </p>
                  </div>
                  <div className="locations-list">
                    {sortedLocations.map((location) => {
                      const savings = (
                        parseFloat(location.retailPrice) -
                        parseFloat(location.userPrice)
                      ).toFixed(2);
                      const price = isNaN(parseFloat(location.userPrice))
                        ? "$0.00"
                        : `$${parseFloat(location.userPrice).toFixed(3)}`;
                      const retailPrice = isNaN(
                        parseFloat(location.retailPrice)
                      )
                        ? "N/A"
                        : `$${parseFloat(
                            location.retailPrice
                          ).toFixed(3)}`;
                      const discount = isNaN(savings)
                        ? "Pricing Unavailable"
                        : `$${savings} Off`;
                      const distance = location.distance
                        ? `${location.distance}`
                        : "";
                      const modifiedOn = new Date(
                        location.modifiedOn
                      ).toLocaleString();

                      return (
                        <div
                          key={location.locationID}
                          className="location-item"
                          onClick={() => handleLocationClick(location)}
                        >
                          <div className="location-item-inner">
                            <div className="price-info">
                              <div className="user-price">{price}</div>
                              <div className="price-label">Your Price</div>
                              <div className="discount">{discount}</div>
                              <div className="retail-price">
                                {retailPrice}
                              </div>
                              <div className="price-label">
                                Retail Price
                              </div>
                            </div>
                            <div className="location-info">
                              <div className="location-name">
                                {location.locationName}
                              </div>
                              <div className="directions-link">
                                <a
                                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                                    location.addr2 +
                                      ", " +
                                      location.city +
                                      ", " +
                                      location.state +
                                      " " +
                                      location.zip
                                  )}${
                                    userLocation
                                      ? `&origin=${userLocation.lat},${userLocation.lng}`
                                      : ""
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Directions
                                </a>
                              </div>
                              <div className="address">
                                {location.addr1}, {location.city},{" "}
                                {location.state} {location.zip}
                              </div>
                              {distance && (
                                <div className="distance">
                                  <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    className="mr-1"
                                    style={{ color: theme.primary }}
                                  />
                                  {distance} miles away
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="last-update">
                            Last Price Update: {modifiedOn}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Footer */}
          <footer className="footer">
            <div className="container">
              <p>
                &copy; {new Date().getFullYear()} eCapital. All Rights Reserved.
                eCapital is a registered Trademark. Registration Number:
                5,098,285
              </p>
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default ECapitalLocationFinder;
