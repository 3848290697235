import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectPolicyData, setPolicyData } from './policyAdminEditSlice'; 

export const PolicyLimitsForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch(); 
  const { isEditing } = useLocation().state || {};
  // Get the current limits array from Redux store
  const { limits } = useSelector(selectPolicyData);
  const currentPolicyData = useSelector(selectPolicyData);

  const [limit, setLimit] = useState(location.state?.limit || {});
  const [formData, setFormData] = useState({
    limitId: limit.limitId || '',
    hours: limit.hours || 0,
    limit: limit.limit || 0,
    minHours: limit.minHours || 0,
    autoRollMap: limit.autoRollMap || 0,
    autoRollMax: limit.autoRollMax || 0,
  });

  const existingLimitIds = new Set(limits.map(limit => limit.limitId));

  // Assuming you have limitIdOptions and other options similar to infoIdOptions
  const limitIdOptions = [
    { key: 'ADD', value: 'Additives' },
    { key: 'AMDS', value: 'Aviation Merchandise' },
    { key: 'ANFR', value: 'Anti-Freeze' },
    { key: 'AVGS', value: 'Aviation Gas' },
    { key: 'BDSL', value: 'BioDiesel' },
    { key: 'BRAK', value: 'Brakes and Wheels' },
    { key: 'CADV', value: 'Cash Advance' },
    { key: 'CLTH', value: 'Clothing' },
    { key: 'CNG', value: 'Compressed Natural Gas' },
    { key: 'COUP', value: 'Coupon' },
    { key: 'DEF', value: 'Diesel Exhaust Fluid Bulk' },
    { key: 'DEFC', value: 'Diesel Exhaust Fluid Container' },
    { key: 'DELI', value: 'Restaurant/Deli' },
    { key: 'DSL', value: 'Diesel' },
    { key: 'DSLM', value: 'Mexico Diesel' },
    { key: 'ELEC', value: 'Electronics' },
    { key: 'EVCH', value: 'Electric Charging' },
    { key: 'FAX', value: 'Faxdefc' },
    { key: 'FURN', value: 'Furnace Oil' },
    { key: 'GAS', value: 'Gasoline' },
    { key: 'GASM', value: 'Mexico Gas Magna' },
    { key: 'GASP', value: 'Premium Gas Mexico' },
    { key: 'GROC', value: 'C-Store/Groceries' },
    { key: 'HARD', value: 'Hardware/Accessories' },
    { key: 'IDLE', value: 'IdleAire' },
    { key: 'JET', value: 'Jet Fuel' },
    { key: 'KERO', value: 'Kerosene' },
    { key: 'LABR', value: 'Labor' },
    { key: 'LMPR', value: 'Lumper' },
    { key: 'LNG', value: 'Liquid Natural Gas' },
    { key: 'MDSL', value: 'Marked/Tax Exempt Diesel' },
    { key: 'MERC', value: 'Miscellaneous Merchandise' },
    { key: 'MGAS', value: 'Marked/Tax Exempt Gas' },
    { key: 'MRFR', value: 'Marked Reefer' },
    { key: 'NGAS', value: 'Natural Gas' },
    { key: 'OIL', value: 'Oil' },
    { key: 'OILC', value: 'Oil Change' },
    { key: 'PART', value: 'Parts' },
    { key: 'PHON', value: 'Phone Time' },
    { key: 'PNT', value: 'Paint' },
    { key: 'PROP', value: 'Propane' },
    { key: 'RECP', value: 'Recap' },
    { key: 'REPR', value: 'Repair Service' },
    { key: 'REST', value: 'Restaurant' },
    { key: 'RFND', value: 'Refund' },
    { key: 'RFR', value: 'Reefer' },
    { key: 'RFRM', value: 'Thermo' },
    { key: 'SCAN', value: 'Tons Imaging' },
    { key: 'SCLE', value: 'Weigh Scale' },
    { key: 'SHWR', value: 'Shower' },
    { key: 'SPLT', value: 'Split/Other Method of Payment' },
    { key: 'STAX', value: 'Sales Tax' },
    { key: 'TIRE', value: 'Tires/Tire Repairs' },
    { key: 'TOLL', value: 'Ambassador Bridge Toll' },
    { key: 'TRAL', value: 'Trailer' },
    { key: 'TRPP', value: 'Trip Permit' },
    { key: 'ULSD', value: 'Ultra Low Sulfur Diesel' },
    { key: 'WASH', value: 'Car Wash' },
    { key: 'WIFI', value: 'Fleet WiFi Billing' },
    { key: 'WWFL', value: 'Windshield Washer Fluid' }
  ];
  

  useEffect(() => {
    console.log("Received limit data:", limit);
    console.log(formData);
    console.log("Limits from Redux:", limits);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDelete = () => {
    const updatedLimits = limits.filter(limit => limit.limitId !== formData.limitId);

    dispatch(
      setPolicyData({
        ...currentPolicyData,
        limits: updatedLimits,
      })
    );

    console.log("Limit deleted:", formData.limitId);
    navigate(-1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const existingLimit = limits.find(limit => limit.limitId === formData.limitId);
  
    let updatedLimits;
    if (existingLimit) {
      updatedLimits = limits.map((limit) =>
        limit.limitId === formData.limitId ? formData : limit
      );
    } else {
      updatedLimits = [...limits, formData];
    }
  
    dispatch(
      setPolicyData({
        ...currentPolicyData,
        limits: updatedLimits,
      })
    );
    console.log("Form data submitted:", formData);  
    navigate(-1);  
  };

  return (
    <div className="relative">
      {/* Delete Button (only if editing) */}
      {isEditing && (
        <div className="text-right mb-4 m-8">
          <button
            type="button"
            onClick={handleDelete}
            className="px-4 py-2 text-white rounded bg-red-500"
          >
            Delete Limit
          </button>
        </div>
      )}
    <form onSubmit={handleSubmit} className="space-y-2 m-8">
  {/* Limit ID */}
  <div>
    <label className="block text-sm font-medium text-gray-700">
      Limit ID
    </label>
    <select
      name="limitId"
      onChange={handleChange}
      value={formData.limitId}
      className="mt-1 p-2 w-full border rounded-md"
      disabled={isEditing}
    >
      <option value="" disabled>Select a Limit ID</option>
      {limitIdOptions.map((option) => (
        <option 
          key={option.key} 
          value={option.key}
          disabled={existingLimitIds.has(option.key) && option.key !== formData.limitId}
        >
          {option.value}
        </option>
      ))}
    </select>
  </div>
  {/* Hours */}
  <div>
    <label className="block text-sm font-medium text-gray-700">
      Hours
    </label>
    <input
      type="number"
      name="hours"
      onChange={handleChange}
      value={formData.hours}
      className="mt-1 p-2 w-full border rounded-md"
    />
  </div>
  {/* Limit */}
  <div>
    <label className="block text-sm font-medium text-gray-700">
      Limit
    </label>
    <input
      type="number"
      name="limit"
      onChange={handleChange}
      value={formData.limit}
      className="mt-1 p-2 w-full border rounded-md"
    />
  </div>
  {/* Min Hours */}
  <div>
    <label className="block text-sm font-medium text-gray-700">
      Min Hours
    </label>
    <input
      type="number"
      name="minHours"
      onChange={handleChange}
      value={formData.minHours}
      className="mt-1 p-2 w-full border rounded-md"
    />
  </div>
  {/* Auto Roll Map */}
  <div>
    <label className="block text-sm font-medium text-gray-700">
      Auto Roll Map
    </label>
    <input
      type="number"
      name="autoRollMap"
      onChange={handleChange}
      value={formData.autoRollMap}
      className="mt-1 p-2 w-full border rounded-md"
    />
  </div>
  {/* Auto Roll Max */}
  <div>
    <label className="block text-sm font-medium text-gray-700">
      Auto Roll Max
    </label>
    <input
      type="number"
      name="autoRollMax"
      onChange={handleChange}
      value={formData.autoRollMax}
      className="mt-1 p-2 w-full border rounded-md"
    />
  </div>
  {/* Submit Button */}
  <button
    type="submit"
    className="mt-4 px-4 py-2 text-white rounded themed-button"
  >
    {id ? 'Add New Limit' : 'Update Limit'}
  </button>
  
</form>
</div>
  );
};

export default PolicyLimitsForm;
