import React, { useState, useEffect, useRef } from 'react';
import EmailEditor from 'react-email-editor';

const SendCRMEmailModal = ({ show, onClose, lead, salesReps, currentUser }) => {
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const emailEditorRef = useRef(null);
    const modalContentRef = useRef(null);
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [emailSubject, setEmailSubject] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [sendToEmail, setSendToEmail] = useState('');
    const [selectedSenderId, setSelectedSenderId] = useState('');
    const [emailAlert, setEmailAlert] = useState({ show: false, message: '', type: '' });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmCallback, setConfirmCallback] = useState(null);
    const [templateName, setTemplateName] = useState(''); 
    const [saveAs, setSaveAs] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (lead) {
            setSendToEmail(lead.EMAIL || '');
            setSelectedSenderId(lead.SalesRep ? lead.SalesRep.Id : '');
        }
        fetchEmailTemplates();
    }, [lead]);

    const fetchEmailTemplates = async () => {
        setLoadingTemplates(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'getEmailTemplatesCRM',
                    payload: { userId: currentUser.userId },
                }),
            });

            const result = await response.json();
            console.log('Email Templates:', result);
            if (response.ok && result.success) {
                setEmailTemplates(result.templates || []);
            } else {
                console.error('Error fetching email templates:', result.message);
            }
        } catch (error) {
            console.error('Error fetching email templates:', error);
        } finally {
            setLoadingTemplates(false);
        }
    };

    const loadTemplate = async (templateId) => {
        setLoading(true);
        if (!templateId) {
            emailEditorRef.current.editor.loadBlank();
            setEmailSubject('');
            setTemplateName(''); // Reset template name
            setLoading(false);
            return;
        }
        try {
            // Make API call to fetch the template data
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'getEmailTemplateById',
                    payload: {
                        templateId,
                        userId: currentUser.userId,
                    },
                }),
            });

            const result = await response.json();

            if (response.ok && result.success) {
                const selectedTemplate = result.template;
                if (selectedTemplate && emailEditorRef.current) {
                    const designJson = JSON.parse(selectedTemplate.designContent);
                    emailEditorRef.current.editor.loadDesign(designJson);
                    setEmailSubject(selectedTemplate.subjectLine);
                    setTemplateName(selectedTemplate.name); // Set the template name
                }
            } else {
                console.error('Error fetching template:', result.message);
                setEmailAlert({ show: true, message: result.message || 'Error fetching template.', type: 'error' });
            }
        } catch (error) {
            console.error('Error loading template:', error);
            setEmailAlert({ show: true, message: 'An error occurred while loading the template.', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleSendEmail = () => {
        // Validate fields
        if (!sendToEmail) {            
            setEmailAlert({ show: true, message: 'Recipient email is required.', type: 'error' })
            modalContentRef.current.scrollTop = 0;
            return;
        }
        if (!selectedSenderId) {            
            setEmailAlert({ show: true, message: 'Sender is required', type: 'error' })
            modalContentRef.current.scrollTop = 0;
            return;
        }
        if (!emailSubject) {            
            setEmailAlert({ show: true, message: 'Email subject is required.', type: 'error' })
            modalContentRef.current.scrollTop = 0;
            return;
        }

        emailEditorRef.current.editor.exportHtml(async (data) => {
            const { html } = data;

            const emailData = {
                userId: currentUser.userId,
                leadId: lead.Id,
                toEmail: sendToEmail,
                fromSenderId: selectedSenderId,
                subject: emailSubject,
                htmlContent: html,                
            };

            console.log('Email Data:', emailData);

            try {
                setLoading(true);
                const response = await fetch(`${apiUrl}/dataServer`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        action: 'sendCRMEmail',
                        payload: emailData,
                    }),
                });

                const result = await response.json();
                if (response.ok && result.success) {
                    setEmailAlert({ show: true, message: 'Email sent successfully.', type: 'success' });
                    modalContentRef.current.scrollTop = 0;
                } else {
                    setEmailAlert({ show: true, message: result.message || 'Error sending email.', type: 'error' });
                    modalContentRef.current.scrollTop = 0;
                }
            } catch (error) {
                console.error('Error sending email:', error);
                setEmailAlert({ show: true, message: 'Error sending email.', type: 'error' });
                modalContentRef.current.scrollTop = 0;
            } finally {
                setLoading(false);
            }
        });
    };    

    const handleSaveTemplate = (isSaveAs) => {
        setSaveAs(isSaveAs); // Set state to determine if it’s a duplicate
        setShowConfirmModal(true);
        setConfirmCallback(() => saveTemplate); // Keep the callback function
    };
    

    const saveTemplate = (name) => {
        emailEditorRef.current.editor.exportHtml(async (data) => {
            const { design, html } = data;

            if (!emailSubject) {
                setEmailAlert({ show: true, message: 'Subject line is required.', type: 'error' })
                modalContentRef.current.scrollTop = 0;
                return;
              }

            // let templateId = null; 
            // if (selectedTemplateId && !saveAs) {
            //     // Find the template Id from selectedTemplateId
            //     const selectedTemplate = emailTemplates.find(t => t.Id === parseInt(selectedTemplateId));
            //     if (selectedTemplate) {
            //         templateId = selectedTemplate.Id;
            //     }
            // }

            const templateId = saveAs ? null : selectedTemplateId;

            const emailTemplate = {
                templateId: templateId, 
                name: name,
                subjectLine: emailSubject,
                htmlContent: html,
                designContent: design,
                userId: currentUser.userId,
                policyNumber: String(currentUser.policyNumber),
                isCRMTemplate: true,
            };           

            try {
                setLoading(true);
                const response = await fetch(`${apiUrl}/dataServer`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        action: 'addEmailTemplate',
                        payload: emailTemplate,
                    }),
                });

                const result = await response.json();

                if (response.ok && result.success) {                    
                    setEmailAlert({ show: true, message: 'Template Saved successfully', type: 'success' })
                    fetchEmailTemplates();
                } else {                    
                    setEmailAlert({ show: true, message: 'Error saving template.', type: 'error' })
                }
            } catch (error) {
                console.error('Error saving template:', error);                
                setEmailAlert({ show: true, message: `An error occurred: ${error.message}`, type: 'error' })
            } finally {
                setLoading(false);
                setShowConfirmModal(false);
                modalContentRef.current.scrollTop = 0;
            }
        });
    };

    const onEditorLoad = () => {
        setIsEditorReady(true);
    };

    const closeConfirmModal = () => {
        setShowConfirmModal(false);
        setConfirmCallback(null);
    };

    const AlertModal = ({ message, initialTemplateName, onConfirm, onCancel }) => {
        const [localTemplateName, setLocalTemplateName] = useState(initialTemplateName || '');

        return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto overflow-hidden">
                    {/* Header */}
                    <div className="px-6 py-4 border-b">
                        <h2 className="text-xl font-semibold text-gray-800">Confirmation</h2>
                    </div>

                    {/* Message */}
                    <div className="px-6 py-4">
                        <p className="text-gray-700">{message}</p>
                        {/* Template Name Input */}
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Template Name:</label>
                            <input
                                type="text"
                                value={localTemplateName}
                                onChange={(e) => setLocalTemplateName(e.target.value)}
                                placeholder="Enter Template Name"
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                            />
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="px-6 py-4 border-t flex justify-end space-x-4">
                        <button className="themed-button bg-gray-500 text-white" onClick={onCancel}>
                            Cancel
                        </button>
                        <button
                            className="themed-button"
                            onClick={() => onConfirm(localTemplateName)}
                            disabled={!localTemplateName.trim()}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-md w-3/4 h-full max-h-[90vh] flex flex-col">
                {/* Header */}
                <div className="p-4 border-b">
                    <h2 className="text-lg font-semibold">
                        Send Email to {lead.LEGALNAME || 'Lead'}
                    </h2>
                </div>

                {/* Content */}
                <div ref={modalContentRef} className="p-4 overflow-y-auto flex-1">
                    {/* Alert inside the modal */}
                    {emailAlert.show && (
                        <div
                            className={`mb-4 p-4 rounded-md ${
                                emailAlert.type === 'success'
                                    ? 'bg-green-100 text-green-800'
                                    : 'bg-red-100 text-red-800'
                            } flex justify-between`}
                        >
                            <span>{emailAlert.message}</span>
                            <button
                                onClick={() => setEmailAlert({ show: false, message: '', type: '' })}
                                className="text-lg font-bold"
                            >
                                &times;
                            </button>
                        </div>
                    )}

                    {/* Send To field */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Send To:</label>
                        <input
                            type="email"
                            value={sendToEmail}
                            onChange={(e) => setSendToEmail(e.target.value)}
                            placeholder="Enter recipient's email address"
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                            required
                        />
                    </div>

                    {/* Sender dropdown */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Sender:</label>
                        <select
                            value={selectedSenderId}
                            onChange={(e) => setSelectedSenderId(e.target.value)}
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                            required
                        >
                            <option value="">Select a Sender</option>
                            {salesReps.map((rep) => (
                                <option key={rep.Id} value={rep.Id}>
                                    {rep.FirstName} {rep.LastName}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Template Selector */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Select Template:</label>
                        <select
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                            value={selectedTemplateId || ''}
                            onChange={(e) => {
                                setSelectedTemplateId(e.target.value);
                                loadTemplate(e.target.value);
                            }}
                        >
                            <option value="">-- Select Template --</option>
                            {emailTemplates.map((template) => (
                                <option key={template.Id} value={template.Id}>
                                    {template.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Subject Line */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Subject Line:</label>
                        <input
                            type="text"
                            value={emailSubject}
                            onChange={(e) => setEmailSubject(e.target.value)}
                            placeholder="Enter Subject Line"
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>

                    {/* Email Editor */}
                    <div className="mb-6">
                        <EmailEditor ref={emailEditorRef} onLoad={onEditorLoad} />
                    </div>
                </div>

                {/* Footer */}
                <div className="p-4 border-t flex justify-end">
                    <button className="themed-button mr-2" onClick={() => handleSaveTemplate(true)}>
                        Copy Template
                    </button>
                    <button className="themed-button mr-2" onClick={() => handleSaveTemplate(false)}>
                        Save Template
                    </button>
                    <button className="themed-button mr-2" onClick={handleSendEmail}>
                        Send Email
                    </button>
                    <button className="themed-button bg-red-500 text-white" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>

            {/* Confirmation Modal */}
            {showConfirmModal && (
                <AlertModal
                    message="Are you sure you want to save the template?"
                    initialTemplateName={templateName}
                    onConfirm={(name) => {
                        setShowConfirmModal(false);
                        saveTemplate(name);
                    }}
                    onCancel={closeConfirmModal}
                />
            )}
        </div>
    );
};

export default SendCRMEmailModal;
