import React, { useMemo, useEffect, useState } from 'react';
import SubTableV2 from "./SubTableV2";

function TransactionDetailsModal({ isOpen, details, onClose }) {
  const [formattedDetails, setFormattedDetails] = useState(null);

  useEffect(() => {
    if (details) {
      const lineItemsData = details.lineItems.map(item => {
        const profit = item.UserAmount - item.Amount;
        const margin = item.UserPPU - item.PPU;

        return {
          ...item,
          RetailPPU: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }).format(item.RetailPPU),
          UserPPU: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }).format(item.UserPPU),
          UserAmount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.UserAmount),
          UserTransactionFee: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.UserTransactionFee),
          Discount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((item.RetailPPU * item.Quantity - item.UserAmount) + item.UserTransactionFee),
          Amount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.Amount),
          PPU: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }).format(item.PPU),
          DiscAmount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.DiscAmount),
          Profit: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(profit),
          Margin: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(margin)
        };
      });

      const taxesData = details.transLineTaxes.map(tax => ({
        ...tax,
        amount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tax.amount)
      }));

      setFormattedDetails({ ...details, lineItems: lineItemsData, transLineTaxes: taxesData });
    }
  }, [details]);

  const lineItemsColumns = useMemo(() => [
    { name: 'Category', selector: row => row.Category, sortable: true, width: '200px' },
    { name: 'Retail Price', selector: row => row.RetailPPU, sortable: true },
    { name: 'Paid Price', selector: row => row.PPU, sortable: true },
    { name: 'User Price', selector: row => row.UserPPU, sortable: true },
    { name: 'Margin', selector: row => row.Margin, sortable: true },
    { name: 'Discount', selector: row => row.DiscAmount, sortable: true },
    { name: 'Quantity', selector: row => row.Quantity, sortable: true },
    { name: 'Paid Amount', selector: row => row.Amount, sortable: true },
    { name: 'User Amount', selector: row => row.UserAmount, sortable: true },
    { name: 'User Fee', selector: row => row.UserTransactionFee, sortable: true },
    { name: 'Profit', selector: row => row.Profit, sortable: true }
  ], []);

  const transLineTaxesColumns = useMemo(() => [
    { name: 'Tax Description', selector: row => row.taxDescription, sortable: true },
    { name: 'Amount', selector: row => row.amount, sortable: true }
  ], []);

  const transactionInfosColumns = useMemo(() => [
    { name: 'Type', selector: row => row.Type, sortable: true },
    { name: 'Value', selector: row => row.Value, sortable: true }
  ], []);

  if (!isOpen || !formattedDetails) {
    return null;
  }

  return (
    <div className="transaction-modal">
      <div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>
      <div className="transaction-modal-content mobile-container mx-2 mt-14 sm:mt-24 md:mt-0 lg:mt-0">
        <button className="absolute top-2 right-2 themed-button" onClick={onClose}>Close</button>
        <h3 className="text-xl font-bold mb-4">Transaction Details</h3>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-1 mb-4">
          {Object.entries(details.transactions).map(([key, value]) => {
            if (key === "POSDate" || key === "TransactionDate") {
              value = new Date(value).toLocaleDateString();
            } else if (typeof value === "boolean") {
              value = value ? 'Yes' : 'No';
            } else if (["CarrierFee", "DiscAmount", "FundedTotal", "NetTotal", "PrefTotal", "SettleAmount"].includes(key)) {
              value = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
            } else if (value === null) {
              value = 'N/A';
            }
            return (
              <div key={key} className="flex justify-start items-center">
                <strong className="mr-2">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</strong>
                <span>{value}</span>
              </div>
            );
          })}
        </div>

        <hr className="mb-4" />

        <SubTableV2
          title="Line Items"
          columns={lineItemsColumns}
          data={formattedDetails.lineItems}
        />
        <SubTableV2
          title="Taxes Paid (Included in the amount)"
          columns={transLineTaxesColumns}
          data={formattedDetails.transLineTaxes}
        />
        <SubTableV2
          title="Prompts / Information"
          columns={transactionInfosColumns}
          data={details.transactionInfos}
        />

        <button className="mt-4 themed-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default TransactionDetailsModal;




// import React, { useMemo, useEffect, useState } from 'react';
// import SubTable from './SubTable';

// function TransactionModalAdmin({ isOpen, details, onClose }) {
//   const [formattedDetails, setFormattedDetails] = useState(null);

//   useEffect(() => {
//     if (details) {
//       const lineItemsData = details.lineItems.map(item => {
//         // Calculate the profit and margin for each line item
//         const profit = item.UserAmount - item.Amount;
//         const margin = item.UserPPU - item.PPU;

//         return {
//           ...item,
//           RetailPPU: new Intl.NumberFormat('en-US', {
//             style: 'currency',
//             currency: 'USD',
//             minimumFractionDigits: 3,
//             maximumFractionDigits: 3
//           }).format(item.RetailPPU),
//           UserPPU: new Intl.NumberFormat('en-US', {
//             style: 'currency',
//             currency: 'USD',
//             minimumFractionDigits: 3,
//             maximumFractionDigits: 3
//           }).format(item.UserPPU),
//           UserAmount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.UserAmount),
//           UserTransactionFee: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.UserTransactionFee),
//           Discount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((item.RetailPPU * item.Quantity - item.UserAmount) + item.UserTransactionFee),
//           // Format the added fields as currency
//           Amount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.Amount),
//           PPU: new Intl.NumberFormat('en-US', {
//             style: 'currency',
//             currency: 'USD',
//             minimumFractionDigits: 3,
//             maximumFractionDigits: 3
//           }).format(item.PPU),
//           DiscAmount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.DiscAmount),
//           // Add the profit and margin, formatted as currency
//           Profit: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(profit),
//           Margin: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(margin)
//         };
//       });

//       const taxesData = details.transLineTaxes.map(tax => ({
//         ...tax,
//         amount: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tax.amount)
//       }));

//       setFormattedDetails({ ...details, lineItems: lineItemsData, transLineTaxes: taxesData });
//     }
//   }, [details]);

//   const transLineTaxesColumns = useMemo(() => [
//     { Header: 'Tax Description', accessor: 'taxDescription' },
//     { Header: 'Amount', accessor: 'amount' }
//   ], []);

//   const transactionInfosColumns = useMemo(() => [
//     { Header: 'Type', accessor: 'Type' },
//     { Header: 'Value', accessor: 'Value' }
//   ], []);

//   const lineItemsColumns = useMemo(() => [
//     { Header: 'Category', accessor: 'Category' },
//     { Header: 'Retail Price', accessor: 'RetailPPU' },
//     { Header: 'Paid Price', accessor: 'PPU' },
//     { Header: 'User Price', accessor: 'UserPPU' },
//     { Header: 'Margin', accessor: 'Margin' },
//     { Header: 'Discount', accessor: 'DiscAmount' },
//     { Header: 'Quantity', accessor: 'Quantity' },
//     { Header: 'Paid Amount', accessor: 'Amount' },
//     { Header: 'User Amount', accessor: 'UserAmount' },
//     { Header: 'User Fee', accessor: 'UserTransactionFee' },
//     { Header: 'Profit', accessor: 'Profit' },
//   ], []);

//   if (!isOpen || !formattedDetails) {
//     return null;
//   }

//   return (
//     <div className="transaction-modal">
//       <div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>
//       <div className="transaction-modal-content">
//         <button className="absolute top-2 right-2 themed-button" onClick={onClose}>Close</button>
//         <h3 className="text-xl font-bold mb-4">Transaction Details</h3>

//         <div className="grid grid-cols-1 md:grid-cols-3 gap-1 mb-4">
//           {/* Iterate over the transaction details object and create a div for each key-value pair */}
//           {Object.entries(details.transactions).map(([key, value]) => {
//             // Format the date fields
//             if (key === "POSDate" || key === "TransactionDate") {
//               value = new Date(value).toLocaleDateString();
//             }
//             // Format the boolean fields
//             else if (typeof value === "boolean") {
//               value = value ? 'Yes' : 'No';
//             }
//             // Format the currency fields
//             else if (key === "CarrierFee" || key === "DiscAmount" || key === "FundedTotal" || key === "NetTotal" || key === "PrefTotal" || key === "SettleAmount") {
//               value = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
//             }
//             // Check for null values
//             else if (value === null) {
//               value = 'N/A'; // or some other placeholder text
//             }

//             return (
//               <div key={key} className="flex justify-start items-center">
//                 <strong className="mr-2">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</strong>
//                 <span>{value}</span>
//               </div>
//             );
//           })}
//         </div>



//         <hr className="mb-4" />

//         <SubTable
//           title="Line Items"
//           columns={lineItemsColumns}
//           data={formattedDetails.lineItems}
//         />
//         <SubTable
//           title="Taxes Paid (Included in the amount)"
//           columns={transLineTaxesColumns}
//           data={formattedDetails.transLineTaxes}  // Use formatted data for taxes
//         />
//         <SubTable
//           title="Prompts / Information"
//           columns={transactionInfosColumns}
//           data={details.transactionInfos}
//         />


//         <button className="mt-4 themed-button" onClick={onClose}>Close</button>
//       </div>
//     </div>
//   );
// }

// export default TransactionModalAdmin;
