// import React, { useEffect, useRef } from 'react';

// const MapMarker = ({ google, map, position, onClick, theme }) => {
//   const markerRef = useRef(null);

//   useEffect(() => {
//     if (google && google.maps && google.maps.marker && google.maps.marker.AdvancedMarkerElement && map && position && !markerRef.current) {
//       // console.log("Creating AdvancedMarkerElement at position:", position); // Log marker creation
//       const markerElement = document.createElement('div');
//       markerElement.style.backgroundColor = theme.primary;
//       markerElement.style.width = '24px';
//       markerElement.style.height = '24px';
//       markerElement.style.borderRadius = '50%';
//       markerElement.style.border = '2px solid white';

//       markerRef.current = new google.maps.marker.AdvancedMarkerElement({
//         map,
//         position,
//         content: markerElement,
//       });

//       markerRef.current.addListener('click', onClick);
//     } else if (google && google.maps && map && position && !markerRef.current) {
//       // console.log("Creating standard Marker at position:", position); // Log marker creation
//       markerRef.current = new google.maps.Marker({
//         map,
//         position,
//         icon: {
//           path: google.maps.SymbolPath.CIRCLE,
//           scale: 10,
//           fillColor: theme.primary,
//           fillOpacity: 1,
//           strokeWeight: 2,
//           strokeColor: '#ffffff',
//         },
//       });

//       markerRef.current.addListener('click', onClick);
//     } else {
//       // console.error("Marker creation failed. Ensure google, map, and position are available."); // Add error logging
//     }

//     return () => {
//       if (markerRef.current) {
//         markerRef.current.setMap(null);
//         markerRef.current = null;
//       }
//     };
//   }, [google, map, position, onClick, theme]);

//   return null;
// };

// export default MapMarker;

import React, { useEffect, useRef } from 'react';

const MapMarker = ({ google, map, position, onClick, theme }) => {
  const markerRef = useRef(null);

  useEffect(() => {
    if (google && google.maps && map && position && !markerRef.current) {
      const markerOptions = {
        map,
        position,
        icon: theme.markerIcon ? {
          url: theme.markerIcon,
          scaledSize: new google.maps.Size(30, 25) // Adjust the size as needed
        } : {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 10,
          fillColor: theme.primary,
          fillOpacity: 1,
          strokeWeight: 1,
          strokeColor: '#ffffff'
        }
      };

      markerRef.current = new google.maps.Marker(markerOptions);
      markerRef.current.addListener('click', onClick);
    }

    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
        markerRef.current = null;
      }
    };
  }, [google, map, position, onClick, theme]);

  return null;
};

export default MapMarker;
