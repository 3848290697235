import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from './ThemeContext';
import MainTableV2 from './MainTableV2';  
import { Link } from 'react-router-dom';
import PageHeader from './PageHeader';
import { useAuth } from './AuthContext';
import MobileHeader from './MobileHeader'

const PolicyTable = () => {
  const {currentUser} = useAuth();
  const { theme } = useTheme();
  const primaryColor = theme.primary;
  const tenantId = theme.tenantId;
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const filterConfig = [
    { key: 'ContractID', type: 'number' },
    { key: 'Description', type: 'string' },
    { key: 'PolicyNumber', type: 'number' },    
  ];

  const clearAlert = () => setAlertMessage(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://efs-getpolicy.azurewebsites.net/api/FuelLink-LoadPoliciesIntoTable?', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: currentUser.userId })
        });

        if (response.ok) {
          const fetchedData = await response.json();
          if (fetchedData.message) {
            setAlertMessage(fetchedData.message);
          } else {
            setData(fetchedData);
          }
        }
      } catch (error) {
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [tenantId]);

  const filteredData = useMemo(() => {
    if (searchQuery === '') return data;
    return data.filter((row) => {
      return Object.values(row).some((s) => 
        s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [searchQuery, data]);

  // const columns = useMemo(() => [
  //   { Header: 'Contract ID', accessor: 'ContractID' },
  //   { Header: 'Description', accessor: 'Description' },
  //   { Header: 'Policy Number', accessor: 'PolicyNumber' },
  //   {
  //     Header: 'Edit',
  //     Cell: ({ row }) => {       
  //       return (
  //         <Link 
  //           className="themed-button"
  //           to={`/PoliciesForm?PolicyNumber=${row.original.PolicyNumber}`}
  //         >
  //           Edit
  //         </Link>
  //       );
  //     }
      
  //   }
  // ], []);

  const columns = useMemo(() => [
    {
      name: 'Contract ID',
      selector: row => row.ContractID,
      reorder: true,
    },
    {
      name: 'Description',
      selector: row => row.Description,
      reorder: true,
    },
    {
      name: 'Policy Number',
      selector: row => row.PolicyNumber,
      reorder: true,
    },
    {
      name: 'Edit',
      cell: row => (
        <Link 
          className="themed-button"
          to={`/PoliciesForm?PolicyNumber=${row.PolicyNumber}`}
        >
          Edit
        </Link>
      ),
      reorder: true,
    }
  ], []);  

  const syncDataWithEFS = async () => {
    setShowModal(false);
    setLoading(true);
    try {
      const response = await fetch('https://efs-getpolicy.azurewebsites.net/api/FuelLink-SyncPolicies?', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ tenantId: tenantId })
      });
  
      const text = await response.text();  // Read the response as text instead of JSON
  
      if (response.ok) {
        setAlertMessage(`Successfully updated policies: ${text}`);
      } else {
        setAlertMessage(`Server Error: ${text}`);
      }
      setLoading(false);
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
      setLoading(false);
    }
  };
  

  return (
    <>
    <MobileHeader theme={theme} />
    <div className='mobile-container'>
    <PageHeader
  heading="Policies"
  context="Edit existing policies and create new ones."
/>

    <MainTableV2
      columns={columns}
      data={filteredData}
      primaryColor={primaryColor}
      loading={loading}
      alertMessage={alertMessage}
      clearAlert={clearAlert}          
      showModal={showModal}
      setShowModal={setShowModal}
      syncDataWithEFS={syncDataWithEFS}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      filterConfig={filterConfig}
    >
    <button 
      className="themed-button" 
      onClick={() => syncDataWithEFS()}

    >
      Sync Data With EFS
    </button>
    </MainTableV2>
    </div>
    </>
  );
};

export default PolicyTable;
