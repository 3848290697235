import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { jsPDF } from 'jspdf';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from './ThemeContext';
import MobileHeader from './MobileHeader';



const ViewServiceAgreement = () => {
  const { currentUser } = useAuth();
  const { userId, version } = useParams();
  const { state } = useLocation();
  const { latestAgreement } = state || {};
  const [agreementContent, setAgreementContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { theme } = useTheme();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState(''); 

  const subDomain = window.location.hostname.split('.')[0];

  useEffect(() => {
    const fetchAgreement = async () => {
      try {
        const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getUserAgreement', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            version,
            requesterUserId: currentUser.userId,
            subDomain: subDomain,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          setAgreementContent(data.Content);
          setFirstName(data.FirstName);
          setLastName(data.LastName);
        } else {
          console.error('Failed to fetch agreement:', data);
        }
      } catch (error) {
        console.error('Error fetching agreement:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgreement();
  }, [userId, version, currentUser.userId]);

  const handleDownload = () => {
    const doc = new jsPDF();
    const margin = 10;
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const contentWidth = pageWidth - 2 * margin;
    const lineHeight = 10;
    let y = margin;
  
    doc.setFontSize(12);
  
    doc.text("Service Agreement", margin, y);
    y += lineHeight;
    doc.text("Agreement Content:", margin, y);
    y += lineHeight;
  
    const lines = doc.splitTextToSize(agreementContent, contentWidth);
    for (const line of lines) {
      if (y + lineHeight > pageHeight - margin) {
        doc.addPage();
        y = margin;
      }
      doc.text(line, margin, y);
      y += lineHeight;
    }
  
    if (latestAgreement) {
      if (y + lineHeight * 7 > pageHeight - margin) {
        doc.addPage();
        y = margin;
      }
      y += lineHeight;
      doc.text("Signature Details:", margin, y);
      doc.setFont('cursive');
      doc.setFontSize(24);
      y += lineHeight;
      y += lineHeight;      
      doc.text(`${firstName} ${lastName}`, margin, y);      
      doc.setFont('helvetica');
      doc.setFontSize(12);
      y += lineHeight;
      y += lineHeight;
      doc.text(`Signature ID: ${userId}`, margin, y);
      y += lineHeight;
      doc.text(`TOS Version: ${latestAgreement.Version}`, margin, y);
      y += lineHeight;
      doc.text(`Timestamp: ${new Date(latestAgreement.Timestamp).toLocaleString()}`, margin, y);
      y += lineHeight;
      doc.text(`IP Address: ${latestAgreement.IpAddress}`, margin, y);
      y += lineHeight;
      doc.text(`Coordinates: ${latestAgreement.Location}`, margin, y);
      y += lineHeight;
      doc.text(`Location: ${latestAgreement.LocationCityState}`, margin, y);
      
      // Change font for the signature
      
    }
  
    doc.save('service-agreement.pdf');
  };

  return (
    <>
    <MobileHeader theme={theme} />
    <div className="mobile-container mx-auto px-4">
      <h1 className="text-2xl font-semibold mb-8">Service Agreement</h1>
      {isLoading && <Spinner />}
      {!isLoading && (
        <div>
          <textarea
            id="agreement-text"
            value={agreementContent}
            readOnly
            className="w-full h-96 p-4 border border-gray-300 rounded"
          />
          {latestAgreement && (
            <div className="signature-stamp mt-4">
              <p><b>Signature Details</b></p>
              <p style={{ fontFamily: 'Brush Script MT, cursive', fontSize: '46px', padding: '10px 0' }}>
                {firstName} {lastName}
              </p>
              <p>Signature ID: {userId}</p>
              <p>TOS Version: {latestAgreement.Version}</p>
              <p>Timestamp: {new Date(latestAgreement.Timestamp).toLocaleString()}</p>
              <p>IP Address: {latestAgreement.IpAddress}</p>
              <p>Coordinates: {latestAgreement.Location}</p>
              <p>Location: {latestAgreement.LocationCityState}</p>
            </div>
          )}
          <button onClick={handleDownload} className="mt-4 px-4 py-2 text-white rounded themed-button bg-blue-500 hover:bg-blue-700">
            <FontAwesomeIcon icon={faDownload} className="mr-2" />
            Download
          </button>
        </div>
      )}
    </div>
    </>
  );
};

export default ViewServiceAgreement;
