import React, { useEffect, useState, useMemo } from 'react';
import MainTableV2 from './MainTableV2';
import Spinner from './Spinner';
import { useAuth } from './AuthContext';
import AddTOSModal from './AddTOSModal';
import EditTOSModal from './EditTOSModal';
import { useTheme } from './ThemeContext';
import MobileHeader from './MobileHeader';
import { useNavigate } from 'react-router-dom';
import PageHeader from './PageHeader';

const TOSManager = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const primaryColor = theme.primary;
    const [tosRecords, setTosRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedTOSRecord, setSelectedTOSRecord] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const fetchTOSRecords = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getTOSRecords', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: currentUser.userId,
                    subDomain: window.location.hostname.split('.')[0],
                    requestType: 'get'
                })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            const data = await response.json();
            // console.log('TOS Data: ', data);
            setTosRecords(data);
        } catch (error) {
            setError('Failed to load data: ' + error.message);
            console.error('There was an error!', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (currentUser?.userId) {
            fetchTOSRecords();
        }
    }, [currentUser]);

    const handleEditClick = (rowData) => {
        setSelectedTOSRecord(rowData);
        setIsEditModalOpen(true);
    };

    const columns = useMemo(() => [
        {
            name: 'Version',
            selector: row => row.Version,
            sortable: true,
            reorder: true
        },
        {
            name: 'Created On',
            selector: row => new Date(row.CreatedAt).toLocaleString(),
            sortable: true,
            reorder: true,
            hide: 'sm'
        },
        {
            name: 'Updated On',
            selector: row => new Date(row.UpdatedAt).toLocaleString(),
            sortable: true,
            reorder: true
        },
        {
            name: 'Action',
            cell: row => (
                <button
                    className="themed-button"
                    style={{ width: '80px' }}
                    onClick={() => handleEditClick(row)}
                >
                    {parseFloat(row.Version) === Math.max(...tosRecords.map(t => parseFloat(t.Version))) ? 'Edit' : 'View'}
                </button>
            ),
            ignoreRowClick: true,
        }
    ], [handleEditClick, tosRecords]);

    const handleAddTOS = (newTOS) => {
        if (newTOS) {
            fetchTOSRecords(); // Refresh the records to reflect the new addition
            setSuccessMessage("Successfully added the TOS record!");
        } else {
            setSuccessMessage("Error adding TOS record. Try Again.");
        }
    };

    const handleSaveEdit = (updatedTOS) => {
        fetchTOSRecords(); // Refresh the records to reflect the new version
    };

    const clearSuccessMessage = () => setSuccessMessage("");

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
            }, 7000);

            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    const filterConfig = [
        { key: 'Version', type: 'number' },
        { key: 'CreatedAt', type: 'string' },
        { key: 'UpdatedAt', type: 'string' }
    ];

    const filteredData = useMemo(() => {
        if (!searchQuery) return tosRecords;
        return tosRecords.filter(record => {
            return filterConfig.some(({ key, type }) => {
                const value = record[key];
                if (!value) return false;
                const valueStr = type === 'number' ? value.toString() : (typeof value === 'string' ? value.toLowerCase() : '');
                return valueStr.includes(searchQuery.toLowerCase());
            });
        });
    }, [searchQuery, tosRecords]);

    return (
        <>
            <MobileHeader theme={theme} />
            <div className='mobile-container'>
                <PageHeader
                    heading="Terms Of Service"
                    context="Manage your terms of service contracts."
                />
                {loading && <Spinner />}
                {error && <div className="alert alert-danger">{error}</div>}
                <MainTableV2
                    columns={columns}
                    data={filteredData}
                    primaryColor={primaryColor}
                    loading={loading}
                    alertMessage={successMessage}
                    clearAlert={clearSuccessMessage}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    filterConfig={filterConfig}
                    isNested={false}
                    children={
                        <>
                            <div className="col-span-1"></div>
                            <div className="col-span-1 flex justify-end">
                                <button onClick={() => setIsModalOpen(true)} className="themed-button rounded-md px-4 py-2">
                                    Add New TOS
                                </button>
                            </div>
                        </>
                    }
                />
                {isModalOpen && (
                    <AddTOSModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onSave={handleAddTOS}
                    />
                )}
                {isEditModalOpen && (
                    <EditTOSModal
                        isOpen={isEditModalOpen}
                        onClose={() => setIsEditModalOpen(false)}
                        onSave={handleSaveEdit}
                        tosRecord={selectedTOSRecord}
                        isEditable={parseFloat(selectedTOSRecord?.Version) === Math.max(...tosRecords.map(t => parseFloat(t.Version)))}
                    />
                )}
            </div>
        </>
    );
};

export default TOSManager;
