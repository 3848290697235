import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useTheme } from './ThemeContext';
import TransactionCountCard from './TransactionCountCard';
import WeeklyAmountChart from './WeeklyAmountChart';
import Spinner from './Spinner';
import DiscountChart from './DiscountDashboardCard';
import AveragePPUChart from './AveragePPUChart';
import BalanceCard from './BalanceCard';
import PaymentProcessed from './PaymentProcessed';
import MobileHeader from './MobileHeader';
import MapMarker from './MapMarker';
import InfoWindow from './InfoWindow';
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import geocodeAddress from './geocode';


const mapContainerStyle = {
  width: '100%',
  height: '400px',
  borderRadius: '5px',
  zIndex: 1,
};

const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
};

const center = {
  lat: 37.0902,
  lng: -95.7129,
};

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const apiUrlStripe = process.env.REACT_APP_API_URL_STRIPE;
const apiUrl = process.env.REACT_APP_API_URL;

function Dashboard() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [showPaymentIntentModal, setShowPaymentIntentModal] = useState(false);
  const [paymentIntentStatus, setPaymentIntentStatus] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [locationCoordinates, setLocationCoordinates] = useState([]); // State to store coordinates
  const subDomain = window.location.hostname.split('.')[0];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntentId = urlParams.get('payment_intent');
    const preLoginPath = sessionStorage.getItem('preLoginPath');
    if (preLoginPath) {
      // console.log("Running preLoginPath in UseEffect");
      navigate(preLoginPath);
      sessionStorage.removeItem('preLoginPath');
    }

    if (paymentIntentId) {
      setIsPaymentLoading(true);
      fetch(`${apiUrlStripe}/getPaymentIntentStatus`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentIntentId: paymentIntentId })
      })
        .then(response => response.json())
        .then(data => {
          setPaymentIntentStatus(data.status);
          setPaymentAmount((data.amount / 100).toFixed(2));
          setShowPaymentIntentModal(true);
          setIsPaymentLoading(false);
        })
        .catch(error => {
          // console.error("Error fetching payment intent status:", error);
          setIsPaymentLoading(false);
        });
    }

    if (currentUser) {
      setIsLoading(true);
      const fetchDashboardData = async () => {
        try {
          const response = await axios.post(`${apiUrl}/dashboardData?`, {
            userId: currentUser.userId,
          });
          const data = response.data;
          setDashboardData(data);
          //console.log('Response Data: ', data);

          // Fetch coordinates for each location
          const locationPromises = data["Location Data"].map(async location => {
            if (location.lat && location.lng) {
              return {
                ...location,
                lat: Number(location.lat),
                lng: Number(location.lng),
              };
            } else {
              const address = `${location.name}, ${location.city}, ${location.state}`;
              const coords = await geocodeAddress(address, googleMapsApiKey);
              return { ...location, ...coords };
            }
          });

          const locationsWithCoordinates = await Promise.all(locationPromises);
          setLocationCoordinates(locationsWithCoordinates);
          //console.log("All Locations with Coordinates:", locationsWithCoordinates); // Log all locations with coordinates

          setIsLoading(false);
        } catch (error) {
          // console.error('Error fetching dashboard data:', error);
          setIsLoading(false);
        }
      };
      fetchDashboardData();
    }
  }, [currentUser]);

  const handleCloseModal = () => {
    setShowPaymentIntentModal(false);
    const cleanURL = window.location.protocol + "//" + window.location.host + window.location.pathname;
    window.history.replaceState({}, document.title, cleanURL);
  };

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
    map.panTo({ lat: location.lat, lng: location.lng });
  };

  const handleInfoWindowClose = () => {
    setSelectedLocation(null);
  };

  if (!currentUser) {
    navigate('/');
    return null;
  }

  const weeklyAmountData = dashboardData["Weekly UserAmount Sum"]
    ? Object.keys(dashboardData["Weekly UserAmount Sum"]).map((week, index) => ({
      week: `Week ${week}`,
      weeklyAmount: dashboardData["Weekly UserAmount Sum"][week],
      key: `${week}-${index}`,
    }))
    : [];


  return (
    <>
      <MobileHeader theme={theme} />
      {showPaymentIntentModal && (
        <PaymentProcessed
          status={paymentIntentStatus}
          amount={paymentAmount}
          onClose={handleCloseModal}
        />
      )}
      <div className="flex-1 p-6 overflow-x-hidden mobile-container">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {/* {currentUser.balance < 100 && (
               <div className={`alert ${currentUser.balance === 0 ? 'bg-red-100 border border-red-400 text-red-700' : 'bg-yellow-100 border border-yellow-400 text-yellow-700'} px-4 py-3 rounded relative mb-4`}>
               {currentUser.balance === 0 ? 
                 "You have no funds on your cards! Fund your account now." : 
                 "Your account balance is getting low, fund your account now."
               }
             </div>
             
              )} */}
              {(subDomain === 'integrity' || subDomain === 'localhost') && (
              <div className="bg-yellow-100 border border-yellow-400 text-yellow-900 px-6 py-5 rounded-lg shadow-lg mb-6 flex items-center" style={{ backgroundColor: '#ffb800' }}>
              <img 
                src="https://cdn.prod.website-files.com/62291652a86b53583094c14a/645299ffbb7d424d80367c1c_Tank%20Nav%20Logo.svg" 
                alt="Tank Payments Logo" 
                className="w-32 h-auto mr-4" 
              />
              <div className="flex flex-col">
                <span className="font-semibold text-lg">
                  Now You Can Fund Your Cards With Your Tank Payments Account!
                </span>
                <a 
                  href="https://app.tankpayments.com/cash" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="underline text-blue-900 font-medium mt-1"
                >
                  Log In To Your Tank Payments Account To Get Started
                </a>
              </div>
            </div>
            
            )}
            {currentUser.hasPendingTransactions ? (
              <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4">
                You have pending transactions. <Link to="/useraccounting" className="font-bold underline">Click here</Link> to view them.
              </div>
            ) : (
              <>
                {currentUser.balance < 100 && (
                  <div className={`alert ${currentUser.balance === 0 ? 'bg-red-100 border border-red-400 text-red-700' : 'bg-yellow-100 border border-yellow-400 text-yellow-700'} px-4 py-3 rounded relative mb-4`}>
                    {currentUser.balance === 0 ?
                      "You have no funds on your cards! Fund your account now." :
                      "Your account balance is getting low, fund your account now."
                    }
                  </div>
                )}
              </>
            )}
            <div className="w-full mb-4">
              <BalanceCard theme={theme} lowBalance={currentUser.balance < 100} zeroBalance={currentUser.balance === 0} />
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
              <div className="bg-white rounded-lg shadow-md p-4 col-span-1 w-full h-full">
                <AveragePPUChart theme={theme} data={dashboardData} />
              </div>
              <div className="bg-white rounded-lg shadow-md p-4 col-span-1 w-full h-full">
                <DiscountChart theme={theme} data={dashboardData} />
              </div>
              <div className="bg-white rounded-lg shadow-md p-4 col-span-1 w-full h-full">
                <TransactionCountCard data={dashboardData} theme={theme} />
              </div>
              <div className="bg-white rounded-lg shadow-md p-4 col-span-1 w-full h-full">
                <WeeklyAmountChart theme={theme} data={weeklyAmountData} />
              </div>
              <div className="bg-white rounded-lg shadow-md p-4 col-span-1 sm:col-span-2 w-full h-full">
                <div className="bg-transparent text-center sm:text-left">
                  <div className="text-lg font-bold pl-2 pt-2">Spend By Location</div>
                  <div className="text-sm font-normal pl-2 pb-2">Last 30 Days</div>
                </div>
                <LoadScriptNext googleMapsApiKey={googleMapsApiKey}>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    options={mapOptions}
                    center={center}
                    zoom={4}
                    onLoad={(map) => setMap(map)}
                  >
                    {window.google && map && locationCoordinates.map((location, index) => {
                      if (location.lat && location.lng) {
                        return (
                          <MapMarker
                            key={index}
                            google={window.google}
                            map={map}
                            position={{ lat: location.lat, lng: location.lng }}
                            onClick={() => handleMarkerClick(location)}
                            theme={theme}
                          />
                        );
                      } else {
                        //console.warn("Invalid location data:", location);
                        return null;
                      }
                    })}
                    {selectedLocation && (
                      <InfoWindow
                        google={window.google}
                        map={map}
                        position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
                        onCloseClick={handleInfoWindowClose}
                        content={`
                            <div>
                              <h3>${selectedLocation.name}</h3>
                              <p>${selectedLocation.city}, ${selectedLocation.state}</p>
                              <p class="text-gray-500"><strong>Amount Spent:</strong> $${selectedLocation.amount.toFixed(2)}</p>
                            </div>
                          `}
                      />
                    )}
                  </GoogleMap>
                </LoadScriptNext>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;

