import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, isSupported } from "firebase/analytics";
import { GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAnesfzqFNtmz-DRAoZoiWRyWqjg_o7PqM",
    authDomain: "fuellink-396705.firebaseapp.com",
    projectId: "fuellink-396705",
    storageBucket: "fuellink-396705.appspot.com",
    messagingSenderId: "931160299463",
    appId: "1:931160299463:web:84e96b833c1e208e182be2",
    measurementId: "G-1P0JGBZ6L4"
  };

const app = initializeApp(firebaseConfig);
let analyticsInstance;

// Check if analytics is supported and then initialize
isSupported().then((supported) => {
  if (supported) {
    analyticsInstance = getAnalytics(app);
  } else {
    console.log("Firebase Analytics is not supported on this browser");
  }
}).catch((error) => {
  console.error("Error checking Firebase Analytics support:", error);
});

export const firebaseApp = app;
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();
export const setTenantId = (tenantId) => {
  auth.tenantId = tenantId;
};
export { analyticsInstance };