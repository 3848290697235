import React, { useState, useEffect } from 'react';
import MainTableV2 from './MainTableV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faEdit,
    faTrash,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from './Spinner';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import SalesRepModal from './SalesRepModal';
import EditLeadModal from './EditLeadModal';
import { getLocalStorage, setLocalStorage } from './localStorageHelpers';
import PageHeader from './PageHeader';
import MobileHeader from './MobileHeader';
import SendCRMEmailModal from './SendCRMEmailModal';

const CRM = () => {
    const [leads, setLeads] = useState([]);
    const [salesReps, setSalesReps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [isConfirm, setIsConfirm] = useState(false);
    const [confirmCallback, setConfirmCallback] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const { theme } = useTheme();
    const { currentUser } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [showAlertBanner, setShowAlertBanner] = useState(false);
    const [hoveredLead, setHoveredLead] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedLeadForEmail, setSelectedLeadForEmail] = useState(null);
    const [filters, setFilters] = useState({
        status: getLocalStorage('crmStatusFilter') || [],
        salesRep: getLocalStorage('crmSalesRepFilter') || '',
    });
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchLeadsAndSalesReps = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'getLeads',
                    payload: { userId: currentUser.userId, subDomain: currentUser.subDomain },
                }),
            });

            const result = await response.json();
            console.log('Leads and SalesReps result:', result);
            if (response.ok && result.success) {
                setLeads(result.leads || []);
                setSalesReps(result.salesReps || []);
            } else {
                console.error('Error fetching leads and sales reps:', result.message);
                setAlertMessage(`Error: ${result.message}`);
            }
        } catch (error) {
            console.error('Error occurred while fetching leads and sales reps:', error);
            setAlertMessage(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLeadsAndSalesReps();
    }, [apiUrl, currentUser]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.status-dropdown')) {
                setStatusDropdownOpen(false);
            }
        };
        if (statusDropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => document.removeEventListener('click', handleClickOutside);
    }, [statusDropdownOpen]);

    // Function to handle assigning sales rep to a lead
    const handleAssignRep = async (repId) => {
        setLoading(true);
        try {
            let response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'assignSalesRepToLead',
                    payload: {
                        userId: currentUser.userId,
                        leadId: selectedLead.Id,
                        repId: repId,
                    },
                }),
            });

            let result = await response.json();

            if (response.ok && result.success) {
                const assignedRep = salesReps.find((rep) => rep.Id === repId);
                setLeads((prevLeads) =>
                    prevLeads.map((lead) =>
                        lead.Id === selectedLead.Id
                            ? { ...lead, SalesRep: assignedRep, SalesPerson: assignedRep.Id }
                            : lead
                    )
                );
                setShowAssignModal(false);
                if (result.message !== 'Sales rep assigned successfully') {
                    setShowAlertBanner(result.message);
                }
            } else if (result.currentRep) {
                // Lead is already assigned; prompt for confirmation
                showConfirmModal(
                    `This lead is already assigned to ${result.currentRep.FirstName} ${result.currentRep.LastName}. Do you want to override?`,
                    async () => {
                        // User confirmed; proceed with override
                        setLoading(true);
                        try {
                            response = await fetch(`${apiUrl}/dataServer`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    action: 'assignSalesRepToLead',
                                    payload: {
                                        userId: currentUser.userId,
                                        leadId: selectedLead.Id,
                                        repId: repId,
                                        override: true,
                                    },
                                }),
                            });

                            result = await response.json();

                            if (response.ok && result.success) {
                                const assignedRep = salesReps.find((rep) => rep.Id === repId);
                                setLeads((prevLeads) =>
                                    prevLeads.map((lead) =>
                                        lead.Id === selectedLead.Id
                                            ? { ...lead, SalesRep: assignedRep, SalesPerson: assignedRep.Id }
                                            : lead
                                    )
                                );
                                setShowAssignModal(false);
                                closeAlertModal();
                            } else {
                                setAlertMessage(`Error: ${result.message}`);
                                setIsConfirm(false);
                            }
                        } catch (error) {
                            setAlertMessage(`Error: ${error.message}`);
                            setIsConfirm(false);
                        } finally {
                            setLoading(false);
                        }
                    }
                );
            } else {
                setAlertMessage(`Error: ${result.message}`);
            }
        } catch (error) {
            setAlertMessage(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    // Function to show confirm modal with custom logic
    const showConfirmModal = (message, callback) => {
        setAlertMessage(message);
        setIsConfirm(true);
        setConfirmCallback(() => callback);
    };

    // Function to close the alert modal
    const closeAlertModal = () => {
        setAlertMessage(null);
        setIsConfirm(false);
        setConfirmCallback(null);
        fetchLeadsAndSalesReps();
    };

    const AlertModal = ({ message, onClose, onConfirm, isConfirm }) => {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto overflow-hidden">
                    {/* Header */}
                    <div className="px-6 py-4 border-b">
                        <h2 className="text-xl font-semibold text-gray-800">
                            {isConfirm ? 'You Sure?' : 'Alert'}
                        </h2>
                    </div>

                    {/* Message */}
                    <div className="px-6 py-4">
                        <p className="text-gray-700">{message}</p>
                    </div>

                    {/* Buttons */}
                    <div className="px-6 py-4 border-t flex justify-end space-x-4">
                        <button className="themed-button bg-gray-500 text-white" onClick={onClose}>
                            {isConfirm ? 'Cancel' : 'Close'}
                        </button>
                        {isConfirm && (
                            <button className="themed-button" onClick={onConfirm}>
                                Confirm
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    // Function to handle adding a new sales rep
    const handleAddRep = async (salesRep) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'addSalesRep',
                    payload: {
                        userId: currentUser.userId,
                        subDomain: currentUser.subDomain,
                        salesRep: salesRep,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setSalesReps([...salesReps, result.salesRep]);
            } else {
                console.error('Error adding sales rep:', result.message);
            }
        } catch (error) {
            console.error('Error adding sales rep:', error);
        } finally {
            setLoading(false);
        }
    };

    const mapLeadToUpdates = (lead) => {
        return {
            docket: lead.DOCKET,
            usdot: lead.USDOT,
            legalName: lead.LEGALNAME,
            dbaName: lead.DBANAME,
            address: lead.ADDRESS,
            city: lead.CITY,
            state: lead.STATE,
            zip: lead.ZIP,
            phone: lead.PHONE,
            cell: lead.CELL,
            fax: lead.FAX,
            email: lead.EMAIL,
            contact1: lead.CONTACT1,
            contact2: lead.CONTACT2,
            trucks: lead.TRUCKS,
            vehInsp: lead.VEHINSP,
            salesPerson: lead.SalesPerson || null,
            notes: lead.Notes,
            optOut: lead.OptOut,
            addedToMailingList: lead.AddedToMailingList,
            leadStatus: lead.LeadStatus,
        };
    };

    const handleAssignClick = (lead) => {
        setSelectedLead(lead);
        setShowAssignModal(true);
    };

    // Function to handle changing lead status
    const handleStatusChange = async (lead, newStatus) => {
        if (!lead.SalesRep) return;

        setLoading(true);
        try {
            const updatedLead = { ...lead, LeadStatus: newStatus };
            const updates = mapLeadToUpdates(updatedLead);

            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'updateLead',
                    payload: {
                        userId: currentUser.userId,
                        leadId: lead.Id,
                        updates: updates,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setLeads((prevLeads) =>
                    prevLeads.map((l) => (l.Id === lead.Id ? { ...l, LeadStatus: newStatus } : l))
                );
            } else {
                console.error('Error updating lead status:', result.message);
            }
        } catch (error) {
            console.error('Error updating lead status:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle editing a lead
    const handleEdit = (lead) => {
        setSelectedLead(lead);
        setShowEditModal(true);
    };

    // Function to handle saving the edited lead
    const handleSaveLead = async (updatedLead) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'updateLead',
                    payload: {
                        userId: currentUser.userId,
                        leadId: updatedLead.Id,
                        updates: {
                            leadStatus: updatedLead.LeadStatus,
                            docket: updatedLead.DOCKET,
                            usdot: updatedLead.USDOT,
                            dbaName: updatedLead.DBANAME,
                            address: updatedLead.ADDRESS,
                            city: updatedLead.CITY,
                            state: updatedLead.STATE,
                            zip: updatedLead.ZIP,
                            cell: updatedLead.CELL,
                            fax: updatedLead.FAX,
                            contact2: updatedLead.CONTACT2,
                            trucks: updatedLead.TRUCKS,
                            vehInsp: updatedLead.VEHINSP,
                            salesPerson: updatedLead.SalesPerson || null,
                            notes: updatedLead.Notes,
                            optOut: updatedLead.OptOut,
                            addedToMailingList: updatedLead.AddedToMailingList,
                            legalName: updatedLead.LEGALNAME,
                            contact1: updatedLead.CONTACT1,
                            email: updatedLead.EMAIL,
                            phone: updatedLead.PHONE,
                        },
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                // Update the lead in the state
                setLeads((prevLeads) =>
                    prevLeads.map((lead) => (lead.Id === updatedLead.Id ? { ...lead, ...updatedLead } : lead))
                );
                setAlertMessage('Lead Updated Successfully.');
                setShowEditModal(false);
            } else {
                console.error('Error updating lead:', result.message);
            }
        } catch (error) {
            console.error('Error updating lead:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle filtering
    const handleFilterChange = (filterKey, value) => {
        const newFilters = { ...filters, [filterKey]: value };
        setFilters(newFilters);
        // Save to local storage
        setLocalStorage(`crm${filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}Filter`, value);
    };

    // Apply filters to the data
    const filteredLeads = leads.filter((lead) => {
        // Filter by status
        if (filters.status.length > 0 && !filters.status.includes(lead.LeadStatus)) {
            return false;
        }
        // Filter by sales rep
        if (filters.salesRep) {
            if (!lead.SalesRep || lead.SalesRep.Id !== filters.salesRep) {
                return false;
            }
        }
        return true;
    });

    // Status options and CSS classes
    const statusOptions = [
        { value: 'New', label: 'New', cssClass: 'status-new' },
        { value: 'Working', label: 'Working', cssClass: 'status-working' },
        { value: 'Contacted', label: 'Contacted', cssClass: 'status-contacted' },
        { value: 'Not Interested', label: 'Not Interested', cssClass: 'status-not-interested' },
        { value: 'Not Qualified', label: 'Not Qualified', cssClass: 'status-not-qualified' },
        { value: 'Qualified', label: 'Qualified', cssClass: 'status-qualified' },
    ];

    // CSS classes for statuses
    const statusCssClasses = {
        New: 'status-new',
        Working: 'status-working',
        Contacted: 'status-contacted',
        'Not Interested': 'status-not-interested',
        'Not Qualified': 'status-not-qualified',
        Qualified: 'status-qualified',
    };

    // Function to handle hover
    const handleMouseEnter = (lead, event) => {
        setHoveredLead(lead);
        setTooltipPosition({ x: event.clientX, y: event.clientY });
    };

    const handleMouseLeave = () => {
        setHoveredLead(null);
    };

    // Function to handle delete confirmation
    const handleDelete = (id) => {
        showConfirmModal('Are you sure you want to delete this lead?', () => {
            deleteLead(id);
        });
    };

    const deleteLead = async (id) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'deleteLead',
                    payload: {
                        userId: currentUser.userId,
                        leadId: id,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setLeads(leads.filter((lead) => lead.Id !== id));
                closeAlertModal();
                setAlertMessage('Lead deleted successfully.');
            } else {
                setAlertMessage(`Error deleting lead: ${result.message}`);
            }
        } catch (error) {
            setAlertMessage(`Error deleting lead: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle file input change
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    // Function to handle file submission
    const handleFileSubmit = async () => {
        if (!selectedFile) {
            alert('Please select a file.');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch(`${apiUrl}/addLeadsFromFile`, {
                method: 'POST',
                body: formData,
            });

            const result = await response.text();

            if (response.ok) {
                setAlertMessage('File uploaded and leads processed successfully.');
            } else {
                setAlertMessage(`Error: ${result.message}`);
            }
        } catch (error) {
            console.error('Error uploading file:', error.message);
            setAlertMessage('An error occurred while uploading the file.');
        } finally {
            setShowModal(false);
            setLoading(false);
        }
    };

    // Function to handle editing a sales rep
    const handleEditRep = async (salesRep) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'editSalesRep',
                    payload: {
                        userId: currentUser.userId,
                        salesRep: salesRep,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setSalesReps((prevSalesReps) =>
                    prevSalesReps.map((rep) => (rep.Id === salesRep.Id ? salesRep : rep))
                );
                setLeads((prevLeads) =>
                    prevLeads.map((lead) =>
                        lead.SalesRep && lead.SalesRep.Id === salesRep.Id
                            ? { ...lead, SalesRep: salesRep }
                            : lead
                    )
                );
            } else {
                console.error('Error editing sales rep:', result.message);
            }
        } catch (error) {
            console.error('Error editing sales rep:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle deleting a sales rep
    const handleDeleteRep = async (repToDelete, replaceWith) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'deleteSalesRep',
                    payload: {
                        userId: currentUser.userId,
                        salesRepId: repToDelete.Id,
                        replaceWith: replaceWith,
                    },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setSalesReps((prevSalesReps) => prevSalesReps.filter((rep) => rep.Id !== repToDelete.Id));
                setLeads((prevLeads) =>
                    prevLeads.map((lead) =>
                        lead.SalesRep && lead.SalesRep.Id === repToDelete.Id
                            ? {
                                  ...lead,
                                  SalesRep: replaceWith
                                      ? salesReps.find((rep) => rep.Id === replaceWith)
                                      : null,
                              }
                            : lead
                    )
                );
            } else {
                console.error('Error deleting sales rep:', result.message);
            }
        } catch (error) {
            console.error('Error deleting sales rep:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle opening the email modal
    const handleEmail = (lead) => {
        setSelectedLeadForEmail(lead);
        setShowEmailModal(true);
    };

    // Columns definition for the DataTable
    const columns = [
        {
            name: 'Contact',
            selector: (row) => row.CONTACT1 || row.CONTACT2 || '',
            sortable: true,
            cell: (row) => (
                <div
                    onMouseEnter={(e) => handleMouseEnter(row, e)}
                    onMouseLeave={handleMouseLeave}
                    className="relative"
                >
                    {row.CONTACT1 || row.CONTACT2 || ''}
                </div>
            ),
        },
        {
            name: 'Company Name',
            selector: (row) => row.LEGALNAME,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.PHONE,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.EMAIL,
            sortable: true,
        },
        {
            name: 'Trucks',
            selector: (row) => row.TRUCKS,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => {
                const isDisabled = !row.SalesRep;
                return (
                    <select
                        className={`p-1 rounded ${statusCssClasses[row.LeadStatus] || ''}`}
                        value={row.LeadStatus}
                        onChange={(e) => handleStatusChange(row, e.target.value)}
                        disabled={isDisabled}
                    >
                        {statusOptions.map((option) => (
                            <option key={option.value} value={option.value} className={option.cssClass}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            },
            sortable: true,
        },
        {
            name: 'Assigned SalesRep',
            cell: (row) =>
                row.SalesRep ? (
                    <>
                        {row.SalesRep.FirstName} {row.SalesRep.LastName}
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="ml-2 text-blue-500 cursor-pointer"
                            onClick={() => handleAssignClick(row)}
                        />
                    </>
                ) : (
                    <span onClick={() => handleAssignClick(row)} className="text-blue-500 cursor-pointer">
                        <FontAwesomeIcon icon={faPlus} /> Assign
                    </span>
                ),
        },
        {
            name: 'Actions',
            cell: (row) => {
                const hasSalesRep = !!row.SalesRep;
                return (
                    <div className="flex space-x-2">
                        <button
                            className={`themed-button ${!hasSalesRep ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={() => hasSalesRep && handleEmail(row)}
                            title="Send Email"
                            disabled={!hasSalesRep}
                        >
                            <FontAwesomeIcon icon={faEnvelope} />
                        </button>
                        <button
                            className={`themed-button ${!hasSalesRep ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={() => hasSalesRep && handleEdit(row)}
                            title="Edit Lead"
                            disabled={!hasSalesRep}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button
                            className="themed-button"
                            onClick={() => handleDelete(row.Id)}
                            title="Delete Lead"
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                );
            },
            ignoreRowClick: true,
            style: {
                width: '550px',
            },
        },
    ];

    return (
        <>
            <MobileHeader theme={theme} />
            <PageHeader heading="CRM" context="Manage Sales Leads" />
            <div className="mx-auto">
                {loading && <Spinner />}

                {/* Custom Alert Modal */}
                {alertMessage && (
                    <AlertModal
                        message={alertMessage}
                        onClose={closeAlertModal}
                        onConfirm={confirmCallback}
                        isConfirm={isConfirm}
                    />
                )}

                {/* Alert Message */}
                {showAlertBanner && (
                    <div className="alert bg-green-200 text-green-600 p-4 rounded-md mb-4">
                        {showAlertBanner}
                        <button onClick={() => setShowAlertBanner(null)} className="float-right">
                            &times;
                        </button>
                    </div>
                )}

                {/* Filter Panel */}
                <div className="bg-white shadow-md rounded-lg p-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Status Filter */}
                        <div className="relative inline-block text-left status-dropdown">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Filter by Status
                            </label>
                            <div>
                                <button
                                    type="button"
                                    className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
                                >
                                    {filters.status.length > 0
                                        ? `Selected (${filters.status.length})`
                                        : 'All Statuses'}
                                    <svg
                                        className="-mr-1 ml-2 h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.292 7.707a1 1 0 011.415 0L10 10.999l3.293-3.292a1 1 0 111.415 1.414l-4 4.001a1 1 0 01-1.415 0l-4-4.001a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>

                            {statusDropdownOpen && (
                                <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                                    <div className="py-1">
                                        {statusOptions.map((option) => (
                                            <label
                                                key={option.value}
                                                className="flex items-center px-4 py-2 text-sm text-gray-700"
                                            >
                                                <input
                                                    type="checkbox"
                                                    value={option.value}
                                                    checked={filters.status.includes(option.value)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        const isChecked = e.target.checked;
                                                        let newStatusFilter = [...filters.status];
                                                        if (isChecked) {
                                                            newStatusFilter.push(value);
                                                        } else {
                                                            newStatusFilter = newStatusFilter.filter(
                                                                (status) => status !== value
                                                            );
                                                        }
                                                        handleFilterChange('status', newStatusFilter);
                                                    }}
                                                    className="mr-2 text-blue-600 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded"
                                                />
                                                {option.label}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Sales Rep Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Filter by Sales Rep
                            </label>
                            <select
                                className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                                value={filters.salesRep}
                                onChange={(e) => handleFilterChange('salesRep', e.target.value)}
                            >
                                <option value="">All</option>
                                {salesReps.map((rep) => (
                                    <option key={rep.Id} value={rep.Id}>
                                        {rep.FirstName} {rep.LastName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <MainTableV2
                    columns={columns}
                    data={filteredLeads}
                    primaryColor={theme.primary}
                    loading={loading}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    filterConfig={[
                        { key: 'CONTACT1', type: 'string' },
                        { key: 'LEGALNAME', type: 'string' },
                        { key: 'EMAIL', type: 'string' },
                        { key: 'PHONE', type: 'string' },
                        { key: 'SalesRep.FirstName', type: 'string' },
                        { key: 'SalesRep.LastName', type: 'string' },
                        { key: 'LeadStatus', type: 'string' },
                    ]}
                >
                    {/* Button to add new leads */}
                    <div className="flex justify-end">
                        <button className="themed-button" onClick={() => setShowModal(true)}>
                            Add New Leads
                        </button>
                    </div>
                </MainTableV2>

                {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-96 transform transition-all duration-300 ease-out scale-100">
                            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                                Upload Leads File
                            </h2>

                            <p className="text-sm text-gray-600 mb-4 text-center">
                                Please select a CSV file containing leads to upload. Ensure the file
                                follows the correct format for successful import.
                            </p>

                            <div className="flex flex-col items-center justify-center mb-6">
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:border-blue-500 p-2"
                                />
                                {selectedFile && (
                                    <p className="mt-2 text-sm text-green-600">
                                        Selected file:{' '}
                                        <span className="font-semibold">{selectedFile.name}</span>
                                    </p>
                                )}
                            </div>

                            <div className="flex justify-between">
                                <button
                                    className="themed-button transition-colors duration-300"
                                    onClick={handleFileSubmit}
                                >
                                    Submit
                                </button>
                                <button
                                    className="bg-red-500 text-white px-4 py-2 rounded-md shadow hover:bg-red-600 transition-colors duration-300"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Sales Rep Modal */}
                {showAssignModal && (
                    <SalesRepModal
                        show={showAssignModal}
                        onClose={() => setShowAssignModal(false)}
                        onAdd={handleAddRep}
                        onAssign={handleAssignRep}
                        currentUser={currentUser}
                        selectedUser={selectedLead}
                        salesReps={salesReps}
                        setSalesReps={setSalesReps}
                        onEdit={handleEditRep}
                        onDelete={handleDeleteRep}
                        setUsers={setLeads}
                    />
                )}

                {/* Edit Lead Modal */}
                {showEditModal && (
                    <EditLeadModal
                        lead={selectedLead}
                        onClose={() => setShowEditModal(false)}
                        onSave={handleSaveLead}
                        statusOptions={statusOptions}
                        statusCssClasses={statusCssClasses}
                    />
                )}

                {/* Email Modal */}
                {showEmailModal && (
                    <SendCRMEmailModal
                        show={showEmailModal}
                        onClose={() => setShowEmailModal(false)}
                        lead={selectedLeadForEmail}
                        salesReps={salesReps}
                        currentUser={currentUser}
                    />
                )}

                {/* Tooltip with lead info */}
                {hoveredLead && (
                    <div
                        className="absolute bg-gray-800 text-white p-3 rounded-lg shadow-lg"
                        style={{ top: `${tooltipPosition.y + 20}px`, left: `${tooltipPosition.x}px` }}
                    >
                        <div>
                            <strong>Address:</strong> {hoveredLead.ADDRESS}, {hoveredLead.CITY},{' '}
                            {hoveredLead.STATE} {hoveredLead.ZIP}
                        </div>
                        <div>
                            <strong>Notes:</strong> {hoveredLead.Notes || 'No Notes'}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default CRM;



// import React, { useState, useEffect, useRef } from 'react';
// import MainTableV2 from './MainTableV2';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//     faEnvelope,
//     faEdit,
//     faTrash,
//     faUpload,
//     faPlus,
// } from '@fortawesome/free-solid-svg-icons';
// import Spinner from './Spinner';
// import { useTheme } from './ThemeContext';
// import { useAuth } from './AuthContext';
// import SalesRepModal from './SalesRepModal';
// import EditLeadModal from './EditLeadModal';
// import { getLocalStorage, setLocalStorage } from './localStorageHelpers';
// import PageHeader from './PageHeader';
// import MobileHeader from './MobileHeader';
// import EmailEditor from 'react-email-editor';

// const CRM = () => {
//     const [leads, setLeads] = useState([]);
//     const [salesReps, setSalesReps] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [alertMessage, setAlertMessage] = useState(null);
//     const [isConfirm, setIsConfirm] = useState(false); // Determine if alert is confirm modal
//     const [confirmCallback, setConfirmCallback] = useState(null); // Store confirm callback
//     const [searchQuery, setSearchQuery] = useState('');
//     const { theme } = useTheme();
//     const { currentUser } = useAuth();
//     const [showModal, setShowModal] = useState(false);
//     const [showAssignModal, setShowAssignModal] = useState(false);
//     const [selectedLead, setSelectedLead] = useState(null);
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [showEditModal, setShowEditModal] = useState(false);
//     const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
//     const [showAlertBanner, setShowAlertBanner] = useState(false);
//     const [hoveredLead, setHoveredLead] = useState(null); // Store the lead being hovered over
//     const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 }); // Store the tooltip position
//     const [showEmailModal, setShowEmailModal] = useState(false);
//     const [emailTemplates, setEmailTemplates] = useState([]);
//     const [selectedTemplateId, setSelectedTemplateId] = useState(null);
//     const emailEditorRef = useRef(null);
//     const [selectedLeadForEmail, setSelectedLeadForEmail] = useState(null);
//     const [isEditorReady, setIsEditorReady] = useState(false);
//     const [emailSubject, setEmailSubject] = useState('');
//     const [loadingTemplates, setLoadingTemplates] = useState(false);
//     const [sendToEmail, setSendToEmail] = useState('');
//     const [selectedSenderId, setSelectedSenderId] = useState('');
//     const [emailAlert, setEmailAlert] = useState({ show: false, message: '', type: '' });
//     const [filters, setFilters] = useState({
//         status: getLocalStorage('crmStatusFilter') || [],
//         salesRep: getLocalStorage('crmSalesRepFilter') || '',
//     });
//     const apiUrl = process.env.REACT_APP_API_URL;

//     const fetchLeadsAndSalesReps = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${apiUrl}/dataServer`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     action: 'getLeads',
//                     payload: { userId: currentUser.userId, subDomain: currentUser.subDomain },
//                 }),
//             });

//             const result = await response.json();
//             console.log('Leads and SalesReps result:', result);
//             if (response.ok && result.success) {
//                 setLeads(result.leads || []);
//                 setSalesReps(result.salesReps || []);
//             } else {
//                 console.error('Error fetching leads and sales reps:', result.message);
//                 setAlertMessage(`Error: ${result.message}`);
//             }
//         } catch (error) {
//             console.error('Error occurred while fetching leads and sales reps:', error);
//             setAlertMessage(`Error: ${error.message}`);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Fetch leads and sales reps from the backend
//     useEffect(() => {
//         fetchLeadsAndSalesReps();
//     }, [apiUrl, currentUser]);

//     useEffect(() => {
//         if (selectedLeadForEmail) {
//             setSendToEmail(selectedLeadForEmail.EMAIL || '');
//             setSelectedSenderId(selectedLeadForEmail.SalesRep ? selectedLeadForEmail.SalesRep.Id : '');
//         }
//     }, [selectedLeadForEmail]);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (!event.target.closest('.status-dropdown')) {
//                 setStatusDropdownOpen(false);
//             }
//         };
//         if (statusDropdownOpen) {
//             document.addEventListener('click', handleClickOutside);
//         } else {
//             document.removeEventListener('click', handleClickOutside);
//         }
//         return () => document.removeEventListener('click', handleClickOutside);
//     }, [statusDropdownOpen]);

//     // Function to handle assigning sales rep to a lead
//     const handleAssignRep = async (repId) => {
//         setLoading(true);
//         try {
//           let response = await fetch(`${apiUrl}/dataServer`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({
//               action: 'assignSalesRepToLead',
//               payload: {
//                 userId: currentUser.userId,
//                 leadId: selectedLead.Id,
//                 repId: repId,
//               },
//             }),
//           });
      
//           let result = await response.json();
      
//           if (response.ok && result.success) {
//             const assignedRep = salesReps.find((rep) => rep.Id === repId);
//             setLeads((prevLeads) =>
//               prevLeads.map((lead) =>
//                 lead.Id === selectedLead.Id
//                   ? { ...lead, SalesRep: assignedRep, SalesPerson: assignedRep.Id }
//                   : lead
//               )
//             );
//             setShowAssignModal(false);
//             if (result.message != 'Sales rep assigned successfully') {
//             setShowAlertBanner(result.message);
//             }
//           } else if (result.currentRep) {
//             // Lead is already assigned; prompt for confirmation
//             showConfirmModal(
//               `This lead is already assigned to ${result.currentRep.FirstName} ${result.currentRep.LastName}. Do you want to override?`,
//               async () => {
//                 // User confirmed; proceed with override
//                 setLoading(true);
//                 try {
//                   response = await fetch(`${apiUrl}/dataServer`, {
//                     method: 'POST',
//                     headers: { 'Content-Type': 'application/json' },
//                     body: JSON.stringify({
//                       action: 'assignSalesRepToLead',
//                       payload: {
//                         userId: currentUser.userId,
//                         leadId: selectedLead.Id,
//                         repId: repId,
//                         override: true,
//                       },
//                     }),
//                   });
      
//                   result = await response.json();
      
//                   if (response.ok && result.success) {
//                     const assignedRep = salesReps.find((rep) => rep.Id === repId);
//                     setLeads((prevLeads) =>
//                       prevLeads.map((lead) =>
//                         lead.Id === selectedLead.Id
//                           ? { ...lead, SalesRep: assignedRep, SalesPerson: assignedRep.Id }
//                           : lead
//                       )
//                     );
//                     setShowAssignModal(false);
//                     closeAlertModal(); // Close the confirmation modal
//                   } else {
//                     setAlertMessage(`Error: ${result.message}`);
//                     setIsConfirm(false);
//                   }
//                 } catch (error) {
//                   setAlertMessage(`Error: ${error.message}`);
//                   setIsConfirm(false);
//                 } finally {
//                   setLoading(false);
//                 }
//               }
//             );
//           } else {
//             // Other errors
//             setAlertMessage(`Error: ${result.message}`);
//           }
//         } catch (error) {
//           setAlertMessage(`Error: ${error.message}`);
//         } finally {
//           setLoading(false);
//         }
//       };
      
    
//       // Function to show confirm modal with custom logic
//       const showConfirmModal = (message, callback) => {
//         setAlertMessage(message);
//         setIsConfirm(true);
//         setConfirmCallback(() => callback);
//       };
    
//       // Function to close the alert modal
//       const closeAlertModal = () => {
//         setAlertMessage(null);
//         setIsConfirm(false);
//         setConfirmCallback(null);
//         fetchLeadsAndSalesReps();
//       };

//       const AlertModal = ({ message, onClose, onConfirm, isConfirm }) => {
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//             <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto overflow-hidden">
//               {/* Header */}
//               <div className="px-6 py-4 border-b">
//                 <h2 className="text-xl font-semibold text-gray-800">
//                   {isConfirm ? 'You Sure?' : 'Alert'}
//                 </h2>
//               </div>
      
//               {/* Message */}
//               <div className="px-6 py-4">
//                 <p className="text-gray-700">{message}</p>
//               </div>
      
//               {/* Buttons */}
//               <div className="px-6 py-4 border-t flex justify-end space-x-4">
//                 <button
//                   className="themed-button bg-gray-500 text-white"
//                   onClick={onClose}
//                 >
//                   {isConfirm ? 'Cancel' : 'Close'}
//                 </button>
//                 {isConfirm && (
//                   <button
//                     className="themed-button"
//                     onClick={onConfirm}
//                   >
//                     Confirm
//                   </button>
//                 )}
//               </div>
//             </div>
//           </div>
//         );
//       };     


//     // Function to handle adding a new sales rep
//     const handleAddRep = async (salesRep) => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${apiUrl}/dataServer`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     action: 'addSalesRep',
//                     payload: {
//                         userId: currentUser.userId,
//                         subDomain: currentUser.subDomain,
//                         salesRep: salesRep,
//                     },
//                 }),
//             });

//             const result = await response.json();
//             if (response.ok && result.success) {
//                 setSalesReps([...salesReps, result.salesRep]);
//             } else {
//                 console.error('Error adding sales rep:', result.message);
//             }
//         } catch (error) {
//             console.error('Error adding sales rep:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const mapLeadToUpdates = (lead) => {
//         return {
//             docket: lead.DOCKET,
//             usdot: lead.USDOT,
//             legalName: lead.LEGALNAME,
//             dbaName: lead.DBANAME,
//             address: lead.ADDRESS,
//             city: lead.CITY,
//             state: lead.STATE,
//             zip: lead.ZIP,
//             phone: lead.PHONE,
//             cell: lead.CELL,
//             fax: lead.FAX,
//             email: lead.EMAIL,
//             contact1: lead.CONTACT1,
//             contact2: lead.CONTACT2,
//             trucks: lead.TRUCKS,
//             vehInsp: lead.VEHINSP,
//             salesPerson: lead.SalesPerson || null,
//             notes: lead.Notes,
//             optOut: lead.OptOut,
//             addedToMailingList: lead.AddedToMailingList,
//             leadStatus: lead.LeadStatus,
//         };
//     };

//     const handleAssignClick = (lead) => {
//         setSelectedLead(lead);
//         setShowAssignModal(true);
//     };

//     // Function to handle changing lead status
//     const handleStatusChange = async (lead, newStatus) => {
//         if (!lead.SalesRep) return;

//         setLoading(true);
//         try {
//             const updatedLead = { ...lead, LeadStatus: newStatus };
//             const updates = mapLeadToUpdates(updatedLead);

//             const response = await fetch(`${apiUrl}/dataServer`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     action: 'updateLead',
//                     payload: {
//                         userId: currentUser.userId,
//                         leadId: lead.Id,
//                         updates: updates,
//                     },
//                 }),
//             });

//             const result = await response.json();
//             if (response.ok && result.success) {
//                 setLeads((prevLeads) =>
//                     prevLeads.map((l) =>
//                         l.Id === lead.Id ? { ...l, LeadStatus: newStatus } : l
//                     )
//                 );                
//             } else {
//                 console.error('Error updating lead status:', result.message);
//             }
//         } catch (error) {
//             console.error('Error updating lead status:', error);
//         } finally {
//             setLoading(false);
//         }
//     };


//     // Function to handle editing a lead
//     const handleEdit = (lead) => {
//         setSelectedLead(lead);
//         setShowEditModal(true);
//     };

//     // Function to handle saving the edited lead
//     const handleSaveLead = async (updatedLead) => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${apiUrl}/dataServer`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     action: 'updateLead',
//                     payload: {
//                         userId: currentUser.userId,
//                         leadId: updatedLead.Id,
//                         updates: {
//                             leadStatus: updatedLead.LeadStatus,
//                             docket: updatedLead.DOCKET,
//                             usdot: updatedLead.USDOT,
//                             dbaName: updatedLead.DBANAME,
//                             address: updatedLead.ADDRESS,
//                             city: updatedLead.CITY,
//                             state: updatedLead.STATE,
//                             zip: updatedLead.ZIP,
//                             cell: updatedLead.CELL,
//                             fax: updatedLead.FAX,
//                             contact2: updatedLead.CONTACT2,
//                             trucks: updatedLead.TRUCKS,
//                             vehInsp: updatedLead.VEHINSP,
//                             salesPerson: updatedLead.SalesPerson || null,
//                             notes: updatedLead.Notes,
//                             optOut: updatedLead.OptOut,
//                             addedToMailingList: updatedLead.AddedToMailingList,
//                             legalName: updatedLead.LEGALNAME,
//                             contact1: updatedLead.CONTACT1,
//                             email: updatedLead.EMAIL,
//                             phone: updatedLead.PHONE,
//                         },
//                     },
//                 }),
//             });

//             const result = await response.json();
//             if (response.ok && result.success) {
//                 // Update the lead in the state
//                 setLeads((prevLeads) =>
//                     prevLeads.map((lead) =>
//                         lead.Id === updatedLead.Id ? { ...lead, ...updatedLead } : lead
//                     )
//                 );
//                 setAlertMessage('Lead Updated Successfully.');
//                 setShowEditModal(false);
//             } else {
//                 console.error('Error updating lead:', result.message);
//             }
//         } catch (error) {
//             console.error('Error updating lead:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Function to handle filtering
//     const handleFilterChange = (filterKey, value) => {
//         const newFilters = { ...filters, [filterKey]: value };
//         setFilters(newFilters);
//         // Save to local storage
//         setLocalStorage(`crm${filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}Filter`, value);
//     };

//     // Apply filters to the data
//     const filteredLeads = leads.filter((lead) => {
//         // Filter by status
//         if (filters.status.length > 0 && !filters.status.includes(lead.LeadStatus)) {
//             return false;
//         }
//         // Filter by sales rep
//         if (filters.salesRep) {
//             if (!lead.SalesRep || lead.SalesRep.Id !== filters.salesRep) {
//                 return false;
//             }
//         }
//         return true;
//     });

//     // Status options and CSS classes
//     const statusOptions = [
//         { value: 'New', label: 'New', cssClass: 'status-new' },
//         { value: 'Working', label: 'Working', cssClass: 'status-working' },
//         { value: 'Contacted', label: 'Contacted', cssClass: 'status-contacted' },
//         { value: 'Not Interested', label: 'Not Interested', cssClass: 'status-not-interested' },
//         { value: 'Not Qualified', label: 'Not Qualified', cssClass: 'status-not-qualified' },
//         { value: 'Qualified', label: 'Qualified', cssClass: 'status-qualified' },
//     ];

//     // CSS classes for statuses
//     const statusCssClasses = {
//         New: 'status-new',
//         Working: 'status-working',
//         Contacted: 'status-contacted',
//         'Not Interested': 'status-not-interested',
//         'Not Qualified': 'status-not-qualified',
//         Qualified: 'status-qualified',
//     };

//     // Function to handle hover
//     const handleMouseEnter = (lead, event) => {
//         setHoveredLead(lead);
//         setTooltipPosition({ x: event.clientX, y: event.clientY });
//     };

//     const handleMouseLeave = () => {
//         setHoveredLead(null);
//     };

//     // Columns definition for the DataTable
//     const columns = [
//         {
//             name: 'Contact',
//             selector: (row) => row.CONTACT1 || row.CONTACT2 || '',
//             sortable: true,
//             cell: (row) => (
//                 <div
//                     onMouseEnter={(e) => handleMouseEnter(row, e)}
//                     onMouseLeave={handleMouseLeave}
//                     className="relative"
//                 >
//                     {row.CONTACT1 || row.CONTACT2 || ''}
//                 </div>
//             ),
//         },
//         {
//             name: 'Company Name',
//             selector: (row) => row.LEGALNAME,
//             sortable: true,
//         },
//         {
//             name: 'Phone',
//             selector: (row) => row.PHONE,
//             sortable: true,
//         },
//         {
//             name: 'Email',
//             selector: (row) => row.EMAIL,
//             sortable: true,
//         },
//         {
//             name: 'Status',
//             cell: (row) => {
//                 const isDisabled = !row.SalesRep;
//                 return (
//                     <select
//                         className={`p-1 rounded ${statusCssClasses[row.LeadStatus] || ''
//                             }`}
//                         value={row.LeadStatus}
//                         onChange={(e) => handleStatusChange(row, e.target.value)}
//                         disabled={isDisabled}
//                     >
//                         {statusOptions.map((option) => (
//                             <option
//                                 key={option.value}
//                                 value={option.value}
//                                 className={option.cssClass}
//                             >
//                                 {option.label}
//                             </option>
//                         ))}
//                     </select>
//                 );
//             },
//             sortable: true,
//         },
//         {
//             name: 'Assigned SalesRep',
//             cell: (row) =>
//                 row.SalesRep ? (
//                     <>
//                         {row.SalesRep.FirstName} {row.SalesRep.LastName}
//                         <FontAwesomeIcon
//                             icon={faEdit}
//                             className="ml-2 text-blue-500 cursor-pointer"
//                             onClick={() => handleAssignClick(row)}
//                         />
//                     </>
//                 ) : (
//                     <span onClick={() => handleAssignClick(row)} className="text-blue-500 cursor-pointer">
//                         <FontAwesomeIcon icon={faPlus} /> Assign
//                     </span>
//                 ),
//             // style: {
//             //     whiteSpace: 'nowrap',
//             // },
//         },
//         {
//             name: 'Actions',
//             cell: (row) => {
//                 const hasSalesRep = !!row.SalesRep;
//                 return (
//                     <div className="flex space-x-2">
//                         <button
//                             className={`themed-button ${!hasSalesRep ? 'opacity-50 cursor-not-allowed' : ''}`}
//                             onClick={() => hasSalesRep && handleEmail(row.EMAIL)}
//                             title="Send Email"
//                             disabled={!hasSalesRep}
//                         >
//                             <FontAwesomeIcon icon={faEnvelope} />
//                         </button>
//                         <button
//                             className={`themed-button ${!hasSalesRep ? 'opacity-50 cursor-not-allowed' : ''}`}
//                             onClick={() => hasSalesRep && handleEdit(row)}
//                             title="Edit Lead"
//                             disabled={!hasSalesRep}
//                         >
//                             <FontAwesomeIcon icon={faEdit} />
//                         </button>
//                         <button
//                             className="themed-button"
//                             onClick={() => handleDelete(row.Id)}
//                             title="Delete Lead"
//                         >
//                             <FontAwesomeIcon icon={faTrash} />
//                         </button>
//                     </div>
//                 );
//             },
//             ignoreRowClick: true,
//             style: {
//                 width: '550px',
//             },
//         },
//     ];
//       // Function to handle delete confirmation
//   const handleDelete = (id) => {
//     showConfirmModal('Are you sure you want to delete this lead?', () => {
//       deleteLead(id);
//     });
//   };

//   const deleteLead = async (id) => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/dataServer`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           action: 'deleteLead',
//           payload: {
//             userId: currentUser.userId,
//             leadId: id,
//           },
//         }),
//       });

//       const result = await response.json();
//       if (response.ok && result.success) {
//         setLeads(leads.filter((lead) => lead.Id !== id));
//         closeAlertModal();
//         setAlertMessage('Lead deleted successfully.');
//       } else {
//         setAlertMessage(`Error deleting lead: ${result.message}`);
//       }
//     } catch (error) {
//       setAlertMessage(`Error deleting lead: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//     // Function to handle file input change
//     const handleFileChange = (e) => {
//         setSelectedFile(e.target.files[0]);
//     };

//     // Function to handle file submission
//     const handleFileSubmit = async () => {
//         if (!selectedFile) {
//             alert('Please select a file.');
//             return;
//         }

//         setLoading(true);

//         const formData = new FormData();
//         formData.append('file', selectedFile);

//         try {
//             const response = await fetch(`${apiUrl}/addLeadsFromFile`, {
//                 method: 'POST',
//                 body: formData,
//             });

//             const result = await response.text();
//             // console.log('result:', result);

//             if (response.ok) {
//                 setAlertMessage('File uploaded and leads processed successfully.');
//             } else {
//                // console.log('Error:', result.message);
//                 setAlertMessage(`Error: ${result.message}`);
//             }
//         } catch (error) {
//             console.error('Error uploading file:', error.message);
//             setAlertMessage('An error occurred while uploading the file.');
//         } finally {
//             setShowModal(false);
//             setLoading(false);
//         }
//     };

//     // Function to handle editing a sales rep
//     const handleEditRep = async (salesRep) => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${apiUrl}/dataServer`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     action: 'editSalesRep',
//                     payload: {
//                         userId: currentUser.userId,
//                         salesRep: salesRep,
//                     },
//                 }),
//             });

//             const result = await response.json();
//             if (response.ok && result.success) {
//                 setSalesReps((prevSalesReps) =>
//                     prevSalesReps.map((rep) => (rep.Id === salesRep.Id ? salesRep : rep))
//                 );
//                 setLeads((prevLeads) =>
//                     prevLeads.map((lead) =>
//                         lead.SalesRep && lead.SalesRep.Id === salesRep.Id
//                             ? { ...lead, SalesRep: salesRep }
//                             : lead
//                     )
//                 );
//             } else {
//                 console.error('Error editing sales rep:', result.message);
//             }
//         } catch (error) {
//             console.error('Error editing sales rep:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Function to handle deleting a sales rep
//     const handleDeleteRep = async (repToDelete, replaceWith) => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${apiUrl}/dataServer`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     action: 'deleteSalesRep',
//                     payload: {
//                         userId: currentUser.userId,
//                         salesRepId: repToDelete.Id,
//                         replaceWith: replaceWith,
//                     },
//                 }),
//             });

//             const result = await response.json();
//             if (response.ok && result.success) {
//                 setSalesReps((prevSalesReps) => prevSalesReps.filter((rep) => rep.Id !== repToDelete.Id));
//                 setLeads((prevLeads) =>
//                     prevLeads.map((lead) =>
//                         lead.SalesRep && lead.SalesRep.Id === repToDelete.Id
//                             ? {
//                                 ...lead,
//                                 SalesRep: replaceWith
//                                     ? salesReps.find((rep) => rep.Id === replaceWith)
//                                     : null,
//                             }
//                             : lead
//                     )
//                 );
//             } else {
//                 console.error('Error deleting sales rep:', result.message);
//             }
//         } catch (error) {
//             console.error('Error deleting sales rep:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const fetchEmailTemplates = async () => {
//         setLoadingTemplates(true);
//         try {
//             const response = await fetch(`${apiUrl}/dataServer`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     action: 'getEmailTemplatesCRM',
//                     payload: { userId: currentUser.userId },
//                 }),
//             });

//             const result = await response.json();
//             if (response.ok && result.success) {
//                 console.log('Email templates:', result.templates);
//                 setEmailTemplates(result.templates || []);
//             } else {
//                 console.error('Error fetching email templates:', result.message);
//             }
//         } catch (error) {
//             console.error('Error fetching email templates:', error);
//         } finally {
//             setLoadingTemplates(false);
//         }
//     };

//     // Function to handle opening the email modal
//     const handleEmail = (lead) => {
//         setSelectedLeadForEmail(lead);
//         setShowEmailModal(true);
//         fetchEmailTemplates();
//     };

//     // Function to load selected template into the email editor
//     const loadTemplate = async (templateId) => {
//         setLoading(true);
//         try {
//             // Make API call to fetch the template data
//             const response = await fetch(`${apiUrl}/dataServer`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     action: 'getEmailTemplateById',
//                     payload: { 
//                         templateId, 
//                         userId: currentUser.userId 
//                     },
//                 }),
//             });
    
//             const result = await response.json();
    
//             if (response.ok && result.success) {
//                 const selectedTemplate = result.template;
//                 if (selectedTemplate && emailEditorRef.current) {
//                     const designJson = JSON.parse(selectedTemplate.designContent);
//                     emailEditorRef.current.editor.loadDesign(designJson);
//                     setEmailSubject(selectedTemplate.subjectLine);
//                 }
//             } else {
//                 console.error('Error fetching template:', result.message);
//                 // Optionally, show an alert to the user
//                 setEmailAlert(result.message || 'Error fetching template.', 'error');
//             }
//         } catch (error) {
//             console.error('Error loading template:', error);
//             // Optionally, show an alert to the user
//             setEmailAlert('An error occurred while loading the template.', 'error');
//         } finally {
//             setLoading(false);
//         }
//     };
    

//     // Function to handle sending the email
//     const handleSendEmail = () => {
//         // Validate fields
//         if (!sendToEmail) {
//             alert('Recipient email is required.');
//             return;
//         }
//         if (!selectedSenderId) {
//             alert('Sender is required.');
//             return;
//         }
//         if (!emailSubject) {
//             alert('Email subject is required.');
//             return;
//         }

//         emailEditorRef.current.editor.exportHtml(async (data) => {
//             const { design, html } = data;

//             const emailData = {
//                 userId: currentUser.userId,
//                 leadId: selectedLeadForEmail.Id,
//                 toEmail: sendToEmail,
//                 fromSenderId: selectedSenderId, // Adjust based on your backend's expectations
//                 subject: emailSubject,
//                 htmlContent: html,
//                 designContent: design,
//             };

//             try {
//                 setLoading(true);
//                 const response = await fetch(`${apiUrl}/dataServer`, {
//                     method: 'POST',
//                     headers: { 'Content-Type': 'application/json' },
//                     body: JSON.stringify({
//                         action: 'sendEmailToLead',
//                         payload: emailData,
//                     }),
//                 });

//                 const result = await response.json();
//                 if (response.ok && result.success) {
//                     setEmailAlert({ show: true, message: 'Email sent successfully.', type: 'success' });
//                     setTimeout(() => {
//                         setShowEmailModal(false);
//                         setEmailAlert({ show: false, message: '', type: '' });
//                     }, 2000);
//                 } else {
//                     setEmailAlert({ show: true, message: result.message || 'Error sending email.', type: 'error' });
//                 }
//             } catch (error) {
//                 console.error('Error sending email:', error);
//                 setEmailAlert({ show: true, message: 'Error sending email.', type: 'error' });
//             } finally {
//                 setLoading(false);
//             }
//         });
//     };

//     // Function to handle saving the email template
//     const handleSaveTemplate = () => {
//         emailEditorRef.current.editor.exportHtml(async (data) => {
//             const { design, html } = data;
//             const templateName = prompt('Enter a name for your template:');
//             if (!templateName) {
//                 alert('Template name is required to save the template.');
//                 return;
//             }

//             const emailTemplate = {
//                 name: templateName,
//                 subjectLine: emailSubject,
//                 htmlContent: html,
//                 designContent: design,
//                 userId: currentUser.userId,
//                 policyNumber: currentUser.policyNumber,
//                 isCRMTemplate: true,
//             };

//             try {
//                 setLoading(true);
//                 const response = await fetch(`${apiUrl}/dataServer`, {
//                     method: 'POST',
//                     headers: { 'Content-Type': 'application/json' },
//                     body: JSON.stringify({
//                         action: 'addEmailTemplate',
//                         payload: emailTemplate,
//                     }),
//                 });

//                 const result = await response.json();

//                 if (response.ok && result.success) {
//                     alert('Template saved successfully.');
//                     // Optionally, refresh the templates list
//                     fetchEmailTemplates();
//                 } else {
//                     alert(result.message || 'Error saving template.');
//                 }
//             } catch (error) {
//                 console.error('Error saving template:', error);
//                 alert('An error occurred while saving the template.');
//             } finally {
//                 setLoading(false);
//             }
//         });
//     };

//     // Function to handle editor load
//     const onEditorLoad = () => {
//         setIsEditorReady(true);
//     };    

//     return (
//         <>
//             <MobileHeader theme={theme} />
//             <PageHeader
//                 heading="CRM"
//                 context="Manage Sales Leads"
//             />
//             <div className="mx-auto">
//                 {loading && <Spinner />}

//                  {/* Custom Alert Modal */}
//         {alertMessage && (
//           <AlertModal
//             message={alertMessage}
//             onClose={closeAlertModal}
//             onConfirm={confirmCallback}
//             isConfirm={isConfirm}
//           />
//         )}

//                 {/* Alert Message */}
//                 {showAlertBanner && (
//                     <div className="alert bg-green-200 text-green-600 p-4 rounded-md mb-4">
//                         {showAlertBanner}
//                         <button onClick={() => setShowAlertBanner(null)} className="float-right">
//                             &times;
//                         </button>
//                     </div>
//                 )}

//                 {/* Filter Panel */}
//                 <div className="bg-white shadow-md rounded-lg p-6">


//                     <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

//                         {/* Status Filter */}
//                         <div className="relative inline-block text-left status-dropdown">
//                             <label className="block text-sm font-medium text-gray-700 mb-1">Filter by Status</label>
//                             <div>
//                                 <button
//                                     type="button"
//                                     className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
//                                     onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
//                                 >
//                                     {filters.status.length > 0 ? `Selected (${filters.status.length})` : 'All Statuses'}
//                                     <svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
//                                         <path
//                                             fillRule="evenodd"
//                                             d="M5.292 7.707a1 1 0 011.415 0L10 10.999l3.293-3.292a1 1 0 111.415 1.414l-4 4.001a1 1 0 01-1.415 0l-4-4.001a1 1 0 010-1.414z"
//                                             clipRule="evenodd"
//                                         />
//                                     </svg>
//                                 </button>
//                             </div>

//                             {statusDropdownOpen && (
//                                 <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
//                                     <div className="py-1">
//                                         {statusOptions.map((option) => (
//                                             <label key={option.value} className="flex items-center px-4 py-2 text-sm text-gray-700">
//                                                 <input
//                                                     type="checkbox"
//                                                     value={option.value}
//                                                     checked={filters.status.includes(option.value)}
//                                                     onChange={(e) => {
//                                                         const value = e.target.value;
//                                                         const isChecked = e.target.checked;
//                                                         let newStatusFilter = [...filters.status];
//                                                         if (isChecked) {
//                                                             newStatusFilter.push(value);
//                                                         } else {
//                                                             newStatusFilter = newStatusFilter.filter((status) => status !== value);
//                                                         }
//                                                         handleFilterChange('status', newStatusFilter);
//                                                     }}
//                                                     className="mr-2 text-blue-600 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded"
//                                                 />
//                                                 {option.label}
//                                             </label>
//                                         ))}
//                                     </div>
//                                 </div>
//                             )}
//                         </div>

//                         {/* Sales Rep Filter */}
//                         <div>
//                             <label className="block text-sm font-medium text-gray-700 mb-1">Filter by Sales Rep</label>
//                             <select
//                                 className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
//                                 value={filters.salesRep}
//                                 onChange={(e) => handleFilterChange('salesRep', e.target.value)}
//                             >
//                                 <option value="">All</option>
//                                 {salesReps.map((rep) => (
//                                     <option key={rep.Id} value={rep.Id}>
//                                         {rep.FirstName} {rep.LastName}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>

//                     </div>
//                 </div>


//                 <MainTableV2
//                     columns={columns}
//                     data={filteredLeads}
//                     primaryColor={theme.primary}
//                     loading={loading}
//                     searchQuery={searchQuery}
//                     setSearchQuery={setSearchQuery}
//                     filterConfig={[
//                         { key: 'CONTACT1', type: 'string' },
//                         { key: 'LEGALNAME', type: 'string' },
//                         { key: 'EMAIL', type: 'string' },
//                         { key: 'PHONE', type: 'string' },
//                         { key: 'SalesRep.FirstName', type: 'string' },
//                         { key: 'SalesRep.LastName', type: 'string' },
//                         { key: 'LeadStatus', type: 'string' },
//                     ]}
//                 >
//                     {/* Button to add new leads */}
//                     <div className="flex justify-end">
//                         <button className="themed-button" onClick={() => setShowModal(true)}>
//                             Add New Leads
//                         </button>
//                     </div>
//                 </MainTableV2>

//                 {showModal && (
//                     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                         <div className="bg-white p-8 rounded-lg shadow-lg w-96 transform transition-all duration-300 ease-out scale-100">
//                             <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
//                                 Upload Leads File
//                             </h2>

//                             <p className="text-sm text-gray-600 mb-4 text-center">
//                                 Please select a CSV file containing leads to upload. Ensure the file follows the correct format for successful import.
//                             </p>

//                             <div className="flex flex-col items-center justify-center mb-6">
//                                 <input
//                                     type="file"
//                                     onChange={handleFileChange}
//                                     className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:border-blue-500 p-2"
//                                 />
//                                 {selectedFile && (
//                                     <p className="mt-2 text-sm text-green-600">
//                                         Selected file: <span className="font-semibold">{selectedFile.name}</span>
//                                     </p>
//                                 )}
//                             </div>

//                             <div className="flex justify-between">
//                                 <button
//                                     className="themed-button transition-colors duration-300"
//                                     onClick={handleFileSubmit}
//                                 >
//                                     Submit
//                                 </button>
//                                 <button
//                                     className="bg-red-500 text-white px-4 py-2 rounded-md shadow hover:bg-red-600 transition-colors duration-300"
//                                     onClick={() => setShowModal(false)}
//                                 >
//                                     Cancel
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 )}

//                 {/* Sales Rep Modal */}
//                 {showAssignModal && (
//                     <SalesRepModal
//                         show={showAssignModal}
//                         onClose={() => setShowAssignModal(false)}
//                         onAdd={handleAddRep}
//                         onAssign={handleAssignRep}
//                         currentUser={currentUser}
//                         selectedUser={selectedLead}
//                         salesReps={salesReps}
//                         setSalesReps={setSalesReps}
//                         onEdit={handleEditRep}
//                         onDelete={handleDeleteRep}
//                         setUsers={setLeads}
//                     />
//                 )}

//                 {/* Edit Lead Modal */}
//                 {showEditModal && (
//                     <EditLeadModal
//                         lead={selectedLead}
//                         onClose={() => setShowEditModal(false)}
//                         onSave={handleSaveLead}
//                         statusOptions={statusOptions}
//                         statusCssClasses={statusCssClasses}
//                     />
//                 )}

                
//                 {/* Email Modal */}
//             {showEmailModal && (
//                 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                     <div className="bg-white p-6 rounded-md w-3/4 max-h-[calc(100vh-20px)] overflow-y-auto">
//                         <h2 className="text-lg font-semibold mb-4">
//                             Send Email to {selectedLeadForEmail.CONTACT1 || selectedLeadForEmail.CONTACT2}
//                         </h2>

//                         {/* Alert inside the modal */}
//                         {emailAlert.show && (
//                             <div
//                                 className={`mb-4 p-4 rounded-md ${
//                                     emailAlert.type === 'success'
//                                         ? 'bg-green-100 text-green-800'
//                                         : 'bg-red-100 text-red-800'
//                                 } flex justify-between`}
//                             >
//                                 <span>{emailAlert.message}</span>
//                                 <button
//                                     onClick={() => setEmailAlert({ show: false, message: '', type: '' })}
//                                     className="text-lg font-bold"
//                                 >
//                                     &times;
//                                 </button>
//                             </div>
//                         )}

//                         {/* Send To field */}
//                         <div className="mb-4">
//                             <label className="block text-sm font-medium text-gray-700 mb-2">Send To:</label>
//                             <input
//                                 type="email"
//                                 value={sendToEmail}
//                                 onChange={(e) => setSendToEmail(e.target.value)}
//                                 placeholder="Enter recipient's email address"
//                                 className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//                                 required
//                             />
//                         </div>

//                         {/* Sender dropdown */}
//                         <div className="mb-4">
//                             <label className="block text-sm font-medium text-gray-700 mb-2">Sender:</label>
//                             <select
//                                 value={selectedSenderId}
//                                 onChange={(e) => setSelectedSenderId(e.target.value)}
//                                 className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//                                 required
//                             >
//                                 <option value="">Select a Sender</option>
//                                 {salesReps.map((rep) => (
//                                     <option key={rep.Id} value={rep.Id}>
//                                         {rep.FirstName} {rep.LastName}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>

//                         {/* Template Selector */}
//                         <div className="mb-4">
//                             <label className="block text-sm font-medium text-gray-700 mb-2">Select Template:</label>
//                             <select
//                                 className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                 value={selectedTemplateId || ''}
//                                 onChange={(e) => {
//                                     setSelectedTemplateId(e.target.value);
//                                     loadTemplate(e.target.value);
//                                 }}
//                             >
//                                 <option value="">-- Select Template --</option>
//                                 {emailTemplates.map((template) => (
//                                     <option key={template.Id} value={template.Id}>
//                                         {template.name}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>

//                         {/* Subject Line */}
//                         <div className="mb-4">
//                             <label className="block text-sm font-medium text-gray-700 mb-2">Subject Line:</label>
//                             <input
//                                 type="text"
//                                 value={emailSubject}
//                                 onChange={(e) => setEmailSubject(e.target.value)}
//                                 placeholder="Enter Subject Line"
//                                 className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                             />
//                         </div>

//                         {/* Email Editor */}
//                         <div className="mb-6">
//                             <EmailEditor ref={emailEditorRef} onLoad={onEditorLoad} />
//                         </div>

//                         {/* Buttons */}
//                         <div className="flex justify-end">
//                             <button className="themed-button mr-2" onClick={handleSaveTemplate}>
//                                 Save Template
//                             </button>
//                             <button className="themed-button mr-2" onClick={handleSendEmail}>
//                                 Send Email
//                             </button>
//                             <button
//                                 className="themed-button bg-red-500 text-white"
//                                 onClick={() => setShowEmailModal(false)}
//                             >
//                                 Cancel
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             )}

//                 {/* Tooltip with lead info */}
//                 {hoveredLead && (
//                     <div
//                         className="absolute bg-gray-800 text-white p-3 rounded-lg shadow-lg"
//                         style={{ top: `${tooltipPosition.y + 20}px`, left: `${tooltipPosition.x}px` }}
//                     >
//                         <div>
//                             <strong>Address:</strong> {hoveredLead.ADDRESS}, {hoveredLead.CITY}, {hoveredLead.STATE} {hoveredLead.ZIP}
//                         </div>
//                         <div>
//                             <strong>Notes:</strong> {hoveredLead.Notes || 'No Notes'}
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </>
//     );
// };

// export default CRM;
