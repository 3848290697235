import React, { useState } from 'react';
import Card from './Card';

const AveragePPUChart = ({ data, theme }) => {
  const [selectedItem, setSelectedItem] = useState('ULSD');

  const mapping = [
    { key: 'ADD', value: 'Additives' },
    { key: 'AMDS', value: 'Aviation Merchandise' },
    { key: 'ANFR', value: 'Anti-Freeze' },
    { key: 'AVGS', value: 'Aviation Gas' },
    { key: 'BDSL', value: 'BioDiesel' },
    { key: 'BRAK', value: 'Brakes and Wheels' },
    { key: 'CADV', value: 'Cash Advance' },
    { key: 'CLTH', value: 'Clothing' },
    { key: 'CNG', value: 'Compressed Natural Gas' },
    { key: 'COUP', value: 'Coupon' },
    { key: 'DEF', value: 'Diesel Exhaust Fluid Bulk' },
    { key: 'DEFC', value: 'Diesel Exhaust Fluid Container' },
    { key: 'DELI', value: 'Restaurant/Deli' },
    { key: 'DSL', value: 'Diesel' },
    { key: 'DSLM', value: 'Mexico Diesel' },
    { key: 'ELEC', value: 'Electronics' },
    { key: 'EVCH', value: 'Electric Charging' },
    { key: 'FAX', value: 'Faxdefc' },
    { key: 'FURN', value: 'Furnace Oil' },
    { key: 'GAS', value: 'Gasoline' },
    { key: 'GASM', value: 'Mexico Gas Magna' },
    { key: 'GASP', value: 'Premium Gas Mexico' },
    { key: 'GROC', value: 'C-Store/Groceries' },
    { key: 'HARD', value: 'Hardware/Accessories' },
    { key: 'IDLE', value: 'IdleAire' },
    { key: 'JET', value: 'Jet Fuel' },
    { key: 'KERO', value: 'Kerosene' },
    { key: 'LABR', value: 'Labor' },
    { key: 'LMPR', value: 'Lumper' },
    { key: 'LNG', value: 'Liquid Natural Gas' },
    { key: 'MDSL', value: 'Marked/Tax Exempt Diesel' },
    { key: 'MERC', value: 'Miscellaneous Merchandise' },
    { key: 'MGAS', value: 'Marked/Tax Exempt Gas' },
    { key: 'MRFR', value: 'Marked Reefer' },
    { key: 'NGAS', value: 'Natural Gas' },
    { key: 'OIL', value: 'Oil' },
    { key: 'OILC', value: 'Oil Change' },
    { key: 'PART', value: 'Parts' },
    { key: 'PHON', value: 'Phone Time' },
    { key: 'PNT', value: 'Paint' },
    { key: 'PROP', value: 'Propane' },
    { key: 'RECP', value: 'Recap' },
    { key: 'REPR', value: 'Repair Service' },
    { key: 'REST', value: 'Restaurant' },
    { key: 'RFND', value: 'Refund' },
    { key: 'RFR', value: 'Reefer' },
    { key: 'RFRM', value: 'Thermo' },
    { key: 'SCAN', value: 'Tons Imaging' },
    { key: 'SCLE', value: 'Weigh Scale' },
    { key: 'SHWR', value: 'Shower' },
    { key: 'SPLT', value: 'Split/Other Method of Payment' },
    { key: 'STAX', value: 'Sales Tax' },
    { key: 'TIRE', value: 'Tires/Tire Repairs' },
    { key: 'TOLL', value: 'Ambassador Bridge Toll' },
    { key: 'TRAL', value: 'Trailer' },
    { key: 'TRPP', value: 'Trip Permit' },
    { key: 'ULSD', value: 'Ultra Low Sulfur Diesel' },
    { key: 'WASH', value: 'Car Wash' },
    { key: 'WIFI', value: 'Fleet WiFi Billing' },
    { key: 'WWFL', value: 'Windshield Washer Fluid' }
  ];

  const items = data && data['Average UserPPU by Category'] ? Object.keys(data['Average UserPPU by Category']).map(key => {
    const foundItem = mapping.find(item => item.key === key);
    return foundItem ? foundItem.value : key;
  }) : [];

  const handleItemChange = (e) => {
    const selectedValue = e.target.value;
    const foundItem = mapping.find(item => item.value === selectedValue);
    setSelectedItem(foundItem ? foundItem.key : selectedValue);
  };

  const getPPUAmount = () => {
    if (!data || !data['Average UserPPU by Category'] || !data['Average UserPPU by Category'][selectedItem]) {
      return null;
    }
    return data['Average UserPPU by Category'][selectedItem];
  };
  const selectedItemValue = mapping.find(item => item.key === selectedItem)?.value;
  const ppuAmount = getPPUAmount();

  return (
    <Card title="Average Price Per Unit" subtitle={'Last 30 Days'} theme={theme}>
      <div className="p-2 rounded-lg shadow-lg text-center" style={{ backgroundColor: theme.primary }}>
        
        <select 
          id="item" 
          onChange={handleItemChange} 
          value={mapping.find(item => item.key === selectedItem)?.value || selectedItem}
          className="ml-2 p-1 rounded border"
        >
          {items.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-6 text-center">
        <h2 className="text-2xl font-bold mt-4">
          {ppuAmount !== null && ppuAmount !== undefined ? (
            `$${ppuAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          ) : (
            "No Data Available"
          )}
        </h2>
      </div>
    </Card>
  );
};

export default AveragePPUChart;
