import { createSlice } from '@reduxjs/toolkit';

export const policyAdminEditSlice = createSlice({
  name: 'policyAdminEdit', 
  initialState: {
    header: {},
    infos: [],
    limits: [],
    locationGroups: [],
    policyNumber: null,
    currentBalance: null,
    StartingBalance: null,  
    AsOfDate: null,
  },
  reducers: {
    setPolicyData: (state, action) => {
      state.header = action.payload.header;
      state.infos = action.payload.infos;
      state.limits = action.payload.limits;
      state.locationGroups = action.payload.locationGroups;
      state.policyNumber = action.payload.policyNumber;
      state.currentBalance = action.payload.currentBalance; 
      state.StartingBalance = action.payload.StartingBalance;
      state.AsOfDate = action.payload.AsOfDate;
    },
    
  },
});

export const { setPolicyData } = policyAdminEditSlice.actions; 

export const selectPolicyData = (state) => state.policyAdminEdit; 

export default policyAdminEditSlice.reducer; 
