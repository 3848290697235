import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import PageHeader from './PageHeader';
import Spinner from './Spinner';

function IntegrationPrompt() {
    const { currentUser } = useContext(AuthContext);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [carrierId, setCarrierId] = useState(null);
    const [isIntegrationActive, setIsIntegrationActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkIntegrationStatus = async () => {
            try {
                const response = await fetch(`https://fuellink-db-ui.azurewebsites.net/api/intellitruxIntegrationCheck?userId=${currentUser.userId}`);
                if (response.ok) {
                    const data = await response.json();
                    setIsIntegrationActive(data.isActive);
                } else {
                    setShowErrorModal(true);
                }
            } catch (error) {
                console.error('Error:', error);
                setShowErrorModal(true);
            } finally {
                setIsLoading(false);
            }
        };

        if (currentUser) {
            checkIntegrationStatus();
        }
    }, [currentUser]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const carrierIdFromUrl = urlParams.get('carrierId');

        if (!carrierIdFromUrl) {
            setShowErrorModal(true);
        } else {
            setCarrierId(carrierIdFromUrl);
        }
    }, []);

    const handleNo = () => {
        window.location.href = 'https://www.app-intellitrux.com/fuellink/';
    };

    const handleYes = async () => {
        setIsLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const carrierId = urlParams.get('carrierId');
        const userId = currentUser ? currentUser.userId : null;

        if (carrierId && userId) {
            try {
                const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/intellitruxIntegration?', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId, carrierId })
                });

                if (response.status === 200) {
                    setIsLoading(false);
                    setShowSuccessModal(true);
                } else {
                    setIsLoading(false);
                    setShowErrorModal(true);
                }
            } catch (error) {
                setIsLoading(false);
                console.error('Error:', error);
            }
        }
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        window.location.href = 'https://www.app-intellitrux.com/fuellink/';
    };

    const handleDisconnect = async () => {
        setIsLoading(true);
        const userId = currentUser ? currentUser.userId : null;
    
        if (userId) {
            try {
                const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/intellitruxIntegration?', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId, carrierId, disconnect: true })
                });
    
                if (response.status === 200) {
                    setShowSuccessModal(true);
                } else {
                    setShowErrorModal(true);
                }
            } catch (error) {
                console.error('Error:', error);
                setShowErrorModal(true);
            }
        }
        setIsLoading(false);
    };
    

    return (
        <>
        <div>{isLoading && <Spinner />}</div>
        <PageHeader 
    heading="INTELLITRUX™ Integration" 
    context="Integrate your fuel account with INTELLITRUX™."
/>
        <div className="flex justify-center items-center mt-6">
        {isIntegrationActive ? (
                // Content for disconnecting
                <div className="border border-gray-300 rounded-lg p-6 text-center bg-white shadow-lg">
                    <img src="/logos/intellitruxLogo.png" alt="INTELLITRUX Logo" className="mx-auto mb-4 transform scale-50" />
                    <p className="mb-4">You are already connected to INTELLITRUX™, would you like to disconnect?</p>
                    <div className="flex justify-center space-x-4">
                        <button onClick={handleDisconnect} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Disconnect From Intellitrux
                        </button>
                        <button onClick={handleNo} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Return To INTELLITRUX™
                        </button>
                    </div>
                    {showSuccessModal && <SuccessModal message="Account Disconnected Successfully" onClose={handleCloseModal} />}
                </div>
            ) : (
            <div className="border border-gray-300 rounded-lg p-6 text-center bg-white shadow-lg">
                <img src="/logos/intellitruxLogo.png" alt="INTELLITRUX Logo" className="mx-auto mb-4 transform scale-50" />
                <p className="mb-4">Are you sure you want to connect your account to INTELLITRUX™?</p>
                <div className="flex justify-center space-x-4">
                    <button onClick={handleNo} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        No
                    </button>
                    <button onClick={handleYes} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                        Yes
                    </button>
                </div>
                {showSuccessModal && <SuccessModal message="Account Connected Successfully" onClose={handleCloseModal} />}
            </div>
            )}
            
            {showErrorModal && <ErrorModal message="Something went wrong, please try again." onClose={handleCloseModal} />}

        </div>
        </>
    );
}

export default IntegrationPrompt;
