import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "./AuthContext";
import { useTheme } from "./ThemeContext";
import { useLocation, Link, useNavigate } from "react-router-dom";
import SubTableV2 from './SubTableV2';
import { useSelector, useDispatch } from "react-redux";
import { selectPolicyData, setPolicyData } from "./policyAdminEditSlice";
import ToggleSwitch from "./ToggleSwitch";
import Spinner from "./Spinner";
import MobileHeader from "./MobileHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import PageHeader from "./PageHeader";

const PoliciesForm = () => {
  const { theme } = useTheme();
  const { tenantId } = theme;
  const primaryColor = theme.primary;
  const { currentUser } = useAuth();
  const [cardSummaries, setCardSummaries] = useState([]);
  // const [transactions, setTransactions] = useState([]);
  const [policyPromptsLoading, setPolicyPromptsLoading] = useState(false);
  const [policyPromptsAlertMessage, setPolicyPromptsAlertMessage] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const policyData = useSelector(selectPolicyData);
  const [isLoading, setIsLoading] = useState(false);
  const [initialActiveState, setInitialActiveState] = useState(null);
  const [recalculateBalance, setRecalculateBalance] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    ID: null,
    ContractID: "",
    Description: "",
    PolicyNumber: "",
    NetworkId: "",
    DiscountGroupID: "",
    CreditLimit: null,
    Balance: null,
    isPrePayAccount: null,
    CompanyName: "",
    active: false,
    StartingBalance: 0,
    AsOfDate: "",
  });
  const [discountGroups, setDiscountGroups] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  let query = useQuery();
  let passedPolicyNumber = query.get("PolicyNumber");  
  const currentPolicyNumber = useSelector(
    (state) => state.policyAdminEdit.policyNumber
  );  

  const fetchPolicyPrompts = async (currentPolicyNumber) => {
    setPolicyPromptsLoading(true);
    if (currentPolicyNumber === passedPolicyNumber) {
        setPolicyPromptsLoading(false);
        return;
    }
    try {
        const payload = {
            userId: currentUser.userId,
            policyNumber: passedPolicyNumber,
        };

        const response = await fetch(`${apiUrl}/dataServer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                payload: payload,
                action: "getPolicy",
            }),
        });

        if (response.ok) {            
            const rawData = await response.json();           
            const policyDetails = rawData.policyDetails.result; 

            dispatch(
                setPolicyData({
                    header: policyDetails.header || {},
                    infos: policyDetails.infos || [],
                    limits: policyDetails.limits || [],
                    locationGroups: policyDetails.locationGroups || [],
                    policyNumber: policyDetails.policyNumber || "",
                    currentBalance: rawData.dayAmtLimit || null,
                    StartingBalance: rawData.StartingBalance || null,
                    AsOfDate: rawData.AsOfDate || null,
                })
            );
        } else {
            throw new Error("Failed to fetch policy prompts");
        }

        setPolicyPromptsLoading(false);
    } catch (error) {
        setPolicyPromptsLoading(false);
        setPolicyPromptsAlertMessage("An error occurred while fetching data.");
        console.error("Fetch Policy Prompts Error:", error);
    }
};

  useEffect(() => {
    fetchPolicyPrompts(currentPolicyNumber);
    const fetchDiscountGroups = async () => {
      try {
        const response = await fetch(`${apiUrl}/getPolicyFormData?`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: currentUser.userId,
              tenantId: tenantId,
              policyNumber: passedPolicyNumber,
            }),
          }
        );
        const data = await response.json();
        // console.log(data);
        setDiscountGroups(data.discountGroups);
        const formattedAsOfDate = data.policyData[0].StartingBalanceDate
          ? data.policyData[0].StartingBalanceDate.split("T")[0]
          : ""; //to format the date for the form field population.
        setFormData({
          ID: data.policyData[0].ID,
          ContractID: data.policyData[0].ContractID,
          Description: data.policyData[0].Description,
          PolicyNumber: data.policyData[0].PolicyNumber,
          CreditLimit: data.policyData[0].CreditLimit,
          Balance: data.policyData[0].Balance,
          isPrePayAccount: String(data.policyData[0].isPrePayAccount),
          DiscountGroupID: data.policyData[0].DiscountGroupID,
          NetworkId: tenantId,
          StartingBalance: data.policyData[0].StartingBalance || 0.0,
          AsOfDate: formattedAsOfDate,
          CompanyName: data.policyData[0].CompanyName || "",
          active: data.policyData[0].Active || false,
        });
        setInitialActiveState(data.policyData[0].Active);
        setCardSummaries(data.cardSummaries);
        // setTransactions(data.transactions);
        // console.log(discountGroups);
      } catch (error) {
        console.error("Error fetching discount groups:", error);
      }
    };

    fetchDiscountGroups();
  }, [currentUser.userId, tenantId]);
 

  const handleChange = (e) => {
    //setIsDirty(true);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlePromptEdit = (rowData) => {
    navigate(`/EditPolicyPrompts`, {
      state: { prompt: rowData, isEditing: true },
    });
  };
  const handleLimitsEdit = (rowData) => {
    navigate(`/EditPolicyLimits`, {
      state: { limit: rowData, isEditing: true },
    });
  };

  const { header, infos, limits, locationGroups, currentBalance } =
    useSelector(selectPolicyData);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();    

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    try {
      const payload = {
        ...policyData, // Sending the Redux state
        formData, 
        userId: currentUser.userId,
        Active: formData.active,
        CompanyName: formData.CompanyName,
      };

      const response = await fetch(`${apiUrl}/FuelLink-setPolicyAdmin?`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log("Policy successfully saved:", responseData);
        setAlertMessage("Successfully updated the policy!");
        setIsLoading(false);
        // console.log("Initial Active State: ", initialActiveState);
        // console.log("formData.active value: ", formData.active);
        if (initialActiveState !== formData.active || recalculateBalance) {
          // Active state has changed, trigger balance recalculation logic
          try {
            const balanceResponse = await fetch(`${apiUrl}/calculateUserStartingBalance`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: currentUser.userId,
                TenantId: currentUser.tenantId,
                PolicyNumber: formData.PolicyNumber,
              }),
            });

            if (balanceResponse.ok && balanceResponse.status === 200) {
              const balanceData = await balanceResponse.json();
              console.log("Balance Recalculation Data:", balanceData);
              setFormData({ ...formData, Balance: balanceData.balance });
              setAlertMessage("Successfully updated policy and balance.");
            } else if (balanceResponse.status === 420) {
              console.error("Failed to recalculate balance:", balanceResponse);
              setAlertMessage("Polcy updated, but failed to recalculate balance. Policies with subabbounts cannot be recalculated.");
            } else {
              console.error("Failed to recalculate balance");              
              setAlertMessage(
                "Failed to recalculate balance. Please try again."
              );
            }
          } catch (error) {
            console.error(
              "There was an error recalculating the balance:",
              error
            );
            setAlertMessage("Failed to recalculate balance. Please try again.");
          }
        }
      } else {        
        setAlertMessage("Failed to update the policy. Please try again.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("There was an error saving the policy:", error);
      setAlertMessage("Failed to update the policy. Please try again.");
      setIsLoading(false);
    }
  };

  const [showStartingBalanceFields, setShowStartingBalanceFields] = useState(
    formData.StartingBalance ? true : false
  );

  const toggleStartingBalanceFields = () => {
    setShowStartingBalanceFields(!showStartingBalanceFields);
  };
  const handleEditClick = (cardData) => {
    navigate('/editcard', { state: { cardNumber: cardData.CardNumber } });
  };
  const cardSummariesColumns = useMemo(() => [
    {
      name: "Card Number",
      selector: row => row.CardNumber,
      reorder: true,
    },
    {
      name: "Driver ID",
      selector: row => row.DriverId,
      reorder: true,
    },
    {
      name: "Driver Name",
      selector: row => row.DriverName,
      reorder: true,
    },
    {
      name: "Unit #",
      selector: row => row.UnitNumber,
      reorder: true,
    },
    {
      name: "Status",
      selector: row => row.Status,
      reorder: true,
    },
    {
      name: "Edit",
      cell: row => (
        <button
          className="subTableEdit"
          onClick={() => handleEditClick(row)}
        >
          Edit
        </button>
      ),
      reorder: true,
    },
  ], []);

  const validateForm = () => {
    if (!formData.active) {
      return true; // If the form is not active, no need to validate other fields
    }

    if (!formData.CompanyName) {
      alert("Company Name is required");
      return false;
    }

    if (formData.isPrePayAccount === "") {
      alert("Please select if this is a PrePay Account");
      return false;
    }

    if (formData.isPrePayAccount !== "true" && !formData.CreditLimit) {
      alert("Credit Limit is required");
      return false;
    }

    if (formData.StartingBalance && !formData.AsOfDate) {
      alert("As of Date is required when Starting Balance is provided");
      return false;
    }

    return true;
  };

  // const transactionsColumns = useMemo(() => {
  //   if (transactions.length > 0) {
  //     return [
  //       {
  //         name: "Date",
  //         selector: row => {
  //           const date = new Date(row.TransactionDate);
  //           const formattedDate = date.toLocaleDateString("en-US");
  //           const formattedTime = date.toLocaleTimeString("en-US", {
  //             hour: "2-digit",
  //             minute: "2-digit",
  //             second: "2-digit",
  //             hour12: true,
  //           });
  //           return `${formattedDate} ${formattedTime}`;
  //         },
  //         reorder: true,
  //       },
  //       {
  //         name: "Transaction ID",
  //         selector: row => row.TransactionId,
  //         reorder: true,
  //       },
  //       {
  //         name: "Location",
  //         selector: row => row.locationName,
  //         reorder: true,
  //       },
  //       {
  //         name: "State",
  //         selector: row => row.locationstate,
  //         reorder: true,
  //       },
  //       {
  //         name: "Amount",
  //         selector: row => new Intl.NumberFormat("en-US", {
  //           style: "currency",
  //           currency: "USD",
  //         }).format(row.PrefTotal),
  //         reorder: true,
  //       },
  //     ];
  //   }
  //   return [];
  // }, [transactions]);

  const policyPromptsColumns = useMemo(() => [
    {
      name: "Info ID",
      selector: row => row.infoId,
      reorder: true,
    },
    {
      name: "Length Check",
      selector: row => row.lengthCheck,
      reorder: true,
    },
    {
      name: "Match Value",
      selector: row => row.matchValue,
      reorder: true,
    },
    {
      name: "Maximum",
      selector: row => row.maximum,
      reorder: true,
    },
    {
      name: "Minimum",
      selector: row => row.minimum,
      reorder: true,
    },
    {
      name: "Report Value",
      selector: row => row.reportValue,
      reorder: true,
    },
    {
      name: "Validation Type",
      selector: row => row.validationType,
      reorder: true,
    },
    {
      name: "Value",
      selector: row => row.value,
      reorder: true,
    },
    {
      name: "Edit",
      cell: row => (
        <button
          onClick={() => handlePromptEdit(row)}
          className="subTableEdit"
        >
          Edit
        </button>
      ),
      reorder: true,
    },
  ], []);

  const policyLimitsColumns = useMemo(() => [
    {
      name: "Limit Id",
      selector: row => row.limitId,
      reorder: true,
    },
    {
      name: "Amount",
      selector: row => row.limit,
      reorder: true,
    },
    {
      name: "Hours",
      selector: row => row.hours,
      reorder: true,
    },
    {
      name: "Auto Roll Map",
      selector: row => row.autoRollMap,
      reorder: true,
    },
    {
      name: "Auto Roll Max",
      selector: row => row.autoRollMax,
      reorder: true,
    },
    {
      name: "Min Hours",
      selector: row => row.minHours,
      reorder: true,
    },
    {
      name: "Edit",
      cell: row => (
        <button
          onClick={() => handleLimitsEdit(row)}
          className="subTableEdit"
        >
          Edit
        </button>
      ),
      reorder: true,
    },
  ], []);

  const locationGroupsColumns = React.useMemo(
    () => [
      {
        Header: "Location ID",
        accessor: "location",
      },
      {
        Header: "Edit",
        Cell: () => <button className="subTableEdit">Edit</button>,
      },
    ],
    []
  ); 

  function ensureArray(data) {
    if (!data) return [];
    return Array.isArray(data) ? data : [data];
  }
 
  return (
    <>
      <MobileHeader theme={theme} />
      <div className="mobile-container">
        <PageHeader
          heading="Policy Details"
          context="Edit Policy Details"
        />
        <div className="text-end mb-4">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="text-gray-700 text-sm mr-4"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
            Go Back
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-2">
          {alertMessage && (
            <div
              className={`alert p-4 rounded ${alertMessage.startsWith("Successfully")
                  ? "bg-green-200 text-green-600"
                  : "bg-red-200 text-red-600"
                } flex justify-between items-center`}
            >
              {alertMessage}
              <button
                onClick={() => setAlertMessage(null)}
                className="text-lg font-bold"
              >
                ×
              </button>
            </div>
          )}
          {/* Activation Toggle */}
          <div className="w-full border-b border-gray-300 py-2">
            <div className="flex items-center">
              <label className="text-sm font-medium text-gray-700 mr-2">
                Enable User Accounts
              </label>
              <ToggleSwitch
                isChecked={formData.active}
                onToggle={() =>
                  setFormData({ ...formData, active: !formData.active })
                }
                primaryColor={primaryColor}
              />
            </div>
            <span className="block text-xs text-gray-500 mt-1">
              Enable this policy to unlock full account functionality, providing
              users with the tools to manage funds, handle invoices, and control
              card settings on this policy.
            </span>
          </div>

          {/* Company Name (Shown only if active) */}
          {formData.active && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Company Name
              </label>
              <span className="block text-xs text-gray-500 mt-1">
                This name will be displayed as the company's name on their portal
                header.
              </span>
              <input
                type="text"
                name="CompanyName"
                onChange={handleChange}
                value={formData.CompanyName}
                className="mt-1 p-2 w-full border rounded-md"
                required
              />
            </div>
          )}

          {/* Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <input
              type="text"
              name="Description"
              onChange={handleChange}
              value={formData.Description}
              className="mt-1 p-2 w-full border rounded-md"
            />
          </div>

          {/* Contract ID */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Contract ID
            </label>
            <input
              type="text"
              name="ContractID"
              onChange={handleChange}
              value={formData.ContractID}
              className="mt-1 p-2 w-full border rounded-md"
              readOnly
            />
          </div>

          {/* Policy Number */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Policy Number
            </label>
            <input
              type="number"
              name="PolicyNumber"
              onChange={handleChange}
              value={formData.PolicyNumber}
              className="mt-1 p-2 w-full border rounded-md"
              readOnly
            />
          </div>

          {/* Financials Section (Shown only if active) */}
          {formData.active && (
            <>
              <div className="relative my-6">
                <div
                  className="absolute inset-0 flex items-end"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-start">
                  <span className="text-lg font-medium text-gray-900">
                    Financials
                  </span>
                </div>
              </div>

              {showStartingBalanceFields || formData.StartingBalance ? (
                <div className="flex flex-col space-y-2">
                  <label className="text-sm font-medium text-gray-700">
                    Starting Balance
                  </label>
                  <div className="flex items-center space-x-4">
                    <div className="relative">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        $
                      </span>
                      <input
                        type="text"
                        name="StartingBalance"
                        onChange={handleChange}
                        value={formData.StartingBalance}
                        className="mt-1 p-2 pl-6 w-32 border rounded-md"
                        required
                        pattern="\d+(\.\d{1,2})?" // regex pattern to allow numbers with up to two decimal places
                        title="Please enter a valid number with up to two decimal places"
                      />
                    </div>
                    <span className="text-sm font-medium text-gray-700">
                      as of
                    </span>
                    <input
                      type="date"
                      name="AsOfDate"
                      onChange={handleChange}
                      value={formData.AsOfDate}
                      className="mt-1 p-2 w-44 border rounded-md"
                      required
                    />
                  </div>
                  <p className="text-xs text-gray-500">
                    Note: For prepaid accounts, the starting balance is added to
                    the balance. For non-prepaid accounts, it is subtracted from
                    the credit limit.
                  </p>
                  {(!formData.StartingBalance ||
                    formData.StartingBalance === "0") && (
                      <span
                        className="text-sm text-blue-600 cursor-pointer"
                        onClick={toggleStartingBalanceFields}
                      >
                        Hide Starting Balance
                      </span>
                    )}
                </div>
              ) : (
                <span
                  className="text-sm text-blue-600 cursor-pointer"
                  onClick={toggleStartingBalanceFields}
                >
                  Add a Starting Balance
                </span>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Discount Group
                </label>
                <select
                  name="DiscountGroupID"
                  onChange={handleChange}
                  value={formData.DiscountGroupID || ""}
                  className="mt-1 p-2 w-full border rounded-md"
                  required
                >
                  <option value="" disabled>
                    Select a discount group
                  </option>
                  {discountGroups.map((group) => (
                    <option key={group.ID} value={group.ID}>
                      {group.DiscountGroupName}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Is PrePay Account
                </label>
                <select
                  name="isPrePayAccount"
                  onChange={handleChange}
                  value={formData.isPrePayAccount || ""}
                  className="mt-1 p-2 w-full border rounded-md"
                  required
                >
                  <option value="">Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              {formData.isPrePayAccount !== "true" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Credit Limit
                  </label>
                  <input
                    type="number"
                    name="CreditLimit"
                    onChange={handleChange}
                    value={formData.CreditLimit || ""}
                    className="mt-1 p-2 w-full border rounded-md"
                    required={formData.isPrePayAccount !== "true"}
                  />
                  <div className="mt-1 p-2 w-full border rounded-md">
                    <span className="text-lg font-semibold text-gray-700">
                      Credit Remaining: ${formData.Balance}
                    </span>
                  </div>
                </div>
              )}

              {formData.isPrePayAccount === "true" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Current PrePay Balance
                  </label>
                  <p>
                    *To add to the PrePay balance, add a new{" "}
                    <a
                      href="/adminfinancialtransactions"
                      className="text-blue-600"
                    >
                      Financial Transaction
                    </a>
                    .
                  </p>
                  <input
                    type="number"
                    name="PrePayBalance"
                    value={formData.Balance || ""}
                    className="mt-1 p-2 w-full border rounded-md"
                    readOnly
                  />
                </div>
              )}
            </>
          )}

          {/* Submit Button */}
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="flex items-center space-x-4 mt-4">
              <button
                type="submit"
                className="px-4 py-2 text-white rounded themed-button"
              >
                Update Policy
              </button>

              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={recalculateBalance}
                  onChange={() => setRecalculateBalance(!recalculateBalance)}
                  className="h-4 w-4"
                />
                <span className="text-sm">Recalculate User Balance</span>
              </label>
            </div>
          )}
        </form>

        <div className="mt-8">
          <SubTableV2
            columns={policyPromptsColumns}
            data={ensureArray(infos)}
            loading={policyPromptsLoading}
            alertMessage={policyPromptsAlertMessage}
            clearAlert={() => setPolicyPromptsAlertMessage(null)}
            title="Policy Prompts"
            infoMessage="You must click 'Update Policy' to save your changes!"
          >
            <Link
              className="themed-button"
              to={{
                pathname: `/EditPolicyPrompts/new`,
                state: { isEditing: false },
              }}
            >
              New Prompt
            </Link>
          </SubTableV2>
          <SubTableV2
            columns={policyLimitsColumns}
            data={ensureArray(limits)}
            loading={policyPromptsLoading}
            alertMessage={policyPromptsAlertMessage}
            clearAlert={() => setPolicyPromptsAlertMessage(null)}
            title="Policy Limits"
            infoMessage="You must click 'Update Policy' to save your changes!"
          >
            <Link
              className="themed-button"
              to={{
                pathname: `/EditPolicyLimits/new`,
                state: { isEditing: false },
              }}
            >
              New Policy Limit
            </Link>
          </SubTableV2>

          <SubTableV2
            columns={cardSummariesColumns}
            data={cardSummaries}
            primaryColor={primaryColor}
            title="Cards Assigned To This Policy"
          />
        </div>
        {/* <div className="mt-8">
          <SubTableV2
            columns={transactionsColumns}
            data={transactions}
            primaryColor={primaryColor}
            title="Transactions Data"
          />
        </div> */}
      </div>
    </>
  );
};

export default PoliciesForm;
