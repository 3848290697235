import React, { useState } from 'react';
import { auth } from './firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useTheme } from './ThemeContext';  
import { Link } from 'react-router-dom';
import Spinner from './Spinner.js';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const subDomain = window.location.hostname.split('.')[0];

  const { carrierPortal } = theme;

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent successfully.');
      setError(null);
    } catch (err) {
      setError(err.message);
      setMessage('');
    }
    setIsLoading(false);
  };

  const logoClass = theme.logoSize === 'small' ? 'w-1/4' : theme.logoSize === 'medium' ? 'w-4/5' : 'w-full';

  return (
    <>
      {isLoading && <Spinner />}
      <div className="min-h-screen flex flex-col md:flex-row">
        {/* Logo Side */}
        <div className="flex-1 flex items-center justify-center bg-white">
          <div className="text-center justify-center">
            <img src={theme.loginImage} alt="Logo" className={`${logoClass} h-auto mx-auto`} />
            {theme.onBehalfOf && (
              <p className="mt-2 text-sm text-gray-700">
                A FuelLink Portal created for <strong>{theme.onBehalfOf}</strong>. <a href="https://www.fuellink.net" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Learn more</a>
              </p>
            )}
          </div>
        </div>

        {/* Form Side */}
        <div className="flex-1 flex items-center justify-center p-4" style={{ backgroundColor: theme.loginPrimaryBG }}>
          <div
            style={{
              background: 'rgba(245, 245, 245, 0.9)',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
            }}
            className="p-8 rounded-lg w-96"
          >
            <h2 className="text-2xl mb-4 text-center">Reset Password</h2>
            {message && <p className="text-green-500 mb-4">{message}</p>}
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleResetPassword}>
              <div className="mb-4">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <button
                type="submit"
                className="w-full themed-login-button p-2 rounded mb-4"
              >
                Send Reset Email
              </button>
            </form>

            <div className="text-center">
              <div>
                <Link to="/" className="text-blue-500 hover:underline">Return to Login</Link>
              </div>
              {!carrierPortal && (
              <div>
                <Link to="/registration" className="text-blue-500 hover:underline">Sign Up</Link>
              </div>
              )}
              <div>
                <Link to="/signup" className="text-blue-500 hover:underline">Redeem an Invitation Code</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
