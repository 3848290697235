import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from './PageHeader';
import { useAuth } from './AuthContext';
import Spinner from "./Spinner";
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import MobileHeader from './MobileHeader';
import { useTheme } from './ThemeContext';

const ReportingUser = () => {
    const { currentUser } = useAuth();
    const {theme} = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currency, setCurrency] = useState('USD');
    const [groupBy, setGroupBy] = useState('driverName');
    const [sortBy, setSortBy] = useState('tranDate');
    const [viewFormat, setViewFormat] = useState('9');  // Default to CSV
    const [includeSummary, setIncludeSummary] = useState(false);
    const [userReport, setUserReport] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [enableMatchByFilters, setEnableMatchByFilters] = useState(true);
    const [isIFTAReport, setIsIFTAReport] = useState(false);

    const [cardNumberChecked, setCardNumberChecked] = useState(false);
    const [cardNumberValue, setCardNumberValue] = useState('');

    const [driverNameChecked, setDriverNameChecked] = useState(false);
    const [driverNameValue, setDriverNameValue] = useState('');

    const [driverIdChecked, setDriverIdChecked] = useState(false);
    const [driverIdValue, setDriverIdValue] = useState('');

    const [unitNumberChecked, setUnitNumberChecked] = useState(false);
    const [unitNumberValue, setUnitNumberValue] = useState('');

    const [countryChecked, setCountryChecked] = useState(false);
    const [countryValue, setCountryValue] = useState('85'); // Default to "USA"

    const [stateChecked, setStateChecked] = useState(false);
    const [stateValue, setStateValue] = useState('');

    const [cityChecked, setCityChecked] = useState(false);
    const [cityValue, setCityValue] = useState('');

    const [chainIdChecked, setChainIdChecked] = useState(false);
    const [chainIdValues, setChainIdValues] = useState('');

    const [itemChecked, setItemChecked] = useState(false);
    const [itemValue, setItemValue] = useState('');

    const apiUrl= process.env.REACT_APP_API_URL;


    useEffect(() => {
        if (isIFTAReport) {
            setGroupBy('stateProv');
            setSortBy('stateProv');
            setIncludeSummary(false);
            setUserReport(false);
        } 
        if (!isIFTAReport){
            setGroupBy('driverName');
            setSortBy('tranDate');
        }
    }, [isIFTAReport]);
    
    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        const userPermissions = {            
            NetworkId: currentUser?.tenantId,
            policyNumber: currentUser?.policyNumber,
            ContractId: currentUser?.ContractId,
            cardGroupID: currentUser.cardGroupID,
        };
        const formData = {
            startDate,
            endDate,
            currency,
            groupBy,
            sortBy,
            includeSummary,
            isIFTA: isIFTAReport,
            userReport,
            viewFormat,
            filters: [],
            userPermissions,
            carrierName: currentUser.companyName,
        };

        // Add filters based on checked values
        if (cardNumberChecked) formData.filters.push({ field: 'Card Number', value: cardNumberValue });
        if (driverNameChecked) formData.filters.push({ field: 'Driver Name', value: driverNameValue });
        if (driverIdChecked) formData.filters.push({ field: 'Driver ID', value: driverIdValue });
        if (unitNumberChecked) formData.filters.push({ field: 'Unit', value: unitNumberValue });
        // if (policyIdChecked) formData.filters.push({ field: 'Policy Number', value: policyIdValue });
        if (countryChecked) formData.filters.push({ field: 'Country', value: countryValue });
        if (stateChecked) formData.filters.push({ field: 'State', value: stateValue });
        if (cityChecked) formData.filters.push({ field: 'City', value: cityValue });
        if (chainIdChecked) formData.filters.push({ field: 'Chain Id', value: chainIdValues });
        if (itemChecked) formData.filters.push({ field: 'Item', value: itemValue });   
        
        console.log('Form Data: ', formData);

        try {
            const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getUserReportsV2', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            // Handle Blob data for file download
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = 'download';
            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(downloadUrl);
            setIsLoading(false);
            setShowSuccessModal(true);
        } catch (error) {
            setIsLoading(false);
            setShowErrorModal(true);
            console.error('Error during file download:', error);
        }
    };

    const handleUserReportChange = (checked) => {
        setUserReport(checked);
        setIncludeSummary(!checked ? includeSummary : false);
        setEnableMatchByFilters(!checked);
        if (checked) {
            setCardNumberChecked(false);
            setDriverNameChecked(false);
            setDriverIdChecked(false);
            setUnitNumberChecked(false);
            setCountryChecked(false);
            setStateChecked(false);
            setCityChecked(false);
            setChainIdChecked(false);
            setItemChecked(false);
        }
    };

    const handleIFTAChange = (event) => {
        setIsIFTAReport(event.target.checked);
    };

    return (
        <>
            {isLoading && <Spinner />}
            {showSuccessModal && (
                <SuccessModal
                    message="The report has been successfully generated and downloaded."
                    onClose={() => setShowSuccessModal(false)}
                />
            )}
            {showErrorModal && (
                <ErrorModal
                    message="There was an error processing your request. Please try again."
                    onClose={() => setShowErrorModal(false)}
                />
            )}
                    <MobileHeader theme={theme} />
        <div className='mobile-container'>           
            <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
                <form onSubmit={handleSubmit} className="space-y-6">
                {(currentUser?.isPolicyAdmin || currentUser?.isContractAdmin || currentUser?.isAdmin) && currentUser?.hasSubaccounts && !currentUser?.cardGroupID && (
                        <div className="flex justify-center">
                            <p className="text-sm text-red-500">**Your policy has subaccounts, you are currently running reports for ALL accounts. To see reports for a specific account, navigate to it.</p>
                        </div>
                    )}
                    <div className="text-center">
                        <h2 className="text-2xl font-bold mb-2">Transaction Report</h2>
                        <p>Select your date range and preferences for the transaction report.</p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {/* Date range inputs */}
                        <div>
                            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Start Date:</label>
                            <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                value={startDate}
                                onChange={e => setStartDate(e.target.value)}
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">End Date:</label>
                            <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                value={endDate}
                                onChange={e => setEndDate(e.target.value)}
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        {/* Additional fields */}
                        <div className="col-span-1 md:col-span-2">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <div>
                                    <label htmlFor="currency" className="block text-sm font-medium text-gray-700">Currency:</label>
                                    <select
                                        id="currency"
                                        name="currency"
                                        value={currency}
                                        onChange={e => setCurrency(e.target.value)}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option value="USD">USD/Gallons</option>
                                        <option value="CAD" disabled>CAD/Liters</option>
                                        <option value="MXN" disabled>MXN/Liters</option>
                                        <option value="ANY" disabled>Multicurrency</option>
                                    </select>
                                </div>

                                <div>
                                    <label htmlFor="groupBy" className="block text-sm font-medium text-gray-700">Group by:</label>
                                    <select
                                        id="groupBy"
                                        name="groupBy"
                                        value={groupBy}                                        
                                        disabled={isIFTAReport}
                                        onChange={e => setGroupBy(e.target.value)}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option value="cardNumber">Card Number</option>
                                        <option value="arNumber">AR Number</option>
                                        <option value="city">City</option>
                                        <option value="chainId">Chain ID</option>
                                        <option value="db">Direct Bill</option>
                                        <option value="driverID">Driver ID</option>
                                        <option value="driverName">Driver Name</option>
                                        <option value="locationId">Location ID</option>
                                        <option value="locationName">Location Name</option>
                                        <option value="stateProv">State/Province</option>
                                        <option value="tranDate">Transaction Date</option>
                                        <option value="tripNumber">Trip Number</option>
                                        <option value="unit">Unit</option>
                                        <option value="odometer">Odometer</option>
                                    </select>
                                </div>

                                <div>
                                    <label htmlFor="sortBy" className="block text-sm font-medium text-gray-700">Sort by:</label>
                                    <select
                                        id="sortBy"
                                        name="sortBy"
                                        value={sortBy}
                                        disabled={isIFTAReport}
                                        onChange={e => setSortBy(e.target.value)}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option value="tranDate">Transaction Date</option>
                                        <option value="cardNumber">Card Number</option>
                                        <option value="city">City</option>
                                        <option value="db">Direct Bill / Funded</option>
                                        <option value="DriverID">Driver ID</option>
                                        <option value="driverName">Driver Name</option>
                                        <option value="locationName">Location Name</option>
                                        <option value="stateProv">State/Province</option>
                                        <option value="unit">Unit</option>
                                        <option value="odometer">Odometer</option>
                                    </select>
                                </div>

                                <div className="md:col-span-3">
                                    <label htmlFor="viewFormat" className="block text-sm font-medium text-gray-700">View Format Type:</label>
                                    <select
                                        id="viewFormat"
                                        name="viewFormat"
                                        value={viewFormat}
                                        onChange={e => setViewFormat(e.target.value)}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option value="2" disabled>PDF</option>
                                        <option value="9">CSV</option>
                                        <option value="4" disabled>Excel</option>
                                        <option value="3" disabled>Text</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <fieldset className="border p-4 rounded">
                            <legend className="font-bold">Options:</legend>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="includeSummary"
                                    checked={includeSummary}
                                    onChange={(e) => setIncludeSummary(e.target.checked)}
                                    disabled={userReport || isIFTAReport}
                                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label htmlFor="includeSummary" className="ml-2 block text-sm text-gray-900">
                                    Include Totals
                                </label>
                            </div>

                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="userReport"
                                    checked={userReport}
                                    disabled={isIFTAReport}
                                    onChange={(e) => handleUserReportChange(e.target.checked)}
                                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label htmlFor="userReport" className="ml-2 block text-sm text-gray-900">
                                    Generate Invoice
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="iftaReport"
                                    checked={isIFTAReport}
                                    onChange={handleIFTAChange}
                                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                    disabled={userReport}
                                />
                                <label className="ml-2 block text-sm text-gray-900" htmlFor="iftaReport">Generate IFTA Report</label>
                            </div>
                        </fieldset>
                    </div>

                    <div>
                        <fieldset className="border p-4 rounded">
                            <legend className="font-bold">Match By (Optional):</legend>
                            <div className="space-y-4">
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                                    <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={cardNumberChecked}
                                            onChange={() => setCardNumberChecked(!cardNumberChecked)}
                                            disabled={!enableMatchByFilters}
                                        />
                                        <label className="flex-grow">Card Number:</label>
                                    </div>
                                    <div className="flex items-center w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="text"
                                            placeholder='Complete Card Number Required'
                                            value={cardNumberValue}
                                            onChange={(e) => setCardNumberValue(e.target.value)}
                                            maxLength="21"
                                            disabled={!cardNumberChecked}
                                            className="border-gray-300 border rounded p-2 flex-grow w-full"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                                    <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={driverNameChecked}
                                            onChange={() => setDriverNameChecked(!driverNameChecked)}
                                            disabled={!enableMatchByFilters}
                                        />
                                        <label className="flex-grow">Driver Name:</label>
                                    </div>
                                    <div className="flex items-center w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="text"
                                            value={driverNameValue}
                                            onChange={(e) => setDriverNameValue(e.target.value)}
                                            disabled={!driverNameChecked}
                                            className="border-gray-300 border rounded p-2 flex-grow w-full"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                                    <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={driverIdChecked}
                                            onChange={() => setDriverIdChecked(!driverIdChecked)}
                                            disabled={!enableMatchByFilters}
                                        />
                                        <label className="flex-grow">Driver ID:</label>
                                    </div>
                                    <div className="flex items-center w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="text"
                                            value={driverIdValue}
                                            onChange={(e) => setDriverIdValue(e.target.value)}
                                            disabled={!driverIdChecked}
                                            className="border-gray-300 border rounded p-2 flex-grow w-full"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                                    <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={unitNumberChecked}
                                            onChange={() => setUnitNumberChecked(!unitNumberChecked)}
                                            disabled={!enableMatchByFilters}
                                        />
                                        <label className="flex-grow">Unit Number:</label>
                                    </div>
                                    <div className="flex items-center w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="text"
                                            value={unitNumberValue}
                                            onChange={(e) => setUnitNumberValue(e.target.value)}
                                            disabled={!unitNumberChecked}
                                            className="border-gray-300 border rounded p-2 flex-grow w-full"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                                    <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={countryChecked}
                                            onChange={() => setCountryChecked(!countryChecked)}
                                            disabled={!enableMatchByFilters || isIFTAReport}
                                        />
                                        <label className="flex-grow">Country:</label>
                                    </div>
                                    <div className="flex items-center w-full sm:w-1/2 px-2 sm:px-0">
                                        <select
                                            value={countryValue}
                                            onChange={(e) => setCountryValue(e.target.value)}
                                            disabled={!countryChecked}
                                            className="border-gray-300 border rounded p-2 flex-grow w-full"
                                        >
                                            <option value="85">United States</option>
                                            <option value="67">Canada</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                                    <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={stateChecked}
                                            onChange={() => setStateChecked(!stateChecked)}
                                            disabled={!enableMatchByFilters || isIFTAReport}
                                        />
                                        <label className="flex-grow">State/Province:</label>
                                    </div>
                                    <div className="flex items-center w-full sm:w-1/2 px-2 sm:px-0">
                                        <select
                                            value={stateValue}
                                            onChange={(e) => setStateValue(e.target.value)}
                                            disabled={!stateChecked}
                                            className="border-gray-300 border rounded p-2 flex-grow w-full"
                                        >
                                            <option value="AB">Alberta</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="BC">British Columbia</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CA">California</option>
                                            <option value="DC">District of Columbia</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IL">Illinois</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="KS">Kansas</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="MB">Manitoba</option>
                                            <option value="MT">Montana</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MI">Michigan</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="NL">Newfoundland</option>
                                            <option value="NB">New Brunswick</option>
                                            <option value="NU">Nunavut</option>
                                            <option value="NT">NW Territories</option>
                                            <option value="NS">Nova Scotia</option>
                                            <option value="NY">New York</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ON">Ontario</option>
                                            <option value="OR">Oregon</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OH">Ohio</option>
                                            <option value="PE">Prince Edward Island</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="QC">Quebec</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SK">Saskatchewan</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WY">Wyoming</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="YT">Yukon</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                                    <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={cityChecked}
                                            onChange={() => setCityChecked(!cityChecked)}
                                            disabled={!enableMatchByFilters || isIFTAReport}
                                        />
                                        <label className="flex-grow">City:</label>
                                    </div>
                                    <div className="flex items-center w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="text"
                                            value={cityValue}
                                            onChange={(e) => setCityValue(e.target.value)}
                                            disabled={!cityChecked}
                                            className="border-gray-300 border rounded p-2 flex-grow w-full"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                                    <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={chainIdChecked}
                                            onChange={() => setChainIdChecked(!chainIdChecked)}
                                            disabled={!enableMatchByFilters || isIFTAReport}
                                        />
                                        <label className="flex-grow">Chain ID:</label>
                                    </div>
                                    <div className="flex items-center w-full sm:w-1/2 px-2 sm:px-0">
                                        <select
                                            value={chainIdValues}
                                            onChange={(e) => setChainIdValues(e.target.value)}
                                            disabled={!chainIdChecked}
                                            className="border-gray-300 border rounded p-2 flex-grow w-full"
                                        >
                                            <option value="0">0 - Other</option>
                                            <option value="1">1 - PILOT FLYING J</option>
                                            <option value="2">2 - LOVES</option>
                                            <option value="3">3 - TA/PETRO</option>
                                            <option value="4">4 - SAPP BROTHERS</option>
                                            <option value="5">5 - WILCO</option>
                                            <option value="6">6 - SPEEDWAY</option>
                                            <option value="7">7 - QUIK TRIP</option>
                                            <option value="8">8 - KANGAROO / PANTRY</option>
                                            <option value="9">9 - PETRO CANADA</option>
                                            <option value="10">10 - IMPERIAL OIL</option>
                                            <option value="11">11 - KWIK TRIP</option>
                                            <option value="12">12 - QUICK FUEL</option>
                                            <option value="13">13 - AMBEST</option>
                                            <option value="14">14 - CAT SCALES</option>
                                            <option value="15">15 - EXXONMOBIL_US_OTR_NETWORK</option>
                                            <option value="16">16 - QUARLES</option>
                                            <option value="17">17 - MAVERIK</option>
                                            <option value="18">18 - IRVING 24</option>
                                            <option value="19">19 - ROAD RANGER</option>
                                            <option value="20">20 - MOBILE FUELERS</option>
                                            <option value="22">22 - Eclipse Advantage LLC</option>
                                            <option value="23">23 - CAPSTONE</option>
                                            <option value="24">24 - ROADSYNC</option>
                                            <option value="25">25 - SC FUELS</option>
                                            <option value="26">26 - CIRCLE K STORES</option>
                                            <option value="27">27 - RACETRAC</option>
                                            <option value="28">28 - CASEYS</option>
                                            <option value="101">101 - WEX NAF</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                                    <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-1/2 px-2 sm:px-0">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            checked={itemChecked}
                                            onChange={() => setItemChecked(!itemChecked)}
                                            disabled={!enableMatchByFilters || isIFTAReport}
                                        />
                                        <label className="flex-grow">Item:</label>
                                    </div>
                                    <div className="flex items-center w-full sm:w-1/2 px-2 sm:px-0">
                                        <select
                                            value={itemValue}
                                            onChange={(e) => setItemValue(e.target.value)}
                                            disabled={!itemChecked}
                                            className="border-gray-300 border rounded p-2 flex-grow w-full"
                                        >
                                            <option value="ACCE">ACCE - ACCESSORIAL</option>
                                            <option value="ADD ">ADD  - ADDITIVES</option>
                                            <option value="AMDS">AMDS - AVIATION MERCHANDISE</option>
                                            <option value="ANFR">ANFR - ANTI-FREEZE</option>
                                            <option value="APRO">APRO - AUTO PROPANE</option>
                                            <option value="ATOM">ATOM - AUTOMOTIVE</option>
                                            <option value="AVGS">AVGS - AVIATION GAS</option>
                                            <option value="BDSL">BDSL - BIO DIESEL</option>
                                            <option value="BEVR">BEVR - BEVERAGE</option>
                                            <option value="BPRP">BPRP - MISC PROPANE</option>
                                            <option value="BRAK">BRAK - BRAKES AND WHEELS</option>
                                            <option value="CADV">CADV - CASH ADVANCE</option>
                                            <option value="CLTH">CLTH - CLOTHING</option>
                                            <option value="COUP">COUP - COUPON</option>
                                            <option value="DEF">DEF  - DIESEL EXHAUST FLUID UREA</option>
                                            <option value="DEFC">DEFC - DEF CONTAINER</option>
                                            <option value="DELI">DELI - RESTAURANT/DELI</option>
                                            <option value="DSL">DSL  - DIESEL</option>
                                            <option value="DSLM">DSLM - MEXICO DIESEL</option>
                                            <option value="ELEC">ELEC - ELECTRONICS</option>
                                            <option value="EVCH">EVCH - EV CHARGING</option>
                                            <option value="FAX">FAX  - FAX</option>
                                            <option value="FURN">FURN - FURNACE OIL</option>
                                            <option value="GAS">GAS  - GASOLINE</option>
                                            <option value="GASM">GASM - MEXICO GAS MAGNA</option>
                                            <option value="GASP">GASP - Premium Gas Mexico</option>
                                            <option value="GROC">GROC - C-STORE/GROCERIES</option>
                                            <option value="HARD">HARD - HARDWARE/ACCESSORIES</option>
                                            <option value="HOTL">HOTL - HOTEL</option>
                                            <option value="IDLE">IDLE - IDLEAIRE</option>
                                            <option value="JET">JET  - JET FUEL</option>
                                            <option value="KERO">KERO - KEROSENE</option>
                                            <option value="LABR">LABR - LABOR</option>
                                            <option value="LMPR">LMPR - LUMPER</option>
                                            <option value="MDSL">MDSL - TAX EXEMPT DIESEL</option>
                                            <option value="MERC">MERC - MISCELLANEOUS MERCHANDISE</option>
                                            <option value="MGAS">MGAS - TAX EXEMPT GAS</option>
                                            <option value="MRFR">MRFR - MARKED RFR</option>
                                            <option value="NGAS">NGAS - NATURAL GAS</option>
                                            <option value="OIL">OIL  - OIL</option>
                                            <option value="OILC">OILC - OIL CHANGE</option>
                                            <option value="PARK">PARK - Parking</option>
                                            <option value="PART">PART - PARTS</option>
                                            <option value="PHON">PHON - PHONE TIME</option>
                                            <option value="PNT">PNT  - PAINT</option>
                                            <option value="PROP">PROP - PROPANE</option>
                                            <option value="RECP">RECP - RECAP</option>
                                            <option value="REPR">REPR - REPAIR SERVICE</option>
                                            <option value="REST">REST - RESTAURANT</option>
                                            <option value="RFND">RFND - REFUND</option>
                                            <option value="RFR">RFR  - REEFER</option>
                                            <option value="RFRM">RFRM - THERMO</option>
                                            <option value="SCAN">SCAN - TONS IMAGING</option>
                                            <option value="SCLE">SCLE - WEIGH SCALE FEE</option>
                                            <option value="SHWR">SHWR - SHOWER</option>
                                            <option value="SPLT">SPLT - OTHER METHOD OF PAYMENT</option>
                                            <option value="STAX">STAX - SALES TAX</option>
                                            <option value="TCHN">TCHN - TIRE CHAINS</option>
                                            <option value="TIRE">TIRE - TIRES/TIRE REPAIR</option>
                                            <option value="TOLL">TOLL - AMBASSADOR BRIDGE TOLL</option>
                                            <option value="TRAL">TRAL - TRAILER</option>
                                            <option value="TRPP">TRPP - TRIP PERMIT</option>
                                            <option value="ULSD">ULSD - ULTRA LOW SULFUR DIESEL</option>
                                            <option value="WASH">WASH - CAR WASH</option>
                                            <option value="WIFI">WIFI - FLEET WIFI BILLING</option>
                                            <option value="WROM">WROM - WASH ROOM</option>
                                            <option value="WWFL">WWFL - WINDSHIELD WASHER FLUID</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        </div>
                        {(currentUser?.isPolicyAdmin || currentUser?.isContractAdmin || currentUser?.isAdmin) && currentUser?.hasSubaccounts && !currentUser?.cardGroupID && (
                       <div className="flex justify-center">
                       <p className="text-sm text-red-500">**Your policy has subaccounts, you are currently running reports for ALL accounts. To see reports for a specific account, navigate to it.</p>
                   </div>
                    )}
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="themed-button py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Generate Report
                        </button>
                    </div>
                    
                </form>
            </div>
        </div>
        </>
    );
};

export default ReportingUser;
