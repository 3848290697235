import React, { useState, useEffect } from 'react';
import MainTableV2 from './MainTableV2';
import PageHeader from './PageHeader';
import { useTheme } from './ThemeContext';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const Marketing = () => {
    const { currentUser } = useAuth();
    const [searchQueryCampaigns, setSearchQueryCampaigns] = useState('');
    const [searchQueryTemplates, setSearchQueryTemplates] = useState('');
    const [campaignsData, setCampaignsData] = useState([]);
    const [templatesData, setTemplatesData] = useState([]);
    const { theme } = useTheme();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { isAdmin, userId } = currentUser;
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [campaignToDelete, setCampaignToDelete] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [messageModalContent, setMessageModalContent] = useState('');

    useEffect(() => {
        const fetchCampaigns = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${apiUrl}/dataServer`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        action: 'getCampaigns',
                        payload: { userId: userId }
                    })
                });

                const result = await response.json();
                console.log('Campaigns result:', result);
                if (response.ok && result.success) {
                    setCampaignsData(result.campaigns);
                } else {
                    console.error('Error fetching campaigns:', result.message);
                }
            } catch (error) {
                console.error('Error occurred while fetching campaigns:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCampaigns();
    }, [apiUrl, userId]);

    // Fetch templates from backend
    useEffect(() => {
        const fetchTemplates = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${apiUrl}/dataServer`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        action: 'getEmailTemplates',
                        payload: { userId: userId }
                    })
                });

                const result = await response.json();
                console.log('Templates result:', result);
                if (response.ok) {
                    setTemplatesData(result.templates);
                } else {
                    console.error('Error fetching templates:', result.message);
                }
            } catch (error) {
                console.error('Error occurred while fetching templates:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTemplates();
    }, [apiUrl]);

    // Columns for Campaigns
    const campaignColumns = [
        {
            name: 'Campaign Type',           
            selector: row => isAdmin ? `${row.CampaignType} (${row.PolicyNumber})` : row.CampaignType,
            sortable: true
        },
        {
            name: 'Status',
            cell: row => (
                <div className="flex items-center">
                    <FontAwesomeIcon
                        icon={faCircle}
                        style={{ color: row.Active ? 'green' : 'red', marginRight: '5px' }}
                    />
                    {row.Active ? 'Active' : 'Inactive'}
                </div>
            ),
            sortable: true
        },
        {
            name: 'Created Date',
            selector: row => new Date(row.CreatedDate).toLocaleString(),
            sortable: true
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="flex">
                    <Link
                        className="themed-button mr-2"
                        to={{
                            pathname: `/campaigns`,
                        }}
                        state={{ campaignId: row.Id }}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Link>
                    <button
                        className="themed-button"
                        onClick={() => {
                            setCampaignToDelete(row);
                            setShowDeleteModal(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <button
                        className="ml-2 themed-button"
                        onClick={() => handleToggleCampaignActiveStatus(row.Id)}
                    >
                        {row.Active ? 'Deactivate' : 'Activate'}
                    </button>
                </div>
            ),
        },
    ];


    // Columns for Templates
    const templateColumns = [
        { name: 'Name', selector: row => isAdmin ? `${row.name} (${row.PolicyNumber})` : row.name, sortable: true },
        { name: 'Subject', selector: row => row.subject, sortable: true },
        { name: 'Created Date', selector: row => new Date(row.createdDate).toLocaleString(), sortable: true },
        {
            name: 'Actions',
            cell: row => (
                <Link
                    className="themed-button"
                    to={{
                        pathname: `/emaileditor`,
                    }}
                    state={{ templateId: row.Id }}
                >
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
            ),
        }
    ];

    const handleConfirmDeleteCampaign = async () => {
        if (!campaignToDelete) return;

        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ action: 'deleteCampaign', payload: { campaignId: campaignToDelete.Id, userId } }),
            });
            const result = await response.json();
            if (response.ok && result.success) {
                // Remove the campaign from the state
                setCampaignsData(prevData => prevData.filter(campaign => campaign.Id !== campaignToDelete.Id));
                setShowDeleteModal(false);
                setCampaignToDelete(null);
            } else {
                console.error('Error deleting campaign:', result.message);
                setMessageModalContent(`Error deleting campaign: ${result.message}`);
                setShowMessageModal(true);
            }
        } catch (error) {
            console.error('Error occurred while deleting campaign:', error);
            setMessageModalContent('An error occurred while deleting the campaign.');
            setShowMessageModal(true);
        } finally {
            setLoading(false);
        }
    };


    const handleToggleCampaignActiveStatus = async (campaignId) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    action: 'toggleCampaignActiveStatus',
                    payload: { campaignId, userId: currentUser.userId },
                }),
            });

            const result = await response.json();
            if (response.ok && result.success) {
                // Update the campaign's active status in the state
                setCampaignsData((prevData) =>
                    prevData.map((campaign) =>
                        campaign.Id === campaignId
                            ? { ...campaign, Active: !campaign.Active }
                            : campaign
                    )
                );
                setMessageModalContent(result.message);
                setShowMessageModal(true);
            } else {
                setMessageModalContent(`Error: ${result.message}`);
                setShowMessageModal(true);
            }
        } catch (error) {
            console.error('Error toggling campaign active status:', error);
            setMessageModalContent('An error occurred while updating the campaign status.');
            setShowMessageModal(true);
        } finally {
            setLoading(false);
        }
    };


    const Modal = ({ title, children, isOpen, onClose }) => {
        if (!isOpen) return null;

        return (
            // Modal backdrop
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                {/* Modal content */}
                <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4">
                    {/* Modal header */}
                    <div className="border-b px-4 py-2 flex justify-between items-center">
                        <h3 className="text-xl font-semibold">{title}</h3>
                        <button
                            className="text-gray-600 hover:text-gray-800"
                            onClick={onClose}
                        >
                            &times;
                        </button>
                    </div>
                    {/* Modal body */}
                    <div className="p-4">{children}</div>
                </div>
            </div>
        );
    };

    return (
        <>
            {loading && <Spinner />}
            {showDeleteModal && (
                <Modal
                    title="Confirm Delete"
                    isOpen={showDeleteModal}
                    onClose={() => {
                        setShowDeleteModal(false);
                        setCampaignToDelete(null);
                    }}
                >
                    <p>Are you sure you want to delete this campaign?</p>
                    <div className="flex justify-end mt-4">
                        <button
                            className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                            onClick={() => {
                                setShowDeleteModal(false);
                                setCampaignToDelete(null);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                            onClick={handleConfirmDeleteCampaign}
                        >
                            Delete
                        </button>
                    </div>
                </Modal>
            )}
            {showMessageModal && (
                <Modal
                    title="Message"
                    isOpen={showMessageModal}
                    onClose={() => setShowMessageModal(false)}
                >
                    <p>{messageModalContent}</p>
                    <div className="flex justify-end mt-4">
                        <button
                            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            onClick={() => setShowMessageModal(false)}
                        >
                            OK
                        </button>
                    </div>
                </Modal>
            )}
            <div className="container mx-auto p-8">
                <PageHeader heading="Marketing Dashboard" context="Create / Edit Marketing Campaigns" />

                {/* Campaigns Section */}
                <div className="mb-12">
                    <MainTableV2
                        title={'Campaigns'}
                        columns={campaignColumns}
                        data={campaignsData}
                        primaryColor={theme.primary}
                        searchQuery={searchQueryCampaigns}
                        setSearchQuery={setSearchQueryCampaigns}
                        filterConfig={[
                            { key: 'CampaignType', type: 'string' },                            
                        ]}
                        children={
                            <div className="col-span-1 flex justify-end">
                                <Link
                                    className="themed-button"
                                    to={{
                                        pathname: `/campaigns`,
                                    }}
                                >
                                    New Campaign
                                </Link>
                            </div>
                        }
                    />
                </div>

                {/* Templates Section */}
                <div>
                    <MainTableV2
                        columns={templateColumns}
                        data={templatesData}
                        primaryColor={theme.primary}
                        title="Templates"
                        searchQuery={searchQueryTemplates}
                        setSearchQuery={setSearchQueryTemplates}
                        filterConfig={[
                            { key: 'name', type: 'string' },
                            { key: 'subject', type: 'string' }
                        ]}
                        children={
                            <div className="col-span-1 flex justify-end">
                                <Link
                                    className="themed-button"
                                    to={{
                                        pathname: `/emaileditor`,
                                    }}
                                    state={{ templateId: null }}  // Pass null for creating a new template
                                >
                                    New Template
                                </Link>
                            </div>
                        }
                    />
                </div>
            </div>
        </>
    );

};

export default Marketing;
