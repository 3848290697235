import React, { useState, useEffect } from "react";
import { useTheme } from "./ThemeContext";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const InvoicePaymentModal = ({
  isOpen,
  onClose,
  totalAmount,
  userId,
  paymentIntentId,
  centsAmount,
}) => {
  const { theme } = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [paymentMethodType, setPaymentMethodType] = useState(null);
  const currentLocation = window.location;
  const currentURL = `${currentLocation.protocol}//${currentLocation.host}/useraccounting`;
  const options = {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  };

  useEffect(() => {
    if (elements) {
      const paymentElement = elements.getElement(PaymentElement);
      if (paymentElement) {
        paymentElement.on("change", (event) => {
          setPaymentMethodType(event.value?.type || null);
          console.log("Payment Method: ", event.value?.type);
        });
      }
    }
  }, [elements]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe or Elements not initialized");
      return;
    }

    setLoading(true);

    try {
      console.log("Updating PaymentIntent... adding card fees");
      await updatePaymentIntentOnBackend(
        paymentIntentId,
        centsAmount,
        userId,
        paymentMethodType
      );

      // confirm the payment
      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        redirect: "always",
        confirmParams: {
          return_url: currentURL,
        },
      });

      if (error) {
        setResponseMessage(error.message);
        console.error("Error confirming payment:", error.message);
        return;
      }

      console.log("Payment confirmed:", paymentIntent);

      // Handle the result
      if (paymentIntent.status === "succeeded") {
        setResponseMessage("Payment Successful");
        console.log("Payment succeeded");
      } else {
        setResponseMessage("Payment processing");
        console.log("Payment processing");
      }
    } catch (error) {
      setResponseMessage("An error occurred while processing the payment");
      console.error("An error occurred while processing the payment:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) return null;

  const updatePaymentIntentOnBackend = async (
    paymentIntentId,
    centsAmount,
    userId,
    paymentMethodType
  ) => {
    try {
      const response = await fetch(
        "https://fuellink-stripe.azurewebsites.net/api/processPaymentToPayInvoices?", 
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            paymentIntentId: paymentIntentId,
            centsAmount: centsAmount,
            paymentMethodType: paymentMethodType,
          }),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        console.log("PaymentIntent updated successfully:", data);
      } else {
        console.error("Failed to update PaymentIntent:", data);
        throw new Error("Failed to update PaymentIntent");
      }
    } catch (error) {
      console.error(
        "An error occurred while updating the PaymentIntent:",
        error
      );
      throw error;
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center payment-modal-overlay">
      <div className="w-96 p-6 bg-white rounded-lg shadow-lg payment-modal-content">
        <div
          style={{ borderBottomColor: theme.primary }}
          className="text-2xl font-semibold mb-4 border-b-4 pb-2"
        >
          Pay Selected Invoices
        </div>
        <p className="mb-4">
          You are about to pay a total of <b>${totalAmount.toFixed(2)}</b> for
          the selected invoices.
        </p>
        <p className="mb-4 text-sm">
              <b>Please note:</b> Depending on your card issuer or funding method, fund availability can vary. While some transactions process immediately, others may take up to two days.
            </p>
        <p className="mb-4">
        <b>
            A 3.5% credit card processing fee will be added to all credit card
            payments, ACH payment fee is $5.00.
          </b>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <PaymentElement options={options} />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="themed-button mr-2"
            >
              {loading ? "Processing..." : "Submit"}
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="px-4 py-2 rounded-md text-gray-700 border border-gray-300 hover:bg-gray-100"
            >
              Cancel
            </button>
          </div>
          <p className="mt-4 text-center text-red-500">{responseMessage}</p>
        </form>
      </div>
    </div>
  );
};

export default InvoicePaymentModal;
