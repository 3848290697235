import React, { useMemo, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import Spinner from "./Spinner";

const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
        cursor: pointer;
    }
`;

const StyledDataTable = styled(DataTable)`
    .rdt_TableRow {
        border-bottom: 1px solid #e5e5e5;
    }   
    .rdt_TableHeadRow {
        background-color: #EAEAEA;
    }
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => ( 
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Search"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        {/* <button type="button" onClick={onClear}>
            X
        </button> */}
    </>
);

const MainTableV2 = ({
    columns,
    data,
    primaryColor,
    loading,
    alertMessage,
    clearAlert,
    title,
    searchQuery,
    setSearchQuery,
    filterConfig,
    children,
    isNested = false
}) => {
    const [filterText, setFilterText] = useState(searchQuery);
    // console.log('Filtered Text: ', filterText);

    useEffect(() => {
        setSearchQuery(filterText); // Sync external search query changes with internal state
    }, [filterText, setSearchQuery]);

    useEffect(() => {
        setFilterText(searchQuery); // Sync internal state with external changes
    }, [searchQuery]);    

    const filteredItems = useMemo(() => {
        if (!searchQuery) return data;

        return data.filter(item => {
            return filterConfig.some(config => {
                let value;
                if (isNested && item.cardGroup) {
                    value = item.cardGroup[config.key];
                } else {
                    value = item[config.key];
                }
                if (!value) return false;

                const valueStr = config.type === 'number' ? value.toString() : value.toLowerCase();
                const queryStr = searchQuery.toLowerCase();

                return valueStr.includes(queryStr);
            });
        });
    }, [data, searchQuery, filterConfig, isNested]);
    

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
                setSearchQuery('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, setSearchQuery]);

    return (
        <>
            {loading && (
                <div className="fixed inset-0 z-10 flex items-center justify-center">
                    <Spinner />
                </div>
            )}
            {alertMessage && (
                <div
                    className={`alert p-4 rounded ${
                        alertMessage.startsWith("Successfully")
                            ? "bg-green-200 text-green-600"
                            : "bg-red-200 text-red-600"
                    } flex justify-between items-center`}
                >
                    {alertMessage}
                    <button onClick={clearAlert} className="text-lg font-bold">
                        ×
                    </button>
                </div>
            )}
            <div>
            <div className="flex flex-col space-y-4">
    <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">{title}</h1>
    </div>

    <div className="w-full">
        {children}
    </div>
</div>


                <StyledDataTable
                    columns={columns}
                    data={filteredItems}
                    primaryColor={primaryColor}
                    responsive={true}
                    pagination
                    paginationResetDefaultPage={filterText.length > 0}
                    persistTableHead
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    highlightOnHover
                    customStyles={{
                                        subHeader: {
                                          style: {
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            marginTop: '10px',
                                            borderTopLeftRadius: '5px',
                                            borderTopRightRadius: '5px',
                                            backgroundColor: primaryColor
                                          },
                                        },
                                        cells: {
                                          style: {
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                          },
                                        },
                                        pagination: {
                                            style: {
                                                borderBottomLeftRadius: '5px',
                                                borderBottomRightRadius: '5px'
                                            },
                                        }
                                      }}
                    conditionalRowStyles={[{
                        when: row => row.hide === 'sm',
                        style: {
                            '@media (max-width: 768px)': {
                                display: 'none',
                            },
                        },
                    }]}
                />
            </div>
        </>
    );
};

export default MainTableV2;

