import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ heading, context }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4">
      <h1 className="text-lg font-semibold text-gray-700">
        {heading}
      </h1>
      <p className="text-sm text-gray-600 mt-2">
        {context}
      </p>
    </div>
  );
};

PageHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired
};

export default PageHeader;