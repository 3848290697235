import React, { useState, useEffect, useMemo } from 'react';
import MainTableV2 from './MainTableV2';
import Spinner from './Spinner';

const LocationsModal = ({ onClose, locations }) => {
  const [updatedLocations, setUpdatedLocations] = useState(locations);
  const [message, setMessage] = useState(''); 
  const [searchQuery, setSearchQuery] = useState(''); 
  const [loading, setLoading] = useState(false); 

  const handleDiscountToggle = (locationID) => {
    setUpdatedLocations(prevLocations =>
      prevLocations.map(location =>
        location.locationID === locationID
          ? { ...location, noDiscount: !location.noDiscount }
          : location
      )
    );
  };

  const handleSubmit = async () => {
    setLoading(true);  
    setMessage('');  

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/updateLocationDiscounts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ locations: updatedLocations }),
      });

      if (response.ok) {
        setMessage('Locations updated successfully!'); 
      } else {
        const errorData = await response.json();
        setMessage(`Failed to update locations: ${errorData.message}`);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(updatedLocations);
  }, [updatedLocations]);

  // Filter the locations based on the search query
  const filteredLocations = useMemo(() => {
    if (!searchQuery) return updatedLocations;

    return updatedLocations.filter(location => {
      return (
        location.locationName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        location.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        location.state.toLowerCase().includes(searchQuery.toLowerCase()) ||
        location.locationID.toString().includes(searchQuery.toLowerCase()) ||
        (location.retailPrice && location.retailPrice.toString().includes(searchQuery.toLowerCase())) ||
        (location.costPrice && location.costPrice.toString().includes(searchQuery.toLowerCase()))
      );
    });
  }, [searchQuery, updatedLocations]);

  const columns = useMemo(() => [
    { name: 'Location ID', selector: row => row.locationID, sortable: true },
    { name: 'Location Name', selector: row => row.locationName, sortable: true },
    { name: 'City', selector: row => row.city, sortable: true },
    { name: 'State', selector: row => row.state, sortable: true },
    { name: 'Retail Price', selector: row => row.retailPrice, sortable: true },
    { name: 'Cost Price', selector: row => row.costPrice, sortable: true },
    { name: 'Margin', selector: row => (row.retailPrice - row.costPrice).toFixed(2), sortable: true }, // Margin column
    {
      name: 'No Discounts',
      cell: row => (
        <input
          type="checkbox"
          checked={row.noDiscount}
          onChange={() => handleDiscountToggle(row.locationID)}
        />
      ),
    },
  ], [handleDiscountToggle]);

  const filterConfig = [
    { key: 'locationID', type: 'number' },
    { key: 'locationName', type: 'string' },
    { key: 'city', type: 'string' },
    { key: 'state', type: 'string' },
    { key: 'retailPrice', type: 'number' },
    { key: 'costPrice', type: 'number' }
  ];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl w-full">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-bold">Locations</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="p-4 overflow-y-auto" style={{ maxHeight: '80vh' }}>
          {loading && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <Spinner />
            </div>
          )}

          {message && (
            <div
              className={`mb-4 p-4 rounded ${
                message.startsWith('Locations updated')
                  ? 'bg-green-200 text-green-600'
                  : 'bg-red-200 text-red-600'
              }`}
            >
              {message}
            </div>
          )}

          <MainTableV2
            columns={columns}
            data={filteredLocations}           
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery} 
            filterConfig={filterConfig} 
            loading={loading}
          />
        </div>

        <div className="p-4 border-t flex justify-end">
          <button onClick={handleSubmit} className="themed-button" disabled={loading}>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationsModal;
