import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import PageHeader from './PageHeader';
import MobileHeader from './MobileHeader';
import MainTableV2 from './MainTableV2';
import TransactionDetailsModal from './TransactionsDetailsModal';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment-timezone';

ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TransactionTableUser = () => {
  const { theme } = useTheme();
  const { currentUser } = useAuth();
  const primaryColor = theme.primary;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(moment().tz('America/Chicago').subtract(30, 'days').startOf('day').toDate());
  const [endDate, setEndDate] = useState(moment().tz('America/Chicago').endOf('day').toDate());
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [averageDiscount, setAverageDiscount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(() => {
    const savedState = localStorage.getItem('filtersOpen');
    return savedState ? JSON.parse(savedState) : true;
  });

  const clearAlert = () => setAlertMessage(null);
  const apiUrl= process.env.REACT_APP_API_URL;

  const filterConfig = [
    { key: 'TransactionId', type: 'string' },
    { key: 'ArNumber', type: 'string' },
    { key: 'CardNumber', type: 'string' },
    { key: 'locationName', type: 'string' },
    { key: 'locationstate', type: 'string' },
    { key: 'locationCity', type: 'string' },
    { key: 'UserAmountSum', type: 'number' }
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'getTransactions',
          payload: {
            userId: currentUser?.userId,
            startDate: moment(startDate).tz('America/Chicago').startOf('day').utc().format(),
            endDate: moment(endDate).tz('America/Chicago').endOf('day').utc().format(),
          }
        })
      });

      if (response.ok) {
        const fetchedData = await response.json();
        const sortedData = fetchedData.sort((a, b) => new Date(b.TransactionDate) - new Date(a.TransactionDate));
        setData(sortedData);
      } else {
        setAlertMessage(`Server Error: ${await response.text()}`);
      }
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Removed dependencies to prevent automatic re-fetch on date change

  const handleViewDetails = async (TransactionId) => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'getTransactionDetails',
          payload: { userId: currentUser?.userId, TransactionId }
        })
      });
  
      if (response.ok) {
        const data = await response.json();
        setTransactionDetails(data);
        setIsModalOpen(true);
      } else {
        setAlertMessage(`Server Error: ${await response.text()}`);
      }
    } catch (error) {
      setAlertMessage(`Error fetching transaction details: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  

  const filteredData = useMemo(() => {
    let filtered = data;
    let sum = 0;
    let discountSum = 0;

    // Text search filter
    if (searchQuery !== '') {
      filtered = data.filter((row) => {
        return Object.values(row).some((s) =>
          s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }

    // Date filter
    if (startDate || endDate) {
      filtered = filtered.filter(row => {
        const rowDate = new Date(row.TransactionDate);
        const validStartDate = startDate && !isNaN(startDate.getTime());
        const validEndDate = endDate && !isNaN(endDate.getTime());
        return (!validStartDate || rowDate >= startDate) && (!validEndDate || rowDate <= endDate);
      });
    }

    filtered.forEach(transaction => {
      // Ensure UserAmountSum is a valid number before adding to sum
      const userAmount = parseFloat(transaction.UserAmountSum);
      if (!isNaN(userAmount)) {
        sum += userAmount;
      }
      const userDiscount = parseFloat(transaction.AvgUserDiscount);
      if (!isNaN(userDiscount)) {
        discountSum += userDiscount;
      }
    });

    setTotalAmount(sum);
    setTotalTransactions(filtered.length);
    setTotalDiscount(discountSum);
    setAverageDiscount(discountSum / filtered.length || 0);

    return filtered;
  }, [searchQuery, data, startDate, endDate]);

  const topLocationData = useMemo(() => {
    const locationCount = {};

    filteredData.forEach(transaction => {
      const location = transaction.locationName;
      if (!locationCount[location]) {
        locationCount[location] = 0;
      }
      locationCount[location] += 1;
    });

    const topLocations = Object.entries(locationCount).sort((a, b) => b[1] - a[1]).slice(0, 5);

    return {
      labels: topLocations.map(l => l[0]),
      datasets: [{
        label: 'Top Locations by Transactions',
        data: topLocations.map(l => l[1]),
        backgroundColor: primaryColor,
      }]
    };
  }, [filteredData, primaryColor]);

  // Chart data preparation
  const last7DaysData = useMemo(() => {
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const date = moment().tz('America/Chicago').subtract(i, 'days').startOf('day').toDate();
      return { date: date.toISOString().split('T')[0], amount: 0 };
    }).reverse();

    filteredData.forEach(transaction => {
      const transactionDate = transaction.TransactionDate.split('T')[0];
      const day = last7Days.find(d => d.date === transactionDate);
      if (day) {
        day.amount += transaction.UserAmountSum;
      }
    });

    return {
      labels: last7Days.map(d => d.date).filter(date => new Date(date) <= new Date()), // filter out future dates
      datasets: [{
        label: 'Total Amount (Last 7 Days)',
        data: last7Days.map(d => d.amount),
        backgroundColor: primaryColor,
      }]
    };
  }, [filteredData, primaryColor]);

  const averageAmountPerTransactionData = useMemo(() => {
    const days = 30;
    const dataPoints = Array.from({ length: days }, (_, i) => {
      const date = moment().tz('America/Chicago').subtract(i, 'days').startOf('day').toDate();
      return { date: date.toISOString().split('T')[0], totalAmount: 0, count: 0 };
    }).reverse();

    filteredData.forEach(transaction => {
      const transactionDate = transaction.TransactionDate.split('T')[0];
      const day = dataPoints.find(d => d.date === transactionDate);
      if (day) {
        day.totalAmount += transaction.UserAmountSum;
        day.count += 1;
      }
    });

    return {
      labels: dataPoints.map(d => d.date).filter(date => new Date(date) <= new Date()), // filter out future dates
      datasets: [{
        label: 'Average Amount per Transaction (Last 30 Days)',
        data: dataPoints.map(d => (d.count ? d.totalAmount / d.count : 0)),
        backgroundColor: primaryColor,
        borderColor: primaryColor,
        fill: false,
      }]
    };
  }, [filteredData, primaryColor]);

  const averagePricePerGallonData = useMemo(() => {
    const days = 30;
    const dataPoints = Array.from({ length: days }, (_, i) => {
      const date = moment().tz('America/Chicago').subtract(i, 'days').startOf('day').toDate();
      return { date: date.toISOString().split('T')[0], totalPPU: 0, count: 0 };
    }).reverse();

    filteredData.forEach(transaction => {
      const transactionDate = transaction.TransactionDate.split('T')[0];
      const day = dataPoints.find(d => d.date === transactionDate);
      if (day) {
        day.totalPPU += transaction.AvgUserPPU;
        day.count += 1;
      }
    });

    return {
      labels: dataPoints.map(d => d.date).filter(date => new Date(date) <= new Date()), // filter out future dates
      datasets: [{
        label: 'Average Price per Gallon (Last 30 Days)',
        data: dataPoints.map(d => (d.count ? d.totalPPU / d.count : 0)),
        backgroundColor: primaryColor,
        borderColor: primaryColor,
        fill: false,
      }]
    };
  }, [filteredData, primaryColor]);

  const columns = useMemo(() => [
    {
      name: "Date",
      selector: (row) => {
        return moment(row.TransactionDate).tz('America/Chicago').format('YYYY-MM-DD HH:mm:ss');
      },
      sortable: true,
      width: '160px'
    },
    { 
      name: 'Transaction ID', 
      selector: row => row.TransactionId,
      sortable: true,
      hide: 'sm',
    },
    { 
      name: 'Location', 
      selector: row => row.locationName,
      sortable: true,           
    },
    { 
      name: 'City', 
      selector: row => row.locationCity,
      sortable: true,      
    },
    { 
      name: 'State', 
      selector: row => row.locationstate,
      sortable: true,      
    },
    {
      name: 'Amount',
      selector: row => `$${parseFloat(row.UserAmountSum).toFixed(2)}`,
      sortable: true,
      width: '100px',
    },
    {
      name: 'View Details',
      cell: (row) => (
        <button
          className="themed-button"
          onClick={() => handleViewDetails(row.TransactionId)}
        >
          View
        </button>
      ),
      ignoreRowClick: false,       
    }
  ], [handleViewDetails]);

  const toggleFilters = () => {
    const newState = !isFiltersOpen;
    setIsFiltersOpen(newState);
    localStorage.setItem('filtersOpen', JSON.stringify(newState));
  };

  return (
    <>
      <MobileHeader theme={theme} />
      <div className='mobile-container'>
        <PageHeader
          heading="Fuel Transactions"
          context="View and search transaction details."
        />

        <MainTableV2
          columns={columns}
          data={filteredData}
          primaryColor={primaryColor}
          loading={loading}
          alertMessage={alertMessage}
          clearAlert={clearAlert}
          title="Transactions"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filterConfig={filterConfig}
        >
          <div className="bg-white rounded-lg shadow-md p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Filters & Summary</h2>
              <button onClick={toggleFilters} className="themed-button">
                {isFiltersOpen ? 'Hide' : 'Show'}
              </button>
            </div>
            {isFiltersOpen && (
              <div className="flex flex-col space-y-4">
                {/* Summary Cards */}
                <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                  <div className="bg-gray-100 rounded-lg p-4 shadow-sm flex flex-col items-center">
                    <span className="text-md font-semibold">Total Amount</span>
                    <span className="text-green-500 text-lg font-bold">${totalAmount.toFixed(2)}</span>
                  </div>
                  <div className="bg-gray-100 rounded-lg p-4 shadow-sm flex flex-col items-center">
                    <span className="text-md font-semibold">Total Transactions</span>
                    <span className="text-blue-500 text-lg font-bold">{totalTransactions}</span>
                  </div>
                  <div className="bg-gray-100 rounded-lg p-4 shadow-sm flex flex-col items-center">
                    <span className="text-md font-semibold">Average Discount</span>
                    <span className="text-purple-500 text-lg font-bold">${averageDiscount.toFixed(2)}</span>
                  </div>
                  <div className="bg-gray-100 rounded-lg p-4 shadow-sm flex flex-col items-center">
                    <span className="text-md font-semibold">Total Discount</span>
                    <span className="text-yellow-500 text-lg font-bold">${totalDiscount.toFixed(2)}</span>
                  </div>
                  <div className="bg-gray-100 rounded-lg p-4 shadow-sm flex flex-col items-center">
                    <span className="text-md font-semibold">Top Location</span>
                    <span className="text-red-500 text-lg font-bold">{topLocationData.labels[0]}</span>
                  </div>
                </div>
                
                {/* Charts Section */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="bg-gray-100 rounded-lg p-4 shadow-sm">
                    <h3 className="text-center font-semibold mb-2">Average Amount per Transaction (Last 30 Days)</h3>
                    <Line data={averageAmountPerTransactionData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
                  </div>
                  <div className="bg-gray-100 rounded-lg p-4 shadow-sm">
                    <h3 className="text-center font-semibold mb-2">Total Amount (Last 7 Days)</h3>
                    <Bar data={last7DaysData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
                  </div>
                  <div className="bg-gray-100 rounded-lg p-4 shadow-sm">
                    <h3 className="text-center font-semibold mb-2">Average Price per Gallon (Last 30 Days)</h3>
                    <Line data={averagePricePerGallonData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
                  </div>
                </div>

                {/* Filters Section */}
                <div className="flex flex-col md:flex-row md:space-x-4">
                  <div className="flex flex-col md:flex-row md:space-x-4 w-full">
                    <div className="w-full md:w-5/12">
                      <label className="text-md font-semibold">Start Date:
                        <input
                          type="date"
                          value={moment(startDate).format('YYYY-MM-DD')}
                          onChange={(e) => setStartDate(moment(e.target.value).toDate())}
                          className="border rounded p-2 mt-1 w-full"
                        />
                      </label>
                    </div>
                    <div className="w-full md:w-5/12">
                      <label className="text-md font-semibold">End Date:
                        <input
                          type="date"
                          value={moment(endDate).format('YYYY-MM-DD')}
                          onChange={(e) => setEndDate(moment(e.target.value).toDate())}
                          className="border rounded p-2 mt-1 w-full"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="flex items-end w-full md:w-2/12">
                    <button
                      className="themed-button w-full md:w-auto md:min-w-[150px] mt-2"
                      onClick={fetchData}
                    >
                      Apply Filters
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </MainTableV2>
        <TransactionDetailsModal
          isOpen={isModalOpen}
          details={transactionDetails}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </>
  );
};

export default TransactionTableUser;


