import React, {useEffect} from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import Spinner from "./Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEnvelope, faCheck, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';


const MainTableAdminInvoices = ({
  columns,
  data,
  primaryColor,
  loading,
  alertMessage,
  clearAlert,
  title,
  searchQuery,
  setSearchQuery,
  children,
//   onViewPdf,
//   onEmailInvoice,
//   onMarkAsPaid,
//   onEditInvoice,
//   onDeleteInvoice,
//   openDropdown,
//   toggleDropdown,
  currentPage,
    setCurrentPage,
    sortState,
    setSortState,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setSortBy,
    state: { sortBy },
  } = useTable(
            {
                columns,
                data,
                initialState: { 
                    pageIndex: currentPage, 
                    pageSize: 10,
                    sortBy: sortState,
                },
            },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    if (pageIndex !== currentPage) {
        gotoPage(currentPage);
    }
}, [currentPage, pageIndex, gotoPage]);

useEffect(() => {
    if (JSON.stringify(sortBy) !== JSON.stringify(sortState)) {
        setSortState(sortBy);
    }
}, [sortBy, sortState, setSortState]);

const handlePageChange = (newPageIndex) => {
    const correctedPageIndex = Math.max(0, Math.min(newPageIndex, pageOptions.length - 1));
    setCurrentPage(correctedPageIndex);
    gotoPage(correctedPageIndex);
};


  return (
    <>
    
      {loading && (
        <div className="fixed inset-0 z-10 flex items-center justify-center table-invoice-admin-container">
          <Spinner />
        </div>
      )}
      {alertMessage && (
        <div
          className={`alert p-4 rounded ${
            alertMessage.startsWith("Successfully")
              ? "bg-green-200 text-green-600"
              : "bg-red-200 text-red-600"
          } flex justify-between items-center`}
        >
          {alertMessage}
          <button onClick={clearAlert} className="text-lg font-bold">
            ×
          </button>
        </div>
      )}
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-semibold">{title}</h1>
          <div className="flex items-center">
            {/* <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border rounded p-2 mr-2"
            /> */}
            {children}
          </div>
        </div>
        <div className="rounded-lg overflow-hidden shadow">
          <table
            {...getTableProps()}
            className="min-w-full divide-y divide-gray-200 adminInvoiceTable"
          >
            <thead
              className="bg-gray-50"
              style={{ backgroundColor: primaryColor }}
            >
              {headerGroups.map((headerGroup) => (
  <tr {...headerGroup.getHeaderGroupProps()}>
    {headerGroup.headers.map((column, index, array) => (
      <th
      {...column.getHeaderProps(
        column.id !== 'selection' ? column.getSortByToggleProps() : {}
      )}
      className={`px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider 
        ${index === 0 ? "rounded-tl-lg" : ""} 
        ${index === array.length - 1 ? "rounded-tr-lg" : ""}`}
    >
      {column.render("Header")}
      {column.id !== 'selection' && (  // Exclude the sort arrows for the "Select" column based on its id
        <span style={{ fontSize: "1.5em" }}>
          {column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : " ↑↓"}
        </span>
      )}
    </th>
    ))}
  </tr>
))}

            </thead>
            <tbody
              {...getTableBodyProps()}
              className="bg-white divide-y divide-gray-200 admin-invoice-table-body"
              
            >
              {page.length === 0 ? (
                <tr>
                  <td
                    colSpan={columns.length}
                    className="px-6 py-4 text-center"
                  >
                    {searchQuery
                      ? "No matching records found"
                      : "No data found"}
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-start items-center mt-4">
  <div className="space-x-2">
    <button
      onClick={() => handlePageChange(0)}
      disabled={!canPreviousPage}
      style={{ backgroundColor: primaryColor }}
      className={`px-4 py-2 rounded text-white ${
        !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      {"<<"}
    </button>
    <button
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={!canPreviousPage}
      style={{ backgroundColor: primaryColor }}
      className={`px-4 py-2 rounded text-white ${
        !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      {"<"}
    </button>
    <button
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={!canNextPage}
      style={{ backgroundColor: primaryColor }}
      className={`px-4 py-2 rounded text-white ${
        !canNextPage ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      {">"}
    </button>
    <button
      onClick={() => handlePageChange(pageOptions.length - 1)}
      disabled={!canNextPage}
      style={{ backgroundColor: primaryColor }}
      className={`px-4 py-2 rounded text-white ${
        !canNextPage ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      {">>"}
    </button>
    <span>
      Page{" "}
      <strong>
        {currentPage + 1} of {pageOptions.length}
      </strong>{" "}
      ({data.length} entries)
    </span>
  </div>
</div>

      </div>
    </>
  );
};

export default MainTableAdminInvoices;
