import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import Spinner from './Spinner';

const EditTOSModal = ({ isOpen, onClose, onSave, tosRecord, isEditable }) => {
    const [content, setContent] = useState(tosRecord.Content);
    const [error, setError] = useState(null);
    const { currentUser } = useAuth();
    const subDomain = window.location.hostname.split('.')[0];
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getTOSRecords', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: currentUser.userId,
                    subDomain,
                    requestType: 'edit',
                    content,
                    version: tosRecord.Version,
                    recordId: tosRecord.Id
                })
            });
            const result = await response.json();
            if (response.ok) {
                onSave(result);
                onClose();
            } else {
                setError(result.error || 'Failed to save TOS');
            }
        } catch (error) {
            setError('Error saving TOS: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <Spinner />}
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-md w-3/4 max-w-lg">
                        <h2 className="text-2xl mb-4">{isEditable ? 'Edit Terms of Service' : 'View Terms of Service'}</h2>
                        {error && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                                <span className="block sm:inline">{error}</span>
                            </div>
                        )}
                        <textarea
                            className="w-full h-64 p-2 border rounded mb-4"
                            placeholder="Enter Terms of Service content here..."
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            readOnly={!isEditable}
                        />
                        <input
                            type="text"
                            className="w-full p-2 border rounded mb-4"
                            placeholder="Version"
                            value={tosRecord.Version}
                            readOnly
                        />
                        <div className="flex justify-end">
                            <button onClick={onClose} className="themed-button mr-2">Close</button>
                            {isEditable && <button onClick={handleSave} className="themed-button">Save</button>}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditTOSModal;
