import React, { useState, useEffect, useMemo } from "react";
import { useTheme } from "./ThemeContext";
import { useAuth } from "./AuthContext";
import MainTableV2 from "./MainTableV2";
import Spinner from "./Spinner";
import PageHeader from "./PageHeader";
import MobileHeader from './MobileHeader';
import { CSVLink } from "react-csv";
import BalanceCard from "./BalanceCard";
import { useSelector } from 'react-redux';

const AccountingArea = () => {
  const { theme } = useTheme();
  const primaryColor = theme.primary;
  const [financialTransactionData, setFinancialTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { currentUser } = useAuth();

  const clearAlert = () => setAlertMessage(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const cardGroups = useSelector((state) => state.policies.cardGroups);
  const [selectedAccountNumber, setSelectedAccountNumber] = useState(currentUser?.cardGroupID);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching data...", selectedAccountNumber);
      if (!selectedAccountNumber && currentUser.hasSubaccounts) return;
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/dataServer`, { 
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            action: 'getFinancialTransactions',
            payload: {
              userId: currentUser?.userId,
              cardGroupID: selectedAccountNumber,
            }
          })
        });

        if (response.ok) {
          const fetchedData = await response.json();
          setFinancialTransactionData(fetchedData.financialTransactions);
          console.log("Fetched Data: ", fetchedData);
        } else {
          setAlertMessage(`Server Error: ${await response.text()}`);
        }
      } catch (error) {
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (!currentUser?.hasSubaccounts) {
      fetchData();
    } else if (currentUser?.hasSubaccounts && selectedAccountNumber) {
      fetchData();
    }
  }, [selectedAccountNumber]);

  const financialTransactionColumns = useMemo(() => [
    {
      name: 'Transaction ID',
      selector: row => row.TransactionId,
      reorder: true,
      hide: 'sm'
    },
    {
      name: 'Date',
      selector: row => {
        const date = new Date(row.Date);
        const formattedDate = date.toLocaleDateString("en-US");
        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
        return `${formattedDate} ${formattedTime}`;
      },
      reorder: true,
    },
    {
      name: 'Amount',
      selector: row => `$${parseFloat(row.Amount).toFixed(2)}`,
      reorder: true,
      hide: 'sm'
    },
    {
      name: 'Fees',
      selector: row => `$${parseFloat(row.Fees).toFixed(2)}`,
      reorder: true,
      hide: 'sm'
    },
    {
      name: 'Total Amount',
      selector: row => `$${parseFloat(row.TotalAmount).toFixed(2)}`,
      reorder: true,
    },
    {
      name: 'Type',
      selector: row => row.Type,
      reorder: true,
    },
    {
      name: 'Status',
      selector: row => row.Status,
      reorder: true,
      cell: row => (
        <span
          className={`px-3 py-1 rounded-full text-sm font-semibold ${
            row.Status === "Completed"
              ? "bg-green-200 text-green-800"
              : row.Status === "Pending"
              ? "bg-yellow-200 text-yellow-800"
              : row.Status === "Failed"
              ? "bg-red-200 text-red-800"
              : "bg-gray-200 text-gray-800"
          }`}
        >
          {row.Status}
        </span>
      ),
    },
    {
      name: 'Balance After',
      selector: row => row.Status === 'Pending' ? 'Processing' : `$${parseFloat(row.BalanceAfter).toFixed(2)}`,
      reorder: true,
    },
  ], []);

  const sortedFinancialTransactionData = useMemo(() => {
    return financialTransactionData.slice().sort((a, b) => {
      if (a.Status === 'Pending' && b.Status !== 'Pending') return -1;
      if (a.Status !== 'Pending' && b.Status === 'Pending') return 1;
      return b.TransactionId - a.TransactionId;
    });
  }, [financialTransactionData]);

  // Prepare data for CSV export
  const csvData = useMemo(() => {
    return financialTransactionData.map(row => ({
      TransactionId: row.TransactionId,
      Date: new Date(row.Date).toLocaleString("en-US"),
      Amount: parseFloat(row.Amount).toFixed(2),
      Fees: parseFloat(row.Fees).toFixed(2),
      TotalAmount: parseFloat(row.TotalAmount).toFixed(2),
      Type: row.Type,
      Status: row.Status,
      BalanceAfter: row.Status === 'Pending' ? 'Processing' : parseFloat(row.BalanceAfter).toFixed(2),
    }));
  }, [financialTransactionData]);

  // Determine if we should display the cardGroup selector
  const shouldShowCardGroupSelector = currentUser?.hasSubaccounts && !currentUser?.cardGroupID && (currentUser?.isPolicyAdmin || currentUser?.isContractAdmin || currentUser?.isAdmin);

  return (
    <>
      <MobileHeader theme={theme} />
      {loading && <Spinner />}
      <div className="mobile-container">
        <PageHeader
          heading="Account Balance and Transactions"
          context="Monitor your account balance and keep track of all credits and debits."
        />
        <div className="my-4 p-2">
          <BalanceCard theme={theme} />
        </div>
        
        {shouldShowCardGroupSelector && (
          <div className="mb-4">
            <label htmlFor="cardGroupSelector" className="block text-sm font-medium text-gray-700 mb-1">Select an Account</label>
            <select
              id="cardGroupSelector"
              name="cardGroupSelector"
              value={selectedAccountNumber || ""}
              onChange={(e) => setSelectedAccountNumber(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select an Account</option>
              {cardGroups.map((group) => (
                <option key={group.cardGroupID} value={group.cardGroupID}>
                  {group.AccountNumber} - {group.groupName}
                </option>
              ))}
            </select>
          </div>
        )}
        
        {alertMessage && (
          <div className="alert p-4 rounded bg-red-200 text-red-600 flex justify-between items-center">
            {alertMessage}
            <button onClick={clearAlert} className="text-lg font-bold">×</button>
          </div>
        )}

        {shouldShowCardGroupSelector && !selectedAccountNumber && (
          <div className="flex justify-center">
            <p className="text-sm text-red-500">
              Please select an account to view transactions.
            </p>
          </div>
        )}

        {(!shouldShowCardGroupSelector || selectedAccountNumber) && (
          <>
            <div className="flex justify-between items-center mb-4">
              <CSVLink data={csvData} filename={"financial_transactions.csv"} className="themed-button">
                Export as CSV
              </CSVLink>
            </div>
            <MainTableV2
              columns={financialTransactionColumns}
              data={sortedFinancialTransactionData}
              primaryColor={primaryColor}
              loading={loading}
              alertMessage={alertMessage}
              clearAlert={clearAlert}
              title=""
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              filterConfig={[
                { key: "TransactionId", type: "number" },
                { key: "Date", type: "date" },
                { key: "Amount", type: "number" },
                { key: "Fees", type: "number" },
                { key: "TotalAmount", type: "number" },
                { key: "Type", type: "string" },
                { key: "Status", type: "string" },
                { key: "BalanceAfter", type: "number" },
              ]}
            />
          </>
        )}
      </div>
    </>
  );
};

export default AccountingArea;



// import React, { useState, useEffect, useMemo } from "react";
// import { useTheme } from "./ThemeContext";
// import { useAuth } from "./AuthContext";
// import MainTableV2 from "./MainTableV2";
// import Spinner from "./Spinner";
// import PageHeader from "./PageHeader";
// import MobileHeader from './MobileHeader';
// import { CSVLink } from "react-csv";
// import BalanceCard from "./BalanceCard";

// const AccountingArea = () => {
//   const { theme } = useTheme();
//   const primaryColor = theme.primary;
//   const [financialTransactionData, setFinancialTransactionData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [alertMessage, setAlertMessage] = useState(null);
//   const [searchQuery, setSearchQuery] = useState("");
//   const { currentUser } = useAuth();

//   const clearAlert = () => setAlertMessage(null);
//   const apiUrl = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(`${apiUrl}/dataServer`, { 
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             action: 'getFinancialTransactions',
//             payload: {
//               userId: currentUser?.userId,
//             }
//           })
//         });

//         if (response.ok) {
//           const fetchedData = await response.json();
//           setFinancialTransactionData(fetchedData.financialTransactions);
//           // console.log("Fetched Data: ", fetchedData);
//         } else {
//           setAlertMessage(`Server Error: ${await response.text()}`);
//         }
//       } catch (error) {
//         setAlertMessage(`Error: ${error.message}`);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (currentUser?.userId) {
//       fetchData();
//     }
//   }, [currentUser?.userId]);

//   const financialTransactionColumns = useMemo(() => [
//     {
//       name: 'Transaction ID',
//       selector: row => row.TransactionId,
//       reorder: true,
//       hide: 'sm'
//     },
//     {
//       name: 'Date',
//       selector: row => {
//         const date = new Date(row.Date);
//         const formattedDate = date.toLocaleDateString("en-US");
//         const formattedTime = date.toLocaleTimeString("en-US", {
//           hour: "2-digit",
//           minute: "2-digit",
//           second: "2-digit",
//           hour12: true,
//         });
//         return `${formattedDate} ${formattedTime}`;
//       },
//       reorder: true,
//     },
//     {
//       name: 'Amount',
//       selector: row => `$${parseFloat(row.Amount).toFixed(2)}`,
//       reorder: true,
//       hide: 'sm'
//     },
//     {
//       name: 'Fees',
//       selector: row => `$${parseFloat(row.Fees).toFixed(2)}`,
//       reorder: true,
//       hide: 'sm'
//     },
//     {
//       name: 'Total Amount',
//       selector: row => `$${parseFloat(row.TotalAmount).toFixed(2)}`,
//       reorder: true,
//     },
//     {
//       name: 'Type',
//       selector: row => row.Type,
//       reorder: true,
//     },
//     {
//       name: 'Status',
//       selector: row => row.Status,
//       reorder: true,
//       cell: row => (
//         <span
//           className={`px-3 py-1 rounded-full text-sm font-semibold ${
//             row.Status === "Completed"
//               ? "bg-green-200 text-green-800"
//               : row.Status === "Pending"
//               ? "bg-yellow-200 text-yellow-800"
//               : row.Status === "Failed"
//               ? "bg-red-200 text-red-800"
//               : "bg-gray-200 text-gray-800" 
//           }`}
//         >
//           {row.Status}
//         </span>
//       ),
//     },
//     {
//       name: 'Balance After',
//       selector: row => row.Status === 'Pending' ? 'Processing' : `$${parseFloat(row.BalanceAfter).toFixed(2)}`,
//       reorder: true,
//     },
//   ], []);

//   const sortedFinancialTransactionData = useMemo(() => {
//     return financialTransactionData.slice().sort((a, b) => {
//       if (a.Status === 'Pending' && b.Status !== 'Pending') return -1;
//       if (a.Status !== 'Pending' && b.Status === 'Pending') return 1;
//       return b.TransactionId - a.TransactionId;
//     });
//   }, [financialTransactionData]);

//   // Prepare data for CSV export
//   const csvData = useMemo(() => {
//     return financialTransactionData.map(row => ({
//       TransactionId: row.TransactionId,
//       Date: new Date(row.Date).toLocaleString("en-US"),
//       Amount: parseFloat(row.Amount).toFixed(2),
//       Fees: parseFloat(row.Fees).toFixed(2),
//       TotalAmount: parseFloat(row.TotalAmount).toFixed(2),
//       Type: row.Type,
//       Status: row.Status,
//       BalanceAfter: row.Status === 'Pending' ? 'Processing' : parseFloat(row.BalanceAfter).toFixed(2),
//     }));
//   }, [financialTransactionData]);

//   return (
//     <>
//       <MobileHeader theme={theme} />
//       {loading && <Spinner />}
//       <div className="mobile-container">
//         <PageHeader
//           heading="Account Balance and Transactions"
//           context="Monitor your account balance and keep track of all credits and debits."
//         />
//         <div className="my-8 p-2">
//           <BalanceCard theme={theme} />
//         </div>
      

//         <div>
//           {loading && <Spinner />}
//           {alertMessage && (
//             <div>
//               {alertMessage}
//               <button onClick={clearAlert}>Clear</button>
//             </div>
//           )}
//           <div className="flex justify-between items-center">
//             {/* <h2 className="text-xl font-semibold">Financial Transactions</h2> */}
//             <CSVLink data={csvData} filename={"financial_transactions.csv"} className="themed-button">
//               Export as CSV
//             </CSVLink>
//           </div>
//           <MainTableV2
//             columns={financialTransactionColumns}
//             data={sortedFinancialTransactionData}
//             primaryColor={primaryColor}
//             loading={loading}
//             alertMessage={alertMessage}
//             clearAlert={clearAlert}
//             title=""
//             searchQuery={searchQuery}
//             setSearchQuery={setSearchQuery}
//             filterConfig={[
//               { key: "TransactionId", type: "number" },
//               { key: "Date", type: "date" },
//               { key: "Amount", type: "number" },
//               { key: "Fees", type: "number" },
//               { key: "TotalAmount", type: "number" },
//               { key: "Type", type: "string" },
//               { key: "Status", type: "string" },
//               { key: "BalanceAfter", type: "number" },
//             ]}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default AccountingArea;
