import React, { useState } from 'react';
import Card from './Card';

const DiscountChart = ({ data, theme }) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState('Last 30 Days');

  const timeRanges = ['Last 30 Days', 'Last 14 Days', 'Last 7 Days' ];

  const handleTimeRangeChange = (e) => {
    setSelectedTimeRange(e.target.value);
  };

  const getDiscountAmount = () => {
    if (!data) {
      return 0;
    }
    let amount;
    switch (selectedTimeRange) {
      case 'Last 7 Days':
        amount = data['Total Discount Last 7 Days'];
        break;
      case 'Last 14 Days':
        amount = data['Total Discount Last 14 Days'];
        break;
      case 'Last 30 Days':
        default:
        amount = data['Total Discount Last 30 Days'];
        break;       
    }
    return amount !== undefined && amount !== null ? amount : 0;
  };
  

  const discountAmount = getDiscountAmount();

  return (
    <Card title="Total Savings" subtitle={selectedTimeRange} theme={theme}>
      <div className="p-2 rounded-lg shadow-lg text-center" style={{ backgroundColor: theme.primary }}>
        
        <select 
          id="timeRange" 
          onChange={handleTimeRangeChange} 
          value={selectedTimeRange}
          className="ml-2 p-1 rounded border"
        >
          {timeRanges.map((range) => (
            <option key={range} value={range}>
              {range}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-6 text-center">
        <h2 className="text-2xl font-bold mt-4">
          {discountAmount !== null ? (
            `$${discountAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          ) : (
            "No Data Available"
          )}
        </h2>
      </div>
    </Card>
  );
};

export default DiscountChart;
