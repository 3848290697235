import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import SubTableV2 from './SubTableV2';
import Spinner from './Spinner';

const SalesRepModal = ({ show, onClose, onAdd, onAssign, currentUser, selectedUser, salesReps, setSalesReps, onEdit, onDelete, setUsers }) => {
  const [showAddRepModal, setShowAddRepModal] = useState(false);
  const [showDeleteRepModal, setShowDeleteRepModal] = useState(false);
  const [newRep, setNewRep] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: ''
  });
  const [selectedRep, setSelectedRep] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [replaceRepId, setReplaceRepId] = useState('');
  const [repToDelete, setRepToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAddRep = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onAdd(newRep);
      setShowAddRepModal(false);
    } catch (err) {
      setError(`Error adding sales rep: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEditRep = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onEdit(newRep);
      setShowAddRepModal(false);
    } catch (err) {
      setError(`Error editing sales rep: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (rep) => {
    setNewRep(rep);
    setIsEditMode(true);
    setShowAddRepModal(true);
  };

  const handleAssignClick = (repId) => {
    setSelectedRep(repId);
  };

  const handleAssignRep = async () => {
    setLoading(true);
    try {
      if (selectedRep) {
        await onAssign(selectedRep);
        onClose();
      }
    } catch (err) {
      setError(`Error assigning sales rep: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (rep) => {
    setRepToDelete(rep);
    setShowDeleteRepModal(true);
  };

  const handleDeleteRep = async () => {
    setLoading(true);
    try {
      await onDelete(repToDelete, replaceRepId);
      setShowDeleteRepModal(false);
      setError('')
    } catch (err) {
      setError(`Error deleting sales rep: ${err.message}`);
    } finally {
      setLoading(false);      
    }
  };

  const filteredSalesReps = salesReps.filter(rep => rep.Id !== repToDelete?.Id);

  const columns = [
    {
      name: 'Select',
      cell: row => (
        <input
          type="radio"
          name="selectedRep"
          value={row.Id}
          onClick={() => handleAssignClick(row.Id)}
        />
      ),
      ignoreRowClick: true,
    },
    {
      name: 'First Name',
      selector: row => row.FirstName,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row.LastName,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.Email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: row => row.Phone,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <>
          <FontAwesomeIcon icon={faEdit} className="ml-2 text-blue-500 cursor-pointer" onClick={() => handleEditClick(row)} />
          <FontAwesomeIcon icon={faTrash} className="ml-2 text-red-500 cursor-pointer" onClick={() => handleDeleteClick(row)} />
        </>
      ),
      ignoreRowClick: true,
    },
  ];

  return (
    <>
      {show && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-3xl mx-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl">Assign Sales Rep</h2>
              <button
                onClick={() => {
                  setNewRep({ FirstName: '', LastName: '', Email: '', Phone: '' });
                  setIsEditMode(false);
                  setShowAddRepModal(true);
                }}
                className="bg-green-500 text-white p-2 rounded"
              >
                <FontAwesomeIcon icon={faPlus} /> Add Sales Rep
              </button>
            </div>
            {error && <div className="alert bg-red-200 text-red-600 p-4 mb-4">{error}</div>}
            <div className="mb-4 max-h-60 overflow-y-auto">
              <SubTableV2
                columns={columns}
                data={salesReps}
                loading={false}
                alertMessage={null}
                clearAlert={() => {}}
                title="Sales Reps"
              />
            </div>
            {loading && <Spinner />}
            <div className="flex justify-end space-x-4">
              <button
                onClick={onClose}
                className="bg-red-500 text-white p-2 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleAssignRep}
                className="themed-button"
              >
                Assign Selected Rep
              </button>
            </div>
          </div>
        </div>
      )}

      {showAddRepModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md mx-auto">
            <h2 className="text-xl mb-4 text-center">{isEditMode ? 'Edit Sales Rep' : 'Add Sales Rep'}</h2>
            {loading && <Spinner />}
            {error && <div className="alert bg-red-200 text-red-600 p-4 mb-4">{error}</div>}
            <form onSubmit={isEditMode ? handleEditRep : handleAddRep}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  name="FirstName"
                  className="mt-1 p-2 block w-full border rounded-md"
                  value={newRep.FirstName}
                  onChange={e => setNewRep({ ...newRep, FirstName: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  name="LastName"
                  className="mt-1 p-2 block w-full border rounded-md"
                  value={newRep.LastName}
                  onChange={e => setNewRep({ ...newRep, LastName: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="Email"
                  className="mt-1 p-2 block w-full border rounded-md"
                  value={newRep.Email}
                  onChange={e => setNewRep({ ...newRep, Email: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="text"
                  name="Phone"
                  className="mt-1 p-2 block w-full border rounded-md"
                  value={newRep.Phone}
                  onChange={e => setNewRep({ ...newRep, Phone: e.target.value })}
                />
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => setShowAddRepModal(false)}
                  className="bg-red-500 text-white p-2 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="themed-button"
                >
                  {isEditMode ? 'Update' : 'Add'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showDeleteRepModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md mx-auto">
            <h2 className="text-xl mb-4 text-center">Delete Sales Rep</h2>
            {error && <div className="alert bg-red-200 text-red-600 p-4 mb-4">{error}</div>}
            <p className="mb-4 text-center">Are you sure you want to delete this sales rep? This action cannot be undone.</p>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Reassign to another Sales Rep (optional)</label>
              <select
                className="mt-1 p-2 block w-full border rounded-md"
                value={replaceRepId}
                onChange={e => setReplaceRepId(e.target.value)}
              >
                <option value="">None</option>
                {filteredSalesReps.map(rep => (
                  <option key={rep.Id} value={rep.Id}>
                    {rep.FirstName} {rep.LastName}
                  </option>
                ))}
              </select>
            </div>
            {loading && <Spinner />}
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteRepModal(false)}
                className="bg-gray-500 text-white p-2 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteRep}
                className="bg-red-500 text-white p-2 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesRepModal;
