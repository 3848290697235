import React from 'react';
import { useAuth } from "./AuthContext";

function StripeRefreshLink() {
    const { currentUser } = useAuth();
    const handleFinishLinking = async () => {
        try {
            
            const userId = currentUser.userId;

            const response = await fetch('https://fuellink-stripe.azurewebsites.net/api/createNewConnectedAccount?', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId })
            });

            const data = await response.json();

            if (data && data.url) {
                window.location.href = data.url;
            } else {
                console.error('Failed to regenerate Stripe link.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <div className="bg-gray-100 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-md w-96">
                <h2 className="text-2xl font-bold mb-4">Session Expired</h2>
                <p className="mb-4">Your bank account linking session has expired. This could be due to:</p>
                <ul className="list-disc pl-5 mb-4">
                    <li>Session timeout due to inactivity.</li>
                    <li>Temporary issue with the Stripe platform.</li>
                    <li>Browser or connectivity issues.</li>
                    <li>Required information was not provided in time.</li>
                </ul>
                <p className="mb-4">Please click the button below to finish linking your bank account.</p>
                <div className="text-center">
                    <button 
                        onClick={handleFinishLinking} 
                        className="themed-button"
                    >
                        Finish Linking Your Bank Account
                    </button>
                </div>
            </div>
        </div>
    );
}

export default StripeRefreshLink;
