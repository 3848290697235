import React, { useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { setCardData, updateCardData } from "./cardSlice";
import SubTable from "./SubTable";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import MobileHeader from './MobileHeader';
import { useTheme } from './ThemeContext';
import SubTableV2 from "./SubTableV2";

const CardsAdminEditAdd = () => {
  const { theme } = useTheme();
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("success");
  const clearAlert = () => setAlertMessage(null);
  const cardData = useSelector((state) => state.card.cardData);
  const cardPrompts = useSelector((state) => state.card.cardData.combinedPrompts);
  console.log("card data: ", cardData);
  const policies = useSelector((state) => state.policies.policies);
  const combinedPrompts = useSelector(
    (state) => state.card.cardData.combinedPrompts
  );
  const combinedLimits = useSelector(
    (state) => state.card.cardData.combinedLimits
  );
  const [formData, setFormData] = useState({
    CardNumber: "",
    DriverId: "",
    DriverName: "",
    UnitNumber: "",
    CardStatus: "",
    HandEnter: "",
    Information: "",
  });

  // useEffect(() => {
  //   if (!cardData || Object.keys(cardData).length === 0) {
  //     navigate("/usercards");
  //   }
  // }, [cardData, navigate]);

  useEffect(() => {
    // const storedCardNumber = sessionStorage.getItem("editingCardNumber"); // Get card number from sessionStorage
    const currentCardNumber = cardData?.CardNumber; // Get card number from Redux store

    if (currentCardNumber == null) {
      navigate('/card_admin');
      return; // Exit the effect early
    }
  
    // Run the fetch if there is no cardData or if the stored number doesn't match the current number
    if (!cardPrompts ||!cardData || Object.keys(cardData).length === 0) {
      setIsLoading(true);
      const payload = {
        userId: currentUser.userId,
        cardNumber: currentCardNumber,
        policyNumber: cardData?.PolicyNumber,
      };
  
      fetch(
        `https://efs-getpolicy.azurewebsites.net/api/FuelLink-getCardAdmin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          dispatch(updateCardData(data));
          //sessionStorage.setItem("editingCardNumber", cardData.CardNumber);
          console.log("Card Data: ", data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    } else {
    }
  }, [dispatch, currentUser, cardData]);  

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedCardData;

    if (["DriverId", "DriverName", "UnitNumber"].includes(name)) {
      // If the changed field is one of the new fields, update the cardData directly
      updatedCardData = {
        ...cardData,
        [name]: value,
      };
    } else {
      // Otherwise, update the nested cardDetails property
      updatedCardData = {
        ...cardData,
        cardDetails: {
          ...cardData.cardDetails,
          result: {
            ...cardData.cardDetails.result,
            header: { ...cardData.cardDetails.result.header, [name]: value },
          },
        },
      };
    }

    dispatch(setCardData(updatedCardData));
  };

  const handlePromptEdit = (rowData) => {
    navigate("/EditCardPrompts", { state: { rowData } });
  };

  // Define function to handle editing of limits
  const handleLimitsEdit = (rowData) => {
    navigate("/EditCardLimits", { state: { rowData } });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(cardData);
    const payload = {
      userId: currentUser.userId,
      result: {
        cardNumber: cardData.CardNumber,
        header: cardData.cardDetails.result.header,
        infos: cardData.combinedPrompts || [],
        limits: cardData.combinedLimits || [],
        locationGroups: cardData.cardDetails.result.locationGroups || [],
        locations: cardData.cardDetails.result.locations || [],
        timeRestrictions: cardData.cardDetails.result.timeRestrictions || [],
      },
    };
    console.log("Payload: ", payload);
    try {
      const response = await fetch(
        `https://efs-getpolicy.azurewebsites.net/api/fuellink-efsSetCardData?`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      
      if (result && result.data) {
        // Dispatch the update to Redux
        dispatch(updateCardData({
          ...cardData, // Spread the existing cardData
          ...result.data // Override with the new data from the result
        }));
        // Set alert message for successful update
        setAlertType("success");
        setAlertMessage("Successfully updated the card data.");
        console.log("Response: ", result.data);
      }
    } catch (error) {
      setAlertType("error");
      setAlertMessage(`Failed to update card data: ${error.message}`);
      //console.error('Submission failed', error);
    } finally {
      setIsLoading(false);
    }
  };
//SubTableV1 columns
  // const combinedPromptsColumns = React.useMemo(
  //   () => [
  //     {
  //       Header: "Source",
  //       accessor: "Source",
  //     },
  //     {
  //       Header: "Info ID",
  //       accessor: "infoId",
  //     },
  //     {
  //       Header: "Length Check",
  //       accessor: "lengthCheck",
  //     },
  //     {
  //       Header: "Match Value",
  //       accessor: "matchValue",
  //     },
  //     {
  //       Header: "Maximum",
  //       accessor: "maximum",
  //     },
  //     {
  //       Header: "Minimum",
  //       accessor: "minimum",
  //     },
  //     {
  //       Header: "Report Value",
  //       accessor: "reportValue",
  //     },
  //     {
  //       Header: "Validation Type",
  //       accessor: "validationType",
  //     },
  //     {
  //       Header: "Value",
  //       accessor: "value",
  //     },
  //     {
  //       Header: "Edit",
  //       Cell: ({ row }) =>
  //         row.original.Source === "Card" ? (
  //           <button
  //             onClick={() => handlePromptEdit(row.original)}
  //             className="subTableEdit"
  //           >
  //             Edit
  //           </button>
  //         ) : null,
  //     },
  //   ],
  //   []
  // );
  
  const combinedPromptsColumns = React.useMemo(() => [
    {
        name: 'Source',
        selector: row => row.Source,
        sortable: true
    },
    {
        name: 'Info ID',
        selector: row => row.infoId,
        sortable: true
    },
    {
        name: 'Length Check',
        selector: row => row.lengthCheck,
        sortable: true,
        hide: 'sm'
        
    },
    {
        name: 'Match Value',
        selector: row => row.matchValue,
        sortable: true,
        hide: 'sm'
    },
    {
        name: 'Maximum',
        selector: row => row.maximum,
        sortable: true,
        hide: 'sm'
    },
    {
        name: 'Minimum',
        selector: row => row.minimum,
        sortable: true,
        hide: 'sm'
    },
    {
        name: 'Report Value',
        selector: row => row.reportValue,
        sortable: true,
        hide: 'sm'
    },
    {
        name: 'Validation Type',
        selector: row => row.validationType,
        sortable: true,
        hide: 'sm'
    },
    {
        name: 'Value',
        selector: row => row.value,
        sortable: true,
        hide: 'sm'
    },
    {
        name: 'Edit',        
        cell: row => row.Source === "Card" ? (
            <button
                onClick={() => handlePromptEdit(row)}
                className="subTableEdit"
            >
                Edit
            </button>
        ) : null
    },
], [handlePromptEdit]);

  // Define columns for the combinedLimits subtable
  //NOT OPTIMIZED FOR MOBILE WITH V2 SUBTABLE **UPDATE BEFORE INITIALIZING**
  const combinedLimitsColumns = React.useMemo(
    () => [
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Limit Id",
        accessor: "limitId",
      },
      {
        Header: "Amount",
        accessor: "limit",
      },
      {
        Header: "Hours",
        accessor: "hours",
      },
      {
        Header: "Auto Roll Map",
        accessor: "autoRollMap",
      },
      {
        Header: "Auto Roll Max",
        accessor: "autoRollMax",
      },
      {
        Header: "Min Hours",
        accessor: "minHours",
      },
      {
        Header: "Edit",
        Cell: ({ row }) =>
          row.original.Source === "Card" ? (
            <button
              onClick={() => handleLimitsEdit(row.original)}
              className="subTableEdit"
            >
              Edit
            </button>
          ) : null,
      },
    ],
    []
  );

  return (
    <div className="mobile-container">
      <MobileHeader theme={theme} />
      {isLoading && <Spinner />}
      {alertMessage && (
        <div
          className={`alert p-4 rounded ${
            alertType === "success"
              ? "bg-green-200 text-green-600"
              : "bg-red-200 text-red-600"
          } flex justify-between items-center`}
        >
          {alertMessage}
          <button onClick={clearAlert} className="text-lg font-bold">
            ×
          </button>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-2 m-8">
        {/* Card Number */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Card Number
          </label>
          <input
            type="text"
            name="CardNumber"
            onChange={handleChange}
            value={cardData.CardNumber}
            className="mt-1 p-2 w-full border rounded-md"
            readOnly
          />
        </div>

         {/* Card Status */}
         <div>
          <label className="block text-sm font-medium text-gray-700">
            Card Status
          </label>
          <select
            name="status"
            onChange={handleChange}
            value={cardData?.cardDetails?.result?.header?.status || ""}
            className="mt-1 p-2 w-full border rounded-md"
          >
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
            <option value="HOLD">Hold</option>
          </select>
        </div>

        <div>
  <label className="block text-sm font-medium text-gray-700">
    Driver Id
  </label>
  <p className="text-xs text-gray-500 mt-1">Add a card prompt for 'Driver ID' (DRID) to update this value.</p>
  <input
    type="text"
    name="DriverId"
    readOnly
    value={cardData.DriverId || ""}
    className="mt-1 p-2 w-full border rounded-md bg-stone-100 cursor-not-allowed"
    title="Add a card prompt for 'Driver ID' (DRID) to update this value."
  />
  
</div>

<div>
  <label className="block text-sm font-medium text-gray-700">
    Driver Name
  </label>
  <p className="text-xs text-gray-500 mt-1">Add a card prompt for 'Driver Name' (NAME) to update this value.</p>
  <input
    type="text"
    name="DriverName"
    readOnly
    value={cardData.DriverName || ""}
    className="mt-1 p-2 w-full border rounded-md bg-stone-100 cursor-not-allowed"
    title="Add a card prompt for 'Driver Name' (NAME) to update this value."
  />
  
</div>

<div>
  <label className="block text-sm font-medium text-gray-700">
    Unit #
  </label>
  <p className="text-xs text-gray-500 mt-1">Add a card prompt for 'Unit #' (UNIT) to update this value.</p>
  <input
    type="text"
    name="UnitNumber"
    readOnly
    value={cardData.UnitNumber || ""}
    className="mt-1 p-2 w-full border rounded-md bg-stone-100 cursor-not-allowed"
    title="Add a card prompt for 'Unit #' (UNIT) to update this value."
  />
  
</div>
     

        {/* Hand Enter
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Hand Enter
          </label>
          <select
            name="handEnter"
            onChange={handleChange}
            value={cardData?.cardDetails?.result?.header?.handEnter || ""}
            className="mt-1 p-2 w-full border rounded-md"
          >
            <option value="ALLOW">Allow Hand Enter</option>
            <option value="DISALLOW">Disallow Hand Enter</option>
            <option value="POLICY">Policy</option>
          </select>
        </div> */}   

        {/* Information */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Information Source (Prompts)
          </label>
          <p className="text-xs text-gray-500 mt-1">Select the source for prompts. If 'Both' is selected then the card
              prompts will always override the policy prompts.</p>
          <select
            name="infoSource"
            onChange={handleChange}
            value={cardData?.cardDetails?.result?.header?.infoSource || ""}
            className="mt-1 p-2 w-full border rounded-md"
          >
            <option value="CARD">Card</option>
            <option value="POLICY">Policy</option>
            <option value="BOTH">Both</option>
          </select>
        </div>
        {/* <div>
          <label className="block text-sm font-medium text-gray-700">
            Limits Source
          </label>
          <select
            name="limitSource"
            onChange={handleChange}
            value={cardData?.cardDetails?.result?.header?.limitSource || ""}
            className="mt-1 p-2 w-full border rounded-md"
          >
            <option value="Card">Card</option>
            <option value="Policy">Policy</option>
            <option value="Both">Both</option>
          </select>
        </div> */}

        {/* Submit Button */}
        <button
          type="submit"
          className="mt-4 px-4 py-2 text-white rounded themed-button"
        >
          Update Card
        </button>
      </form>
      <div className="mt-8">
      <div className="my-4">
          <div
            className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded-md"
            role="alert"
          >
            <p className="font-bold">Note</p>
            {/* <p>
              Select the source for limits and prompts. If 'Both' is selected
              then the card prompts and limits will always override the policy
              prompts and limits. Policy limits and prompts cannot be editied,
              please contact the card issuer if you want to update these values.
            </p> */}
            <p>
               Policy prompts cannot be edited, instead, create a new prompt at the card level
              to override the policy level, or select 'Card'. INFO_POOL type prompts are added in bulk by the card issuer. To change the value, delete the 'INFO_POOL' and create a new prompt.
            </p>
          </div>
        </div>
        {combinedPrompts && (
          <SubTableV2
            columns={combinedPromptsColumns}
            data={combinedPrompts}
            title="Prompts"
            infoMessage="You must click 'Update Card' to save your changes!"
          >
            <button
              className="themed-button"
              onClick={() =>
                navigate("/EditCardPrompts", { state: { isCreating: true } })
              }
            >
              New Prompt
            </button>
          </SubTableV2>
        )}
      </div>
      {/* <div className="mt-8">
        {combinedLimits && (
          <SubTable
            columns={combinedLimitsColumns}
            data={combinedLimits}
            title="Limits"
          >
            <button
              className="themed-button"
              onClick={() =>
                navigate("/EditCardLimits", { state: { isCreating: true } })
              }
            >
              New Limit
            </button>
          </SubTable>
        )}
      </div> */}
    </div>
  );
};

export default CardsAdminEditAdd;
