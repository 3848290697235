import React, { useState, useEffect, useContext } from 'react';
import { getRedirectResult } from "firebase/auth";
import { auth } from './firebase.js';
import { useDispatch } from 'react-redux';
import { login } from './userSlice';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useTheme } from './ThemeContext';  
import Spinner from './Spinner.js';
import bgImage from './images/home_bg.png';

function LoginDemo() {
  // State and context hooks
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, loginWithEmail } = useContext(AuthContext);
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const { carrierPortal } = theme;

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Set a timeout to hide the spinner and show an error message
    const loginTimeout = setTimeout(() => {
      setIsLoading(false);
      setError('Login process is taking too long. Please try again.');
    }, 15000); // 15 seconds timeout

    try {
      await loginWithEmail(email, password);
      clearTimeout(loginTimeout); // Clear the timeout if login is successful
    } catch (err) {
      console.error("Error Logging In:", err);
      setError(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    } else {
      getRedirectResult(auth)
        .then((result) => {
          if (result && result.user) {
            const { displayName, email, uid, photoURL } = result.user;
            dispatch(login({ displayName, email, uid, photoURL }));
            navigate('/dashboard');
          }
        })
        .catch((error) => {
          console.error("Error in getRedirectResult:", error);
          setError(error.message);
        });
    }
  }, [currentUser, dispatch, navigate]);

  // Define the landing page content
  return (
    <>
      {isLoading && <Spinner />}
      <div className="min-h-screen flex flex-col">
        {/* Hero Section */}
        <div className="relative bg-cover bg-center" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: '75%', backgroundRepeat: 'no-repeat' }}>
          <div className="absolute inset-0 bg-black opacity-10"></div>
          <div className="relative container mx-auto px-6 py-16 flex flex-col md:flex-row items-center">
            <div className="w-full md:w-2/3 text-white">
              <h1 className="text-4xl md:text-6xl font-bold mb-4" style={{ color: theme.primaryText }}>Welcome to FuelLink</h1>
              <p className="text-lg md:text-2xl mb-6">Manage your fuel cards and fuel program with ease.</p>
              <a href="#contact" className="bg-orange-600 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded" style={{ backgroundColor: theme.primary }}>Contact Us</a>
            </div>
            {/* Login area in the corner */}
            <div className="w-full md:w-1/3 mt-8 md:mt-0">
              <div
                style={{
                  background: 'rgba(245, 245, 245, 0.9)',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }}
                className="p-6 rounded-lg"
              >
                <h2 className="text-2xl mb-4 text-center">Login</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleLogin}>
                  <div className="mb-4">
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full themed-login-button p-2 rounded mb-4"
                  >
                    Login
                  </button>
                </form>
                <div className="text-center">
                  {!carrierPortal && (
                    <div>
                      <Link to="/registration" className="text-blue-500 hover:underline">Sign Up</Link>
                    </div>
                  )}
                  <div>
                    <Link to="/resetpassword" className="text-blue-500 hover:underline">Forgot Password?</Link>
                  </div>
                  {!carrierPortal && (
                    <div>
                      <Link to="/faq" className="text-blue-500 hover:underline">FAQ's</Link>
                    </div>
                  )}
                  {carrierPortal && (
                    <Link to="/signup" className="text-blue-500 hover:underline">Redeem an Invitation Code</Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Main Content */}
        <div className="container mx-auto px-6 py-16">
          <h2 className="text-3xl font-bold mb-8 text-center">Why Choose FuelLink?</h2>
          <div className="flex flex-wrap -mx-4">
            {/* Features */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-4">Extensive Network</h3>
                <p>Access over 4,500+ fuel locations nationwide, more than any other network.</p>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-4">White Label Solutions</h3>
                <p>Create a customized fuel program for fleets of 5 or more with our out-of-the-box solutions.</p>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-4">Advanced Management</h3>
                <p>Manage your fuel cards and fuel program efficiently with our intuitive web app.</p>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Us Form */}
        <div id="contact" className="bg-gray-100 py-16">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center">Contact Us</h2>
            <form className="max-w-lg mx-auto">
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Name</label>
                <input type="text" className="w-full p-2 border rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Email</label>
                <input type="email" className="w-full p-2 border rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Message</label>
                <textarea className="w-full p-2 border rounded"></textarea>
              </div>
              <button type="submit" className="bg-orange-600 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded" style={{ backgroundColor: theme.primary }}>Submit</button>
            </form>
          </div>
        </div>
        {/* Footer */}
        <footer className="bg-black text-white py-6">
          <div className="container mx-auto px-6 text-center">
            &copy; {new Date().getFullYear()} FuelLink. All rights reserved.
          </div>
        </footer>
      </div>
    </>
  );
}

export default LoginDemo;
