import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCardLimits, selectCardData, deleteCardLimit } from './cardSlice';
import { useTheme } from './ThemeContext';
import MobileHeader from './MobileHeader';

const CardLimitForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const cardData = useSelector(selectCardData);
  const limits = cardData.cardDetails?.result?.limits || [];
  const existingLimitIds = new Set(limits.map(limit => limit.limitId));
  const rowData = location.state?.rowData || {};
  const isEditing = Boolean(rowData.limitId);
  const isCreating = location.state?.isCreating || false;
  console.log('Row Data: ', rowData);

  const [limitData, setLimitData] = useState(
    isCreating
      ? {
          // Initialize with default or empty values for a new record
          limitId: '',
          amount: '',
          hours: '',
          limit: '',
          minHours: '',
          autoRollMap: '',
          autoRollMax: ''
        }
      : {
          // Initialize with existing values for editing
          limitId: rowData.limitId,
          amount: rowData.amount,
          hours: rowData.hours,
          limit: rowData.limit,
          minHours: rowData.minHours,
          autoRollMap: rowData.autoRollMap,
          autoRollMax: rowData.autoRollMax
        }
  );
  
  const limitIdOptions = [
    { key: 'ADD', value: 'Additives' },
    { key: 'AMDS', value: 'Aviation Merchandise' },
    { key: 'ANFR', value: 'Anti-Freeze' },
    { key: 'AVGS', value: 'Aviation Gas' },
    { key: 'BDSL', value: 'BioDiesel' },
    { key: 'BRAK', value: 'Brakes and Wheels' },
    { key: 'CADV', value: 'Cash Advance' },
    { key: 'CLTH', value: 'Clothing' },
    { key: 'CNG', value: 'Compressed Natural Gas' },
    { key: 'COUP', value: 'Coupon' },
    { key: 'DEF', value: 'Diesel Exhaust Fluid Bulk' },
    { key: 'DEFC', value: 'Diesel Exhaust Fluid Container' },
    { key: 'DELI', value: 'Restaurant/Deli' },
    { key: 'DSL', value: 'Diesel' },
    { key: 'DSLM', value: 'Mexico Diesel' },
    { key: 'ELEC', value: 'Electronics' },
    { key: 'EVCH', value: 'Electric Charging' },
    { key: 'FAX', value: 'Faxdefc' },
    { key: 'FURN', value: 'Furnace Oil' },
    { key: 'GAS', value: 'Gasoline' },
    { key: 'GASM', value: 'Mexico Gas Magna' },
    { key: 'GASP', value: 'Premium Gas Mexico' },
    { key: 'GROC', value: 'C-Store/Groceries' },
    { key: 'HARD', value: 'Hardware/Accessories' },
    { key: 'IDLE', value: 'IdleAire' },
    { key: 'JET', value: 'Jet Fuel' },
    { key: 'KERO', value: 'Kerosene' },
    { key: 'LABR', value: 'Labor' },
    { key: 'LMPR', value: 'Lumper' },
    { key: 'LNG', value: 'Liquid Natural Gas' },
    { key: 'MDSL', value: 'Marked/Tax Exempt Diesel' },
    { key: 'MERC', value: 'Miscellaneous Merchandise' },
    { key: 'MGAS', value: 'Marked/Tax Exempt Gas' },
    { key: 'MRFR', value: 'Marked Reefer' },
    { key: 'NGAS', value: 'Natural Gas' },
    { key: 'OIL', value: 'Oil' },
    { key: 'OILC', value: 'Oil Change' },
    { key: 'PART', value: 'Parts' },
    { key: 'PHON', value: 'Phone Time' },
    { key: 'PNT', value: 'Paint' },
    { key: 'PROP', value: 'Propane' },
    { key: 'RECP', value: 'Recap' },
    { key: 'REPR', value: 'Repair Service' },
    { key: 'REST', value: 'Restaurant' },
    { key: 'RFND', value: 'Refund' },
    { key: 'RFR', value: 'Reefer' },
    { key: 'RFRM', value: 'Thermo' },
    { key: 'SCAN', value: 'Tons Imaging' },
    { key: 'SCLE', value: 'Weigh Scale' },
    { key: 'SHWR', value: 'Shower' },
    { key: 'SPLT', value: 'Split/Other Method of Payment' },
    { key: 'STAX', value: 'Sales Tax' },
    { key: 'TIRE', value: 'Tires/Tire Repairs' },
    { key: 'TOLL', value: 'Ambassador Bridge Toll' },
    { key: 'TRAL', value: 'Trailer' },
    { key: 'TRPP', value: 'Trip Permit' },
    { key: 'ULSD', value: 'Ultra Low Sulfur Diesel' },
    { key: 'WASH', value: 'Car Wash' },
    { key: 'WIFI', value: 'Fleet WiFi Billing' },
    { key: 'WWFL', value: 'Windshield Washer Fluid' }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLimitData({
      ...limitData,
      [name]: value,
    });
  };
  useEffect(() => {
    console.log("Updated cardData from Redux store:", cardData);
  }, [cardData]);
  const handleDelete = () => {
    const limitsArray = cardData.cardDetails?.result?.limits || [];
    const updatedLimits = limitsArray.filter(limit => limit.limitId !== limitData.limitId);
  
    const combinedLimitsArray = cardData.combinedLimits || [];
    const updatedCombinedLimits = combinedLimitsArray.filter(
      limit => !(limit.limitId === limitData.limitId && limit.Source === 'Card')
    );
  
    console.log("Before Delete, limitsArray:", limitsArray);
    console.log("After Delete, updatedLimits:", updatedLimits);
    console.log("Before Delete, combinedLimitsArray:", combinedLimitsArray);
    console.log("After Delete, updatedCombinedLimits:", updatedCombinedLimits);
  
    // Dispatch the new delete action
    dispatch(deleteCardLimit({ updatedLimits, updatedCombinedLimits }));
  
    console.log("After Dispatch, cardData:", cardData);  // Note: This may not reflect the latest state
  
    navigate(-1);
  };
 

  const handleSubmit = (e) => {
    e.preventDefault();
    const limitsArray = cardData.cardDetails?.result?.limits || [];
    let updatedLimits;

    const existingLimit = limitsArray.find(limit => limit.limitId === limitData.limitId);

    if (existingLimit) {
      updatedLimits = limitsArray.map((limit) =>
        limit.limitId === limitData.limitId ? limitData : limit
      );
    } else {
      updatedLimits = [...limitsArray, limitData];
    }

    dispatch(updateCardLimits(limitData));  // <-- Dispatch your updateCardLimits action here

    navigate(-1);
  };

  return (
    <div className="mobile-container mx-auto px-4 sm:px-6 lg:px-8">
    <MobileHeader theme={theme} />
    {isEditing && (
        <div className="text-right mb-4 m-8">
          <button
            type="button"
            onClick={handleDelete}
            className="px-4 py-2 text-white rounded bg-red-500"
          >
            Delete Limit
          </button>
        </div>
      )}
    <form onSubmit={handleSubmit} className="space-y-4 m-8">
  <div>
  <label className="block text-sm font-medium text-gray-700">
    Limit ID
  </label>
  <select
    name="limitId"
    value={limitData.limitId}
    onChange={handleChange}
    className="mt-1 p-2 w-full border rounded-md"
    disabled={isEditing}
  >
    <option value="" disabled>Select a Limit ID</option>
    {limitIdOptions.map((option) => (
      <option 
        key={option.key} 
        value={option.key}
        disabled={existingLimitIds.has(option.key) && option.key !== limitData.limitId}
      >
        {option.value}
      </option>
    ))}
  </select>
</div>

  <div>
    <label className="block text-sm font-medium text-gray-700">
      Hours
    </label>
    <p className="text-xs text-gray-500 mt-1">The hours the limit is good for before resetting.</p>
    <input
      type="number"
      name="hours"
      value={limitData.hours}
      onChange={handleChange}
      className="mt-1 p-2 w-full border rounded-md"
      maxLength={3}
    />
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700">
      Limit
    </label>
    <p className="text-xs text-gray-500 mt-1">The limit value, which will be gallons for fuel or DEF dispensed and dollar amounts in all other cases. For example if the limit is ULSD and this value is 300 that will be 300 gallons. This will be a value from 0 to 9999.</p>
    <input
      type="number"
      name="limit"
      value={limitData.limit}
      onChange={handleChange}
      className="mt-1 p-2 w-full border rounded-md"
      maxLength={4}
    />
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700">
      Min Hours
    </label>
    <p className="text-xs text-gray-500 mt-1">Minimum hours between using the limit between uses.</p>
    <input
      type="number"
      name="minHours"
      value={limitData.minHours}
      onChange={handleChange}
      className="mt-1 p-2 w-full border rounded-md"
      maxLength={3}
    />
  </div>

  {/* <div>
    <label className="block text-sm font-medium text-gray-700">
      Auto Roll Map
    </label>
    <input
      type="number"
      name="autoRollMap"
      value={limitData.autoRollMap}
      onChange={handleChange}
      className="mt-1 p-2 w-full border rounded-md"
    />
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700">
      Auto Roll Max
    </label>
    <input
      type="number"
      name="autoRollMax"
      value={limitData.autoRollMax}
      onChange={handleChange}
      className="mt-1 p-2 w-full border rounded-md"
    />
  </div> */}

  <button
  type="submit"
  className="mt-4 px-4 py-2 text-white rounded themed-button"
>
  {isCreating ? "Add Limit" : "Update Limit"}
</button>

</form>
</div>
  );
};

export default CardLimitForm;
