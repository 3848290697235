import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const FinancialTransactionModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  modalType,
  onNewTransaction,
}) => {
  const [formData, setFormData] = useState({
    TransactionId: "",
    PolicyNumber: "",
    cardGroupID: "",
    Date: "",
    Type: "",
    Status: "",
    Amount: "",
    Fees: "",
    TotalAmount: "",
    EFSTransactionId: "",
    Notes: "",  
  });

  const policies = useSelector((state) => state.policies.policies);
  const cardGroups = useSelector((state) => state.policies.cardGroups);

  const [selectedPolicy, setSelectedPolicy] = useState(initialData?.PolicyNumber || "");
  const [selectedCardGroup, setSelectedCardGroup] = useState(initialData?.cardGroupID || "");

  useEffect(() => {
    if (!isOpen) {
      setFormData({
        TransactionId: "",
        PolicyNumber: "",
        cardGroupID: "",
        Date: "",
        Type: "",
        Status: "",
        Amount: "",
        Fees: "",
        TotalAmount: "",
        EFSTransactionId: "",
        Notes: "",
      });
      setSelectedPolicy("");
      setSelectedCardGroup("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    if (initialData) {
      setFormData(initialData);
      if (initialData.cardGroupID) {
        setSelectedPolicy(""); // Clear the policy value if there's a cardGroupID
        setSelectedCardGroup(initialData.cardGroupID);
      } else {
        setSelectedPolicy(initialData.PolicyNumber || "");
        setSelectedCardGroup("");
      }
    }
    
    console.log(initialData);
  }, [initialData, isOpen]);

  useEffect(() => {
    const totalAmount = (parseFloat(formData.Amount) || 0) + (parseFloat(formData.Fees) || 0);
    setFormData((prevFormData) => ({ ...prevFormData, TotalAmount: totalAmount.toFixed(2) }));
  }, [formData.Amount, formData.Fees]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePolicyChange = (e) => {
    const selectedValue = e.target.value;

    const selectedCardGroup = cardGroups.find(group => group.AccountNumber.toString() === selectedValue);
    const selectedPolicy = selectedCardGroup 
      ? policies.find(policy => policy.PolicyNumber === selectedCardGroup.PolicyNumber) 
      : policies.find(policy => policy.PolicyNumber.toString() === selectedValue);

    setSelectedPolicy(selectedPolicy ? selectedPolicy.PolicyNumber : "");
    setSelectedCardGroup(selectedCardGroup ? selectedCardGroup.cardGroupID : "");

    setFormData({
      ...formData,
      PolicyNumber: selectedPolicy ? selectedPolicy.PolicyNumber : "",
      cardGroupID: selectedCardGroup ? selectedCardGroup.cardGroupID : "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(formData);
    if (modalType === 'new') {
      onNewTransaction();
    }
    onClose();
  };

  const getPolicyDescription = (policyNumber) => {
    const policy = policies.find((p) => p.PolicyNumber === policyNumber);
    return policy ? policy.Description : "";
  };

  const getCardGroupDescription = (cardGroupID) => {
    const cardGroup = cardGroups.find((cg) => cg.cardGroupID === cardGroupID);
    return cardGroup ? `${cardGroup.groupName} (${cardGroup.AccountNumber})` : "";
  };

  if (!isOpen) return null;

  return (
    <div
      className={`modal ${isOpen ? "open" : ""}`}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto"
      }}
    >
      <div
        className="modal-content"
        style={{
          width: "80%",
          maxWidth: "600px",
          maxHeight: "80vh", // Limit the height
          overflowY: "auto", // Scroll for inner content overflow
          position: "fixed", // Use fixed positioning
          top: "50%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Adjust for exact centering
          marginTop: "10px", // Add a margin at the top for safety
        }}
      >
        <span
          className="close"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "15px",
            right: "10px",
            cursor: "pointer",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          &times;
        </span>
        <div className="space-y-8" style={{ padding: "20px" }}>
          <div className="m-8">
            {modalType === 'new' ? (
              <>
                <h1 className="text-2xl font-semibold">
                  Add A New Financial Transaction
                </h1>
                <p className="text-sm mt-2">
                  Adding a new financial transaction will affect the user's account balance / credit remaining.
                </p>
              </>
            ) : (
              <h1 className="text-2xl font-semibold">
                Transaction Details
              </h1>
            )}
          </div>
  
          <form onSubmit={handleSubmit} className="space-y-2 m-8">
            {modalType !== "new" && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Transaction ID
                </label>
                <input
                  type="text"
                  id="TransactionId"
                  name="TransactionId"
                  value={formData.TransactionId}
                  readOnly
                  className="mt-1 p-2 w-full border rounded-md bg-gray-100"
                />
              </div>
            )}
  
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Date
              </label>
              <input
                type="text"
                id="Date"
                name="Date"
                value={new Date().toLocaleDateString()} 
                readOnly 
                className="mt-1 p-2 w-full border rounded-md bg-gray-100"
                required
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Amount
              </label>
              <input
                type="number"
                id="Amount"
                name="Amount"
                value={formData.Amount}
                onChange={handleChange}
                className="mt-1 p-2 w-full border rounded-md"
                readOnly={modalType === "edit"}
                required
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Fees
              </label>
              <input
                type="number"
                id="Fees"
                name="Fees"
                value={formData.Fees}
                onChange={handleChange}
                className="mt-1 p-2 w-full border rounded-md"
                readOnly={modalType === "edit"}
                required
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Total Amount
              </label>
              <input
                type="text"
                id="TotalAmount"
                name="TotalAmount"
                value={formData.TotalAmount}
                readOnly
                className="mt-1 p-2 w-full border rounded-md bg-gray-100"
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Type
              </label>
              <select
                id="Type"
                name="Type"
                value={formData.Type}
                onChange={handleChange}
                className="mt-1 p-2 w-full border rounded-md"
                disabled={modalType === "edit"}
                required
              >
                <option value="" disabled>
                  Select a type
                </option>
                <option value="Payment">Payment</option>
                <option value="Fuel Card Purchase">
                  Fuel Card Purchase
                </option>
                <option value="Deposit">Deposit</option>
              </select>
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Status
              </label>
              <select
                id="Status"
                name="Status"
                value={formData.Status}
                onChange={handleChange}
                className="mt-1 p-2 w-full border rounded-md"
                required
                disabled={formData.Status === "Completed"} // Disable the dropdown if status is "Completed"
              >
                <option value="" disabled>
                  Select a status
                </option>
                <option value="Completed">Completed</option>
                <option value="Pending">Pending</option>
                <option value="Failed">Failed</option>
              </select>
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Account
              </label>
              <select
                id="PolicyNumber"
                name="PolicyNumber"
                value={selectedCardGroup ? cardGroups.find(group => group.cardGroupID === selectedCardGroup)?.AccountNumber : selectedPolicy}
                onChange={handlePolicyChange}
                className="mt-1 p-2 w-full border rounded-md"
                disabled={modalType === "edit"}
                required
              >
                <option value="" disabled>
                  Select a policy
                </option>
                {policies &&
                  policies.map((policy) => (
                    <option key={policy.ID} value={policy.PolicyNumber}>
                      {policy.PolicyNumber} - {policy.Description}
                    </option>
                  ))}
                {cardGroups &&
                  cardGroups.map((group) => (
                    <option key={group.cardGroupID} value={group.AccountNumber}>
                      {group.groupName} ({group.AccountNumber})
                    </option>
                  ))}
              </select>
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700">
                EFS Transaction ID
              </label>
              <input
                type="text"
                id="EFSTransactionId"
                name="EFSTransactionId"
                value={formData.EFSTransactionId}
                onChange={handleChange}
                readOnly={modalType === "edit"}
                placeholder={
                  modalType === "new"
                    ? "If you have an EFS transaction ID, please enter it here"
                    : ""
                }
                className="mt-1 p-2 w-full border rounded-md"
              />
              <label className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <textarea
                id="Notes"
                name="Notes"
                value={formData.Notes}
                onChange={handleChange}
                className="mt-1 p-2 w-full border rounded-md"
                rows="3"
                placeholder="Enter any notes here"
              />
            </div>
  
            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end' }}>
              {modalType === 'edit' && (
                <p className="text-sm text-red-500 mb-2">
                  Note: Transaction amounts and details cannot be adjusted. If you need to make changes, create a new transaction.
                </p>
              )}
              <button
                type="submit"
                className="px-4 py-2 themed-button"
              >
                {modalType === 'new' ? 'Add New Transaction' : 'Save Transaction'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}  

export default FinancialTransactionModal;



// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";

// const FinancialTransactionModal = ({
//   isOpen,
//   onClose,
//   onSubmit,
//   initialData,
//   modalType,
//   onNewTransaction,
// }) => {
//   const [formData, setFormData] = useState({
//     TransactionId: "",
//     PolicyNumber: "",
//     cardGroupID: "",
//     Date: "",
//     Type: "",
//     Status: "",
//     Amount: "",
//     Fees: "",
//     TotalAmount: "",
//     EFSTransactionId: "",
//     Notes: "",  
//   });

//   const policies = useSelector((state) => state.policies.policies);
//   const cardGroups = useSelector((state) => state.policies.cardGroups);

//   const [selectedPolicy, setSelectedPolicy] = useState(initialData?.PolicyNumber || "");
//   const [selectedCardGroup, setSelectedCardGroup] = useState(initialData?.cardGroupID || "");

//   useEffect(() => {
//     if (!isOpen) {
//       setFormData({
//         TransactionId: "",
//         PolicyNumber: "",
//         cardGroupID: "",
//         Date: "",
//         Type: "",
//         Status: "",
//         Amount: "",
//         Fees: "",
//         TotalAmount: "",
//         EFSTransactionId: "",
//         Notes: "",
//       });
//       setSelectedPolicy("");
//       setSelectedCardGroup("");
//     }
//   }, [isOpen]);

//   useEffect(() => {
//     if (!isOpen) return;
//     if (initialData) {
//       setFormData(initialData);
//       if (initialData.cardGroupID) {
//         setSelectedPolicy(""); // Clear the policy value if there's a cardGroupID
//         setSelectedCardGroup(initialData.cardGroupID);
//       } else {
//         setSelectedPolicy(initialData.PolicyNumber || "");
//         setSelectedCardGroup("");
//       }
//     }
    
//     console.log(initialData);
//   }, [initialData, isOpen]);

//   useEffect(() => {
//     const totalAmount = (parseFloat(formData.Amount) || 0) + (parseFloat(formData.Fees) || 0);
//     setFormData((prevFormData) => ({ ...prevFormData, TotalAmount: totalAmount.toFixed(2) }));
//   }, [formData.Amount, formData.Fees]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // const handlePolicyChange = (e) => {
//   //   const selectedValue = e.target.value;

//   //   const selectedCardGroup = cardGroups.find(group => group.AccountNumber.toString() === selectedValue);
//   //   const selectedPolicy = selectedCardGroup 
//   //     ? policies.find(policy => policy.PolicyNumber === selectedCardGroup.PolicyNumber) 
//   //     : policies.find(policy => policy.PolicyNumber.toString() === selectedValue);

//   //   // setFormData({
//   //   //   ...formData,
//   //   //   PolicyNumber: selectedPolicy ? selectedPolicy.PolicyNumber : "",
//   //   //   cardGroupID: selectedCardGroup ? selectedCardGroup.cardGroupID : "",
//   //   // });

//   //   setFormData({
//   //     ...formData,
//   //     PolicyNumber: selectedPolicy ? selectedPolicy.PolicyNumber : "",
//   //     cardGroupID: selectedCardGroup ? selectedCardGroup.cardGroupID : "",
//   //   });
//   // };

//   const handlePolicyChange = (e) => {
//     const selectedValue = e.target.value;

//     const selectedCardGroup = cardGroups.find(group => group.AccountNumber.toString() === selectedValue);
//     const selectedPolicy = selectedCardGroup 
//       ? policies.find(policy => policy.PolicyNumber === selectedCardGroup.PolicyNumber) 
//       : policies.find(policy => policy.PolicyNumber.toString() === selectedValue);

//     setSelectedPolicy(selectedPolicy ? selectedPolicy.PolicyNumber : "");
//     setSelectedCardGroup(selectedCardGroup ? selectedCardGroup.cardGroupID : "");

//     setFormData({
//       ...formData,
//       PolicyNumber: selectedPolicy ? selectedPolicy.PolicyNumber : "",
//       cardGroupID: selectedCardGroup ? selectedCardGroup.cardGroupID : "",
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     await onSubmit(formData);
//     if (modalType === 'new') {
//       onNewTransaction();
//     }
//     onClose();
//   };

//   const getPolicyDescription = (policyNumber) => {
//     const policy = policies.find((p) => p.PolicyNumber === policyNumber);
//     return policy ? policy.Description : "";
//   };

//   const getCardGroupDescription = (cardGroupID) => {
//     const cardGroup = cardGroups.find((cg) => cg.cardGroupID === cardGroupID);
//     return cardGroup ? `${cardGroup.groupName} (${cardGroup.AccountNumber})` : "";
//   };

//   if (!isOpen) return null;

//   return (
//     <div
//       className={`modal ${isOpen ? "open" : ""}`}
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         overflowY: "auto", 
//       }}
//     >
      // <div
      //   className="modal-content"
      //   style={{
      //     width: "80%",
      //     maxWidth: "600px",
      //     maxHeight: "90vh", // Limit the height
      //     overflowY: "auto", // Scroll for inner content overflow
      //     position: "fixed", // Use fixed positioning
      //     top: "50%", // Center vertically
      //     left: "50%", // Center horizontally
      //     transform: "translate(-50%, -50%)", // Adjust for exact centering
      //     marginTop: "20px", // Add a margin at the top for safety
      //   }}
      // >
      //   <span
      //     className="close"
      //     onClick={onClose}
      //     style={{
      //       position: "absolute",
      //       top: "10px",
      //       right: "10px",
      //       cursor: "pointer",
      //       fontSize: "24px",
      //       fontWeight: "bold",
      //     }}
      //   >
      //     &times;
      //   </span>
//         <div className="space-y-8" style={{ padding: "20px" }}>
//           <div className="m-8">
//             {modalType === 'new' ? (
//               <>
//                 <h1 className="text-2xl font-semibold">
//                   Add A New Financial Transaction
//                 </h1>
//                 <p className="text-sm mt-2">
//                   Adding a new financial transaction will affect the user's account balance / credit remaining.
//                 </p>
//               </>
//             ) : (
//               <h1 className="text-2xl font-semibold">
//                 Transaction Details
//               </h1>
//             )}
//           </div>

//           <form onSubmit={handleSubmit} className="space-y-2 m-8">
//             {modalType !== "new" && (
//               <div>
//                 <label className="block text-sm font-medium text-gray-700">
//                   Transaction ID
//                 </label>
//                 <input
//                   type="text"
//                   id="TransactionId"
//                   name="TransactionId"
//                   value={formData.TransactionId}
//                   readOnly
//                   className="mt-1 p-2 w-full border rounded-md bg-gray-100"
//                 />
//               </div>
//             )}

//             <div>
//               <label className="block text-sm font-medium text-gray-700">
//                 Date
//               </label>
//               <input
//                 type="text"
//                 id="Date"
//                 name="Date"
//                 value={new Date().toLocaleDateString()} 
//                 readOnly 
//                 className="mt-1 p-2 w-full border rounded-md bg-gray-100"
//                 required
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 id="Amount"
//                 name="Amount"
//                 value={formData.Amount}
//                 onChange={handleChange}
//                 className="mt-1 p-2 w-full border rounded-md"
//                 readOnly={modalType === "edit"}
//                 required
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700">
//                 Fees
//               </label>
//               <input
//                 type="number"
//                 id="Fees"
//                 name="Fees"
//                 value={formData.Fees}
//                 onChange={handleChange}
//                 className="mt-1 p-2 w-full border rounded-md"
//                 readOnly={modalType === "edit"}
//                 required
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700">
//                 Total Amount
//               </label>
//               <input
//                 type="text"
//                 id="TotalAmount"
//                 name="TotalAmount"
//                 value={formData.TotalAmount}
//                 readOnly
//                 className="mt-1 p-2 w-full border rounded-md bg-gray-100"
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700">
//                 Type
//               </label>
//               <select
//                 id="Type"
//                 name="Type"
//                 value={formData.Type}
//                 onChange={handleChange}
//                 className="mt-1 p-2 w-full border rounded-md"
//                 disabled={modalType === "edit"}
//                 required
//               >
//                 <option value="" disabled>
//                   Select a type
//                 </option>
//                 <option value="Payment">Payment</option>
//                 <option value="Fuel Card Purchase">
//                   Fuel Card Purchase
//                 </option>
//                 <option value="Deposit">Deposit</option>
//               </select>
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700">
//                 Status
//               </label>
//               <select
//                 id="Status"
//                 name="Status"
//                 value={formData.Status}
//                 onChange={handleChange}
//                 className="mt-1 p-2 w-full border rounded-md"                
//                 required
//               >
//                 <option value="" disabled>
//                   Select a status
//                 </option>
//                 <option value="Completed">Completed</option>
//                 <option value="Pending">Pending</option>
//                 <option value="Failed">Failed</option>
//               </select>
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700">
//                 Account
//               </label>
//               <select
//                 id="PolicyNumber"
//                 name="PolicyNumber"
//                 value={selectedCardGroup ? cardGroups.find(group => group.cardGroupID === selectedCardGroup)?.AccountNumber : selectedPolicy}
//                 onChange={handlePolicyChange}
//                 className="mt-1 p-2 w-full border rounded-md"
//                 disabled={modalType === "edit"}
//                 required
//               >
//                 {/* {modalType === "new" ? (
//                   <> */}
//                     <option value="" disabled>
//                       Select a policy
//                     </option>
//                     {policies &&
//                       policies.map((policy) => (
//                         <option key={policy.ID} value={policy.PolicyNumber}>
//                           {policy.PolicyNumber} - {policy.Description}
//                         </option>
//                       ))}
//                     {cardGroups &&
//                       cardGroups.map((group) => (
//                         <option key={group.cardGroupID} value={group.AccountNumber}>
//                           {group.groupName} ({group.AccountNumber})
//                         </option>
//                       ))}
//                   {/* </>
//                 ) : (
//                   <>
//                     {formData.PolicyNumber && (
//                       <option value={formData.PolicyNumber} selected>
//                         {formData.PolicyNumber} - {getPolicyDescription(formData.PolicyNumber)}
//                       </option>
//                     )}
//                     {formData.cardGroupID && (
//                       <option value={formData.cardGroupID} selected>
//                         {getCardGroupDescription(formData.cardGroupID)}
//                       </option>
//                     )}
//                   </>
//                 )} */}
//               </select>
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-gray-700">
//                 EFS Transaction ID
//               </label>
//               <input
//                 type="text"
//                 id="EFSTransactionId"
//                 name="EFSTransactionId"
//                 value={formData.EFSTransactionId}
//                 onChange={handleChange}
//                 readOnly={modalType === "edit"}
//                 placeholder={
//                   modalType === "new"
//                     ? "If you have an EFS transaction ID, please enter it here"
//                     : ""
//                 }
//                 className="mt-1 p-2 w-full border rounded-md"
//               />
//               <label className="block text-sm font-medium text-gray-700">
//                 Notes
//               </label>
//               <textarea
//                 id="Notes"
//                 name="Notes"
//                 value={formData.Notes}
//                 onChange={handleChange}
//                 className="mt-1 p-2 w-full border rounded-md"
//                 rows="3"
//                 placeholder="Enter any notes here"
//               />
//             </div>

//             <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end' }}>
//               {modalType === 'edit' && (
//                 <p className="text-sm text-red-500 mb-2">
//                   Note: Transaction amounts and details cannot be adjusted. If you need to make changes, create a new transaction.
//                 </p>
//               )}
//               <button
//                 type="submit"
//                 className="px-4 py-2 themed-button"
//               >
//                 {modalType === 'new' ? 'Add New Transaction' : 'Save Transaction'}
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FinancialTransactionModal;

