import { createSlice } from '@reduxjs/toolkit';

export const cardSlice = createSlice({
  name: 'card',
  initialState: {
    cardData: {},
  },
  reducers: {
    setCardData: (state, action) => {
      state.cardData = action.payload;
    },
    updateCardData: (state, action) => {
      state.cardData = {
        ...state.cardData,
        ...action.payload,
      };
    },
    updateCardInfos: (state, action) => {
      const updatedInfo = action.payload;
      
      // Initialize cardDetails and infos if they don't exist
      if (!state.cardData.cardDetails) {
        state.cardData.cardDetails = { result: {} };
      }
      if (!Array.isArray(state.cardData.cardDetails.result.infos)) {
        state.cardData.cardDetails.result.infos = [];
      }
    
      if (!Array.isArray(state.cardData.combinedPrompts)) {
        state.cardData.combinedPrompts = [];
      }
    
      // Update cardDetails
      const infosArray = state.cardData.cardDetails.result.infos;
      const infoIndex = infosArray.findIndex(
        (info) => info.infoId === updatedInfo.infoId
      );
    
      if (infoIndex !== -1) {
        // Update if available
        infosArray[infoIndex] = updatedInfo;
      } else {
        // Create if not available
        infosArray.push(updatedInfo);
      }
    
      state.cardData.cardDetails.result.infos = infosArray;
    
      // Update combinedPrompts
      const combinedPromptsArray = state.cardData.combinedPrompts;
      const combinedInfoIndex = combinedPromptsArray.findIndex(
        (info) => info.infoId === updatedInfo.infoId && info.Source === 'Card'
      );
    
      if (combinedInfoIndex !== -1) {
        // Update if available
        combinedPromptsArray[combinedInfoIndex] = { ...updatedInfo, Source: 'Card' };
      } else {
        // Create if not available
        combinedPromptsArray.push({ ...updatedInfo, Source: 'Card' });
      }
    
      state.cardData.combinedPrompts = combinedPromptsArray;
    },
    
      updateCardLimits: (state, action) => {
        console.log('Received action:', action);
      
        const updatedLimit = action.payload;
      
        // Initialize cardDetails and limits if they don't exist
        if (!state.cardData.cardDetails) {
          state.cardData.cardDetails = { result: {} };
        }
        if (!state.cardData.cardDetails.result.limits) {
          state.cardData.cardDetails.result.limits = [];
        }
      
        if (!state.cardData.combinedLimits) {
          state.cardData.combinedLimits = [];
        }
      
        console.log('Before update, cardDetails.limits:', state.cardData.cardDetails.result.limits);
        console.log('Before update, combinedLimits:', state.cardData.combinedLimits);
      
        // Update cardDetails.limits
        const limitsArray = state.cardData.cardDetails.result.limits;
        const limitIndex = limitsArray.findIndex(
          (limit) => limit.limitId === updatedLimit.limitId
        );
      
        if (limitIndex !== -1) {
          // Update if available
          limitsArray[limitIndex] = updatedLimit;
        } else {
          // Create if not available
          limitsArray.push(updatedLimit);
        }
      
        state.cardData.cardDetails.result.limits = limitsArray;
      
        // Update combinedLimits
        const combinedLimitsArray = state.cardData.combinedLimits;
        const combinedLimitIndex = combinedLimitsArray.findIndex(
          (limit) => limit.limitId === updatedLimit.limitId && limit.Source === 'Card'
        );
      
        if (combinedLimitIndex !== -1) {
          // Update if available
          combinedLimitsArray[combinedLimitIndex] = { ...updatedLimit, Source: 'Card' };
        } else {
          // Create if not available
          combinedLimitsArray.push({ ...updatedLimit, Source: 'Card' });
        }
      
        state.cardData.combinedLimits = combinedLimitsArray;
      
        console.log('After update, cardDetails.limits:', state.cardData.cardDetails.result.limits);
        console.log('After update, combinedLimits:', state.cardData.combinedLimits);
      },
      deleteCardLimit: (state, action) => {
        const { updatedLimits, updatedCombinedLimits } = action.payload;
      
        // Update cardDetails.result.limits
        if (state.cardData.cardDetails?.result) {
          state.cardData.cardDetails.result.limits = updatedLimits;
        }
      
        // Update combinedLimits
        state.cardData.combinedLimits = updatedCombinedLimits;
      },
      deleteCardInfo: (state, action) => {
        const { updatedInfos, updatedCombinedPrompts } = action.payload;
      
        // Update cardDetails.result.infos
        if (state.cardData.cardDetails?.result) {
          state.cardData.cardDetails.result.infos = updatedInfos;
        }
      
        // Update combinedPrompts
        state.cardData.combinedPrompts = updatedCombinedPrompts;
      }
    },
  });      

  export const { setCardData, updateCardData, updateCardInfos, updateCardLimits, deleteCardLimit, deleteCardInfo } = cardSlice.actions;

  export const selectCardData = (state) => state.card.cardData;

export default cardSlice.reducer;
