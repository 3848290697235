import React from 'react';

function SuccessModal({ message, onClose }) {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold mb-4">Success</h3>
                <p>{message}</p> {/* Display the custom message */}
                <div className="mt-4 flex justify-end">
                    <button 
                        onClick={onClose} 
                        className="themed-button"
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SuccessModal;
