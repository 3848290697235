import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.js';
import LocationsModal from './LocationsModal.js';
import Spinner from './Spinner.js';

const chainIdToColumn = { 
  0: "IndependentsOther",
  1: "PilotFJ",
  2: "Loves",
  3: "TAPetro",
  4: "SappBrothers",
  5: "Wilco",
  6: "SpeedwaySuperAmerica",
  7: "QuickTrip",
  8: "KangarooPantry", 
  9: "PetroCanada",
  10: "ImperialOilEsso",
  11: "KwikTrip",
  12: "QuickFuel",
  13: "Ambest",
  14: "CatScales",
  15: "ExxonMobileUSOTRNetwork",
  16: "Quarles",
  17: "Maverik",
  18: "Irving24",
  19: "RoadRanger",
  20: "NFNRetail",
  21: "NFNCardlock",
  22: "EclipseAdvantageLLC",
  23: "Capstone",
  24: "Radsync",
  25: "SCFuels",
  26: "CircleK",
  27: "Racetrac",
  28: "Caseys",
  101: "WEXNAF",
  201: "RetailUltramar",
  202: "RetailChevron",
  203: "RetailPioneer",
  204: "RetailCoucheTard",
  205: "RetailFasGas",
  206: "RetailRaceTrac",
  211: "CardlockChevron",
  212: "CardlockColumbiaFuels",
  213: "CardlockBluewave",
  214: "CardlockPipeline",
  215: "CardlockUltramar",
};

const defaultDiscountType = "None";  // Default discount type

const LocationData = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [locations, setLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [chains, setChains] = useState({});
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  
  // Fetch chain discounts from the backend and populate the form
  useEffect(() => {
    const fetchChainDiscounts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getChainDiscounts?networkId=${currentUser.tenantId}`);
        if (response.ok) {
          const data = await response.json();
          const chainValues = Object.keys(chainIdToColumn).reduce((acc, chainId) => {
            const chainData = data.find(item => item.ChainId === parseInt(chainId));
            acc[chainId] = {
              discountType: chainData ? chainData.DiscountType : defaultDiscountType,
              costPlusAmount: chainData ? chainData.CostPlusAmount : null,
              retailMinusAmount: chainData ? chainData.RetailMinusAmount : null,
              showOnLocationFinder: chainData ? chainData.ShowOnLocationFinder ? 1 : 0 : 0
            };
            return acc;
          }, {});
          setChains(chainValues);
        } else {
          console.error('Failed to fetch chain discounts');
        }
      } catch (err) {
        console.error('An error occurred while fetching chain discounts:', err);
      }
    };
  
    fetchChainDiscounts();
  }, [currentUser.tenantId]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError('');
    setSuccess('');
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);   

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/updateLocations?networkId=${currentUser.tenantId}`, {
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          setSuccess('File uploaded successfully!');
          setFile(null);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'An error occurred while uploading the file.');
        }
      } catch (err) {
        setError('An error occurred while uploading the file.');
      } finally {
        setLoading(false);
        window.scrollTo({ top: 0, behavior: 'smooth' }); 
      }
    };

  const handleViewLocations = async (chainId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getLocationsByChain?networkId=${currentUser.tenantId}&chainId=${chainId}`);
      if (response.ok) {
        const data = await response.json();
        setLocations(data.locations);
        setShowModal(true);
      } else {
        setError('Failed to load locations.');
      }
    } catch (err) {
      setError('An error occurred while loading locations.');
    }
  };

  const handleChainChange = (chainId, field, value) => {
    //console.log(`handleChainChange called with: chainId=${chainId}, field=${field}, value=${value}`);
    
    let newValue = value;
  
    if (field === 'discountType' && !value) {
      newValue = defaultDiscountType;
      //console.log(`Setting newValue for discountType to default: ${newValue}`);
    }
  
    if (field === 'showOnLocationFinder') {
      newValue = value ? 1 : 0;
     // console.log(`Setting newValue for showOnLocationFinder: ${newValue}`);
    }

   // console.log(`Updating chains state for chainId=${chainId}, field=${field}, newValue=${newValue}`);
  
    setChains(prevChains => {
      const updatedChains = {
        ...prevChains,
        [chainId]: {
          ...prevChains[chainId],
          [field]: newValue
        }
      };
      //console.log('Updated chains state:', updatedChains);
      return updatedChains;
    });
  };

  const validateChainData = (chainData) => {
    const { discountType, chainId, networkId, showOnLocationFinder } = chainData;

    // Ensure that the required fields are present and correctly formatted
    if (chainId === undefined || discountType === undefined || networkId === undefined || showOnLocationFinder === undefined) {
      return false;
    }

    // Ensure discountType matches allowed values
    const allowedDiscountTypes = ['None', 'BetterOf', 'CostPlus', 'RetailMinus'];
    return allowedDiscountTypes.includes(discountType);
  };

  const handleChainSubmit = async () => {
    const chainDataArray = Object.entries(chains)
      .map(([chainId, chainData]) => ({
        ...chainData,
        chainId: parseInt(chainId),
        networkId: currentUser.tenantId
      }))
      .filter(validateChainData);

    if (chainDataArray.length === 0) {
      setError('No valid chain data to submit.');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/updateChainDiscounts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(chainDataArray)
      });

      if (response.ok) {
        setSuccess('Chain discounts updated successfully!');
        setMessage('Chain discounts updated successfully!');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'An error occurred while updating the chain discounts.');
        setMessage(errorData.message || 'An error occurred while updating the chain discounts.');
      }
    } catch (err) {
      setError('An error occurred while updating the chain discounts.');
    } finally {
      setLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });      
    }
  };


  return (
    <div className="container mx-auto p-4">
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <Spinner />
        </div>
      )}

      {message && (
        <div
          className={`mb-4 p-4 rounded ${
            message.startsWith('Chain discounts updated') || message.startsWith('File uploaded')
              ? 'bg-green-200 text-green-600'
              : 'bg-red-200 text-red-600'
          }`}
        >
          {message}
          <button onClick={() => setMessage('')} className="ml-4 text-lg font-bold">
            ×
          </button>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="col-span-1 sm:col-span-2 lg:col-span-3">
          <form onSubmit={handleFileSubmit} className="p-4 rounded shadow-md border border-gray-300">
            <h2 className="mb-4 text-2xl font-bold">Upload Excel File</h2>
            <h3 className='mb-4'>Manually update location pricing by uploading a cost file.</h3>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="fileInput">
                Select File
              </label>
              <input
                type="file"
                id="fileInput"
                accept=".xlsx"
                onChange={handleFileChange}
                className="w-full px-3 py-2 text-gray-700 border rounded focus:outline-none focus:border-blue-500"
              />
            </div>
            {error && <p className="mb-4 text-red-500">{error}</p>}
            {success && <p className="mb-4 text-green-500">{success}</p>}
            <button
              type="submit"
              className="themed-button w-full"
              disabled={loading}
            >
              Upload
            </button>
          </form>
        </div>

        {Object.entries(chainIdToColumn).map(([chainId, chainName]) => (
          <div key={chainId} className="p-4 rounded shadow-md border border-gray-300">
            <h3 className="text-xl font-bold mb-2">{chainName}</h3>
            <div className="mb-2">
              <label className="block text-sm font-bold text-gray-700">Discount Type:</label>
              <select
                className="w-full px-3 py-2 text-gray-700 border rounded focus:outline-none focus:border-blue-500"
                value={chains[chainId]?.discountType || defaultDiscountType} 
                onChange={(e) => handleChainChange(chainId, 'discountType', e.target.value)}
              >
                <option value="None">None</option>
                <option value="CostPlus">Cost Plus</option>  
                <option value="RetailMinus">Retail Minus</option> 
                <option value="BetterOf">Better Of</option>
              </select>
            </div>
            <div className="mb-2">
              <label className="block text-sm font-bold text-gray-700">Cost Plus:</label>
              <input
                type="number"
                className="w-full px-3 py-2 text-gray-700 border rounded focus:outline-none focus:border-blue-500"
                value={chains[chainId]?.costPlusAmount || ''} 
                onChange={(e) => handleChainChange(chainId, 'costPlusAmount', e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm font-bold text-gray-700">Retail Minus:</label>
              <input
                type="number"
                className="w-full px-3 py-2 text-gray-700 border rounded focus:outline-none focus:border-blue-500"
                value={chains[chainId]?.retailMinusAmount || ''} 
                onChange={(e) => handleChainChange(chainId, 'retailMinusAmount', e.target.value)}
              />
            </div>
            <div className="mb-4">
  <label className="inline-flex items-center">
    <input 
      type="checkbox" 
      className="form-checkbox text-blue-500" 
      checked={chains[chainId]?.showOnLocationFinder === 1} 
      onChange={(e) => handleChainChange(chainId, 'showOnLocationFinder', e.target.checked)}
    />
    <span className="ml-2 text-gray-700">Show on Location Finder</span>
  </label>
</div>

            <button
              onClick={() => handleViewLocations(chainId)}
              className="themed-button w-full"
            >
              View Locations
            </button>
          </div>
        ))}
      </div>

      <div className="mt-8 flex justify-center">
        <button
          onClick={handleChainSubmit}
          className="themed-button fixed bottom-4 right-8" // Floating button style
          disabled={loading}
        >
          Submit Changes
        </button>
      </div>

      {showModal && (
        <LocationsModal
          onClose={() => setShowModal(false)}
          locations={locations}
          handleToggleDiscount={(locationId) => {
            setLocations((prevLocations) =>
              prevLocations.map((loc) =>
                loc.locationId === locationId ? { ...loc, noDiscounts: !loc.noDiscounts } : loc
              )
            );
          }}
        />
      )}
    </div>
  );
};

export default LocationData;