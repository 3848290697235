import React from 'react';

const ConfirmModalAch = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          &times;
        </button>
        <h2 className="text-xl font-bold mb-4">Confirm</h2>
        <p className="mb-4">{message}</p>
        <div className="flex justify-end">
          <button onClick={onClose} className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded mr-2">
            Cancel
          </button>
          <button onClick={onConfirm} className="themed-button font-bold py-2 px-4 rounded">
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModalAch;
