import React, { useState, useEffect } from 'react';

function InactivityModal({ onStayLoggedIn, onLogout, promptTime }) {
    const [remainingTime, setRemainingTime] = useState(promptTime / 1000); // Initialize with total prompt time in seconds

    useEffect(() => {
        // Start the countdown when the modal is displayed
        const intervalId = setInterval(() => {
            setRemainingTime(prevTime => prevTime - 1);
        }, 1000); // Decrease every second

        return () => {
            // Cleanup the interval when the component unmounts or when the modal is closed
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (remainingTime <= 0) {
            // If time runs out, trigger the logout function
            onLogout();
        }
    }, [remainingTime, onLogout]);

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-1">
            <div className="bg-white p-6 rounded shadow-md text-center">
                <h2 className="text-xl font-semibold mb-4">Session Timeout</h2>
                <p className="mb-4">
                    You have been inactive for a while. Do you want to stay logged in?
                </p>
                <p className="mb-6">
                    You will be logged out automatically in <span className="font-bold">{remainingTime}</span> seconds.
                </p>
                <div className="flex justify-center space-x-4">
                    <button
                        onClick={onStayLoggedIn}
                        className="themed-button"
                    >
                        Stay Logged In
                    </button>
                    <button
                        onClick={onLogout}
                        className="px-4 py-2 bg-red-700 rounded hover:bg-red-400 text-white"
                    >
                        Log Out
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InactivityModal;



// import React from 'react';

// function InactivityModal({ onStayLoggedIn, onLogout }) {
//     return (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-1">
//             <div className="bg-white p-6 rounded shadow-md text-center">
//                 <h2 className="text-xl font-semibold mb-4">Session Timeout</h2>
//                 <p className="mb-6">
//                     You have been inactive for a while. Do you want to stay logged in?
//                 </p>
//                 <div className="flex justify-center space-x-4">
//                     <button
//                         onClick={onStayLoggedIn}
//                         className="themed-button"
//                     >
//                         Stay Logged In
//                     </button>
//                     <button
//                         onClick={onLogout}
//                         className="px-4 py-2 bg-red-700 rounded hover:bg-red-400 text-white"
//                     >
//                         Log Out
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default InactivityModal;
