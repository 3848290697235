import React, { useState, useMemo } from 'react';
import MainTableV2 from './MainTableV2';
import Spinner from './Spinner';
import { useTheme } from './ThemeContext';

const LogsModal = ({ logs, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const loading = false;
  const { theme } = useTheme();

  const primaryColor = theme.primary;

  // Define the columns for the logs table
  const columns = useMemo(() => [
    {
      name: 'Action',
      selector: log => log.Action,
      sortable: true,
      style: { whiteSpace: 'nowrap' }
    },
    {
      name: 'Timestamp',
      selector: log => new Date(log.Timestamp).toLocaleString(),
      sortable: true,
      style: { whiteSpace: 'nowrap' }
    }
  ], []);

  // Define filter configuration
  const filterConfig = [
    { key: 'Action', type: 'string' },
    { key: 'Timestamp', type: 'date' }
  ];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl w-full">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-bold">User Activity Logs</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="p-4 overflow-y-auto" style={{ maxHeight: '80vh' }}>
          {loading && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <Spinner />
            </div>
          )}

          <MainTableV2
            columns={columns}
            data={logs}
            primaryColor={primaryColor} 
            loading={loading}            
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            filterConfig={filterConfig}
          />
        </div>

        <div className="p-4 border-t flex justify-end">
          <button onClick={onClose} className="themed-button">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogsModal;
