import React, { useState, useContext, useEffect } from 'react';
import { routingNumber } from 'us-bank-account-validator';
import { useTheme } from './ThemeContext';
import { AuthContext } from './AuthContext';
import MobileHeader from './MobileHeader';
import Spinner from './Spinner';
import checkImage from './images/check_details.gif';
import ConfirmModalAch from './ConfirmModalAch';
import SuccessModalAch from './SuccessModalAch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


const AchPaymentModal = ({ isOpen, onClose }) => {
  const { theme } = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showAddBankModal, setShowAddBankModal] = useState(false);
  const [nameOnAccount, setNameOnAccount] = useState('');
  const [newRoutingNum, setNewRoutingNum] = useState('');
  const [isRoutingValid, setIsRoutingValid] = useState(null);
  const [newAcctNum, setNewAcctNum] = useState('');
  const [newAcctNumConfirm, setNewAcctNumConfirm] = useState('');
  const [isAcctMatch, setIsAcctMatch] = useState(true);
  const [isPrimary, setIsPrimary] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [ipAddress, setIpAddress] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const subDomain = window.location.hostname.split('.')[0];

  const fetchIpAddress = async () => {
    const ipInfoToken = 'ad22ffe4eb7ee0';
    try {
      const response = await fetch(`https://ipinfo.io/json?token=${ipInfoToken}`);
      //console.log("response: ", response);
      if (!response.ok) throw new Error('Failed to fetch IP address');
      const data = await response.json();
      // console.log('Data: ', data);
      setIpAddress(data.ip);
    } catch (error) {
      console.error('Error fetching IP address and location:', error);
      setIpAddress('0.0.0.0');
    }
  };
  useEffect(() => {
    fetchIpAddress();
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchBankAccounts();
      setShowSuccessModal(false);
    }
  }, [isOpen]);

  const fetchBankAccounts = async () => {
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getUserAccounts',
          payload: { userId: currentUser.userId }
        }),
      });
      const data = await response.json();
      // console.log(data);
      setAccounts(data);
    } catch (error) {
      console.log('Error fetching bank accounts:', error);
      setAccounts([]);
    }
  };

  const handleAccountChange = (e) => {
    setSelectedAccount(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleRoutingNumChange = (e) => {
    const value = e.target.value;
    setNewRoutingNum(value);
  };

  const validateRoutingNumberOnBlur = () => {
    const validation = routingNumber(newRoutingNum);
    setIsRoutingValid(validation.isValid);
  };

  const clearRoutingValidationError = () => {
    setIsRoutingValid(null);
  };

  const handleNewAcctNumChange = (e) => {
    setNewAcctNum(e.target.value);
  };

  const handleNewAcctNumConfirmChange = (e) => {
    setNewAcctNumConfirm(e.target.value);
    setIsAcctMatch(e.target.value === newAcctNum);
  };

  const handleAddBank = async (e) => {
    e.preventDefault();
    if (!isRoutingValid || !isAcctMatch || !nameOnAccount) {
      return;
    }
    setShowConfirmModal(true);
    setConfirmMessage('Are you sure you want to add this bank account?');
  };

  const confirmAddBank = async () => {
    setShowConfirmModal(false);
    setLoading(true);
    const payload = {
      userId: currentUser.userId,
      nameOnAccount,
      routingNum: newRoutingNum,
      acctNum: newAcctNum,
      subDomain: subDomain,
      cardGroupId: currentUser.cardGroupID,
      policyNumber: currentUser.policyNumber,
      isPrimary: isPrimary ? 1 : 0
    };
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'addUserAccount',
          payload
        }),
      });
      if (response.ok) {
        fetchBankAccounts();
        setShowAddBankModal(false);
        setShowSuccessModal(true);
        setSuccessMessage('Bank account added successfully.');
      } else {
        // Handle error response
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedAccount || !amount) {
      return;
    }
    setShowConfirmModal(true);
    setConfirmMessage('Are you sure you want to submit this ACH transaction?');
  };

  const confirmSubmit = async () => {
    setShowConfirmModal(false);
    setLoading(true);
    const payload = {
      userId: currentUser.userId,
      sbddData007Id: selectedAccount,
      amount,
      ipAddress: ipAddress,
      subDomain: subDomain,
    };
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'submitAchTransaction',
          payload
        }),
      });
      if (response.ok) {
        setSuccess(true);
        setShowSuccessModal(true);
        setSuccessMessage('ACH transaction submitted successfully.');
      } else {
        // Handle error response
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className='p-4'>
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto relative">
            <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
              &times;
            </button>
            <MobileHeader theme={theme} />
            <div className="p-6 m-2 bg-white rounded-lg max-w-lg mx-auto">
              <img src={theme.logo} alt="Logo" className="w-32 mx-auto mb-4" />
              <h2 className="text-2xl text-center font-bold mb-4">Add Funds</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Select Bank Account</label>
                  {accounts.length > 0 ? (
                    <>
                      <select
                        value={selectedAccount}
                        onChange={handleAccountChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="" disabled>Select an account</option>
                        {accounts.map((account) => (
                          <option key={account.Id} value={account.Id}>
                            Account ending in {account.LastFourDigits} {account.IsPrimary ? '(Primary)' : ''}
                          </option>
                        ))}
                      </select>
                      <div className="flex justify-end mt-2">
                        <span
                          onClick={() => setShowAddBankModal(true)}
                          className="flex items-center text-blue-500 hover:text-blue-700 cursor-pointer"
                        >
                          <FontAwesomeIcon icon={faPlus} className="mr-1" />
                          Add New Account
                        </span>
                      </div>
                    </>
                  ) : (
                    <div>
                      <p className="text-gray-500 mb-2">No accounts available. Please add an account.</p>
                      <button
                        onClick={() => setShowAddBankModal(true)}
                        className="w-full themed-button p-2 rounded-md"
                      >
                        Add Account
                      </button>
                    </div>
                  )}
                </div>


                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Amount</label>
                  <input
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Minimum $100.00"
                    min="100"
                    increment="1.00"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full themed-button p-2 rounded-md"
                >
                  {loading ? <Spinner /> : 'Submit'}
                </button>
              </form>
              {success && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto text-center">
                    <h2 className="text-xl font-bold mb-4">Success!</h2>
                    <p className="mb-4">{theme.welcomeMessage}</p>
                    <button
                      className="w-full p-2 bg-blue-500 text-white rounded-md"
                      style={{ backgroundColor: theme.primary, color: theme.BtnTxtPrimaryColor }}
                      onClick={() => window.location.href = '/dashboard'}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showAddBankModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className='p-4'>
            <div className="bg-white mt-20 p-6 rounded-lg shadow-lg max-w-lg mx-auto relative">
              <button
                onClick={() => setShowAddBankModal(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
              <h2 className="text-xl font-bold mb-4">Add Bank Account</h2>
              <form onSubmit={handleAddBank}>
                <img src={checkImage} alt="Check Details" className="w-full mb-4" />
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Name on Account</label>
                  <input
                    type="text"
                    value={nameOnAccount}
                    onChange={(e) => setNameOnAccount(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Routing Number</label>
                  <input
                    type="text"
                    value={newRoutingNum}
                    onChange={handleRoutingNumChange}
                    onBlur={validateRoutingNumberOnBlur}
                    onFocus={clearRoutingValidationError}
                    className={`w-full p-2 border ${isRoutingValid === false ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                    maxLength="9"
                    autocomplete="off"
                  />
                  {isRoutingValid === false && <p className="text-red-500 text-sm mt-1">Invalid routing number</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Account Number</label>
                  <input
                    type="password"
                    value={newAcctNum}
                    onChange={handleNewAcctNumChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    autocomplete="new-password"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Confirm Account Number</label>
                  <input
                    type="password"
                    value={newAcctNumConfirm}
                    onChange={handleNewAcctNumConfirmChange}
                    className={`w-full p-2 border ${isAcctMatch ? 'border-gray-300' : 'border-red-500'} rounded-md`}
                  />
                  {!isAcctMatch && <p className="text-red-500 text-sm mt-1">Account numbers do not match</p>}
                </div>
                <div className="mb-4 flex items-center">
                  <input
                    type="checkbox"
                    checked={isPrimary}
                    onChange={(e) => setIsPrimary(e.target.checked)}
                    className="mr-2"
                  />
                  <label className="text-sm font-medium">Make this my primary account</label>
                </div>
                <button
                  type="submit"
                  className="w-full themed-button p-2 rounded-md"
                >
                  {loading ? <Spinner /> : 'Add Bank'}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      <ConfirmModalAch
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={showAddBankModal ? confirmAddBank : confirmSubmit}
        message={confirmMessage}
      />

      <SuccessModalAch
        isOpen={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          if (showAddBankModal) {
            setShowAddBankModal(false);
          } else {
            onClose();
          }
        }}
        message={successMessage}
      />


    </>
  );

};



export default AchPaymentModal;
