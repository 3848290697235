import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cardGroupData: {
      cardGroup: null,
      cardSummaries: [],
      userTenantMappings: []
    }
  };

export const cardGroupSlice = createSlice({
  name: 'cardGroup',
  initialState,
  reducers: {
    setCardGroupData: (state, action) => {
        // console.log("Updating state with:", action.payload);        
        state.cardGroupData.cardGroup = action.payload.cardGroup || null;
        state.cardGroupData.cardSummaries = action.payload.cardSummaries || [];
        state.cardGroupData.userTenantMappings = action.payload.userTenantMappings || [];
      },
    updateCardGroupData: (state, action) => {
      state.cardGroupData.cardGroup = { ...state.cardGroupData.cardGroup, ...action.payload };
    },
    addCardsToGroup: (state, action) => {
      if (state.cardGroupData && state.cardGroupData.cardSummaries) {
        state.cardGroupData.cardSummaries = [
          ...state.cardGroupData.cardSummaries,
          ...action.payload
        ];
      }
    },
    removeCardFromGroup: (state, action) => {
        state.cardGroupData.cardSummaries = state.cardGroupData.cardSummaries.filter(card => card.CardNumber !== action.payload);
      },
    clearCardGroupData: (state) => {
      state.cardGroupData = null;
    },
    addUserTenantMappings: (state, action) => {
        const existingUserIds = new Set(state.cardGroupData.userTenantMappings.map(user => user.UserId));
        const filteredNewUsers = action.payload.filter(user => !existingUserIds.has(user.UserId));
        state.cardGroupData.userTenantMappings = [
          ...state.cardGroupData.userTenantMappings,
          ...filteredNewUsers
        ];
      },
      removeUserTenantMapping: (state, action) => {
        state.cardGroupData.userTenantMappings = state.cardGroupData.userTenantMappings.filter(user => user.UserId !== action.payload);
      },    
  },
});

export const { setCardGroupData, updateCardGroupData, addCardsToGroup, clearCardGroupData, removeCardFromGroup, addUserTenantMappings, removeUserTenantMapping } = cardGroupSlice.actions;

export const selectCardGroupData = (state) => state.cardGroup.cardGroupData;

export default cardGroupSlice.reducer;
