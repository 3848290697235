import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import EmailEditor from 'react-email-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './AuthContext';
import Spinner from './Spinner';
import { useSelector } from 'react-redux'; // Import useSelector to access the Redux store

const EmailTemplateEditor = () => {
  const emailEditorRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { currentUser } = useAuth();
  const policies = useSelector(state => state.policies.activePolicies); // Get policies from Redux store

  const templateId = location.state?.templateId || null;  

  // State for managing template-related fields
  const [templateName, setTemplateName] = useState('');
  const [subjectLine, setSubjectLine] = useState('');
  const [designContent, setDesignContent] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');  
  const [policyNumber, setPolicyNumber] = useState('');

  // State for alerts and test email modal
  const [showTestModal, setShowTestModal] = useState(false);
  const [testEmail, setTestEmail] = useState(currentUser?.email || '');
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [isEditorReady, setIsEditorReady] = useState(false);

  // Function to handle editor load
  const onLoad = () => {
    setIsEditorReady(true);
  };

  // Helper function to display an alert
  const showAlert = (message, type) => {
    setAlert({ show: true, message, type });
  };

  // Helper function to handle form validation
  const validateForm = () => {
    if (!templateName || !subjectLine) {
      showAlert('Template name and subject line are required.', 'error');
      return false;
    }
    if (currentUser.isAdmin && !templateId && !policyNumber) {
      showAlert('Policy selection is required for admins.', 'error');
      return false;
    }
    return true;
  };

  // Function to fetch the template data by Id
  const fetchTemplate = async () => {
    if (!templateId) return;

    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'getEmailTemplateById',
          payload: { templateId },
        }),
      });

      const result = await response.json();   
      console.log('Fetch Template result:', result);   

      if (response.ok && result.success) {
        const { name, subjectLine, designContent, htmlContent, policyNumber } = result.template;
        setTemplateName(name);
        setSubjectLine(subjectLine);
        setDesignContent(designContent);
        setHtmlContent(htmlContent);
        setPolicyNumber(policyNumber || '');        
      } else {
        showAlert(result.message || 'Error fetching template data.', 'error');
      }
    } catch (error) {
      showAlert('Error occurred while fetching template data.', 'error');
    } finally {
        setLoading(false);
    }
  };

  // Load the design into the editor once fetched
  useEffect(() => {
    fetchTemplate();
  }, [templateId]);

  useEffect(() => {
    if (isEditorReady && designContent) {
      try {
        const parsedDesign = JSON.parse(designContent);
        emailEditorRef.current.editor.loadDesign(parsedDesign);
      } catch (error) {
        console.error('Error parsing designContent:', error);
        showAlert('Error loading email design. Please refresh the page.', 'error');
      }
    }
  }, [isEditorReady, designContent]);

  // Function to handle saving the template
  const handleSaveTemplate = async () => {
    if (!validateForm()) return;

    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      console.log('subjectLine:', subjectLine);

      if (!subjectLine || !templateName) {
        showAlert('Template name and subject line are required.', 'error');
        return;
      }

      const emailTemplate = {        
        name: templateName,
        subjectLine,
        htmlContent: html,
        designContent: design,
        userId: currentUser?.userId,
        templateId: templateId || null,
        policyNumber: currentUser.isAdmin ? policyNumber : currentUser.policyNumber,
        isCRMTemplate: false,
      };

      try {
        const response = await fetch(`${apiUrl}/dataServer`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            action: 'addEmailTemplate',
            payload: emailTemplate     
          }),
        });

        const result = await response.json();
        console.log('Save Template result:', result);

        if (result.success) {
          showAlert(result.message || 'Email template saved successfully.', 'success');
          if (!templateId) {
            setTimeout(() => navigate('/marketing'), 1500);
          }
        } else {
          showAlert(`Error saving template: ${result.message}`, 'error');
        }
      } catch (error) {
        showAlert('Error occurred while saving template.', 'error');
      }
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to handle sending a test email
  const handleSendTestEmail = async () => {
    if (!validateForm()) return;

    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      if (!testEmail) {
        showAlert('Test email address is required.', 'error');
        setShowTestModal(false);
        return;
      }

      if (!validateEmail(testEmail)) {
        showAlert('Please enter a valid email address.', 'error');
        setShowTestModal(false);
        return;
      }

      const emailTemplate = {
        name: templateName,
        subjectLine,
        htmlContent: html,
        designContent: design,
        userId: currentUser?.userId,
        testEmail,
        policyNumber: currentUser.isAdmin ? policyNumber : currentUser.policyNumber,
      };

      try {
        const response = await fetch(`${apiUrl}/dataServer`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            action: 'sendTestEmail',
            payload: emailTemplate,
          }),
        });

        const result = await response.json();       

        if (response.ok) {
          showAlert('Test email sent successfully.', 'success');
          setShowTestModal(false);  // Close the modal on success
        } else {
          showAlert(result.message || 'Error sending test email.', 'error');
          setShowTestModal(false);
        }
      } catch (error) {
        showAlert('Error occurred while sending test email.', 'error');
        setShowTestModal(false);
      }
    });
  };

  return (
    <>
    {loading && <Spinner />}
    <div className="container mx-auto p-8">
      <div className="text-end mb-4">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-gray-700 text-sm mr-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
          Go Back
        </button>
      </div>
      
      <h1 className="text-2xl font-bold mb-6">
        {templateId ? `Editing Email Template: ${templateName}` : 'Create New Email Template'}
      </h1>

      {/* Alert div */}
      {alert.show && (
        <div className={`mb-4 p-4 rounded-md ${alert.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'} flex justify-between`}>
          <span>{alert.message}</span>
          <button onClick={() => setAlert({ show: false, message: '', type: '' })} className="text-lg font-bold">
            &times;
          </button>
        </div>
      )}

      {/* Input fields for template name and subject line */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">Template Name:</label>
        <input
          type="text"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          placeholder="Enter Template Name"
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">Subject Line:</label>
        <input
          type="text"
          value={subjectLine}
          onChange={(e) => setSubjectLine(e.target.value)}
          placeholder="Enter Subject Line"
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      {/* Admin-specific fields */}
      {(currentUser.isAdmin && !templateId) && (
        <>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Policy:</label>
            <select
              id="policyNumber"
              value={policyNumber}
              onChange={(e) => setPolicyNumber(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              required
            >
              <option value="">Select A Policy</option> 
              {policies.map(policy => (
                <option key={policy.PolicyNumber} value={policy.PolicyNumber}>
                  {policy.PolicyNumber} - {policy.Description}
                </option>
              ))}
            </select>
          </div>          
        </>
      )}

      {/* Email Editor */}
      <div className="mb-6">
          <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
      </div>

      {/* Submit buttons */}
      <div className="text-right mb-4 m-2">
        <button
          onClick={() => setShowTestModal(true)}  // Open modal for test email
          className="themed-button mr-2"
        >
          Send Test Email
        </button>
        <button
          onClick={handleSaveTemplate}
          className="themed-button"
        >
           {templateId ? `Update Template` : 'Save Template'}
        </button>
      </div>

      {/* Modal for Test Email Input */}
      {showTestModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md w-96">
            <h2 className="text-lg font-semibold mb-4">Send Test Email</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Send To:</label>
              <input
                type="email"
                value={testEmail}
                onChange={(e) => setTestEmail(e.target.value)}
                placeholder="Enter email address"
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div className="flex justify-end">
              <button
                className="themed-button mr-2"
                onClick={handleSendTestEmail}
              >
                Send Email
              </button>
              <button
                className="themed-button bg-red-500 text-white"
                onClick={() => setShowTestModal(false)} 
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default EmailTemplateEditor;



// import React, { useRef, useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import EmailEditor from 'react-email-editor';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { useAuth } from './AuthContext';
// import Spinner from './Spinner';
// import { set } from 'date-fns';

// const EmailTemplateEditor = () => {
//   const emailEditorRef = useRef(null);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const apiUrl = process.env.REACT_APP_API_URL;
//   const { currentUser } = useAuth();

//   const templateId = location.state?.templateId || null;  

//   // State for managing template-related fields
//   const [templateName, setTemplateName] = useState('');
//   const [subjectLine, setSubjectLine] = useState('');
//   const [designContent, setDesignContent] = useState(null);
//   const [htmlContent, setHtmlContent] = useState('');

//   // State for alerts and test email modal
//   const [showTestModal, setShowTestModal] = useState(false);
//   const [testEmail, setTestEmail] = useState(currentUser?.email || '');
//   const [alert, setAlert] = useState({ show: false, message: '', type: '' });
//   const [loading, setLoading] = useState(false);
//   const [isEditorReady, setIsEditorReady] = useState(false);

//   // Function to handle editor load
//   const onLoad = () => {
//     setIsEditorReady(true);
//   };

//   // Helper function to display an alert
//   const showAlert = (message, type) => {
//     setAlert({ show: true, message, type });
//   };

//   // Helper function to handle form validation
//   const validateForm = () => {
//     if (!templateName || !subjectLine) {
//       showAlert('Template name and subject line are required.', 'error');
//       return false;
//     }
//     return true;
//   };

//   // Function to fetch the template data by Id
//   const fetchTemplate = async () => {
//     if (!templateId) return;

//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/dataServer`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           action: 'getEmailTemplateById',
//           payload: { templateId },
//         }),
//       });

//       const result = await response.json();      

//       if (response.ok && result.success) {
//         const { name, subjectLine, designContent, htmlContent } = result.template;
//         setTemplateName(name);
//         setSubjectLine(subjectLine);
//         setDesignContent(designContent);
//         setHtmlContent(htmlContent);
//       } else {
//         showAlert(result.message || 'Error fetching template data.', 'error');
//       }
//     } catch (error) {
//       showAlert('Error occurred while fetching template data.', 'error');
//     } finally {
//         setLoading(false);
//     }
//   };

//   // Load the design into the editor once fetched
//   useEffect(() => {
//     fetchTemplate();
//   }, [templateId]);

//   // useEffect(() => {
//   //   if (designContent) {
//   //     console.log('designContent:', designContent);
//   //     try {
//   //       const parsedDesign = JSON.parse(designContent);  // Parse the designContent string
//   //       emailEditorRef.current.editor.loadDesign(parsedDesign);   // Load parsed design into the editor
//   //     } catch (error) {
//   //       console.error('Error parsing designContent:', error);
//   //     }
//   //   }
//   // }, [designContent]);

//   useEffect(() => {
//     if (isEditorReady && designContent) {
//       try {
//         const parsedDesign = JSON.parse(designContent);
//         emailEditorRef.current.editor.loadDesign(parsedDesign);
//       } catch (error) {
//         console.error('Error parsing designContent:', error);
//         showAlert('Error loading email design. Please refresh the page.', 'error');
//       }
//     }
//   }, [isEditorReady, designContent]);

//   // Function to handle saving the template
//   const handleSaveTemplate = async () => {
//     if (!validateForm()) return;

//     emailEditorRef.current.editor.exportHtml(async (data) => {
//       const { design, html } = data;

//       const emailTemplate = {        
//         name: templateName,
//         subjectLine,
//         htmlContent: html,
//         designContent: design,
//         userId: currentUser?.userId,
//         templateId: templateId || null,
//       };

//       try {
//         const response = await fetch(`${apiUrl}/dataServer`, {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             action: 'addEmailTemplate',
//             payload: emailTemplate     
//           }),
//         });

//         const result = await response.json();
//         console.log('Save Template result:', result);

//         if (response.ok) {
//           showAlert(result.message || 'Email template saved successfully.', 'success');
//           if (!templateId) {
//           setTimeout(() => navigate('/marketing'), 1500);
//           }
//         } else {
//           showAlert(result.message || 'Error saving template.', 'error');
//         }
//       } catch (error) {
//         showAlert('Error occurred while saving template.', 'error');
//       }
//     });
//   };

//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   // Function to handle sending a test email
//   const handleSendTestEmail = async () => {
//     if (!validateForm()) return;

//     emailEditorRef.current.editor.exportHtml(async (data) => {
//       const { design, html } = data;

//       if (!testEmail) {
//         showAlert('Test email address is required.', 'error');
//         setShowTestModal(false);
//         return;
//       }

//       if (!validateEmail(testEmail)) {
//         showAlert('Please enter a valid email address.', 'error');
//         setShowTestModal(false);
//         return;
//       }

//       const emailTemplate = {
//         name: templateName,
//         subjectLine,
//         htmlContent: html,
//         designContent: design,
//         userId: currentUser?.userId,
//         testEmail,  // This is the email entered in the modal
//       };

//       try {
//         const response = await fetch(`${apiUrl}/dataServer`, {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             action: 'sendTestEmail',
//             payload: emailTemplate,
//           }),
//         });

//         const result = await response.json();       

//         if (response.ok) {
//           showAlert('Test email sent successfully.', 'success');
//           setShowTestModal(false);  // Close the modal on success
//         } else {
//           showAlert(result.message || 'Error sending test email.', 'error');
//           setShowTestModal(false);
//         }
//       } catch (error) {
//         showAlert('Error occurred while sending test email.', 'error');
//         setShowTestModal(false);
//       }
//     });
//   };

//   return (
//     <>
//     {loading && <Spinner />}
//     <div className="container mx-auto p-8">
//       <div className="text-end mb-4">
//         <button
//           type="button"
//           onClick={() => navigate(-1)}
//           className="text-gray-700 text-sm mr-4"
//         >
//           <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
//           Go Back
//         </button>
//       </div>
      
//       <h1 className="text-2xl font-bold mb-6">
//         {templateId ? `Editing Email Template: ${templateName}` : 'Create New Email Template'}
//       </h1>

//       {/* Alert div */}
//       {alert.show && (
//         <div className={`mb-4 p-4 rounded-md ${alert.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'} flex justify-between`}>
//           <span>{alert.message}</span>
//           <button onClick={() => setAlert({ show: false, message: '', type: '' })} className="text-lg font-bold">
//             &times;
//           </button>
//         </div>
//       )}

//       {/* Input fields for template name and subject line */}
//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700 mb-2">Template Name:</label>
//         <input
//           type="text"
//           value={templateName}
//           onChange={(e) => setTemplateName(e.target.value)}
//           placeholder="Enter Template Name"
//           className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//         />
//       </div>

//       <div className="mb-4">
//         <label className="block text-sm font-medium text-gray-700 mb-2">Subject Line:</label>
//         <input
//           type="text"
//           value={subjectLine}
//           onChange={(e) => setSubjectLine(e.target.value)}
//           placeholder="Enter Subject Line"
//           className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//         />
//       </div>

//       {/* Email Editor */}
//       <div className="mb-6">
//           <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
//       </div>

//       {/* Submit buttons */}
//       <div className="text-right mb-4 m-2">
//         <button
//           onClick={() => setShowTestModal(true)}  // Open modal for test email
//           className="themed-button mr-2"
//         >
//           Send Test Email
//         </button>
//         <button
//           onClick={handleSaveTemplate}
//           className="themed-button"
//         >
//            {templateId ? `Update Template` : 'Save Template'}
//         </button>
//       </div>

//       {/* Modal for Test Email Input */}
//       {showTestModal && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-md w-96">
//             <h2 className="text-lg font-semibold mb-4">Send Test Email</h2>
//             <div className="mb-4">
//               <label className="block text-sm font-medium text-gray-700 mb-2">Send To:</label>
//               <input
//                 type="email"
//                 value={testEmail}
//                 onChange={(e) => setTestEmail(e.target.value)}
//                 placeholder="Enter email address"
//                 className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                 required
//               />
//             </div>
//             <div className="flex justify-end">
//               <button
//                 className="themed-button mr-2"
//                 onClick={handleSendTestEmail}
//               >
//                 Send Email
//               </button>
//               <button
//                 className="themed-button bg-red-500 text-white"
//                 onClick={() => setShowTestModal(false)} 
//               >
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//     </>
//   );
// };

// export default EmailTemplateEditor;
