// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// export const fetchPolicies = createAsyncThunk(
//   'policies/fetchPolicies',
//   async (userId) => {
//     const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getAdminAllPolicies', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ userId })
//     });
//     const data = await response.json();
//     // console.log('Policies From Slice: ', data);
//     return data;
//   }
// );

// const policiesSlice = createSlice({
//   name: 'policies',
//   initialState: {
//     policies: [],
//     activePolicies: [],
//     status: 'idle',
//     error: null,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchPolicies.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(fetchPolicies.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         // Check if the response is an array
//         if (Array.isArray(action.payload)) {
//           state.policies = action.payload;        
//           state.activePolicies = action.payload.filter(policy => policy.Active);
//         } else {
//           // Handle non-array response (e.g., error message)
//           state.error = 'Received unexpected response format';
//         }
//       })
//       .addCase(fetchPolicies.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.error.message;
//       });
//   },
// });

// export default policiesSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const apiUrl= process.env.REACT_APP_API_URL;

export const fetchPolicies = createAsyncThunk(  
  'policies/fetchPolicies',
  async (userId) => {    
    const response = await fetch(`${apiUrl}/dataServer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        action: 'fetchPoliciesAndCardGroups',
        payload: { userId } 
      })
    });
    const data = await response.json();
    // console.log('Policies From Slice: ', data);
    return data;
  }
);

const policiesSlice = createSlice({
  name: 'policies',
  initialState: {
    policies: [],
    cardGroups: [],
    activePolicies: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPolicies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPolicies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { policies, cardGroups } = action.payload;

        // Set policies and card groups in the state
        state.policies = policies;
        state.cardGroups = cardGroups;

        // Filter active policies and include all card groups
        state.activePolicies = [
          ...policies.filter(policy => policy.Active)          
        ];
      })
      .addCase(fetchPolicies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default policiesSlice.reducer;

