import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectPolicyData, setPolicyData } from './policyAdminEditSlice';

const PolicyPromptForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch(); 

  // Get the current infos array from Redux store
  const { infos: rawInfos } = useSelector(selectPolicyData);
  const infos = Array.isArray(rawInfos) ? rawInfos : [rawInfos]; // Ensure infos is always an array  
  const currentPolicyData = useSelector(selectPolicyData);

  const [prompt, setPrompt] = useState(location.state?.prompt || {});
  const [formData, setFormData] = useState({
    infoId: prompt.infoId || '',
    lengthCheck: prompt.lengthCheck || 'false',
    matchValue: prompt.matchValue || null,
    maximum: prompt.maximum || '0',
    minimum: prompt.minimum || '0',
    reportValue: prompt.reportValue || null,
    validationType: prompt.validationType || '',
    value: prompt.value || '0',
  });
  const existingInfoIds = new Set(infos.map(info => info.infoId));
  const infoIdOptions = [
    { key: 'BDAY', value: 'Birthday' },
    { key: 'BLID', value: 'Billing ID' },
    { key: 'CNTN', value: 'Control Number' },
    { key: 'CRDR', value: 'Card Description' },
    { key: 'DLIC', value: 'Driver’s License #' },
    { key: 'DLST', value: 'Driver’s License State' },
    { key: 'DRID', value: 'Driver ID' },
    { key: 'EXPT', value: 'Expense Type' },
    { key: 'FSTI', value: 'First Initial' },
    { key: 'GLCD', value: 'GL Code' },
    { key: 'HBRD', value: 'Hubometer' },
    { key: 'HRRD', value: 'Reefer Hour Reading' },
    { key: 'LCST', value: 'License State' },
    { key: 'LICN', value: 'License #' },
    { key: 'LSTN', value: 'Last Name' },
    { key: 'NAME', value: 'Driver Name' },
    { key: 'ODRD', value: 'Odometer' },
    { key: 'OINV', value: 'Original Invoice' },
    { key: 'PONB', value: 'Purchase Order #' },
    { key: 'PPIN', value: 'Personal Identifier' },
    { key: 'RTMP', value: 'Reefer Temperature' },
    { key: 'SSUB', value: 'Sub Fleet Identifier' },
    { key: 'TLOC', value: 'Terminal Location' },
    { key: 'TRIP', value: 'Trip #' },
    { key: 'TRLR', value: 'Trailer #' },
    { key: 'UNIT', value: 'Unit #' },
  ];
  const validationTypeOptions = [
    'ALPHABETIC',
    'ALPHA_NUMERIC',
    'NUMERIC',
    'REPORT_ONLY',
    'EXACT_MATCH',
    'ACCRUAL_CHECK',
    'INFO_POOL'
  ];

  useEffect(() => {
    console.log("Received prompt data:", prompt);
    console.log(formData);
    console.log("Infos from Redux:", infos);

  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDelete = () => {
    const updatedInfos = infos.filter(info => info.infoId !== formData.infoId);

    dispatch(
      setPolicyData({
        ...currentPolicyData,
        infos: updatedInfos,
      })
    );

    console.log("Prompt deleted:", formData.infoId);
    navigate(-1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();    
    const existingInfo = infos.find(info => info.infoId === formData.infoId);  
    let updatedInfos;
    if (existingInfo) {      
      updatedInfos = infos.map((info) =>
        info.infoId === formData.infoId ? formData : info
      );
    } else {      
      updatedInfos = [...infos, formData];
    }    
    dispatch(
      setPolicyData({
        ...currentPolicyData, // Spread the existing state
        infos: updatedInfos, // Update the infos array
      })
    );
    console.log("Form data submitted:", formData);  
    navigate(-1);  
  };
  
  

  return (
    <div>
      {/* Delete Button (only if editing) */}
      {location.state?.isEditing && (
        <div className="text-right mb-4">
          <button
            type="button"
            onClick={handleDelete}
            className="px-4 py-2 text-white rounded bg-red-500"
          >
            Delete Prompt
          </button>
        </div>
      )}

    <form onSubmit={handleSubmit} className="space-y-2 m-8">
      {/* Info ID */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Info ID
        </label>
        <select
    name="infoId"
    onChange={handleChange}
    value={formData.infoId}
    className="mt-1 p-2 w-full border rounded-md"
    disabled={location.state?.isEditing}
  >
    <option value="" disabled>Select an Info ID</option>
    {infoIdOptions.map((option) => (
      <option 
        key={option.key} 
        value={option.key}
        disabled={existingInfoIds.has(option.key) && option.key !== formData.infoId} // Disable the option if it already exists and is not the current one
      >
        {option.value}
      </option>
    ))}
  </select>
      </div>
      {/* Length Check */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Length Check
        </label>
        <select
          name="lengthCheck"
          onChange={handleChange}
          value={formData.lengthCheck}
          className="mt-1 p-2 w-full border rounded-md"
        >
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>
      {/* Match Value */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Match Value
        </label>
        <input
          type="text"
          name="matchValue"
          onChange={handleChange}
          value={formData.matchValue || ''}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      {/* Maximum */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Maximum
        </label>
        <input
          type="number"
          name="maximum"
          onChange={handleChange}
          value={formData.maximum}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      {/* Minimum */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Minimum
        </label>
        <input
          type="number"
          name="minimum"
          onChange={handleChange}
          value={formData.minimum}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      {/* Report Value */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Report Value
        </label>
        <input
          type="text"
          name="reportValue"
          onChange={handleChange}
          value={formData.reportValue || ''}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      {/* Validation Type */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Validation Type
        </label>
        <select
          name="validationType"
          onChange={handleChange}
          value={formData.validationType}
          className="mt-1 p-2 w-full border rounded-md"
        >
          <option value="" disabled>Select a Validation Type</option>
          {validationTypeOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {/* Value */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Value
        </label>
        <input
          type="text"
          name="value"
          onChange={handleChange}
          value={formData.value}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      {/* Submit Button */}
      <button
        type="submit"
        className="mt-4 px-4 py-2 text-white rounded themed-button"
      >
        {id ? 'Add Prompt' : 'Update Prompt'}
      </button>
    </form>
    </div>
  );
};
export default PolicyPromptForm;
