import React from 'react';
import DataTable from 'react-data-table-component';
import Spinner from './Spinner';
import styled from 'styled-components';

const CustomTableContainer = styled.div`
  .rdt_TableHeadRow {
    background-color: #f4f4f4;
  }
`;

const SubTableV2 = ({ columns, data, loading, alertMessage, clearAlert, title, children, infoMessage }) => {
  // Memoize columns setup
  const columnsSetup = React.useMemo(() => columns, [columns]);

  return (
    <CustomTableContainer>
      {loading && <Spinner />}
      {alertMessage && (
        <div className={`alert p-4 rounded ${alertMessage.startsWith('Successfully') ? 'bg-green-200 text-green-600' : 'bg-red-200 text-red-600'} flex justify-between items-center`}>
          {alertMessage}
          <button onClick={clearAlert} className="text-lg font-bold">×</button>
        </div>
      )}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-lg font-semibold text-gray-700">{title}</h1>
          {children}
        </div>
        {infoMessage && (
          <div className="bg-blue-100 border-blue-500 text-blue-700 p-2 mb-2 rounded-md" role="alert">
            {infoMessage}
          </div>
        )}
        <DataTable
          columns={columnsSetup}
          data={data}
          pagination
          highlightOnHover
          responsive
          customStyles={{
            headCells: {
              style: {
                fontSize: '12px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                paddingLeft: '8px',
                paddingRight: '8px',
                backgroundColor: '#EAEAEA',                
                // borderTopLeftRadius: '5px',
                // borderTopRightRadius: '5px'
              },
            },
            cells: {
              style: {
                paddingLeft: '8px',
                paddingRight: '8px',
              },
            },
          }}
          conditionalRowStyles={[{
            when: row => row.hide === 'sm',
            style: {
                '@media (max-width: 768px)': {
                    display: 'none',
                },
            },
        }]}
        />
      </div>
    </CustomTableContainer>
  );
};

export default SubTableV2;
