import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt, faQuestionCircle, faCog } from '@fortawesome/free-solid-svg-icons'; 
import { useSelector } from 'react-redux';
import { useAuth } from './AuthContext';
import { Link } from 'react-router-dom';

function Header({ theme, handleLogout }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const { currentUser } = useAuth();  
  const isAdmin = currentUser && currentUser.isAdmin;
  const isContractAdmin = currentUser && currentUser.isContractAdmin;
  const isPolicyAdmin = currentUser && currentUser.isPolicyAdmin;

  const policies = useSelector((state) => state.policies.activePolicies);  
  const cardGroups = useSelector((state) => state.policies.cardGroups);  
  const [selectedPolicy, setSelectedPolicy] = useState(currentUser ? currentUser.policyNumber : null);
  const [selectedCardGroup, setSelectedCardGroup] = useState(currentUser ? currentUser.cardGroupID : null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.cardGroupID) {
        setSelectedCardGroup(currentUser.cardGroupID);
        setSelectedPolicy(null);
      } else {
        setSelectedPolicy(currentUser.policyNumber || "");
        setSelectedCardGroup(null);
      }
    }
  }, [currentUser?.policyNumber, currentUser?.cardGroupID]);

  const handlePolicyChange = async (e) => {
    const newSelectedValue = e.target.value;

    const selectedCardGroup = cardGroups.find(group => group.AccountNumber.toString() === newSelectedValue);
    const selectedPolicy = selectedCardGroup 
      ? policies.find(policy => policy.PolicyNumber === selectedCardGroup.PolicyNumber) 
      : policies.find(policy => policy.PolicyNumber.toString() === newSelectedValue);

    const policyNumber = selectedPolicy ? selectedPolicy.PolicyNumber : null;
    const cardGroupID = selectedCardGroup ? selectedCardGroup.cardGroupID : null;
    // setSelectedPolicy(selectedPolicy ? selectedPolicy.PolicyNumber : null);
    // setSelectedCardGroup(selectedCardGroup ? selectedCardGroup.cardGroupID : null);

    if (policyNumber === null) {
      console.error('No valid policy number selected');
      return;
    }

    setSelectedPolicy(policyNumber);
    setSelectedCardGroup(cardGroupID);

    try {
        const response = await fetch(`${apiUrl}/dataServer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                action: 'updateAdminPolicyAndCardGroup',
                payload: {
                    userId: currentUser.userId,
                    selectedPolicy: selectedPolicy ? selectedPolicy.PolicyNumber : null,
                    selectedCardGroup: selectedCardGroup ? selectedCardGroup.cardGroupID : null,
                },
            }),
        });

        if (response.ok) {
            window.location.reload();
        } else {
            console.error('Failed to update Policy Number');
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
  };

  return (
    <div className="flex justify-between items-center p-4 hidden-xs" style={{
      boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'rgba(255,255,255,0.9)',
      zIndex: 2
    }}>
      <div className="text-2xl font-bold" style={{ color: theme.menuText }}>
        {isAdmin || isContractAdmin || isPolicyAdmin ? (
          <div className="flex items-center">
            <label className="mr-2 text-sm font-medium text-gray-700">Account:</label>
            <select
              value={selectedCardGroup ? cardGroups.find(group => group.cardGroupID === selectedCardGroup)?.AccountNumber : selectedPolicy}
              onChange={handlePolicyChange}
              className="bg-gray-100 rounded-md p-2"
              disabled={!(policies.length > 0 || cardGroups.length > 0)}
            >
              {(policies.length > 0 || cardGroups.length > 0) ? (
                <>
                  <option value="">Select a policy</option>
                  {policies.map((policy) => (
                            <option key={policy.ID} value={policy.PolicyNumber}>
                                {isPolicyAdmin ? policy.CompanyName || policy.Description : `${policy.PolicyNumber} - ${policy.CompanyName || policy.Description}`}
                            </option>
                        ))}
                  {cardGroups.map((group) => (
                    <option key={group.cardGroupID} value={group.AccountNumber}>
                      {group.groupName} ({group.AccountNumber})
                    </option>
                  ))}
                </>
              ) : (
                <option value="" disabled>No Active Policies or Card Groups</option>
              )}
            </select>
          </div>
        ) : (
          currentUser ? currentUser.companyName : ""
        )}
      </div>

      <div className="relative">
        <FontAwesomeIcon icon={faBars} onClick={toggleMenu} className="text-lg cursor-pointer" />
        {menuOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg">
            <ul>
              <li className="cursor-pointer hover:bg-gray-200 p-2">
                <Link to="/faq" className="flex items-center" onClick={() => setMenuOpen(false)}>
                  <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                  FAQ
                </Link>
              </li>
              <li className="cursor-pointer hover:bg-gray-200 p-2">
                <Link to="/settings" className="flex items-center" onClick={() => setMenuOpen(false)}>
                  <FontAwesomeIcon icon={faCog} className="mr-2" />
                  Settings
                </Link>
              </li>
              <li className="cursor-pointer hover:bg-gray-200 p-2" onClick={() => { handleLogout(); setMenuOpen(false); }}>
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
